import * as React from 'react';
import createMuiIcon from '../createMuiIcon';

/**
 * @deprecated This Material UI icon is deprecated. Please use an icon from our design system instead.
 * Contact the design team for the appropriate replacement icon.
 */
export default createMuiIcon(
    <path d="M22 2H2.01L2 22l4-4h16V2zM6 9h12v2H6V9zm8 5H6v-2h8v2zm4-6H6V6h12v2z" />,
    'ChatSharp'
);
