import React from 'react';
import styles from './Card.module.scss';
import cx from 'classnames';
import { motion } from 'framer-motion';

enum CardStyles {
    DEFAULT,
    SQUARE
}

enum CardShadow {
    DEFAULT,
    NONE,
    SMALL
}

type CardProps = React.PropsWithChildren<{
    className?: string;
    testId?: string;
    style?: CardStyles;
    shadow?: CardShadow;
    animate?: boolean;
    [rest: string]: unknown;
}>;

const Card = React.forwardRef<HTMLDivElement, CardProps>(
    (
        {
            className,
            testId,
            children,
            style = CardStyles.DEFAULT,
            shadow = CardShadow.DEFAULT,
            animate = true,
            ...rest
        },
        ref
    ) => {
        const ComponentToRender = animate ? motion.div : 'div';
        return (
            <ComponentToRender
                ref={ref}
                layout="size"
                className={cx(styles.card, className, {
                    [styles.square]: style === CardStyles.SQUARE,
                    [styles.noShadow]: shadow === CardShadow.NONE,
                    [styles.smallShadow]: shadow === CardShadow.SMALL
                })}
                data-test={testId}
                {...rest}
            >
                {children}
            </ComponentToRender>
        );
    }
);

export { CardStyles, CardShadow };
export default Card;
