import React, { memo, useEffect, useImperativeHandle, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import { getEmergencyContactList } from '../../../../actions/emergencyContacts';
import ContactServiceGroup from '../../../../components/lease/mainScreen/common/ContactServiceGroup';

import '../../../../sass/contacts.scss';
import { ReactComponent as plusIcon } from '../../../../../assets/images/icons/plus.svg';
import { forwardRef } from 'react';
import { getEmergencyContacts } from '../../../../selectors/contacts';
import { getLocation, getNominatedRepairers, getLeaseType, getStep, getDirtyStep } from '../../../../selectors/lease';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../../actions/lease';
import axios from 'axios';
import * as Lease from '../../../../reducers/lease';
import { has } from 'lodash';
import { NO_LABEL, YES_LABEL } from '../../../../config';
import { FormRadioGroup } from '../../../../components/form/FormRadioGroup';
import { FormTextRegular } from '../../../../components/form/FormText';
const NOMINATED_REPAIRS_FORM = 'nominatedRepairersForm';

const NominatedRepairers = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const reduxLeaseNominatedRepairers = useSelector(getNominatedRepairers);
    const reduxSettingsEmergencyContacts = useSelector(getEmergencyContacts);

    let [leaseContacts, setLeaseContacts] = useState(reduxLeaseNominatedRepairers);
    let [addModal, setAddModal] = useState(false);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(NOMINATED_REPAIRS_FORM).dispatchEvent(
                new Event('submit', {
                    cancelable: true,
                    bubbles: true
                })
            );
        }
    }));

    useEffect(() => {
        dispatch(getEmergencyContactList());
    }, []);

    const getContactListForSelectNew = emergencyContacts => {
        return emergencyContacts.filter(item => {
            if (!leaseContacts || !leaseContacts.contacts) {
                return true;
            }
            return undefined === leaseContacts.contacts.find(contact => contact.tradeType === item.tradeType);
        });
    };

    const toggleAddModal = values => {
        const contactList = getContactListForSelectNew(reduxSettingsEmergencyContacts);
        if (contactList.length === 0) {
            addPerson({ tradeType: 'Custom' }, values);
            setAddModal(false);
        } else {
            setAddModal(!addModal);
        }
    };

    const addPerson = (newContact, values) => {
        const contactInfo = {
            tradeType: '',
            firstName: '',
            secondName: '',
            phone: '',
            company: '',
            extraContacts: [],
            ...newContact
        };

        setAddModal(!addModal);
        setLeaseContacts({ ...values, contacts: [...values.contacts, contactInfo] });
        if (dirtyStep !== step) {
            dispatch(setDirtyStep(step));
        }
    };

    let contactListForSelectNew = getContactListForSelectNew(reduxSettingsEmergencyContacts);

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const updateEmergencyContacts = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/nominated-repairers`, data);
    };

    const submitForm = values => {
        /**
         * Store the ref of the component so it can be used in this function
         * We need this otherwise the promise below doesn't have access to the ref
         */
        const currentRef = ref.current;
        /**
         * Grab the bypassFormValidation that was set from the parent component: LeaseAgreementForm.js
         */
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updateEmergencyContacts(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_NOMINATED_REPAIRERS_SUCCESS)).then(() => {
                    /**
                     * Callback after submit this form so that the parent component can take an action
                     */
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.nominatedRepairers')) {
                    return error.response.data.errors.nominatedRepairers;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    function removeContactPerson(fields, index, values) {
        fields.remove(index);
        const clonedContacts = [...values.contacts];
        clonedContacts.splice(index, 1);
        setLeaseContacts({ ...values, contacts: clonedContacts });
    }

    return (
        <div className="contacts">
            <Form
                onSubmit={submitForm}
                initialValues={leaseContacts}
                mutators={{
                    ...arrayMutators
                }}
            >
                {({
                    handleSubmit,
                    form,
                    form: {
                        mutators: { push }
                    },
                    values
                }) => {
                    return (
                        <form id={NOMINATED_REPAIRS_FORM} onSubmit={handleSubmit} noValidate>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <FormRadioGroup
                                label="Are the nominated repairers the tenant's first point of contact for notifying the need for emergency repairs?"
                                name="details.isNominatedRepairers"
                                data={[
                                    {
                                        label: YES_LABEL,
                                        value: YES_LABEL
                                    },
                                    {
                                        label: NO_LABEL,
                                        value: NO_LABEL
                                    }
                                ]}
                            />
                            {values?.details?.isNominatedRepairers === NO_LABEL && (
                                <>
                                    <FormTextRegular name="details.lessorName" label="Lessor Name" required />
                                    <FormTextRegular name="details.lessorPhone" label="Lessor Phone" required />
                                </>
                            )}
                            <p>Nominated tradespeople for urgent repairs:</p>
                            <FieldArray name="contacts" initialValue={leaseContacts.contacts}>
                                {({ fields }) => (
                                    <React.Fragment>
                                        {fields.map((name, index) => {
                                            return (
                                                <ContactServiceGroup
                                                    push={push}
                                                    key={index}
                                                    location={location}
                                                    index={index}
                                                    contact={fields.value[index]}
                                                    fieldName="contacts"
                                                    removeContactPerson={() =>
                                                        removeContactPerson(fields, index, values)
                                                    }
                                                />
                                            );
                                        })}
                                    </React.Fragment>
                                )}
                            </FieldArray>
                            <div className="button">
                                <button
                                    type="button"
                                    className={'mobile add-item test-add-contact'}
                                    onClick={() => toggleAddModal(values)}
                                >
                                    <img src={plusIcon} className="for-sm-modal" />
                                    Add
                                </button>
                                <div className={addModal ? 'addModal' : 'addModal hide'}>
                                    <div className="choose-items">
                                        {!!contactListForSelectNew.length &&
                                            contactListForSelectNew.map((newContactType, index) => (
                                                <div
                                                    key={index}
                                                    className="choose"
                                                    onClick={() => addPerson(newContactType, values)}
                                                >
                                                    {newContactType.tradeType}
                                                </div>
                                            ))}
                                        <div
                                            className="choose"
                                            onClick={() => addPerson({ tradeType: 'Custom' }, values)}
                                        >
                                            Custom
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(NominatedRepairers);
