import React, { useEffect, useState } from 'react';
import { FormMaskedText } from '@app/components/form/FormMaskedText';
import { FormTextRegular } from '../../../form/FormText';
import { CheckboxCheck } from '../../../form/FormCheckboxCheck';
import { VIC_STATE, NSW_STATE, SA_STATE, QLD_STATE } from '../../../../config';
import { FormRadioGroup } from '../../../form/FormRadioGroup';
import * as PropTypes from 'prop-types';
import { getResTenLabel } from '../../../../utils/labelUtils';
import SuggestionTextField from '../../../form/SuggestionTextField';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { SUGGESTIONS_LANDLORD_CONTACT_DETAILS_PROPERTY_ME } from '../../../../constants/featureFlags';

const LandlordOutsideOfNSW = ({ person, location, index }) => (
    <React.Fragment>
        {location === NSW_STATE && (
            <React.Fragment>
                <FormRadioGroup
                    label="The landlord resides in "
                    name={`persons[${index}].theLandlordResidesIn`}
                    className="outside-of-nsw-radio-group"
                    data={[
                        {
                            label: 'NSW',
                            className: 'the-landlord-resides-in-nsw',
                            id: `the-landlord-resides-in-${index}-nsw`,
                            value: 'NSW'
                        },
                        {
                            label: 'Other',
                            className: 'the-landlord-resides-in-other',
                            id: `the-landlord-resides-in-${index}-other`,
                            value: 'other'
                        }
                    ]}
                />
                {person.theLandlordResidesIn !== null && person.theLandlordResidesIn === 'other' && (
                    <div className="additional-group">
                        <div className="formBox-column">
                            <FormTextRegular
                                name={`persons[${index}].otherResidence`}
                                label="The state, territory or country the landlord resides in (e.g QLD, Singapore)"
                            />
                        </div>
                    </div>
                )}
            </React.Fragment>
        )}
    </React.Fragment>
);

LandlordOutsideOfNSW.propTypes = {
    location: PropTypes.any,
    person: PropTypes.any,
    index: PropTypes.any
};
export const LandlordPersonCorporationGroup = ({ index, person, removePerson, form, location }) => {
    const [isCorporation, setIsCorporation] = useState(person.isCorporation);

    const isSuggestionsLandLordContactDetailsActive = useFeatureFlag(SUGGESTIONS_LANDLORD_CONTACT_DETAILS_PROPERTY_ME);

    useEffect(() => {
        setIsCorporation(person.isCorporation);
    }, [person]);

    let header;
    if (index === 0) {
        header = (
            <h3 className="person-header">
                {getResTenLabel('landlord', location)} {index + 1}
            </h3>
        );
    } else {
        header = (
            <span className="removePerson">
                <h3>
                    {getResTenLabel('landlord', location)} {index + 1}
                </h3>
                <button type="button" onClick={() => removePerson(index)}>
                    <span className="span-remove">Remove</span>
                </button>
            </span>
        );
    }

    function clearPerson(index) {
        form.change(`persons[${index}].phone`, '');
        form.change(`persons[${index}].other`, '');
        form.change(`persons[${index}].corporationName`, '');
        form.change(`persons[${index}].corporationAddress`, '');
        form.change(`persons[${index}].firstName`, '');
        form.change(`persons[${index}].middleName`, '');
        form.change(`persons[${index}].secondName`, '');
        form.change(`persons[${index}].address`, '');
        form.change(`persons[${index}].ABN`, '');
        form.change(`persons[${index}].ACN`, '');
    }

    const setCorporation = (value, index) => {
        setIsCorporation(value);
        clearPerson(index);
    };

    const getInputFields = () => {
        if (isCorporation) {
            return (
                <React.Fragment>
                    <FormTextRegular name={`persons[${index}].corporationName`} label="Corporation name" required />
                    {[NSW_STATE, QLD_STATE, SA_STATE].includes(location) && (
                        <React.Fragment>
                            <FormTextRegular
                                name={`persons[${index}].corporationAddress`}
                                label={getResTenLabel('landlordCorporationAddress', location)}
                                required
                            />
                            <FormMaskedText name={`persons[${index}].ABN`} label="ABN" mask="11 111 111 111" />
                            <FormMaskedText name={`persons[${index}].ACN`} label="ACN" mask="111 111 111" />
                        </React.Fragment>
                    )}
                    {location === VIC_STATE && <FormTextRegular name={`persons[${index}].ACN`} label="ACN" required />}
                    {[NSW_STATE, QLD_STATE].includes(location) && (
                        <FormTextRegular name={`persons[${index}].other`} label="Other" />
                    )}
                    <LandlordOutsideOfNSW location={location} person={person} index={index} />
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    {location === SA_STATE && (
                        <React.Fragment>
                            <div className="nowrap">
                                <FormTextRegular name={`persons[${index}].firstName`} label="First Name" required />
                                <FormTextRegular name={`persons[${index}].middleName`} label="Middle Name" />
                            </div>
                            <FormTextRegular name={`persons[${index}].secondName`} label="Last Name" required />
                        </React.Fragment>
                    )}
                    {location !== SA_STATE && (
                        <React.Fragment>
                            <FormTextRegular name={`persons[${index}].firstName`} label="First Name" required />
                            <FormTextRegular name={`persons[${index}].secondName`} label="Last Name" required />
                        </React.Fragment>
                    )}
                    {location === NSW_STATE && (
                        <React.Fragment>
                            {isSuggestionsLandLordContactDetailsActive ? (
                                <SuggestionTextField
                                    name={`persons[${index}].phone`}
                                    label={`Nominated ${getResTenLabel(
                                        'landlordLower',
                                        location
                                    )} contact details (these details must be provided)`}
                                    suggestionsFieldName={`landlords[${index}].contactDetails`}
                                    allowSelectMultipleSuggestions
                                />
                            ) : (
                                <FormTextRegular
                                    name={`persons[${index}].phone`}
                                    label={`Nominated ${getResTenLabel(
                                        'landlordLower',
                                        location
                                    )} contact details (these details must be provided)`}
                                />
                            )}
                        </React.Fragment>
                    )}

                    {[NSW_STATE, SA_STATE].includes(location) && (
                        <React.Fragment>
                            {isSuggestionsLandLordContactDetailsActive ? (
                                <SuggestionTextField
                                    name={`persons[${index}].address`}
                                    label={getResTenLabel('landlordAddress', location)}
                                    required={location === SA_STATE}
                                    suggestionsFieldName={
                                        location === SA_STATE
                                            ? `landlords[${index}].postalAddress`
                                            : `landlords[${index}].physicalAddress`
                                    }
                                />
                            ) : (
                                <FormTextRegular
                                    name={`persons[${index}].address`}
                                    label={getResTenLabel('landlordAddress', location)}
                                    required={location === SA_STATE}
                                />
                            )}
                        </React.Fragment>
                    )}
                    <LandlordOutsideOfNSW location={location} person={person} index={index} />
                </React.Fragment>
            );
        }
    };

    return (
        <div className="person">
            {header}
            <div className="formBox">
                <CheckboxCheck
                    name={`persons[${index}].isCorporation`}
                    className="is-corporation"
                    label={`${getResTenLabel('landlord', location)} is a corporation`}
                    value={`persons[${index}].isCorporation`}
                    onClick={value => setCorporation(value, index)}
                />
                {getInputFields()}
            </div>
        </div>
    );
};
