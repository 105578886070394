import React, { memo, useState, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { has } from 'lodash';

import * as Lease from '../../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../../selectors/lease';
import { getPmRentAndTerm } from '../../../../selectors/lease/pmLease';

import {
    DEFAULT_NUMBER_OF_WEEKS_FOR_PROPERTY_MANAGEMENT_BOND,
    PERIOD_WEEK,
    propertyManagementRentPeriods
} from '../../../../config';
import { FormTextRegular } from '../../../../components/form/FormText';

import '../../../../sass/management/rentAndTerm.scss';
import { SelectField } from '../../../../components/form/FormSelect';

const PM_RENT_TERM_FORM = 'pmRentAndTermForm';
const initState = {
    rent: '$',
    payablePer: PERIOD_WEEK,
    advertisedRent: '$',
    advertisedRentPayablePer: PERIOD_WEEK
};

const RentTerm = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxPmRentAndTerm = useSelector(getPmRentAndTerm);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [pmRentAndTerm, setPmRentAndTerm] = useState(reduxPmRentAndTerm || initState);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(PM_RENT_TERM_FORM).dispatchEvent(
                new Event('submit', {
                    cancelable: true,
                    bubbles: true
                })
            );
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return axios
            .post(`/api/agency/lease/${props.leaseId}/pm-rent-and-term`, values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.pmRentAndTerm')) {
                    return error.response.data.errors.pmRentAndTerm;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    return (
        <div className="form-container rent rent-term">
            <Form onSubmit={submitForm} initialValues={pmRentAndTerm}>
                {({ handleSubmit, values, form }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={PM_RENT_TERM_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <FormTextRegular name="rent" label="Rental estimate" required />
                            <SelectField
                                name="payablePer"
                                options={propertyManagementRentPeriods}
                                value={values.payablePer}
                                label="Payable per"
                                required
                            />
                            <FormTextRegular name="advertisedRent" label="Advertised rental price" required />
                            <SelectField
                                name="advertisedRentPayablePer"
                                options={propertyManagementRentPeriods}
                                value={values.payablePer}
                                label="Payable per"
                                required
                            />
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(RentTerm);
