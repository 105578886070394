import React from 'react';
import { FormMaskedText } from '@app/components/form/FormMaskedText';
import { FormTextRegular } from '../../../form/FormText';
import { CheckboxCheck } from '../../../form/FormCheckboxCheck';
import { values } from 'lodash';

export const LandlordSuperiorTitlesGroup = ({ index, removePerson, values }) => {
    let header;
    if (index === 0) {
        header = <h3>Person {index + 1} with superior title to the landlord</h3>;
    } else {
        header = (
            <span className="removePerson">
                <h3>Person {index + 1} with superior title to the landlord</h3>
                <button type="button" onClick={() => removePerson(index)}>
                    <span className="span-remove">Remove</span>
                </button>
            </span>
        );
    }

    return (
        <div className="person">
            {header}
            <div className="formBox">
                <CheckboxCheck
                    name={`superiorTitles[${index}].isCorporation`}
                    className="is-corporation"
                    label="Person with superior title to the Landlord is a corporation"
                    value={`superiorTitles[${index}].isCorporation`}
                />
                {!values.superiorTitles[index].isCorporation && (
                    <React.Fragment>
                        <div className="nowrap margin-top-10">
                            <FormTextRegular name={`superiorTitles[${index}].firstName`} label="First Name" required />
                            <FormTextRegular name={`superiorTitles[${index}].middleName`} label="Middle Name" />
                        </div>
                        <FormTextRegular name={`superiorTitles[${index}].secondName`} label="Last Name" required />
                    </React.Fragment>
                )}
                {values.superiorTitles[index].isCorporation && (
                    <React.Fragment>
                        <FormTextRegular
                            name={`superiorTitles[${index}].corporationName`}
                            label="Corporation name"
                            required
                        />
                        <FormMaskedText name={`superiorTitles[${index}].ABN`} label="ABN" mask="11 111 111 111" />
                        <FormMaskedText name={`superiorTitles[${index}].ACN`} label="ACN" mask="111 111 111" />
                    </React.Fragment>
                )}
                <FormTextRegular
                    name={`superiorTitles[${index}].address`}
                    label="Address for service of documents (which cannot be the Agent's address for service)"
                    required
                />
            </div>
        </div>
    );
};
