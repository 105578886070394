import React from 'react';
import ExpandMoreIcon from '@flk-mui-icons/ExpandMore';
import {
    MuiAccordion,
    MuiAccordionSummary,
    MuiAccordionDetails
} from '@app/common/components/mui-accordion/MuiAccordion';
import parse from 'html-react-parser';

const ANSWER_VALUE_TRUE = 'true';

const LABEL_YES = 'Yes';
const LABEL_NO = 'No';

const AgreementSalesClientInstructions = props => {
    const { leaseAllInfo } = props;

    return (
        <div>
            {leaseAllInfo &&
                leaseAllInfo.signatory &&
                leaseAllInfo.signatory.salesAnswersSummary &&
                leaseAllInfo.signatory.primaryVendor && (
                    <div className="section client-answers-section">
                        <h2>Client instructions</h2>
                        <p>Has the vendor...</p>
                        <br />
                        <MuiAccordion
                            className={
                                leaseAllInfo.signatory.salesAnswersSummary.hasMaterialFacts === LABEL_YES
                                    ? ''
                                    : 'fixed-accordian'
                            }
                        >
                            <MuiAccordionSummary
                                expandIcon={
                                    leaseAllInfo.signatory.salesAnswersSummary.hasMaterialFacts === LABEL_YES && (
                                        <ExpandMoreIcon />
                                    )
                                }
                                aria-controls="material-content"
                                id="material-header"
                            >
                                Declared a material fact? &nbsp;
                                <b>{leaseAllInfo.signatory.salesAnswersSummary.hasMaterialFacts}</b>
                            </MuiAccordionSummary>
                            <MuiAccordionDetails>
                                <div>
                                    {leaseAllInfo.signatory.primaryVendor.salesMaterialFactAnswers &&
                                        leaseAllInfo.signatory.primaryVendor.salesMaterialFactAnswers.questions.map(
                                            (fact, index) => {
                                                if (fact.parentQuestion) {
                                                    if (fact.parentQuestion.answer === ANSWER_VALUE_TRUE) {
                                                        return (
                                                            <div key={index}>
                                                                {fact.questionId} {parse(fact.questionText)}{' '}
                                                                <b>
                                                                    {fact.answer === ANSWER_VALUE_TRUE
                                                                        ? LABEL_YES
                                                                        : LABEL_NO}
                                                                </b>
                                                                <p>
                                                                    <i>{fact.details}</i>
                                                                </p>
                                                                <br />
                                                            </div>
                                                        );
                                                    }
                                                } else {
                                                    if (fact.answer === ANSWER_VALUE_TRUE) {
                                                        return (
                                                            <div key={index}>
                                                                {fact.questionId} {parse(fact.questionText)}{' '}
                                                                <b>
                                                                    {fact.answer === ANSWER_VALUE_TRUE
                                                                        ? LABEL_YES
                                                                        : LABEL_NO}
                                                                </b>
                                                                <p>
                                                                    <i>{fact.details}</i>
                                                                </p>
                                                                <br />
                                                            </div>
                                                        );
                                                    }
                                                }
                                            }
                                        )}
                                </div>
                            </MuiAccordionDetails>
                        </MuiAccordion>
                    </div>
                )}
        </div>
    );
};

export default AgreementSalesClientInstructions;
