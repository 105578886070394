import React, { memo, useEffect, useImperativeHandle, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import { getEmergencyContactList } from '../../../../actions/emergencyContacts';
import { FormRadioGroup } from '../../../../components/form/FormRadioGroup';
import ContactServiceGroup from '../../../../components/lease/mainScreen/common/ContactServiceGroup';
import '../../../../sass/contacts.scss';
import { ReactComponent as plusIcon } from '../../../../../assets/images/icons/plus.svg';
import { forwardRef } from 'react';
import { getEmergencyContacts } from '../../../../selectors/contacts';
import {
    getLocation,
    getRepairsAndMaintenance,
    getLeaseType,
    getStep,
    getDirtyStep
} from '../../../../selectors/lease';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../../actions/lease';
import axios from 'axios';
import * as Lease from '../../../../reducers/lease';
import { has } from 'lodash';
import { CONTACT_PERSON_NOMINATED_TRADES, CONTACT_PERSON_AGENT } from '../../../../config';
const REPAIRS_AND_MAINTENANCE_FORM = 'repairsAndMaintenanceForm';

const RepairsAndMaintenance = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const reduxRepairsAndMaintenance = useSelector(getRepairsAndMaintenance);
    const reduxSettingsEmergencyContacts = useSelector(getEmergencyContacts);

    let [leaseContacts, setLeaseContacts] = useState(reduxRepairsAndMaintenance);
    let [addModal, setAddModal] = useState(false);
    let [addBusinessHoursModal, setAddBusinessHoursModal] = useState(false);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(REPAIRS_AND_MAINTENANCE_FORM).dispatchEvent(
                new Event('submit', {
                    cancelable: true,
                    bubbles: true
                })
            );
        }
    }));

    useEffect(() => {
        dispatch(getEmergencyContactList());
    }, []);

    const getContactListForSelectNew = (emergencyContacts, isBusinessHours) => {
        return emergencyContacts.filter(item => {
            if (isBusinessHours) {
                if (!leaseContacts || !leaseContacts.businessHoursContacts) {
                    return true;
                }
                return (
                    leaseContacts.businessHoursContacts.find(contact => contact.tradeType === item.tradeType) ===
                    undefined
                );
            } else {
                if (!leaseContacts || !leaseContacts.contacts) {
                    return true;
                }
                return leaseContacts.contacts.find(contact => contact.tradeType === item.tradeType) === undefined;
            }
        });
    };

    const toggleAddModal = (values, isBusinessHours) => {
        const contactList = getContactListForSelectNew(reduxSettingsEmergencyContacts, isBusinessHours);
        if (contactList.length === 0) {
            addPerson({ tradeType: 'Custom' }, values, isBusinessHours);
            if (isBusinessHours) {
                setAddBusinessHoursModal(false);
            } else {
                setAddModal(false);
            }
        } else {
            if (isBusinessHours) {
                setAddBusinessHoursModal(!addBusinessHoursModal);
            } else {
                setAddModal(!addModal);
            }
        }
    };

    const addPerson = (newContact, values, isBusinessHours) => {
        const contactInfo = {
            tradeType: '',
            firstName: '',
            secondName: '',
            phone: '',
            company: '',
            extraContacts: [],
            ...newContact
        };

        if (isBusinessHours) {
            setAddBusinessHoursModal(!addBusinessHoursModal);
            setLeaseContacts({ ...values, businessHoursContacts: [...values.businessHoursContacts, contactInfo] });
        } else {
            setAddModal(!addModal);
            setLeaseContacts({ ...values, contacts: [...values.contacts, contactInfo] });
        }
        if (dirtyStep !== step) {
            dispatch(setDirtyStep(step));
        }
    };

    let contactListForSelectNew = getContactListForSelectNew(reduxSettingsEmergencyContacts);
    let businessHoursContactListForSelectNew = getContactListForSelectNew(reduxSettingsEmergencyContacts, true);

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const updateEmergencyContacts = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/repairs-and-maintenance`, data);
    };

    const submitForm = values => {
        /**
         * Store the ref of the component so it can be used in this function
         * We need this otherwise the promise below doesn't have access to the ref
         */
        const currentRef = ref.current;
        /**
         * Grab the bypassFormValidation that was set from the parent component: LeaseAgreementForm.js
         */
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updateEmergencyContacts(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_REPAIRS_AND_MAINTENANCE_SUCCESS)).then(() => {
                    /**
                     * Callback after submit this form so that the parent component can take an action
                     */
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.repairsAndMaintenance')) {
                    return { ...error.response.data.errors.repairsAndMaintenance };
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    function removeContactPerson(fields, index, values, isBusinessHours) {
        fields.remove(index);
        if (isBusinessHours) {
            const clonedContacts = [...values.businessHoursContacts];
            clonedContacts.splice(index, 1);
            setLeaseContacts({ ...values, businessHoursContacts: clonedContacts });
        } else {
            const clonedContacts = [...values.contacts];
            clonedContacts.splice(index, 1);
            setLeaseContacts({ ...values, contacts: clonedContacts });
        }
    }

    return (
        <div className="contacts">
            <Form
                onSubmit={submitForm}
                initialValues={leaseContacts}
                mutators={{
                    ...arrayMutators
                }}
            >
                {({
                    handleSubmit,
                    form,
                    form: {
                        mutators: { push }
                    },
                    values
                }) => {
                    return (
                        <form id={REPAIRS_AND_MAINTENANCE_FORM} onSubmit={handleSubmit} noValidate>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <FormRadioGroup
                                label="In circumstances where repairs and maintenance are required during business hours"
                                name="contactPersonDuringBusinessHours"
                                className="full-width"
                                data={[
                                    {
                                        label: 'Always contact Agent',
                                        value: CONTACT_PERSON_AGENT,
                                        id: CONTACT_PERSON_AGENT
                                    },
                                    {
                                        label: 'Contact the nominated trades',
                                        value: CONTACT_PERSON_NOMINATED_TRADES,
                                        id: CONTACT_PERSON_NOMINATED_TRADES
                                    }
                                ]}
                            />
                            {values.contactPersonDuringBusinessHours === CONTACT_PERSON_NOMINATED_TRADES && (
                                <React.Fragment>
                                    <p>Nominated tradespeople during business hours:</p>
                                    <FieldArray
                                        name="businessHoursContacts"
                                        initialValue={leaseContacts.businessHoursContacts}
                                    >
                                        {({ fields }) => (
                                            <React.Fragment>
                                                {fields.map((name, index) => {
                                                    return (
                                                        <ContactServiceGroup
                                                            push={push}
                                                            key={index}
                                                            location={location}
                                                            index={index}
                                                            contact={fields.value[index]}
                                                            fieldName="businessHoursContacts"
                                                            removeContactPerson={() =>
                                                                removeContactPerson(fields, index, values, true)
                                                            }
                                                        />
                                                    );
                                                })}
                                            </React.Fragment>
                                        )}
                                    </FieldArray>
                                    <div className="button">
                                        <button
                                            type="button"
                                            className={'mobile add-item add-business-hours-contact'}
                                            onClick={() => toggleAddModal(values, true)}
                                        >
                                            <img src={plusIcon} className="for-sm-modal" alt="Add new icon" />
                                            Add
                                        </button>
                                        <div className={addBusinessHoursModal ? 'addModal' : 'addModal hide'}>
                                            <div className="choose-items">
                                                {!!businessHoursContactListForSelectNew.length &&
                                                    businessHoursContactListForSelectNew.map(
                                                        (newContactType, index) => (
                                                            <div
                                                                key={index}
                                                                className="choose"
                                                                onClick={() => addPerson(newContactType, values, true)}
                                                            >
                                                                {newContactType.tradeType}
                                                            </div>
                                                        )
                                                    )}
                                                <div
                                                    className="choose"
                                                    onClick={() => addPerson({ tradeType: 'Custom' }, values, true)}
                                                >
                                                    Custom
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                            <p>In circumstances where repairs and maintenance are required after business hours</p>
                            <p>Contact the nominated tradespeople below:</p>
                            <FieldArray name="contacts" initialValue={leaseContacts.contacts}>
                                {({ fields }) => (
                                    <React.Fragment>
                                        {fields.map((name, index) => {
                                            return (
                                                <ContactServiceGroup
                                                    push={push}
                                                    key={index}
                                                    location={location}
                                                    index={index}
                                                    contact={fields.value[index]}
                                                    fieldName="contacts"
                                                    removeContactPerson={() =>
                                                        removeContactPerson(fields, index, values)
                                                    }
                                                />
                                            );
                                        })}
                                    </React.Fragment>
                                )}
                            </FieldArray>
                            <div className="button">
                                <button
                                    type="button"
                                    className={'mobile add-item add-contacts'}
                                    onClick={() => toggleAddModal(values)}
                                >
                                    <img src={plusIcon} className="for-sm-modal" alt="Add new icon" />
                                    Add
                                </button>
                                <div className={addModal ? 'addModal' : 'addModal hide'}>
                                    <div className="choose-items">
                                        {!!contactListForSelectNew.length &&
                                            contactListForSelectNew.map((newContactType, index) => (
                                                <div
                                                    key={index}
                                                    className="choose"
                                                    onClick={() => addPerson(newContactType, values)}
                                                >
                                                    {newContactType.tradeType}
                                                </div>
                                            ))}
                                        <div
                                            className="choose"
                                            onClick={() => addPerson({ tradeType: 'Custom' }, values)}
                                        >
                                            Custom
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(RepairsAndMaintenance);
