import { remove } from 'lodash';

import { getId } from '@app/utils/getId';

export const GET_AGENCY_TEAM_REQUEST = 'GET_AGENCY_TEAM_REQUEST';
export const GET_AGENCY_TEAM_SUCCESS = 'GET_AGENCY_TEAM_SUCCESS';
export const GET_AGENCY_TEAM_FAILURE = 'GET_AGENCY_TEAM_FAILURE';

export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';

export const CHECK_TOKEN_REQUEST = 'CHECK_TOKEN_REQUEST';
export const CHECK_TOKEN_SUCCESS = 'CHECK_TOKEN_SUCCESS';
export const CHECK_TOKEN_FAILURE = 'CHECK_TOKEN_FAILURE';

export const ACCEPT_USER_REQUEST = 'ACCEPT_USER_REQUEST';
export const ACCEPT_USER_SUCCESS = 'ACCEPT_USER_SUCCESS';
export const ACCEPT_USER_FAILURE = 'ACCEPT_USER_FAILURE';

export const OPEN_MODAL_TEAM = 'OPEN_MODAL_TEAM';
export const CLOSE_MODAL_TEAM = 'CLOSE_MODAL_TEAM';

export const UPDATE_TEAM_USER_INFO_SUCCESS = 'UPDATE_TEAM_USER_INFO_SUCCESS';
export const UPDATE_TEAM_USER_INFO_FAILURE = 'UPDATE_TEAM_USER_INFO_FAILURE';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const BLOCK_USER_REQUEST = 'BLOCK_USER_REQUEST';
export const BLOCK_USER_FAILURE = 'BLOCK_USER_FAILURE';

export const UNBLOCK_USER_REQUEST = 'UNBLOCK_USER_REQUEST';
export const UNBLOCK_USER_SUCCESS = 'UNBLOCK_USER_SUCCESS';
export const UNBLOCK_USER_FAILURE = 'UNBLOCK_USER_FAILURE';

export const CANCEL_INVITATION_REQUEST = 'CANCEL_INVITATION_REQUEST';
export const CANCEL_INVITATION_SUCCESS = 'CANCEL_INVITATION_SUCCESS';
export const CANCEL_INVITATION_FAILURE = 'CANCEL_INVITATION_FAILURE';

export const REPLACE_INVITATION = 'REPLACE_INVITATION';

const initState = {
    //Error during add user
    team: [],
    invitations: [],
    deletedUsers: [],
    checkTokenStatus: false,
    checkTokenStatusRequesting: false,
    checkTokenError: '',
    acceptUserStatus: false,
    acceptUserinfo: {},
    isShowModalTeam: false,
    user: {},
    agency: {},
    inviting: {},
    isEditingTeamUser: false,
    editingTeamUserSuccess: null
};

export default function joinTeam(state = initState, action) {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case GET_AGENCY_TEAM_SUCCESS: {
            newState.team = action.result.data.team;
            newState.invitations = action.result.data.invitations || [];
            newState.deletedUsers = action.result.data.deletedUsers || [];
            break;
        }
        case ADD_USER_SUCCESS: {
            let invitations = [...state.invitations];

            invitations.push(action.payload.data.invite);
            newState.isShowModalTeam = false;
            newState.invitations = invitations;

            break;
        }

        case CHECK_TOKEN_REQUEST: {
            newState.checkTokenStatusRequesting = true;
            break;
        }
        case CHECK_TOKEN_SUCCESS: {
            newState.checkTokenStatusRequesting = false;
            newState.checkTokenStatus = true;
            newState.agency = action.result.data.invite.agency;
            newState.invite = action.result.data.invite;
            newState.user = action.result.data.invite.user;
            newState.inviting = action.result.data.invite.inviting;
            break;
        }
        case CHECK_TOKEN_FAILURE: {
            newState.checkTokenStatusRequesting = false;
            newState.checkTokenStatus = false;
            if (
                action.error &&
                action.error.response &&
                action.error.response.data &&
                action.error.response.data.error
            ) {
                newState.checkTokenError = action.error.response.data.error;
            }
            break;
        }

        case ACCEPT_USER_REQUEST: {
            newState.acceptUserStatus = false;
            newState.acceptUserinfo = {};
            break;
        }
        case ACCEPT_USER_SUCCESS: {
            newState.acceptUserStatus = true;
            newState.acceptUserinfo = action.result.data;
            break;
        }
        case ACCEPT_USER_FAILURE: {
            newState.acceptUserStatus = false;
            newState.acceptUserinfo = {};
            break;
        }

        case OPEN_MODAL_TEAM: {
            newState.isShowModalTeam = true;
            newState.isEditingTeamUser = true;
            newState.editingTeamUserSuccess = false;
            break;
        }
        case CLOSE_MODAL_TEAM: {
            newState.isShowModalTeam = false;
            break;
        }
        case BLOCK_USER_REQUEST: {
            newState.isEditingTeamUser = true;
            break;
        }
        case UNBLOCK_USER_REQUEST: {
            newState.isEditingTeamUser = true;
            break;
        }
        case UPDATE_TEAM_USER_INFO_SUCCESS: {
            newState.isEditingTeamUser = false;
            newState.editingTeamUserSuccess = true;
            newState.isShowModalTeam = false;
            newState.team = [];
            newState.invitations = [];
            break;
        }
        case DELETE_USER_SUCCESS: {
            newState.team = action.result.data.team;
            newState.isShowModalTeam = false;
            break;
        }
        case REPLACE_INVITATION: {
            let invitations = [...state.invitations];
            remove(invitations, invitation => getId(invitation) === action.data.oldInvitationId);
            if (action.data.invitation) {
                invitations.push(action.data.invitation);
            }
            newState.invitations = invitations;
            break;
        }
        case CANCEL_INVITATION_SUCCESS: {
            break;
        }
    }

    return newState;
}
