import React, { memo, useImperativeHandle, useState, forwardRef } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { FormTextRegular, FormNumber } from '../../../../components/form/FormText';
import { CheckboxCheck } from '../../../../components/form/FormCheckboxCheck';
import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../../actions/lease';
import { useDispatch, useSelector } from 'react-redux';
import { getDirtyStep, getLeaseType, getStep, getOutgoings } from '../../../../selectors/lease';
import * as Lease from '../../../../reducers/lease';
import axios from 'axios';
import { has } from 'lodash';
import { FormRadioGroup } from '../../../../components/form/FormRadioGroup';
import { NO_LABEL, YES_LABEL } from '../../../../config';
const OUTGOINGS_FORM = 'outgoingsForm';

const Outgoings = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);
    const leaseType = useSelector(getLeaseType);
    const reduxOutgoings = useSelector(getOutgoings);

    let [formState, setFormState] = useState(reduxOutgoings);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(OUTGOINGS_FORM).dispatchEvent(
                new Event('submit', {
                    cancelable: true,
                    bubbles: true
                })
            );
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const updateOutgoings = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/outgoings`, data);
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updateOutgoings(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_OUTGOINGS_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.outgoings')) {
                    return error.response.data.errors.outgoings;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    return (
        <div className="outgoings">
            <Form
                onSubmit={submitForm}
                initialValues={formState}
                mutators={{
                    ...arrayMutators
                }}
            >
                {({
                    handleSubmit,
                    form,
                    values,
                    form: {
                        mutators: { push }
                    }
                }) => {
                    return (
                        <form id={OUTGOINGS_FORM} onSubmit={handleSubmit} noValidate>
                            <p className="text">
                                The tenants are required to pay water chargers and usage as details below
                            </p>
                            <br />
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <CheckboxCheck
                                className="wrap"
                                name="isWaterUsageCostAdjusted"
                                label="All water usage costs adjusted for the period of tenancy"
                            />
                            <CheckboxCheck
                                className="wrap"
                                name="isWaterUsageCostExcess"
                                label="All water usage costs in excess of [___]kl per annum, with such allowance to be adjusted for the period of the tenancy"
                            />
                            {values?.isWaterUsageCostExcess === true && (
                                <FormNumber name="klPerAnnum" label="kl per annum" required />
                            )}
                            <CheckboxCheck
                                className="wrap"
                                name="isWaterSupplyCostAdjusted"
                                label="All water supply charges adjusted for the period of the tenancy"
                            />
                            <CheckboxCheck className="wrap" name="isNoCharges" label="No charge for water" />
                            <CheckboxCheck className="wrap" name="isOthers" label="Other (specify)" />
                            {values?.isOthers === true && (
                                <FormTextRegular name="otherDetails" label="Other" required />
                            )}
                            <p className="note">
                                If the Property is not separately metered for a service, the tenant will pay an
                                apportionment of the cost of the service, as set out below, where any services are in
                                the name of the landlord (see section 73 of the Residential Tenancies Act 1995 (SA)).
                            </p>
                            <FieldArray name="servicesList" initialValue={formState.servicesList}>
                                {({ fields }) => (
                                    <React.Fragment>
                                        {fields.map((name, index) => {
                                            return (
                                                <div key={index} className="item-condition">
                                                    <div className="clauses">
                                                        <div className="clauses-header">
                                                            <h3>Service/Apportionment {index + 1}</h3>
                                                            <div
                                                                className="toggle"
                                                                onClick={() => fields.remove(index)}
                                                            >
                                                                <span className="span-remove">Remove</span>
                                                            </div>
                                                        </div>
                                                        <div className="formBox-column">
                                                            <FormTextRegular
                                                                name={`servicesList[${index}].service`}
                                                                label="Service"
                                                                required
                                                            />
                                                            <FormTextRegular
                                                                name={`servicesList[${index}].apportionment`}
                                                                label="Apportionment"
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </React.Fragment>
                                )}
                            </FieldArray>
                            <div className="button add-item-button-container">
                                <button
                                    className="mobile add-item"
                                    type="button"
                                    onClick={() => {
                                        push('servicesList', {
                                            service: '',
                                            apportionment: ''
                                        });
                                    }}
                                >
                                    Add Service / Apportionment
                                </button>
                            </div>
                            <h4>Details of embedded electricity network</h4>
                            <FormRadioGroup
                                label="Is electricity supplied to the property via an embedded network?"
                                name="isElectricitySupplied"
                                data={[
                                    {
                                        label: YES_LABEL,
                                        value: YES_LABEL
                                    },
                                    {
                                        label: NO_LABEL,
                                        value: NO_LABEL
                                    }
                                ]}
                            />
                            {values?.isElectricitySupplied === YES_LABEL && (
                                <>
                                    <FormTextRegular
                                        name="retailerDetails"
                                        label="Details of the retailer of the embedded network (please include the contact information, ABN, website and any applicable electricity tariffs)"
                                        required
                                    />
                                    <FormTextRegular
                                        name="natureAndBenefits"
                                        label="Information about the nature and benefits of participating in an embedded network (and any potential consequences)"
                                        required
                                    />
                                    <FormTextRegular
                                        name="meteringArrangements"
                                        label="Information about metering arrangements in relation to the embedded network and any potential costs of participating in the embedded network"
                                        required
                                    />
                                    <FormTextRegular
                                        name="costApportionments"
                                        label="Cost apportionments per kilowatt hour for any bundled utilities arising from participating in the embedded network"
                                        required
                                    />
                                </>
                            )}
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(Outgoings);
