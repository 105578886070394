import React, { ReactNode } from 'react';

type SaveChangesButtonProps = {
    isLoading?: boolean;
    hasError?: boolean;
    isThrottled?: boolean;
    disabled?: boolean;
    failMessage?: string;
};

export function SaveChangesButton({ isLoading, hasError, isThrottled, disabled, failMessage }: SaveChangesButtonProps) {
    const showRecentlySaved = !isLoading && isThrottled && !hasError;
    const showRecentlyFailed = !isLoading && isThrottled && hasError;
    const showSaveButton = !isLoading && !isThrottled;

    return (
        <div>
            {showSaveButton && (
                <button type="submit" disabled={disabled} className="savestatus-button btn-agency-details-save">
                    Save changes
                </button>
            )}
            {isLoading && (
                <span>
                    <button type="submit" disabled className="savestatus-button btn-agency-details-save">
                        <span className="savestatus-spinner" /> Saving changes
                    </button>
                </span>
            )}
            {showRecentlySaved && (
                <span className="savestatus-saved">
                    Saved
                    <span className="icon savestatus-icon" />
                </span>
            )}
            {showRecentlyFailed && (
                <span className="savestatus-failed">
                    {failMessage || 'Save failed'} <span className="icon savestatus-icon" />
                </span>
            )}
        </div>
    );
}

export default SaveChangesButton;
