import React from 'react';
import cx from 'classnames';
import { indexOf } from 'lodash';
import { leaseCustomValidation, isTemplate } from '../../utils/agreementUtils';
import '../../sass/sidebarLease.scss';
import { isEmpty } from '../../utils/formChangeHandler';
import { ChevronRightSharp } from '@flk-mui-icons';
import { useDispatch, useSelector } from 'react-redux';
import { getLeaseType } from '../../selectors/lease';
import { getMobileViewEnabled } from '../../selectors/dashboard';
import { openAgreementInfoModal } from '../../actions/dashboard';
import Button from '../../common/components/Button';
import { isMobileWidth, REQUEST_TO_CHANGE_STATUS_RESOLVED } from '../../config';

const SideBarLease = props => {
    const dispatch = useDispatch();
    const mobileViewEnabled = useSelector(getMobileViewEnabled);
    const leaseType = useSelector(getLeaseType);
    /**
     * Check that sidebar item has unresolved change request.
     *
     * @param {object} stepItem item of step config from components/lease/steps.js
     * @return {boolean}
     */
    const isHasUnresolvedRequest = stepItem => {
        const { requests, isOpenRequestsToChangeForm, lease } = props;

        if (isOpenRequestsToChangeForm && !!requests.length) {
            for (let request of requests) {
                const isUnresolved = request.status !== REQUEST_TO_CHANGE_STATUS_RESOLVED;
                const isValidLeaseId = request.lease === lease.id;
                const isItemHasRequest = request.leaseAgreementStep.part.indexOf(stepItem.part) !== -1;
                if (isUnresolved && isValidLeaseId && isItemHasRequest) {
                    return true;
                }
            }
        }

        return false;
    };

    const goToStep = (newStep, part) => {
        if (props.setDetailViewOpened) {
            props.setDetailViewOpened(true);
        }
        props.saveStep(newStep, part);

        if (props.isOpenRequestsToChangeForm) {
            props.setActiveRequest(props.lease.id, newStep);
        }
    };

    const partIsInvalid = (lease, part) => {
        let valid = true;
        if (!isEmpty(lease.invalidSteps)) {
            lease.invalidSteps.forEach(invalidPart => {
                if (part === invalidPart.part) {
                    valid = false;
                }
            });
        }
        return valid;
    };

    const isPartValid = (lease, part) => {
        if (!isTemplate(lease.leaseType)) {
            return lease[part] && partIsInvalid(lease, part);
        }
        return false;
    };

    const { step, items, validationErrors, lease, detailViewOpened, isLeaseComplete, user } = props;
    let customInvalidParts = leaseCustomValidation(lease);

    return (
        <aside>
            <h6 className="for-sm-modal">{lease.address}</h6>
            <h2 className="for-sm-modal">Agreement summary</h2>
            <div className="sidebar-lease">
                <nav>
                    <ul>
                        {items.map((item, index) => {
                            let isRequestedStep = isHasUnresolvedRequest(item);
                            let part = item.part;
                            switch (item.part) {
                                case 'salesLandlord':
                                    part = 'landlord';
                                    break;
                                case 'salesInclusions':
                                    part = 'inclusions';
                                    break;
                                case 'salesTerm':
                                    part = 'term';
                                    break;
                                case 'salesExpensesAndCharges':
                                    part = 'expensesAndCharges';
                                    break;
                                case 'pmPaymentMethod':
                                    part = 'paymentMethod';
                                    break;
                            }
                            const errorForPart =
                                (!!validationErrors && !!validationErrors[part]) ||
                                isRequestedStep ||
                                indexOf(customInvalidParts, item.part) >= 0;
                            return (
                                <li
                                    key={index}
                                    className={cx(
                                        { active: detailViewOpened && index === step - 2 },
                                        { 'not-started': !lease[part] },
                                        { complete: lease[part] && isPartValid(lease, part) },
                                        { incomplete: lease[part] && !isPartValid(lease, part) },
                                        {
                                            error: errorForPart
                                        }
                                    )}
                                    onClick={() => goToStep(index + 2, items[step - 2])}
                                >
                                    <div
                                        className={cx(
                                            'marker for-lg-modal',
                                            { success: isPartValid(lease, part) },
                                            { active: index === step - 2 },
                                            {
                                                error: errorForPart
                                            }
                                        )}
                                    />
                                    <p>{item.title}</p>
                                    <span className="for-sm-modal">
                                        <ChevronRightSharp />
                                    </span>
                                </li>
                            );
                        })}
                    </ul>
                </nav>
                {mobileViewEnabled && isMobileWidth() && isLeaseComplete() && (
                    <div className="mobile-button-wrapper for-sm-modal">
                        <Button
                            className="button mobile filled"
                            onClick={() => {
                                dispatch(openAgreementInfoModal(lease));
                            }}
                        >
                            Continue
                        </Button>
                    </div>
                )}
            </div>
        </aside>
    );
};

export default SideBarLease;
