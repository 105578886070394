import { capitalize } from '../../utils/generalUtils';
import LinkOffSharpIcon from '@flk-mui-icons/LinkOffSharp';
import * as PropTypes from 'prop-types';
import React from 'react';

export const IntegrationDisconnect = props => {
    return (
        <div className="integration header-container">
            <label>
                <p>
                    <b>{capitalize(props.label)}:</b>
                </p>
            </label>
            <p className="integration-details">{props.propertyAddress}</p>
            {!props.viewOnly && (
                <p>
                    <a onClick={props.onUnlink}>
                        <LinkOffSharpIcon />
                        Unlink
                    </a>
                </p>
            )}
        </div>
    );
};

IntegrationDisconnect.propTypes = {
    label: PropTypes.any.isRequired,
    propertyAddress: PropTypes.string.isRequired,
    onUnlink: PropTypes.func.isRequired,
    viewOnly: PropTypes.bool.isRequired
};
