import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import { customSelectStyle } from '../../../utils/formUtils';
import { get, map, includes, remove } from 'lodash';
import { getAvailableStates, NSW_STATE, QLD_STATE } from '../../../config';
import { isLeaseTypeSales, isLeaseTypePm, mappingsToBackend } from '../../../utils/agreementUtils';
import { FormTextRegular } from '../../../components/form/FormText';
import { AsyncSelect } from '../../../components/form/AsyncSelect';
import logger from '../../../utils/logger';
import { getUserAgency } from '../../../selectors/agency';
import { getCreateTemplateError, getCloneTemplate } from '../../../selectors/lease';
import ArrowDropDownSharpIcon from '@flk-mui-icons/ArrowDropDownSharp';
import Button from '../../../common/components/Button';

import '../../../sass/createNewLease.scss';

import nswImage from '../../../../assets/images/content/NSW.svg?url';
import qldImage from '../../../../assets/images/content/QLD.svg?url';
import vicImage from '../../../../assets/images/content/VIC.svg?url';
import actImage from '../../../../assets/images/content/ACT.svg?url';
import saImage from '../../../../assets/images/content/SA.svg?url';

const stateImagesMap = {
    NSW: nswImage,
    QLD: qldImage,
    VIC: vicImage,
    ACT: actImage,
    SA: saImage
};

function CreateNewTemplate({ closeAgreementTemplateForm, leaseType, createDocument }) {
    const agency = useSelector(getUserAgency);
    const createTemplateError = useSelector(getCreateTemplateError);
    const cloneTemplate = useSelector(getCloneTemplate);

    const [createTemplateForm, setCreateTemplateForm] = useState({
        templateName: ''
    });
    const [showStates, setShowStates] = useState(false);
    const [cloneDocument, setCloneDocument] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    const defaultLocation = getDefaultLocation(leaseType);
    const allowedStates = getAllowedStates(leaseType);

    useEffect(() => {
        setCreateTemplateForm({
            ...createTemplateForm,
            location: cloneTemplate ? cloneTemplate.location : defaultLocation
        });
        setCloneDocument(cloneTemplate);
    }, []);

    function getDefaultLocation(leaseType) {
        let location = get(agency, 'details.location', NSW_STATE);
        // if agency default location not allowed, then get back NSW state
        if (!includes(getAllowedStates(leaseType), location)) {
            location = NSW_STATE;
        }

        // NSW is not available from the V1 creation modal for these lease types
        if ((isLeaseTypeSales(leaseType) || isLeaseTypePm(leaseType)) && location === NSW_STATE) {
            location = QLD_STATE;
        }

        return location;
    }

    const selectState = state => {
        setCreateTemplateForm({
            ...createTemplateForm,
            location: state
        });
        setShowStates(false);
        setCloneDocument(null);
    };

    function toggleShowStates() {
        setShowStates(!showStates);
    }

    const createDocumentHandler = data => {
        const newData = {
            ...data,
            leaseType,
            cloneDocument
        };
        createDocument(newData);
    };

    const changeForm = form => {
        setCreateTemplateForm(form.values);
    };

    function getAllowedStates(leaseType) {
        let availableStates = getAvailableStates(leaseType);

        // NSW is not available from the V1 creation modal for these lease types
        if (isLeaseTypeSales(leaseType) || isLeaseTypePm(leaseType)) {
            remove(availableStates, value => value === NSW_STATE);
        }

        return availableStates;
    }

    function getTitle(leaseType) {
        let type = `${mappingsToBackend[leaseType]} Template`;
        return (
            <h1>
                Create new <span className="text">{type}</span> for
            </h1>
        );
    }

    const inputChange = searchQuery => {
        setSearchQuery(searchQuery);
    };

    // handle selection
    const handleChange = value => {
        setCloneDocument(value);
    };

    // load options using API call
    const loadOptions = (inputValue, callback) => {
        if (inputValue && inputValue.length > 2) {
            return axios
                .get(
                    `/api/template/search?query=${inputValue}&location=${createTemplateForm.location}&leaseType=${leaseType}`
                )
                .then(res => {
                    return res.data.searchResult;
                })
                .catch(error => {
                    logger.push({
                        status: 'error',
                        env: ENVIRONMENT,
                        message: 'Something went wrong getting agreements and templates',
                        error_message: error.message,
                        errorObj: error
                    });
                });
        }
        return callback([]);
    };

    const NoOptionsMessage = props => {
        return (
            <div className="no-option-message" {...props}>
                {searchQuery
                    ? searchQuery.length < 3
                        ? 'Your search is too short, keep typing'
                        : 'No result found for your input'
                    : 'Please type to search templates and agreements'}
            </div>
        );
    };

    function getCreateButton(disableButton, creatingTemplate = false) {
        const label = `Create new template`;
        return (
            <Button primary type="submit" disabled={disableButton || creatingTemplate}>
                {creatingTemplate ? <span className="savestatus-spinner-white" /> : null}
                {label}
            </Button>
        );
    }

    return (
        <div className="container">
            {getTitle(leaseType)}
            <div className="create-newLease">
                <Form onSubmit={values => createDocumentHandler(values)} initialValues={createTemplateForm}>
                    {({ handleSubmit, values, form }) => {
                        return (
                            <form onSubmit={handleSubmit} noValidate>
                                <FormSpy onChange={state => changeForm(state)} />
                                <div className="column-box">
                                    <div className={`state-image`}>
                                        <img src={stateImagesMap[values.location]} />
                                    </div>
                                    <div className="select-state-container">
                                        <div className="selected-location">{values.location}</div>
                                        <button
                                            type="button"
                                            className="button-change-state"
                                            onClick={() => toggleShowStates()}
                                        >
                                            Change state
                                            <ArrowDropDownSharpIcon className="Select-arrow" />
                                        </button>
                                        {showStates && (
                                            <div className={'showStates'}>
                                                <div className="state-dropdown">
                                                    {map(allowedStates, state => {
                                                        return (
                                                            <p
                                                                key={state}
                                                                className="state-dropdown-item"
                                                                onClick={() => selectState(state)}
                                                            >
                                                                {state}
                                                            </p>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <FormTextRegular name="templateName" label="Template name" />
                                <AsyncSelect
                                    defaultOptions
                                    isClearable={true}
                                    value={cloneDocument}
                                    styles={customSelectStyle}
                                    loadOptions={loadOptions}
                                    onChange={handleChange}
                                    onInputChange={inputChange}
                                    selectClassName="async-select"
                                    placeholder="Clone from..."
                                    components={{
                                        NoOptionsMessage
                                    }}
                                />

                                <div className="create-new-lease-button-container">
                                    <div className="button-cancel">
                                        <span onClick={closeAgreementTemplateForm}>Cancel</span>
                                    </div>
                                    <div className="button btn-spinner button-create-new-lease">
                                        {getCreateButton(!values.templateName)}
                                    </div>
                                </div>
                                {createTemplateError && (
                                    <div className="text-danger">
                                        <br />
                                        Something went wrong when creating the template, please try again or contact us
                                        via the live chat
                                    </div>
                                )}
                            </form>
                        );
                    }}
                </Form>
            </div>
        </div>
    );
}

export default CreateNewTemplate;
