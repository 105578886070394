import React, { Component } from 'react';

import Notice from '../components/Notice';

import '../../../sass/agentSignatures/expiredLease.scss';
import mainLogo from '../../../../assets/images/main_logo.svg?url';

class Expired extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="expired-screen">
                <div className="expired-screen-logo">
                    <img src={mainLogo} alt="logo" />
                </div>
                <Notice>
                    <h4>OUTDATED AGENT SIGNATURE LINK</h4>
                    <p>
                        Looks like the page you're trying to view doesn't exist or the link has expired. Please check
                        the URL and try again.
                    </p>
                </Notice>
            </div>
        );
    }
}

export default Expired;
