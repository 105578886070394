import * as React from 'react';
import PropTypes from 'prop-types';

import ModalDialog from '../../common/components/ModalDialog';
import Button from '../../common/components/Button';

import '../../sass/modalSignature.scss';
import { deleteSignature } from '../../actions/signatures';
import { useDispatch, useSelector } from 'react-redux';
import { getSignatures } from '../../selectors/signatures';
import ErrorIcon from '@flk-mui-icons/Error';

const ModalSignatureDeleteRequest = ({ isOpen, closeModal, signatureId }) => {
    const dispatch = useDispatch();
    const signatureState = useSelector(getSignatures);

    const isSuccess = signatureState.deleteSignatureSuccess;
    const isLoading = signatureState.isSendingRequestToDeleteSignature;
    const isFail = signatureState.deleteSignatureFail;

    const renderRightButton = () => {
        return (
            <Button primary className="btn-delete-signature" disabled={isLoading} height={40} onClick={onClickDelete}>
                {isLoading && <span className="loading-spinner" />}
                {isFail && !isLoading && (
                    <div className="button-text-send-sms">
                        <ErrorIcon className="icon-margin" color="inherit" />
                        Try again
                    </div>
                )}
                {!isLoading && !isFail && <span>Delete</span>}
            </Button>
        );
    };

    const onClickDelete = () => {
        dispatch(deleteSignature(signatureId));
    };

    if (isSuccess) {
        signatureState.deleteSignatureSuccess = false;
        closeModal();
    }

    const renderLeftButton = () => {
        return (
            <Button primary className="btn-close" height={40} onClick={closeModal}>
                Cancel
            </Button>
        );
    };

    return (
        <ModalDialog
            isOpen={isOpen}
            closeModal={closeModal}
            rightButton={renderRightButton()}
            leftButton={renderLeftButton()}
            showModalHeader={false}
            shouldCloseOnOverlayClick={false}
            className="small-modal-dialog"
        >
            {isFail && !isLoading && (
                <p className="error-message">An error has occurred, please try again or contact support for help</p>
            )}
            <p>Are you sure you want to delete this signature?</p>
        </ModalDialog>
    );
};

ModalSignatureDeleteRequest.propTypes = {
    isOpen: PropTypes.bool,
    closeModal: PropTypes.func,
    onClickConfirmButton: PropTypes.func
};

ModalSignatureDeleteRequest.defaultProps = {
    isOpen: false,
    closeModal: () => {},
    onClickConfirmButton: () => {}
};

export default ModalSignatureDeleteRequest;
