import React, { memo } from 'react';
import { FormMaskedText } from '@app/components/form/FormMaskedText';
import { FormTextRegular } from '../../../components/form/FormText';
import { FormPhone } from '../../../components/form/FormPhone';
import { FormEmail } from '../../../components/form/FormEmail';
import { FormRadioGroup } from '../../../components/form/FormRadioGroup';
import DatePickerInAgencyTimeZone from '../../../components/form/DatePickerInAgencyTimeZone';
import { LEASE_TYPE_PROPERTY_MANAGEMENT, NSW_STATE, QLD_STATE } from '../../../config';
import { getAgencyTimezoneDate } from '../../../utils/dateUtils';
import { getLabel } from '../../../utils/labelUtils';
import { CheckboxCheck } from '../../../components/form/FormCheckboxCheck';
import { isLeaseLocationQLD, isLeaseLocationSA, isLeaseLocationVIC } from '../../../utils/agreementUtils';

const PmLicensee = ({ values, toggleRegisteredForGst, changePhone, error, licensee, loggedInUser, location }) => {
    return (
        <div className="formBox">
            {isLeaseLocationQLD(location) && (
                <div className="formBox-column">
                    <FormRadioGroup
                        label="Licensee Type"
                        required
                        name="licenseeType"
                        value={values.licenseeType}
                        data={[
                            {
                                label: 'Real estate agent',
                                value: 'Real estate agent'
                            },
                            {
                                label: 'Resident letting agent',
                                value: 'Resident letting agent'
                            }
                        ]}
                    />
                </div>
            )}
            {!isLeaseLocationQLD(location) && !isLeaseLocationSA(location) && (
                <div className="nowrap fields-full-width-for-mobile">
                    <FormTextRegular name="firstName" label="First Name" required />
                    <FormTextRegular name="secondName" label="Last Name" required />
                </div>
            )}
            <div className="formBox-column">
                <FormTextRegular name="agencyName" label="Agency Name" required />
                <FormTextRegular
                    name="companyName"
                    label={getLabel('companyName', location, LEASE_TYPE_PROPERTY_MANAGEMENT)}
                    required={!isLeaseLocationSA(location)}
                />
                <FormMaskedText
                    dataTest="licensee-abn"
                    name="abn"
                    label="ABN"
                    mask="11 111 111 111"
                    required
                    className="licensee-abn"
                />
                <FormMaskedText
                    dataTest="licensee-acn"
                    name="acn"
                    label="ACN"
                    mask="111 111 111"
                    className="licensee-acn"
                />
                {!isLeaseLocationVIC(location) && !isLeaseLocationSA(location) && (
                    <FormTextRegular
                        name="licenceNo"
                        label={getLabel('licenceNo', location, LEASE_TYPE_PROPERTY_MANAGEMENT)}
                        required
                    />
                )}
                {isLeaseLocationSA(location) && <FormTextRegular name="rlaNumber" label="RLA number" required />}
                {isLeaseLocationQLD(location) && (
                    <DatePickerInAgencyTimeZone
                        label="Licence Expiry"
                        name="licenceExpiry"
                        selected={values.licenceExpiry}
                        minValue={getAgencyTimezoneDate(new Date(), loggedInUser)}
                        required
                    />
                )}
                <FormTextRegular name="address" label="Address" required />
                {!isLeaseLocationQLD(location) && !isLeaseLocationSA(location) && (
                    <FormPhone dataTest="licensee-mobile" name="mobile" label="Mobile" className={`licensee-mobile`} />
                )}
                <FormPhone
                    name="phone"
                    label="Phone"
                    className={`licensee-phone`}
                    onChange={value => changePhone(value, values)}
                    dataTest="licensee-phone"
                    required={isLeaseLocationSA(location)}
                />
                {error && (
                    <p className="FormInput custom-error">
                        <span className="FormError">{error}</span>
                    </p>
                )}
                {[NSW_STATE, QLD_STATE].includes(location) && (
                    <FormMaskedText name="fax" label="Fax" mask="1111 111 111" />
                )}
                <FormEmail name="email" label="Email" required value={licensee.email} />
                {!isLeaseLocationQLD(location) && (
                    <CheckboxCheck
                        name="isRegisteredForGst"
                        label="Registered for GST"
                        onClick={value => {
                            // value is the previous value of the checkbox not the new value
                            toggleRegisteredForGst(!value);
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default memo(PmLicensee);
