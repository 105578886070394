import React, { useState } from 'react';
import '../../../../../../sass/documentTitleHeader.scss';
import { ArrowBackIosSharp, CloseSharp, HelpSharp, LockSharp, LockOpenSharp } from '@flk-mui-icons';
import AuditTrailOpenButton from '../../../../../../common/components/AuditTrailOpenButton';
import { isDocumentStatusDraft } from '../../../../../../utils/generalUtils';
import VisibilityListModal from '../../../../../../components/modals/VisibilityListModal';
import classNames from 'classnames';
import { DocumentHeaderLeftPublic } from './DocumentHeaderLeftPublic';
import { ONBOARDING_TRACKING_PREFIX } from '../../../../../../constants/constants';

const DocumentTitleHeaderPublic = ({
    doc,
    closeModal,
    showHelpIcon,
    helpComponent,
    isTemplate,
    docType,
    showLockedIcon,
    saveDoc,
    isReadOnlyTemplate,
    hideCloseButton,
    customRightButton
}) => {
    const [showHelpMessage, setShowHelpMessage] = useState(false);
    const [isOpenLockedModal, setIsOpenLockedModal] = useState(false);

    return (
        <div className="document-title-header">
            <div className="document-title-header-content">
                <div
                    className={classNames('document-title-header-content-info-agreement', {
                        approvalStatus: isDocumentStatusDraft(doc.status) && doc.requiresApproval,
                        large: isTemplate
                    })}
                >
                    {doc && (
                        <DocumentHeaderLeftPublic
                            handleUpdateDocumentTitle={title => saveDoc({ documentTitle: title })}
                            doc={doc}
                            isTemplate={isTemplate}
                            docType={docType}
                            isReadOnlyTemplate={isReadOnlyTemplate}
                        />
                    )}

                    <div className="header-right">
                        <div className="header-right-wrapper">
                            {customRightButton}
                            {showLockedIcon && (
                                <div className="header-button for-lg-modal">
                                    {doc.locked && (
                                        <LockSharp
                                            className={`help-icon ${isReadOnlyTemplate ? 'disabled' : ''}`}
                                            onClick={() => {
                                                setIsOpenLockedModal(true);
                                            }}
                                        />
                                    )}
                                    {!doc.locked && (
                                        <LockOpenSharp
                                            className={`help-icon ${isReadOnlyTemplate ? 'disabled' : ''}`}
                                            onClick={() => {
                                                setIsOpenLockedModal(true);
                                            }}
                                        />
                                    )}
                                </div>
                            )}
                            {showHelpIcon && (
                                <div className="header-button for-lg-modal">
                                    <HelpSharp
                                        className="help-icon"
                                        onClick={() => {
                                            // keep local state so we can toggle
                                            setShowHelpMessage(!showHelpMessage);
                                        }}
                                        data-automation-id={`${ONBOARDING_TRACKING_PREFIX}-form-help-button`}
                                    />
                                </div>
                            )}
                            {doc &&
                                !isDocumentStatusDraft(doc.status) &&
                                doc.auditTrail &&
                                doc.auditTrail.length > 0 && <AuditTrailOpenButton leaseId={doc.id} showText={false} />}
                            <div onClick={() => closeModal()} className="close header-button for-sm-modal">
                                <ArrowBackIosSharp />
                            </div>
                            {!hideCloseButton && (
                                <div
                                    onClick={() => closeModal()}
                                    className="close header-button for-lg-modal"
                                    data-test="template-model-close-button"
                                    data-automation-id={`${ONBOARDING_TRACKING_PREFIX}-form-close-button`}
                                >
                                    <CloseSharp>Close</CloseSharp>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {showHelpMessage && helpComponent}
                {showLockedIcon && (
                    <VisibilityListModal
                        doc={doc}
                        isOpenLockedModal={isOpenLockedModal}
                        closeModal={() => setIsOpenLockedModal(false)}
                        saveDoc={saveDoc}
                    />
                )}
            </div>
        </div>
    );
};

export default DocumentTitleHeaderPublic;

