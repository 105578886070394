export async function loadFileAsArrayBuffer(file) {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
        reader.onerror = () => {
            reject('Unable to read file');
        };

        reader.onabort = () => {
            reject('Unable to read file');
        };

        reader.onload = () => {
            resolve(reader.result);
        };

        reader.readAsArrayBuffer(file);
    });
}
