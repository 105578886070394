import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Table, Th, Tr, Td, Thead } from 'reactable';
import { cloneDeep } from 'lodash';
import '../../sass/terminateLeaseListModal.scss';
import ModalDialog from '../../common/components/ModalDialog';

import { closeTerminateLeaseListModal } from '../../actions/terminateLease';

import { leaseTerminationReasonOptions } from '../../config';
import TerminationListButton from '../dashboard/documentList/components/TerminationListButton';

import { formatDateStandard, getAgencyTimezoneFromUser } from '../../utils/dateUtils';
import { ErrorOutlineSharp } from '@flk-mui-icons';

export default function TerminateLeaseListModal({ isOpen, loggedInUser, agreementInfo }) {
    const dispatch = useDispatch();

    function closeModal() {
        dispatch(closeTerminateLeaseListModal());
    }

    function getReasonForTerminationText(leaseLocation, reasonOptionValue) {
        let reason = {};
        if (leaseTerminationReasonOptions[leaseLocation]) {
            reason = leaseTerminationReasonOptions[leaseLocation].find(
                reasonOption => reasonOption.value === reasonOptionValue
            );
        }
        if (reason && reason.label) return reason.label;
        return reasonOptionValue;
    }

    function getActionRow(leaseTermination, lease) {
        if (leaseTermination.status && leaseTermination.status === 'error') {
            return (
                <div className="termination-send-error">
                    <ErrorOutlineSharp />
                    Error Sending Termination
                </div>
            );
        }
        return <TerminationListButton lease={lease} doc={leaseTermination} />;
    }

    let { address, location } = agreementInfo;
    let lease = agreementInfo;

    let leaseTerminations = cloneDeep(agreementInfo.leaseTerminations);

    if (leaseTerminations && leaseTerminations.length > 0) {
        leaseTerminations.sort((a, b) => {
            return (
                new Date(b.dateOfNotice) - new Date(a.dateOfNotice) ||
                new Date(a.vacantPossessionDate) - new Date(b.vacantPossessionDate)
            );
        });
    }

    return (
        <ModalDialog
            title="Termination Notices"
            isOpen={isOpen}
            closeModal={closeModal}
            className="terminate-list-modal-dialog"
        >
            <p>Address: {address}</p>

            <div className="table-sort">
                <Table>
                    <Thead>
                        <Th column="dateOfNotice">Date of Notice</Th>
                        <Th column="reasonForTermination">Reason For Termination</Th>
                        <Th column="vacantPossessionDate">Vacant Possession Date</Th>
                        <Th column="pdf">&nbsp;</Th>
                        <Th column="">&nbsp;</Th>
                    </Thead>
                    {leaseTerminations &&
                        leaseTerminations.map((leaseTermination, key) => {
                            return (
                                <Tr key={key}>
                                    <Td column="dateOfNotice">
                                        {leaseTermination.dateOfNotice
                                            ? formatDateStandard(
                                                  leaseTermination.dateOfNotice,
                                                  getAgencyTimezoneFromUser(loggedInUser)
                                              )
                                            : '-'}
                                    </Td>
                                    <Td column="reasonForTermination">
                                        {getReasonForTerminationText(location, leaseTermination.reasonForTermination)}
                                    </Td>
                                    <Td column="vacantPossessionDate">
                                        {leaseTermination.vacantPossessionDate
                                            ? formatDateStandard(
                                                  leaseTermination.vacantPossessionDate,
                                                  getAgencyTimezoneFromUser(loggedInUser)
                                              )
                                            : '-'}
                                    </Td>
                                    <Td column="pdf">{getActionRow(leaseTermination, lease)}</Td>
                                    <Td column="">&nbsp;</Td>
                                </Tr>
                            );
                        })}
                </Table>
            </div>
        </ModalDialog>
    );
}
