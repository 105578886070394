import React from 'react';
import { Field, useField } from 'react-final-form';
import cx from 'classnames';
import { getFormError } from '../../utils/finalFormUtils';
import CheckCircleSharpIcon from '@flk-mui-icons/CheckCircleSharp';

import '../../sass/form-radio.scss';
import useComponentId from '../../hooks/useComponentId';
import Tooltip from '../../common/components/tooltips/Tooltip';

const config = {
    subscription: {
        error: true,
        submitError: true,
        dirtySinceLastSubmit: true,
        touched: true,
        modified: true
    }
};

const radioInput = ({ input, value, onClick, meta, disabled, ...rest }) => (
    <div className={cx('form-group', { on: value })}>
        <input type="radio" onClick={onClick} {...input} {...rest} disabled={disabled} hidden={true} />
    </div>
);

/**
 * We can use this component inside form to add radio buttons.
 * ex:  <FormRadioGroup
 name="deliveryType"
 data={[
                {
                    label: 'Hand',
                    value: 'value'
                    tooltipText: 'id of tooltip'
                    showTooltip: shouldShowTooltip
                    icon: 'icon material component'
                },
                {
                    label: 'Post',
                    value: 'post',
                    tooltipText: ''
                },
                {
                    label: 'Email',
                    value: 'email',
                    onClick: () => setDeliveryType(form.change, values)
                },
                {
                    label: 'Other',
                    value: 'other'
                }
            ]}
 />
 */
export const FormRadioGroupButton = ({
    label,
    name,
    className,
    labelClassName,
    radioGroupClass,
    radioItemClassName,
    data,
    required,
    validateOnTouch,
    value,
    disabled,
    small
}) => {
    const field = useField(name, config);
    const tooltipId = useComponentId().toString();

    return (
        <div className={cx('FormInput', className)}>
            <label
                className={cx('form-radio-button-label', labelClassName, {
                    FormError: getFormError(field.meta, validateOnTouch)
                })}
            >
                {label} {required ? '*' : null}
            </label>
            <div className={cx('radioGroupButton', radioGroupClass, { small })}>
                {data.map((item, index) => {
                    return (
                        <label
                            key={index}
                            htmlFor={item?.id || name + item?.label}
                            className={cx(radioItemClassName, {
                                active: value === item.value,
                                disabled: item.disabled
                            })}
                            onClick={() => {
                                field.input.onFocus();
                                field.input.onBlur();
                            }}
                        >
                            <div
                                className={cx('itemGroup', {
                                    active: value === item.value,
                                    'without-icon': !item.icon
                                })}
                                data-tip={item.showTooltip ? item.tooltipText : undefined}
                                data-for={tooltipId}
                            >
                                {item.permanentToolTip && <p className="permanent-tooltip">{item.permanentToolTip}</p>}
                                <Field
                                    className={item.className}
                                    name={name}
                                    value={item.value}
                                    component={radioInput}
                                    type="radio"
                                    id={item?.id || name + item.label}
                                    disabled={item.disabled || disabled}
                                    onClick={item.onClick}
                                />
                                {item.icon && <div className="itemGroup__icon">{React.createElement(item.icon)}</div>}
                                <span className="label" data-test={item.dataTest}>
                                    {item.label}
                                </span>
                            </div>
                        </label>
                    );
                })}
            </div>
            {getFormError(field.meta, validateOnTouch) && (
                <span className={'FormError'}>{getFormError(field.meta, validateOnTouch)}</span>
            )}
            <Tooltip effect="solid" id={tooltipId} place="bottom" />
        </div>
    );
};

export const FormRadioGroupSummaryButton = ({
    label,
    name,
    className,
    radioGroupClass,
    radioItemClassName,
    data,
    required,
    validateOnTouch,
    value,
    disabled
}) => {
    const field = useField(name, config);
    return (
        <div className={cx('FormInput', className)}>
            <label className={cx('form-radio-button-label', { FormError: getFormError(field.meta, validateOnTouch) })}>
                {label} {required ? '*' : null}
            </label>
            <div className={cx('radio-group-summary-button', radioGroupClass)}>
                {data.map((item, index) => {
                    return (
                        <label
                            key={index}
                            htmlFor={item?.id || name + item?.label}
                            className={cx('radio-item', radioItemClassName, {
                                active: value === item.value,
                                disabled: item.disabled
                            })}
                            data-test={`${item.dataTest}-wrapper`}
                        >
                            <div className="active-icon">
                                {value === item.value && <CheckCircleSharpIcon className="check-icon" />}
                                {item.image}
                            </div>
                            <div>
                                <Field
                                    className={item.className}
                                    name={name}
                                    value={item.value}
                                    component={radioInput}
                                    type="radio"
                                    id={item?.id || name + item?.label}
                                    disabled={item.disabled || disabled}
                                    onClick={item.onClick}
                                />
                            </div>
                            <div className="text-area">
                                <p className="label">{item.label}</p>
                                <p className="message">{item.message}</p>
                            </div>
                        </label>
                    );
                })}
            </div>
            {getFormError(field.meta, validateOnTouch) && (
                <span className={'FormError'}>{getFormError(field.meta, validateOnTouch)}</span>
            )}
        </div>
    );
};
