import React, { memo, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useImmer } from 'use-immer';
import { parseISO } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import { CheckboxCheck } from '../../../components/form/FormCheckboxCheck';
import { FormTextRegular } from '../../../components/form/FormText';
import SuggestionTextField from '../../../components/form/SuggestionTextField.tsx';
import DatePickerInAgencyTimeZone from '../../../components/form/DatePickerInAgencyTimeZone';
import {
    PERIOD_MONTHLY,
    PERIOD_QUARTERLY,
    PERIOD_WEEKLY,
    PERIOD_FORTNIGHTLY,
    isPaymentMethodAvailable
} from '../../../config';
import {
    isLeaseLocationQLD,
    isLeaseLocationSA,
    isLeaseTypePm,
    isLeaseTypeResidentialTenancy,
    isTemplate
} from '../../../utils/agreementUtils';
import '../../../sass/payment.scss';
import { formSubmitFail, removeSuggestion, setDirtyStep, updateSubmitTypeSuccess } from '../../../actions/lease';
import {
    getDirtyStep,
    getStep,
    getPaymentMethod,
    getLocation,
    getLeaseType,
    getSuggestions
} from '../../../selectors/lease';
import axios from 'axios';
import * as Lease from '../../../reducers/lease';
import { cloneDeep, has } from 'lodash';
import { getResTenLabel, getLabel } from '../../../utils/labelUtils';
import { SelectField } from '../../../components/form/FormSelect';
import { upperFirst } from 'lodash';
import Suggestion from '../../../components/suggestions/Suggestion.tsx';

const PAYMENT_FORM = 'paymentForm';
const initState = {
    rentLocation: '',
    frequencyOfFundsDeposit: '',
    DirectDebit: {
        enabled: false,
        details: {
            accountName: '',
            accountNumber: '',
            BSB: '',
            comments: '',
            maximumDebitAmount: '',
            debitStartDate: '',
            debitEndDate: ''
        }
    },
    EFT: {
        enabled: false,
        details: {
            accountName: '',
            accountNumber: '',
            bankName: '',
            BSB: '',
            reference: ''
        }
    },
    BPAY: {
        enabled: false,
        details: {
            billerCode: '',
            customerReferenceNumber: ''
        }
    },
    Cheque: {
        enabled: false,
        details: {
            madePayableTo: '',
            address: ''
        }
    },
    Cash: {
        enabled: false,
        details: ''
    },
    OwnerToEnter: {
        enabled: false
    },
    Other: {
        enabled: false,
        details: ''
    }
};

const frequencyOfFundsDeposits = [
    { value: PERIOD_WEEKLY, label: upperFirst(PERIOD_WEEKLY) },
    { value: PERIOD_FORTNIGHTLY, label: upperFirst(PERIOD_FORTNIGHTLY) },
    { value: PERIOD_MONTHLY, label: upperFirst(PERIOD_MONTHLY) },
    { value: PERIOD_QUARTERLY, label: upperFirst(PERIOD_QUARTERLY) }
];

const Payment = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);
    const reduxPayment = useSelector(getPaymentMethod);
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const suggestions = useSelector(getSuggestions);

    let [payment, setPayment] = useImmer(reduxPayment || initState);
    let [error, setError] = useState('');
    let [disabled, setDisabled] = useState(false);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(PAYMENT_FORM).dispatchEvent(
                new Event('submit', {
                    cancelable: true,
                    bubbles: true
                })
            );
        }
    }));

    useEffect(() => {
        if (isLeaseTypeResidentialTenancy(leaseType)) {
            setPayment(draft => {
                draft.DirectDebit.details.debitStartDate =
                    payment.DirectDebit.details.debitStartDate && payment.DirectDebit.details.debitStartDate !== ''
                        ? parseISO(payment.DirectDebit.details.debitStartDate)
                        : null;
                draft.DirectDebit.details.debitEndDate =
                    payment.DirectDebit.details.debitEndDate && payment.DirectDebit.details.debitEndDate !== ''
                        ? parseISO(payment.DirectDebit.details.debitEndDate)
                        : null;
            });
        }
        if (isLeaseTypeResidentialTenancy(leaseType)) {
            setPayment(draft => {
                const paymentMethodKeys = Object.keys(payment);
                // remove the fields that are not a payment method
                const allPaymentMethods = paymentMethodKeys.filter(
                    pMethod =>
                        !['id', 'rentLocation', 'isDefaultSettings', 'hasAtLeast1EnabledPaymentMethod'].includes(
                            pMethod
                        )
                );
                allPaymentMethods.map(name => {
                    if (!isPaymentMethodAvailable(name, leaseType, location) && payment[name].enabled) {
                        draft[name].enabled = false;
                    }
                });
            });
        }
    }, []);

    const handleOwnerToEnter = value => {
        if (value) {
            setPayment(draft => {
                draft.EFT.enabled = false;
                draft.Cheque.enabled = false;
                draft.OwnerToEnter.enabled = true;
            });
        }
    };
    const handleCheque = value => {
        if (isLeaseTypePm(leaseType)) {
            if (value) {
                setPayment(draft => {
                    draft.EFT.enabled = false;
                    draft.Cheque.enabled = true;
                    draft.OwnerToEnter.enabled = false;
                });
            }
        }
    };
    const handleEFT = value => {
        if (isLeaseTypePm(leaseType)) {
            if (value) {
                setPayment(draft => {
                    draft.EFT.enabled = true;
                    draft.Cheque.enabled = false;
                    draft.OwnerToEnter.enabled = false;
                });
            }
        }
    };

    const handleFrequencyOfFunds = val => {
        if (val) {
            setPayment(draft => {
                draft.frequencyOfFundsDeposit = val.value;
            });
        }
    };

    const updatePaymentMethod = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/payment-details`, data);
    };

    const submitForm = values => {
        const data = cloneDeep(values);
        data.isDefaultSettings = false;
        /**
         * Store the ref of the component so it can be used in this function
         * We need this otherwise the promise below doesn't have access to the ref
         */
        const currentRef = ref.current;
        /**
         * Grab the bypassFormValidation that was set from the parent component: LeaseAgreementForm.js
         */
        data.bypassFormValidation = currentRef.bypassFormValidation;
        let isValid = false;
        if (!data.bypassFormValidation && !isTemplate(leaseType)) {
            for (const key in data) {
                // eslint-disable-next-line no-prototype-builtins
                if (data.hasOwnProperty(key) && data[key]) {
                    isValid = isValid || !!data[key].enabled;
                }
            }
        } else {
            isValid = true;
        }
        if (!isValid) {
            setError('At least one payment method must be selected');
        } else {
            setError('');
        }
        return updatePaymentMethod(data)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    /**
                     * Callback after submit this form so that the parent component can take an action
                     */
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.paymentMethod')) {
                    return error.response.data.errors.paymentMethod;
                } else if (has(error, 'response.data.error')) {
                    return error.response.data.error;
                } else {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };
    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    return (
        <div className="payment mobile-multi-select-wrapper">
            <Form onSubmit={submitForm} initialValues={payment}>
                {({ handleSubmit, values, form }) => {
                    return (
                        <form id={PAYMENT_FORM} onSubmit={handleSubmit} noValidate>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            {isLeaseTypePm(leaseType) && isLeaseLocationSA(location) && (
                                <div>
                                    <h3>Payment to landlord</h3>
                                    <br />
                                    <div className="column payable">
                                        <p className="form-label">Frequency of funds deposit</p>
                                        <SelectField
                                            dataTest="paymentFrequency"
                                            name="frequencyOfFundsDeposit"
                                            options={frequencyOfFundsDeposits}
                                            required
                                            value={values.frequencyOfFundsDeposit}
                                            onChange={value => handleFrequencyOfFunds(value)}
                                        />
                                    </div>
                                </div>
                            )}
                            {isPaymentMethodAvailable('DirectDebit', leaseType, location) && (
                                <CheckboxCheck
                                    className={'direct-debit'}
                                    name="DirectDebit.enabled"
                                    label="Direct Debit"
                                    disabled={disabled}
                                />
                            )}
                            {isPaymentMethodAvailable('DirectDebit', leaseType, location) &&
                                values.DirectDebit &&
                                values.DirectDebit.enabled && (
                                    <div className="details">
                                        <p className="name">Details for Direct Debit</p>
                                        <div className="details-items">
                                            <FormTextRegular
                                                name="DirectDebit.details.accountName"
                                                label="Account name"
                                            />
                                            <FormTextRegular
                                                name="DirectDebit.details.accountNumber"
                                                label="Account number"
                                            />
                                            <FormTextRegular name="DirectDebit.details.BSB" label="BSB" />
                                            <FormTextRegular
                                                name="DirectDebit.details.maximumDebitAmount"
                                                label="Max debit amount"
                                            />
                                            <FormTextRegular
                                                name="DirectDebit.details.comments"
                                                label="Comment"
                                                required
                                            />
                                            <DatePickerInAgencyTimeZone
                                                label="Direct Debit start date:"
                                                name="DirectDebit.details.debitStartDate"
                                                selected={values.DirectDebit.details.debitStartDate}
                                            />
                                            <DatePickerInAgencyTimeZone
                                                label="Direct Debit end date:"
                                                name="DirectDebit.details.debitEndDate"
                                                selected={values.DirectDebit.details.debitEndDate}
                                            />
                                            <div>
                                                <CheckboxCheck
                                                    className={'permission-to-debit-invoices'}
                                                    name="DirectDebit.details.permissionToDebitInvoices"
                                                    label="Permission to debit invoices"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            {isPaymentMethodAvailable('EFT', leaseType, location) && (
                                <CheckboxCheck
                                    name="EFT.enabled"
                                    label={getLabel('eft', location, leaseType)}
                                    className={'eft mobile-multi-select-enabled'}
                                    onClick={value => handleEFT(value)}
                                    disabled={disabled}
                                />
                            )}
                            {isPaymentMethodAvailable('EFT', leaseType, location) &&
                                values.EFT &&
                                values.EFT.enabled && (
                                    <div className="details">
                                        <p className="name">Details for {getLabel('eft', location, leaseType)}</p>
                                        <div className="details-items">
                                            <FormTextRegular
                                                name="EFT.details.accountName"
                                                label="Account name"
                                                required
                                            />
                                            <FormTextRegular name="EFT.details.bankName" label="Bank name" required />
                                            <FormTextRegular name="EFT.details.BSB" label="BSB" required />
                                            <FormTextRegular
                                                name="EFT.details.accountNumber"
                                                label="Account number"
                                                required
                                            />
                                            {!isLeaseTypePm(leaseType) && (
                                                <SuggestionTextField
                                                    name="EFT.details.reference"
                                                    label="Reference"
                                                    required
                                                    suggestionsFieldName="bankReference"
                                                />
                                            )}
                                        </div>
                                    </div>
                                )}
                            {isPaymentMethodAvailable('BPAY', leaseType, location) && (
                                <CheckboxCheck
                                    name="BPAY.enabled"
                                    label="BPAY"
                                    className={'bpay mobile-multi-select-enabled'}
                                    disabled={disabled}
                                />
                            )}
                            {isPaymentMethodAvailable('BPAY', leaseType, location) &&
                                values.BPAY &&
                                values.BPAY.enabled && (
                                    <div className="details">
                                        <p className="name">Details for BPAY</p>
                                        <div className="details-items">
                                            <FormTextRegular
                                                name="BPAY.details.billerCode"
                                                label="Biller Code"
                                                required
                                            />
                                            <SuggestionTextField
                                                name="BPAY.details.customerReferenceNumber"
                                                label="Customer Reference Number"
                                                required
                                                suggestionsFieldName="bankReference"
                                            />
                                        </div>
                                    </div>
                                )}
                            {isPaymentMethodAvailable('Cheque', leaseType, location) && (
                                <CheckboxCheck
                                    name="Cheque.enabled"
                                    label="Cheque"
                                    className={'cheque mobile-multi-select-enabled'}
                                    onClick={value => handleCheque(value)}
                                    disabled={disabled}
                                />
                            )}
                            {isPaymentMethodAvailable('Cheque', leaseType, location) && values.Cheque.enabled && (
                                <div className="details">
                                    <p className="name">Details for Cheque</p>
                                    <div className="details-items">
                                        <FormTextRegular
                                            name="Cheque.details.madePayableTo"
                                            label="Made payable to"
                                            required
                                        />
                                        {isLeaseTypePm(leaseType) && (
                                            <FormTextRegular name="Cheque.details.address" label="Address" required />
                                        )}
                                    </div>
                                </div>
                            )}
                            {isPaymentMethodAvailable('OwnerToEnter', leaseType, location) && (
                                <CheckboxCheck
                                    className={'mobile-multi-select-enabled'}
                                    name="OwnerToEnter.enabled"
                                    label="Owner To Enter"
                                    onClick={value => handleOwnerToEnter(value)}
                                />
                            )}
                            {isPaymentMethodAvailable('Cash', leaseType, location) && (
                                <CheckboxCheck
                                    className={'mobile-multi-select-enabled'}
                                    name="Cash.enabled"
                                    value={true}
                                    label="Cash"
                                    disabled={disabled}
                                />
                            )}
                            {isPaymentMethodAvailable('Cash', leaseType, location) &&
                                values.Cash &&
                                values.Cash.enabled && <FormTextRegular name="Cash.details" label="Details" required />}
                            {isPaymentMethodAvailable('MoneyOrder', leaseType, location) && (
                                <CheckboxCheck
                                    className={'mobile-multi-select-enabled'}
                                    name="MoneyOrder.enabled"
                                    value={true}
                                    label="Money Order"
                                    disabled={disabled}
                                />
                            )}
                            {isPaymentMethodAvailable('MoneyOrder', leaseType, location) &&
                                values.MoneyOrder &&
                                values.MoneyOrder.enabled && (
                                    <FormTextRegular name="MoneyOrder.details" label="Details" required />
                                )}
                            {isPaymentMethodAvailable('Other', leaseType, location) && (
                                <CheckboxCheck
                                    className={'mobile-multi-select-enabled'}
                                    name="Other.enabled"
                                    value={true}
                                    label={getResTenLabel('other', location)}
                                    disabled={disabled}
                                />
                            )}
                            {isPaymentMethodAvailable('Other', leaseType, location) &&
                                values.Other &&
                                values.Other.enabled && (
                                    <FormTextRegular name="Other.details" label="Details" required />
                                )}
                            {isLeaseLocationSA(location) && isLeaseTypeResidentialTenancy(leaseType) && (
                                <p className="note">
                                    Note: The payment of rent is considered to have been made when it is credited to the
                                    Agent/Landlord. The Tenant must take into account any potential delays caused by
                                    financial institutions or payment methods when transferring rent.
                                </p>
                            )}
                            <div className="payment-error">{error}</div>
                            {isLeaseLocationQLD(location) && (
                                <FormTextRegular name="rentLocation" label="Place for rent payment (Optional)" />
                            )}
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(Payment);
