import React, { useState, memo } from 'react';
import SecurityCard from '../../components/security/SecurityCard.tsx';
import SecuritySetupCard from '../../components/security/SecuritySetupCard.tsx';
import AuthenticationAppSetupCard from '../../components/security/AuthenticationAppSetupCard.tsx';
import { useDispatch, useSelector } from 'react-redux';
import { getAgency, getUserInfo } from '../../selectors/user';
import Banner from '../../common/components/Banner';
import {
    BANNER_ID_2FA,
    VERIFICATION_CODE_LIMIT_MESSAGE,
    TWO_FACTOR_AUTHENTICATION_APP,
    TWO_FACTOR_AUTHENTICATION_SMS
} from '../../config';
import { Icons } from '../../common/components/Icon';
import Card, { CardStyles } from '../../common/components/cards/Card';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { updateUserStore } from '../../actions/user';
import { openHelp } from '../../actions/help';
import { CHANGE_MOBILE_HELP } from '../../reducers/help';
import MaskData from 'maskdata';
import { useTimer } from 'react-timer-hook';

import styles from './Security.module.scss';
import useToast from '../../hooks/useToast.ts';
import { hasBannerId } from '../../utils/userUtils';

type AnyObject = Record<string, any>;

const Security = () => {
    const dispatch = useDispatch();
    const { addNewToast } = useToast();
    const loggedInUser: AnyObject = useSelector(getUserInfo);
    const agency: AnyObject = useSelector(getAgency);

    const [isSetupSmsAuth, setIsSetupSmsAuth] = useState(false);
    const [isSetupAuthenticationApp, setIsSetupAuthenticationApp] = useState(false);

    const [isDisableSmsAuth, setIsDisableSmsAuth] = useState(false);
    const [isDisableAuthenticationApp, setIsDisableAuthenticationApp] = useState(false);

    const [error, setError] = useState('');

    const { seconds, restart } = useTimer({ expiryTimestamp: new Date() });

    function startSms() {
        send2faCode.mutate({ method: TWO_FACTOR_AUTHENTICATION_SMS });
        setIsSetupSmsAuth(true);
    }
    function startGoogle() {
        setIsSetupAuthenticationApp(true);
    }
    function disableSms() {
        send2faCode.mutate({ method: TWO_FACTOR_AUTHENTICATION_SMS });
        setIsDisableSmsAuth(true);
    }
    function disableGoogle() {
        setIsDisableAuthenticationApp(true);
    }
    function closeSetUp2FaMethodWizard() {
        setIsSetupSmsAuth(false);
        setIsSetupAuthenticationApp(false);
        setIsDisableSmsAuth(false);
        setIsDisableAuthenticationApp(false);
    }

    function getDisabledMessage(method: any) {
        switch (method) {
            case TWO_FACTOR_AUTHENTICATION_SMS:
                return 'You have disabled SMS authentication';
            case TWO_FACTOR_AUTHENTICATION_APP:
                return 'You have disabled Google authentication';
            default:
                return 'You have disabled 2FA authentication';
        }
    }

    function getEnableMessage(method: any) {
        switch (method) {
            case TWO_FACTOR_AUTHENTICATION_SMS:
                return 'You have successfully enabled SMS authentication!';
            case TWO_FACTOR_AUTHENTICATION_APP:
                return 'You have successfully enabled Google authenticator!';
            default:
                return 'You have successfully enabled 2FA authentication';
        }
    }

    const update2faMethod = useMutation(
        (data: { method: string; code: string }) => {
            return axios.post(
                data.method === TWO_FACTOR_AUTHENTICATION_APP ? '/api/user/verify-totp-factor' : '/api/user/2fa-method',
                data
            );
        },
        {
            onSuccess: (result, variables) => {
                dispatch(updateUserStore(result));
                closeSetUp2FaMethodWizard();
                addNewToast(getEnableMessage(variables.method));
            }
        }
    );

    const disable2faMethodMutation = useMutation(
        (data: { method: string; code: string }) => {
            return axios.delete('/api/user/2fa-method', { data });
        },
        {
            onSuccess: (result, variables) => {
                dispatch(updateUserStore(result));
                closeSetUp2FaMethodWizard();
                addNewToast(getDisabledMessage(variables.method));
            }
        }
    );

    const send2faCode = useMutation(
        (data: { method: string }) => {
            setError('');
            return axios.post(`/api/user/send-2fa-code`, data);
        },
        {
            onSuccess: () => {
                const time = new Date();
                time.setSeconds(time.getSeconds() + 30);
                restart(time);
            },
            onError: error => {
                if (error?.response?.data?.error === VERIFICATION_CODE_LIMIT_MESSAGE) {
                    setError(VERIFICATION_CODE_LIMIT_MESSAGE);
                }
            }
        }
    );

    const change2faMethod = async (method: string, code: string) => {
        update2faMethod.reset();
        try {
            await update2faMethod.mutateAsync({ method, code });
        } catch (error) {
            return { code: error?.response?.data?.error };
        }
    };

    const disable2faMethod = async (method: string, code: string) => {
        disable2faMethodMutation.reset();
        try {
            await disable2faMethodMutation.mutateAsync({ method, code });
        } catch (error) {
            return { code: error?.response?.data?.error };
        }
    };

    return (
        <div>
            <div className={styles.securityContainer}>
                {/* Summary view. display all methods and their status (active or not) */}
                {!isSetupSmsAuth && !isSetupAuthenticationApp && !isDisableSmsAuth && !isDisableAuthenticationApp && (
                    <Card style={CardStyles.SQUARE} className={styles.securityMethodsCard}>
                        <h2 className={styles.header}>Two factor authentication settings</h2>
                        <div className={styles.row}>
                            <SecurityCard
                                title="SMS authentication"
                                description="Adds a second step to sign in via a code sent to your mobile."
                                headerIcon={Icons.SMS}
                                onClick={startSms}
                                disableAuth={disableSms}
                                active={loggedInUser.multiFactorAuthenticationMethods.includes(
                                    TWO_FACTOR_AUTHENTICATION_SMS
                                )}
                                error={error}
                            />
                        </div>
                        <div className={styles.row}>
                            <SecurityCard
                                title="Authentication app"
                                description='Adds a second step to sign in via third party apps like: <a href="https://apps.apple.com/au/app/google-authenticator/id388497605" target="_blank" >Google Authenticator</a> or <a href="https://apps.apple.com/us/app/twilio-authy/id494168017" target="_blank">Authy</a>'
                                headerIcon={Icons.GOOGLE_AUTH}
                                onClick={startGoogle}
                                disableAuth={disableGoogle}
                                active={loggedInUser.multiFactorAuthenticationMethods.includes(
                                    TWO_FACTOR_AUTHENTICATION_APP
                                )}
                            />
                        </div>
                    </Card>
                )}
                {/* Enable sms authentication */}
                {isSetupSmsAuth && (
                    <SecuritySetupCard
                        title="Enable SMS authentication"
                        description="Verify your mobile number"
                        label={`Enter the code send to ${loggedInUser.phone &&
                            MaskData.maskPhone(loggedInUser.phone, {
                                maskWith: '*',
                                unmaskedStartDigits: 0,
                                unmaskedEndDigits: 4
                            })}`}
                        buttonText="Send code again"
                        wrongDataText="Wrong mobile number?"
                        changeData={() => dispatch(openHelp(CHANGE_MOBILE_HELP))}
                        headerIcon={Icons.SMS}
                        goBack={closeSetUp2FaMethodWizard}
                        submit={change2faMethod}
                        method={TWO_FACTOR_AUTHENTICATION_SMS}
                        resend={() => send2faCode.mutate({ method: TWO_FACTOR_AUTHENTICATION_SMS })}
                        resendRemainingTime={seconds}
                        isSending2faCode={send2faCode.isLoading}
                        loading={update2faMethod.isLoading}
                        error={error}
                    />
                )}
                {/* Enable authentication app */}
                {isSetupAuthenticationApp && (
                    <AuthenticationAppSetupCard
                        title="Enable authentication app"
                        headerIcon={Icons.GOOGLE_AUTH}
                        goBack={closeSetUp2FaMethodWizard}
                        submit={change2faMethod}
                        method={TWO_FACTOR_AUTHENTICATION_APP}
                        loading={update2faMethod.isLoading}
                    />
                )}
                {/* Disable sms authentication */}
                {isDisableSmsAuth && (
                    <SecuritySetupCard
                        title="Disable SMS authentication"
                        description="To disable, please verify your phone number"
                        label={`Enter the code send to ${loggedInUser.phone &&
                            MaskData.maskPhone(loggedInUser.phone, {
                                maskWith: '*',
                                unmaskedStartDigits: 0,
                                unmaskedEndDigits: 4
                            })}`}
                        buttonText="Send code again"
                        isDisable
                        headerIcon={Icons.SMS}
                        goBack={closeSetUp2FaMethodWizard}
                        submit={disable2faMethod}
                        resend={() => send2faCode.mutate({ method: TWO_FACTOR_AUTHENTICATION_SMS })}
                        resendRemainingTime={seconds}
                        isSending2faCode={send2faCode.isLoading}
                        method={TWO_FACTOR_AUTHENTICATION_SMS}
                        loading={disable2faMethodMutation.isLoading}
                        error={error}
                    />
                )}
                {/* Disable authentication app */}
                {isDisableAuthenticationApp && (
                    <SecuritySetupCard
                        title="Disable authentication app authentication"
                        description="To disable, please verify your authentication app"
                        label="Enter the code sent to your authentication app"
                        isDisable
                        headerIcon={Icons.GOOGLE_AUTH}
                        goBack={closeSetUp2FaMethodWizard}
                        submit={disable2faMethod}
                        method={TWO_FACTOR_AUTHENTICATION_APP}
                        loading={disable2faMethodMutation.isLoading}
                    />
                )}
            </div>
            {/* After agent close the banner then we store it in DB. And never show it again */}
            {loggedInUser?.bannerActions && !hasBannerId(loggedInUser?.bannerActions, BANNER_ID_2FA) && (
                <Banner
                    title="Important tip"
                    message="Set up two authentication methods on multiple devices, like SMS and authentication app - in case you lose your phone"
                    bannerId={BANNER_ID_2FA}
                    agencyId={agency?.id}
                />
            )}
        </div>
    );
};

export default memo(Security);
