import { HelpSharp } from '@flk-mui-icons';
import WistiaEmbed from '../../../common/components/WistiaEmbed';
import React from 'react';
import { CREATE_A_FLK_DISPLAY } from '../../../config';
import { isBusinessUserAccount } from '../../../utils/userUtils';
import { useAccountProvider } from '../../../components/providers/AccountProvider';

export function CreateFlkHelp() {
    const accountType = useAccountProvider();

    return (
        <div className="help-message help-message-and-video">
            <HelpSharp />
            <p className="description">
                {CREATE_A_FLK_DISPLAY} allows you to create any workflow, document or agreement and send it to your
                client for review and signing.
            </p>
            {isBusinessUserAccount(accountType) && <WistiaEmbed hashedId="y9fepve97f" width="300" height="200" />}
            {!isBusinessUserAccount(accountType) && <WistiaEmbed hashedId="4tb96u896x" width="300" height="200" />}
        </div>
    );
}
