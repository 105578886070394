import React, { forwardRef, useState, useImperativeHandle, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { has } from 'lodash';
import { Form, FormSpy } from 'react-final-form';

import { updateSubmitTypeSuccess, setDirtyStep, formSubmitFail } from '../../../actions/lease';
import * as Lease from '../../../reducers/lease';

import { getLocation, getPremises, getStep, getDirtyStep, getLeaseType } from '../../../selectors/lease';
import { FormTextMultiline } from '../../../components/form/FormText';
import { FormRadioGroup } from '../../../components/form/FormRadioGroup';
import { YES_LABEL, NO_LABEL } from '../../../config';
import Warning from '../../../components/dashboard/warnings/Warning';

const PREMISES_FORM = 'premisesForm';
const initState = {
    premises: '',
    anyAttachedInventory: 'No'
};

function Premises(props, ref) {
    const dispatch = useDispatch();
    const reduxPremises = useSelector(getPremises);
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [premises, setPremises] = useState(initState);

    useEffect(() => {
        setPremises({
            ...initState,
            ...reduxPremises
        });
    }, []);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(PREMISES_FORM).dispatchEvent(
                new Event('submit', {
                    cancelable: true,
                    bubbles: true
                })
            );
        }
    }));

    const updatePremises = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/premises`, data);
    };

    const submitForm = values => {
        /**
         * Store the ref of the component so it can be used in this function
         * We need this otherwise the promise below doesn't have access to the ref
         */
        const currentRef = ref.current;

        /**
         * Grab the bypassFormValidation that was set from the parent component: LeaseAgreementForm.js
         */
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updatePremises(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    /**
                     * Callback after submit this form so that the parent component can take an action
                     */
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.premises')) {
                    return error.response.data.errors.premises;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    return (
        <div className="premises">
            <Form onSubmit={submitForm} initialValues={premises}>
                {({ handleSubmit, values, form }) => {
                    return (
                        <form onSubmit={handleSubmit} id={PREMISES_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <FormTextMultiline name="premises" label="Premises (the Premises) known as" required />
                            <FormRadioGroup
                                label="Any attached inventory signed forming part of this lease?"
                                name="anyAttachedInventory"
                                data={[
                                    {
                                        label: YES_LABEL,
                                        id: 'anyAttachedInventory-yes',
                                        value: YES_LABEL
                                    },
                                    {
                                        label: NO_LABEL,
                                        id: 'anyAttachedInventory-no',
                                        value: NO_LABEL
                                    }
                                ]}
                            />
                            {values.anyAttachedInventory === YES_LABEL && (
                                <Warning>
                                    You will be required to upload a copy of the inventory in the documents section
                                </Warning>
                            )}
                        </form>
                    );
                }}
            </Form>
        </div>
    );
}

export default memo(forwardRef(Premises));
