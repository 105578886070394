import * as React from 'react';
import { useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { editPermissions } from '../../../utils/userPermissions';
import { HIDE_MESSAGE_TIME, OPTION_TYPE_AGREE_ONLY, OPTION_TYPE_YES_OR_NO, tabs } from '../../../config';
import Panel from '../../../common/components/Panel';
import { getUserInfo } from '../../../selectors/user';
import { getAgency } from '../../../selectors/settings/account';
import { cloneDeep, has } from 'lodash';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { FormTextMultiline } from '../../../components/form/FormText';
import DoneIcon from '@flk-mui-icons/DoneSharp';
import yesNoIcon from '@flk-mui-icons/ThumbsUpDownSharp';
import { FormRadioGroupButton } from '../../../components/form/FormRadioGroupButton';
import { ReactComponent as PlusIcon } from '../../../../assets/images/icons/plus.svg';
import Button from '../../../common/components/Button';
import { confirmAlert } from 'react-confirm-alert';
import SaveButtonIcon from '../SaveButtonIcon';

interface Clause {
    description: string;
    optionType: string;
}

interface FormValues {
    clauseList: Clause[];
}

const ComplianceChecklistForm = () => {
    const loggedInUser: any = useSelector(getUserInfo);
    const agencySelected: any = useSelector(getAgency);
    const [formValues, setFormValues] = useState<FormValues>(agencySelected.complianceChecklist || { clauseList: [] });

    useEffect(() => {
        if (agencySelected.complianceChecklist) {
            setFormValues(agencySelected.complianceChecklist);
        }
    }, [agencySelected]);

    const saveComplianceChecklistSettings = useMutation((data: FormValues) => {
        return axios.put(`/api/agency/${agencySelected.id}/compliance-checklist`, data);
    });

    const saveChanges = async (values: FormValues) => {
        saveComplianceChecklistSettings.reset();
        try {
            await saveComplianceChecklistSettings.mutateAsync(values);
        } catch (error: any) {
            let err: any = {};
            if (has(error, 'response.data.errors.complianceChecklist')) {
                err = error.response.data.errors.complianceChecklist;
            }
            return err;
        }
    };

    const handleSubmit = async (values: FormValues) => {
        confirmAlert({
            title: '',
            message: 'Once you save this, all checklist answers in draft agreements will be removed.',
            buttons: [
                {
                    label: 'Cancel',
                    onClick: () => {}
                },
                {
                    label: 'OK',
                    onClick: async () => {
                        await saveChanges(values);
                    }
                }
            ]
        });
    };

    const userRole = loggedInUser.role;

    function removeItem(index: number, values: FormValues) {
        confirmAlert({
            title: '',
            message: 'Do you want to remove this item? Click Yes to confirm',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        const newConditions = cloneDeep(values);
                        newConditions.clauseList = [
                            ...newConditions.clauseList.slice(0, index),
                            ...newConditions.clauseList.slice(index + 1)
                        ];
                        setFormValues(newConditions);
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }

    return (
        <Form
            initialValues={formValues}
            mutators={{
                ...arrayMutators
            }}
            onSubmit={handleSubmit}
        >
            {({
                handleSubmit,
                values,
                form,
                form: {
                    mutators: { push }
                }
            }) => {
                return (
                    <form onSubmit={handleSubmit} id="complianceChecklistForm">
                        <Panel
                            title="RTA Checklist"
                            rightButton={
                                <div>
                                    {editPermissions(userRole, tabs.TAB_AGENCY_DETAILS) && (
                                        <SaveButtonIcon
                                            isSaving={saveComplianceChecklistSettings.isLoading}
                                            success={saveComplianceChecklistSettings.isSuccess}
                                            failure={saveComplianceChecklistSettings.isError}
                                        />
                                    )}
                                </div>
                            }
                        >
                            <p className="text-gray margin-bottom-1rem">
                                Create a checklist item to review prior to sending a lease
                            </p>
                            <FieldArray name="clauseList" initialValue={formValues.clauseList}>
                                {({ fields }) => (
                                    <React.Fragment>
                                        {fields.map((name, index) => {
                                            return (
                                                <div key={index} className="item-condition margin-bottom-1rem">
                                                    <div className="clauses">
                                                        <div className="clauses-header">
                                                            <h3>Checklist Item {index + 1}</h3>
                                                            <div
                                                                className="toggle"
                                                                onClick={() => removeItem(index, values)}
                                                            >
                                                                <span className="span-remove">Remove</span>
                                                            </div>
                                                        </div>
                                                        <div className="formBox-column">
                                                            <FormTextMultiline
                                                                name={`clauseList[${index}].description`}
                                                                label="Description"
                                                                required
                                                                maxlength="250"
                                                                small
                                                            />
                                                            <FormRadioGroupButton
                                                                className={'option-group'}
                                                                label=""
                                                                name={`clauseList[${index}].optionType`}
                                                                data={[
                                                                    {
                                                                        label: 'Yes/No',
                                                                        value: OPTION_TYPE_YES_OR_NO,
                                                                        icon: yesNoIcon
                                                                    },
                                                                    {
                                                                        label: 'Done',
                                                                        value: OPTION_TYPE_AGREE_ONLY,
                                                                        icon: DoneIcon
                                                                    }
                                                                ]}
                                                                validateOnTouch={false}
                                                                value={values.clauseList[index].optionType}
                                                                small
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </React.Fragment>
                                )}
                            </FieldArray>
                            <Button
                                type="button"
                                onClick={() =>
                                    push('clauseList', {
                                        description: '',
                                        optionType: ''
                                    })
                                }
                                startIcon={<PlusIcon />}
                            >
                                Add checklist item
                            </Button>
                        </Panel>
                    </form>
                );
            }}
        </Form>
    );
};

export default ComplianceChecklistForm;
