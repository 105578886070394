import React from 'react';
import ReactSelectAsync, { AsyncProps as ReactSelectAsyncProps } from 'react-select/async';
import cx from 'classnames';

// Define base option type
type BaseOptionType = {
    label: string;
    value: any;
};

type BaseGroupType<OptionType extends BaseOptionType> = {
    options: OptionType[];
    label: string;
};

// Augment ReactSelectAsyncProps
type AsyncSelectFieldProps<
    OptionType extends BaseOptionType = BaseOptionType,
    IsMulti extends boolean = false,
    GroupType extends BaseGroupType<OptionType> = BaseGroupType<OptionType>
> = {
    selectClassName?: string;
    label?: string;
    containerClassName?: string;
    'data-for'?: string;
    'data-tip'?: string;
    'aria-label'?: string;
} & ReactSelectAsyncProps<OptionType, IsMulti, GroupType>;

export const AsyncSelect = ({
    selectClassName,
    containerClassName,
    label,
    'data-tip': dataTip,
    'data-for': dataFor,
    'aria-label': ariaLabel,
    ...selectProps
}: AsyncSelectFieldProps) => {
    return (
        <div className={cx('FormInput', containerClassName)} data-for={dataFor} data-tip={dataTip}>
            <div className="form-group">
                {label && <label>{label}</label>}
                <ReactSelectAsync
                    className={cx('form-select', selectClassName)}
                    classNamePrefix="react-select"
                    aria-label={ariaLabel}
                    {...selectProps}
                />
            </div>
        </div>
    );
};
