import React, { memo, useState, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { has } from 'lodash';

import * as Lease from '../../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../../selectors/lease';
import { getPmRentAndTerm } from '../../../../selectors/lease/pmLease';

import { PERIOD_MONTHLY } from '../../../../config';
import { FormTextCurrency } from '../../../../components/form/FormText';

import '../../../../sass/management/rentAndTerm.scss';
import { getRentForPeriod } from '../../../../utils/agreementUtils';

const PM_RENT_TERM_FORM = 'pmRentAndTermForm';
const initState = {
    rent: null
};

const RentTerm = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxPmRentAndTerm = useSelector(getPmRentAndTerm);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [pmRentAndTerm, setPmRentAndTerm] = useState(reduxPmRentAndTerm || initState);
    const [rentAmountPerMonth, setRentAmountPerMonth] = useState(reduxPmRentAndTerm?.rentAmountPerMonth || '0.00');

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(PM_RENT_TERM_FORM).dispatchEvent(
                new Event('submit', {
                    cancelable: true,
                    bubbles: true
                })
            );
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        values.rentAmountPerMonth = rentAmountPerMonth;
        return axios
            .post(`/api/agency/lease/${props.leaseId}/pm-rent-and-term`, values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.pmRentAndTerm')) {
                    return error.response.data.errors.pmRentAndTerm;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    function increaseToNextWholeNumber() {
        const newMonthlyRent = Math.floor(parseFloat(rentAmountPerMonth)) + 1;
        setRentAmountPerMonth(newMonthlyRent.toFixed(2));
    }

    function decreaseToNextWholeNumber() {
        const newMonthlyRent = Math.ceil(parseFloat(rentAmountPerMonth)) - 1;
        if (newMonthlyRent >= 0) {
            setRentAmountPerMonth(newMonthlyRent.toFixed(2));
        }
    }

    return (
        <div className="form-container rent rent-term">
            <Form onSubmit={submitForm} initialValues={pmRentAndTerm}>
                {({ handleSubmit, values, form }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={PM_RENT_TERM_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <React.Fragment>
                                <p>
                                    This Authority remains valid upon leasing the Property at any other rental amount
                                    amount agreed to by the Client.
                                </p>
                                <FormTextCurrency
                                    name="rent"
                                    label="Rent amount per week"
                                    onChange={value => {
                                        setRentAmountPerMonth(getRentForPeriod(value, PERIOD_MONTHLY));
                                    }}
                                    precision={2}
                                    required
                                />
                                <p className="help-text big monthly-rent">
                                    Rent amount per month{' '}
                                    <span className="button-minus" onClick={() => decreaseToNextWholeNumber(values)} />
                                    <span>{`$${rentAmountPerMonth}`}</span>
                                    <span className="button-plus" onClick={() => increaseToNextWholeNumber(values)} />
                                </p>
                            </React.Fragment>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(RentTerm);
