import { InfoSharp } from '@flk-mui-icons';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import styles from './ConnectionToolTip.module.scss';
import cx from 'classnames';

type ConnectionToolTipProps = {
    title: string;
    toolTip: string;
};

const ConnectionToolTip: React.FC<ConnectionToolTipProps> = ({ title, toolTip }) => {
    return (
        <>
            <ReactTooltip id="integrationInfo" class="tooltip" place="bottom" effect="solid" globalEventOff="wheel" html={true}>
                {toolTip}
            </ReactTooltip>
            <p className={cx(styles.label, 'gray-label')}>
                <span data-tip={true} data-for="integrationInfo">
                    {title}
                    <InfoSharp className="tooltip-info-icon" />
                </span>
            </p>
        </>
    );
};

export default ConnectionToolTip;
