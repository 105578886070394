import React, { memo, useState, forwardRef, useEffect, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import { has, cloneDeep, isString } from 'lodash';
import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../actions/lease';
import axios from 'axios';
import * as Lease from '../../../reducers/lease';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { getLocation, getLessee, getStep, getDirtyStep, getLeaseType } from '../../../selectors/lease';
import LesseeFields from './LesseeFields';
import { CheckboxCheck } from '../../../components/form/FormCheckboxCheck';
import { FormMaskedText } from '@app/components/form/FormMaskedText';
import { FormTextRegular } from '../../../components/form/FormText';
import FormSection from '../../../components/form/FormSection';
import Line from '../../../common/components/Line';

const LESSEE_FORM = 'LesseeForm';
const personDefault = {
    firstName: '',
    middleName: '',
    secondName: '',
    phone: '',
    email: '',
    extraContacts: [],
    postcode: '',
    address: '',
    abn: '',
    acn: ''
};

const initState = {
    abn: '',
    acn: '',
    corporationName: '',
    corporationAddress: '',
    persons: [personDefault]
};

function Lessee(props, ref) {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxLessee = useSelector(getLessee);
    const [lessee, setLessee] = useState(initState);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(LESSEE_FORM).dispatchEvent(
                new Event('submit', {
                    cancelable: true,
                    bubbles: true
                })
            );
        }
    }));

    useEffect(() => {
        if (reduxLessee) {
            setLessee({ ...initState, ...reduxLessee });
        }
    }, [reduxLessee]);

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    function submitForm(formValues) {
        let values = cloneDeep(formValues);
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        let lesseeValues = cloneDeep(values);
        return axios
            .post(`/api/agency/lease/${props.leaseId}/lessee`, lesseeValues)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.lessee')) {
                    return error.response.data.errors.lessee;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    }

    return (
        <div className="tenant">
            <Form
                onSubmit={submitForm}
                initialValues={lessee}
                mutators={{
                    ...arrayMutators
                }}
            >
                {({
                    handleSubmit,
                    values,
                    form,
                    form: {
                        mutators: { push }
                    }
                }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={LESSEE_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <CheckboxCheck
                                name={`isCorporation`}
                                className="is-corporation"
                                label="Lessee is a company"
                            />
                            {values.isCorporation && (
                                <>
                                    <div className="form-section">
                                        <p className="form-section__heading">Company</p>
                                        <div className="form-section__body">
                                            <FormTextRegular name={`corporationName`} label="Company name" required />
                                            <FormTextRegular
                                                name={`corporationAddress`}
                                                label="Company registered address"
                                                required
                                            />
                                            <FormMaskedText
                                                dataTest="lessee-abn"
                                                name="abn"
                                                label="ABN"
                                                mask="11 111 111 111"
                                                required
                                            />
                                            <FormMaskedText
                                                dataTest="lessee-acn"
                                                name="acn"
                                                label="ACN"
                                                mask="111 111 111"
                                            />
                                            <CheckboxCheck name="isRegisteredForGST" label="Registered for GST" />
                                        </div>
                                    </div>
                                    <Line />
                                </>
                            )}
                            <FieldArray name="persons" initialValue={lessee.persons}>
                                {({ fields }) => (
                                    <React.Fragment>
                                        {fields.map((name, index) => {
                                            return (
                                                <FormSection
                                                    key={index}
                                                    title={`Signatory ${index + 1}`}
                                                    showRemoveButton={index > 0}
                                                    removeButtonProps={{
                                                        onClick: () => fields.remove(index),
                                                        label: 'Remove'
                                                    }}
                                                >
                                                    <LesseeFields
                                                        index={index}
                                                        push={push}
                                                        values={values}
                                                        key={index}
                                                        person={fields.value[index]}
                                                    />
                                                </FormSection>
                                            );
                                        })}
                                    </React.Fragment>
                                )}
                            </FieldArray>
                            <div className="button">
                                <button
                                    className="add-person"
                                    type="button"
                                    onClick={() => {
                                        push('persons', {});
                                    }}
                                >
                                    Add lessee
                                </button>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
}

export default memo(forwardRef(Lessee));
