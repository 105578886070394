import { CompletionStatus } from '@app/config';
import { Agency } from './Agency';
import { Agent } from './Agent';
import { AddressDetails } from './Common';
import { DEFINE_ME, DateString } from './utilityTypes';

export type UploadedDocument = {
    pdfGeneratedState: string;
    annotations: string;
    annotationsCount: string;
    witnessAnnotationsCount: string;
    id: string;
    document: {
        category: string;
        leaseType: string;
        agencyDefault: boolean;
        id: string;
        created: DateString;
        updated: DateString;
        documentName: string;
        size: number;
        file: string;
        fileName: string;
    };
    optimisedPdfFile: boolean;
    confirmedPdf: string;
    insertedSignaturesPdf: string;
};

export type Client = {
    id: string;
    name: string;
    email: string;
    phone: string;
    source: string;
    signingOrder?: number;
    uploadedDocumentsMeta?: {
        read: boolean;
        id: string;
        uploadedDocumentId: string;
        parentDocumentId: string;
        documentName: string;
    }[];
    acknowledgedDate?: DateString;
    signature?: {
        signedDate: string;
        ip: string;
        signedDateFormatted: string;
        signedDateFormattedForPdf: string;
        id: string;
    };
    isSigned?: boolean;
};

export enum CustomPlaceholderRespondentType {
    SENDER = 'sender',
    CLIENT = 'client'
}

export type CustomPlaceholder = {
    id: string;
    label: string;
    value?: string;
    respondentType: CustomPlaceholderRespondentType;
    count: number;
};

type Completion = {
    progress?: {
        completed: boolean;
        stepNumber: number;
        step: string;
        error: string;
    }[];
    status?: CompletionStatus;
    sendSeparateEmails: boolean;
    toNotificationList: string[];
    ccNotificationList: string[];
    bccNotificationList: string[];
    emails: {
        clientEmail: string;
        toNotificationList: string[];
        ccNotificationList: string[];
        bccNotificationList: string[];
    }[];
    signedByAgent: boolean;
};

type ListNow = {
    enabled?: boolean;
    address?: string;
    budget?: number;
    addressDetails?: AddressDetails;
};
// This is an in-progress type definition
export type UploadADoc = {
    id: string;
    additionalCharges: {
        requiresApproval: boolean;
        bulkSendApprovalStatus: string;
    };
    isSentViaContactList: boolean;
    createdViaEmail: boolean;
    docType: string;
    pdfGeneratedState: string;
    locked: boolean;
    visibilityList: DEFINE_ME[]; // Could be an array of specific types if known
    signingClients: DEFINE_ME[]; // Could be an array of specific types if known
    documentTitle: string;
    status: string;
    sentForSigning?: DateString;
    firstSentForSigning: null;
    agency: string | Agency;
    agent: Agent;
    documentLink?: DEFINE_ME;
    client?: {
        id: string;
        clients: Client[];
        currentSigningClientOrder?: number;
    };
    documents: {
        category: string;
        leaseType: string;
        agencyDefault: boolean;
        id: string;
        documentName: string;
        size: number;
        file: string;
        fileName: string;
    }[];
    uploadedDocuments: UploadedDocument[];
    created: DateString;
    updated: DateString;
    auditTrail: DEFINE_ME[]; // Could be an array of specific types if known
    acknowledgementText: string;
    confirmationType: string;
    deadline: number;
    deliveryType?: string;
    descriptionText: string;
    executionType: string;
    hasAgentSignatureAnnotations?: boolean;
    acknowledgedDate?: DateString;
    customPlaceholders?: CustomPlaceholder[];
    integrationPropertyId?: string;
    address?: string;
    completion?: Completion;
    listNow?: ListNow;
    enforceSequentialSigning?: boolean;
    isLinkExpired?: boolean;
};
