import React from 'react';
import cx from 'classnames';
import { Field } from 'react-final-form';
import { InfoSharp } from '@flk-mui-icons';
import ReactTooltip from 'react-tooltip';
import { getError } from '../../utils/formUtils';
import parse from 'html-react-parser';

import '../../sass/form-chekbox-notice-to-leave.scss';

const CheckboxInputNoticeToLeave = ({
    input,
    dataTest,
    className,
    inputClassName,
    labelClassName,
    topAlignInput,
    text,
    onClick,
    disabled,
    validateOnTouch,
    meta,
    children,
    toolTip
}) => {
    return (
        <div className="FormInput FormInputCheck">
            <div
                data-test={dataTest}
                className={cx('checkbox-container', className, {
                    active: input.checked,
                    disabled: disabled,
                    'checkbox-container--top-aligned-input': topAlignInput
                })}
                onClick={() => {
                    if (!disabled) {
                        input.onChange(!input.checked);
                        if (onClick) {
                            onClick(!input.checked);
                        }
                    }
                }}
            >
                <div
                    className={cx('input-checkbox', inputClassName, {
                        active: input.checked,
                        disabled: disabled,
                        'input-checkbox--top-aligned': topAlignInput
                    })}
                />
                <div className={cx('checkbox-label', labelClassName, { disabled: disabled })}>
                    {text ? text : children}
                </div>
                {toolTip && <InfoSharp className="tooltip-info-icon text-gray" data-tip={true} data-for={input.name} />}
                <ReactTooltip id={input.name} class="tooltip" place="bottom" effect="solid" globalEventOff="wheel">
                    <p>{parse(`${toolTip}`)} </p>
                </ReactTooltip>
            </div>
            {getError(meta, validateOnTouch) && <p className={'FormError'}>{getError(meta, validateOnTouch)}</p>}
        </div>
    );
};

export const CheckboxNoticeToLeave = ({
    name,
    dataTest,
    className,
    inputClassName,
    labelClassName,
    topAlignInput,
    text,
    onClick,
    disabled,
    validateOnTouch = false,
    children,
    toolTip
}) => {
    return (
        <Field
            name={name}
            dataTest={dataTest}
            className={className}
            topAlignInput={topAlignInput}
            inputClassName={inputClassName}
            labelClassName={labelClassName}
            component={CheckboxInputNoticeToLeave}
            type={'checkbox'}
            onClick={onClick}
            text={text}
            disabled={disabled}
            validateOnTouch={validateOnTouch}
            toolTip={toolTip}
        >
            {children}
        </Field>
    );
};
