import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Table, Th, Td, Tr, Thead } from 'reactable';
import LeaseDropdown from '../../../dropdowns/LeaseDropdown';
import renewalsIcon from '../../../../../assets/images/icons/renewals-icon.svg?url';
import AgentLogo from '../../../AgentLogo';
import { formatDateStandard, getAgencyTimezoneFromUser } from '../../../../utils/dateUtils';
import { getUserInfo } from '../../../../selectors/user';
import { useSelector } from 'react-redux';
import { isBefore, differenceInDays, endOfDay } from 'date-fns';

import '../../../../sass/dashboard/awaitingRenewal.scss';
import RenewalIntentionIndicator from './TableRenewals/RenewalIntentionIndicator';
import { cloneDeep, isEmpty } from 'lodash';
import * as dashboard from '../../../../actions/dashboard';
import { getSavedTeamMemberSelection } from '../../../../selectors/user';
import { defaultPage, defaultRecordsPerPage, LEASE_TYPE_RESIDENTIAL } from '../../../../config';
import { openIntentionRenewalModal } from '../../../../actions/dashboard';
import { getLabel } from '../../../../utils/labelUtils';
import { isAccountEnabled } from '../../../../utils/userUtils';

const SORT_OPTION_ADDRESS = 'address';
const SORT_OPTION_TENANTS = 'tenant.tenants.0.firstName';
const SORT_OPTION_START_DATE = 'term.startDate';
const SORT_OPTION_TERM = 'term.qty';
const SORT_OPTION_END_DATE = 'term.endDate';
const SORT_OPTION_ENDS_IN = 'term.endDate';
const SORT_OPTION_RENEWAL_INTENTION = 'renewalIntention.isTenantRenewing';
const SORT_OPTION_RENEWAL_INTENTION_SECONDARY = 'renewalIntention.created';

const TableRenewals = ({ leaseStatus, agreementList, leaseType, showModalInfoAgreement }) => {
    const dispatch = useDispatch();
    const loggedInUser = useSelector(getUserInfo);
    const savedTeamMemberSelection = useSelector(getSavedTeamMemberSelection);

    const [sortOptions, setSortOptions] = useState({ [SORT_OPTION_END_DATE]: 1 });

    useEffect(() => {
        return () => dispatch(dashboard.updateSortOptions(leaseStatus, {}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getNumberOfDaysTillEnd = endDate => {
        // Set the time of the end date to the end of the day
        return differenceInDays(endOfDay(new Date(endDate)), new Date());
    };

    const getClassForEndIn = endDate => {
        let endsInDays = getNumberOfDaysTillEnd(endDate);

        return classNames({
            critical: endsInDays <= 3,
            warning: endsInDays > 3 && endsInDays <= 7
        });
    };

    const displayNumberOfDaysTillEnd = endDate => {
        if (isBefore(new Date(endDate), new Date())) {
            return 'Expired';
        }

        let endsInDays = getNumberOfDaysTillEnd(endDate);

        if (endsInDays === 0) {
            return 'Today';
        }

        return `${endsInDays}d`;
    };

    const openModal = lease => {
        dispatch(openIntentionRenewalModal(lease.id, lease));
    };

    const onChangeSort = item => {
        const oldSort = cloneDeep(sortOptions);
        let newSort = {};
        switch (oldSort[item]) {
            // if current sort option is 1(asc) then next option is -1(desc)
            case 1: {
                newSort[item] = -1;
                break;
            }
            // if current sort option is -1(desc) then next option is no sort
            case -1: {
                newSort = {};
                break;
            }
            // default means we do not have any sort option. then next sort option is 1(asc)
            default: {
                newSort[item] = 1;
                break;
            }
        }
        // we need two fields to sort renewal intention column
        if (newSort[SORT_OPTION_RENEWAL_INTENTION]) {
            newSort[SORT_OPTION_RENEWAL_INTENTION_SECONDARY] = 1;
        }
        setSortOptions(newSort);
        if (isEmpty(savedTeamMemberSelection)) {
            dispatch(
                dashboard.getAgreementList(
                    LEASE_TYPE_RESIDENTIAL,
                    defaultPage,
                    defaultRecordsPerPage,
                    leaseStatus,
                    undefined,
                    false,
                    newSort
                )
            );
        } else {
            dispatch(
                dashboard.getAgreementList(
                    LEASE_TYPE_RESIDENTIAL,
                    defaultPage,
                    defaultRecordsPerPage,
                    leaseStatus,
                    savedTeamMemberSelection,
                    false,
                    newSort
                )
            );
        }
        // update redux for use  sort options in pagination
        dispatch(dashboard.updateSortOptions(leaseStatus, newSort));
    };

    const getClassName = item => {
        let newClassName = 'no-sort';
        if (sortOptions[item] === 1) {
            newClassName = 'sort-asc';
        } else if (sortOptions[item] === -1) {
            newClassName = 'sort-desc';
        }
        return newClassName;
    };

    const startDateHeader = leaseType.toLowerCase() !== 'sales' ? 'lease start date' : 'agreement start date';
    return (
        <React.Fragment>
            <Table>
                <Thead>
                    <Th column="agentLogo"> </Th>

                    <Th column="address">
                        <div
                            className={`table-header-sort ${getClassName(SORT_OPTION_ADDRESS)}`}
                            onClick={() => onChangeSort(SORT_OPTION_ADDRESS)}
                        >
                            address
                        </div>
                    </Th>
                    <Th column="tenants">
                        <div
                            className={`table-header-sort ${getClassName(SORT_OPTION_TENANTS)}`}
                            onClick={() => onChangeSort(SORT_OPTION_TENANTS)}
                        >
                            {getLabel('signorsHeader', null, leaseType)}
                        </div>
                    </Th>
                    <Th column="startDate">
                        <div
                            className={`table-header-sort ${getClassName(SORT_OPTION_START_DATE)}`}
                            onClick={() => onChangeSort(SORT_OPTION_START_DATE)}
                        >
                            {startDateHeader}
                        </div>
                    </Th>
                    <Th column="term">
                        <div>term</div>
                    </Th>
                    <Th column="finishDate">
                        <div
                            className={`table-header-sort ${getClassName(SORT_OPTION_END_DATE)}`}
                            onClick={() => onChangeSort(SORT_OPTION_END_DATE)}
                        >
                            finish date
                        </div>
                    </Th>
                    <Th column="endsIn">
                        <div
                            className={`table-header-sort ${getClassName(SORT_OPTION_ENDS_IN)}`}
                            onClick={() => onChangeSort(SORT_OPTION_ENDS_IN)}
                        >
                            ends in
                        </div>
                    </Th>
                    <Th column="renewalIntention">
                        <div
                            className={`table-header-sort ${getClassName(SORT_OPTION_RENEWAL_INTENTION)}`}
                            onClick={() => onChangeSort(SORT_OPTION_RENEWAL_INTENTION)}
                        >
                            Renewal intent
                        </div>
                    </Th>
                    <Th column="action">&nbsp;</Th>
                </Thead>
                {agreementList &&
                    agreementList.map((lease, key) => {
                        const loadingClassName = lease.isLoading ? 'loading' : '';

                        return (
                            <Tr
                                key={key}
                                className={`showmodal ${loadingClassName}`}
                                onClick={() => {
                                    showModalInfoAgreement(lease);
                                }}
                            >
                                <Td column="agentLogo">
                                    <AgentLogo agent={lease.agent} />
                                </Td>

                                {lease.isRenewal && (
                                    <Td column="isRenewal">
                                        <img src={renewalsIcon} />
                                    </Td>
                                )}
                                <Td column="address">
                                    <b>{lease.address}</b>
                                </Td>
                                <Td column="tenants">
                                    {leaseType.toLowerCase() !== 'sales'
                                        ? (lease.tenant && lease.tenant.tenantsListText) || '-'
                                        : (lease.signatory && lease.signatory.signatoryListText) || '-'}
                                </Td>
                                <Td column="startDate">
                                    {formatDateStandard(
                                        lease.term && lease.term.startDate,
                                        getAgencyTimezoneFromUser(loggedInUser)
                                    ) || '-'}
                                </Td>
                                <Td column="term">{lease.term ? `${lease.term.qty} ${lease.term.period} ` : '-'}</Td>
                                <Td column="finishDate">
                                    {formatDateStandard(
                                        lease.term && lease.term.endDate,
                                        getAgencyTimezoneFromUser(loggedInUser)
                                    ) || '-'}
                                </Td>
                                <Td column="endsIn" className={getClassForEndIn(lease.term.endDate)}>
                                    {displayNumberOfDaysTillEnd(lease.term.endDate)}
                                </Td>
                                <Td column="renewalIntention">
                                    <div>
                                        <RenewalIntentionIndicator renewalIntention={lease.renewalIntention} />
                                    </div>
                                </Td>
                                {isAccountEnabled(loggedInUser) && (
                                    <Td column="action">
                                        <div className="action">
                                            <LeaseDropdown
                                                loggedInUser={loggedInUser}
                                                lease={lease}
                                                leaseStatus={leaseStatus}
                                            >
                                                <div className="more-action" />
                                            </LeaseDropdown>
                                            <span className="arrow-right" />
                                        </div>
                                    </Td>
                                )}
                            </Tr>
                        );
                    })}
            </Table>
        </React.Fragment>
    );
};

export default TableRenewals;
