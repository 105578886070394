import React from 'react';
import { Table, Th, Tr, Td, Thead } from 'reactable';
import LeaseDropdown from '../../../dropdowns/LeaseDropdown';
import AgentLogo from '../../../AgentLogo';
import renewalsIcon from '../../../../../assets/images/icons/renewals-icon.svg?url';
import { formatDateTime, getAgencyTimezoneFromUser } from '../../../../utils/dateUtils';
import { getUserInfo } from '../../../../selectors/user';
import { useSelector } from 'react-redux';
import { getLabel, getSubLeaseTypeLabel } from '../../../../utils/labelUtils';
import {
    getTenantNames,
    hasSaExtensions,
    isLeaseTypeResidentialTenancy,
    isLeaseTypeSales
} from '../../../../utils/agreementUtils';
import { isAccountEnabled } from '../../../../utils/userUtils';

const TableAwaiting = ({ leaseStatus, agreementList, leaseType, showModalInfoAgreement }) => {
    const loggedInUser = useSelector(getUserInfo);
    const haveSaExtensions = hasSaExtensions(agreementList);
    const tableHead = leaseType => {
        if (leaseType === 'sales') {
            return (
                <Thead>
                    <Th column="agentLogo"> </Th>
                    <Th column="isRenewal"> </Th>
                    <Th column="address">address</Th>
                    <Th column="agent">agent</Th>
                    <Th column="tenants">{getLabel('signorsHeader', null, leaseType)}</Th>
                    <Th column="signed">signed</Th>
                    <Th column="action">&nbsp;</Th>
                </Thead>
            );
        } else {
            return (
                <Thead>
                    <Th column="agentLogo"> </Th>
                    <Th column="isRenewal"> </Th>
                    <Th column="address">address</Th>
                    {haveSaExtensions && <Th column="subLeaseType">lease type</Th>}
                    <Th column="tenants">{getLabel('signorsHeader', null, leaseType)}</Th>
                    <Th column="signed">signed</Th>
                    <Th column="action">&nbsp;</Th>
                </Thead>
            );
        }
    };
    return (
        <Table className="pm-awaiting-tb">
            {tableHead(leaseType)}
            {agreementList &&
                agreementList.map((lease, key) => {
                    const loadingClassName = lease.isLoading ? 'loading' : '';
                    return (
                        <Tr
                            key={key}
                            className={`showmodal ${loadingClassName}`}
                            onClick={() => {
                                showModalInfoAgreement(lease);
                            }}
                        >
                            <Td column="agentLogo">
                                <AgentLogo agent={lease.agent} />
                            </Td>
                            {lease.isRenewal && (
                                <Td className="desktop-only" column="isRenewal">
                                    <img src={renewalsIcon} />
                                </Td>
                            )}
                            <Td column="address">
                                <b>{lease.address}</b>
                            </Td>
                            {haveSaExtensions && isLeaseTypeResidentialTenancy(leaseType) && (
                                <Td column="subLeaseType">{getSubLeaseTypeLabel(lease.subLeaseType)}</Td>
                            )}
                            {isLeaseTypeSales(leaseType) && <Td column="agent">{lease.agent.fullName}</Td>}
                            <Td column="tenants">{getTenantNames(lease)}</Td>
                            <Td column="signed">
                                {formatDateTime(lease.signedByAllDate, getAgencyTimezoneFromUser(loggedInUser))}
                            </Td>
                            {isAccountEnabled(loggedInUser) && (
                                <Td className="desktop-only" column="action">
                                    <div className="action">
                                        <LeaseDropdown lease={lease} leaseStatus={leaseStatus}>
                                            <div className="more-action" />
                                        </LeaseDropdown>
                                        <span className="arrow-right" />
                                    </div>
                                </Td>
                            )}
                        </Tr>
                    );
                })}
        </Table>
    );
};

export default TableAwaiting;
