import { AccountType } from '@app/constants/constants';
import { TariffPlan } from '@app/types/TariffPlan';
import AccountBalanceSharpIcon from '@flk-mui-icons/AccountBalanceSharp';
import CreditCardSharpIcon from '@flk-mui-icons/CreditCardSharp';
import { AuBankAccountElement, CardElement } from '@stripe/react-stripe-js';
import { FC, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { createSetupIntent } from '../../../../actions/account.js';
import {
    dispatchUpdatingPaymentDetails,
    dispatchUpdatingPaymentDetailsFail,
    resetPaymentState,
    updatePaymentDetails
} from '../../../../actions/subscription.js';
import ModalDialog from '../../../../common/components/ModalDialog.js';
import '../../../../sass/changePaymentDetailsModal.scss';
import { pushPaymentDetailsToGTM } from '../../../../utils/marketingUtils';
import { FormEmail } from '../../../form/FormEmail.js';
import { FormText } from '../../../form/FormText.js';

import {
    SetupIntent,
    SetupIntentResult,
    Stripe,
    StripeElementChangeEvent,
    StripeElements,
    StripeError
} from '@stripe/stripe-js';
import React from 'react';
import useCountry from '../../../../hooks/useCountry';
import { getAccountType, getDefaultPaymentMethod, getTariffPlan } from '../../../../selectors/account.js';
import { getAgencyId } from '../../../../selectors/agency.js';
import { getIsUpdatingPaymentDetails } from '../../../../selectors/subscription.js';

const TAB_INDEX_CREDIT_CARD = 0;
const TAB_INDEX_DIRECT_DEBIT = 1;
const PAYMENT_METHOD_CREDIT_CARD = 'card';
const PAYMENT_METHOD_DIRECT_DEBIT = 'direct_debit';

type AuBankAccountElementType = {
    accountHolderName?: string;
    accountHolderEmail?: string;
};

interface ChangePaymentDetailsModalProps {
    closeModal: () => void;
    elements: StripeElements | null;
    isOpen: boolean;
    stripe: Stripe | null;
}

const ChangePaymentDetailsModal: FC<ChangePaymentDetailsModalProps> = props => {
    const { closeModal, elements, isOpen, stripe } = props;
    const dispatch = useDispatch();
    const { isNewZealand } = useCountry();

    const [tabIndex, setTabIndex] = useState(0);
    const [cardErrors, setCardErrors] = useState<string>('');
    const [errors, setErrors] = useState<string>('');
    const [directDebitErrors, setDirectDebitErrors] = useState<string>('');

    const agencyId: string | undefined = useSelector(getAgencyId);
    const isUpdatingPaymentDetails: boolean = useSelector(getIsUpdatingPaymentDetails);
    const defaultPaymentMethod = useSelector(getDefaultPaymentMethod);
    const tariffPlan: TariffPlan = useSelector(getTariffPlan);
    const accountType: AccountType = useSelector(getAccountType);

    const handleUpdatePaymentDetails = (agencyId: string, data: unknown) => {
        dispatch(updatePaymentDetails(agencyId, data));
    };
    const handleUpdatingPaymentDetails = () => {
        dispatch(dispatchUpdatingPaymentDetails());
    };
    const handleUpdatingPaymentDetailsFail = (error: unknown) => {
        dispatch(dispatchUpdatingPaymentDetailsFail(error));
    };
    const handleResetPaymentState = () => {
        dispatch(resetPaymentState());
    };

    useEffect(() => {
        if (!isOpen) {
            handleResetPaymentState();
            setCardErrors('');
            setDirectDebitErrors('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const getPaymentMethod = (tabIndex: number): string => {
        if (tabIndex === TAB_INDEX_DIRECT_DEBIT) {
            return PAYMENT_METHOD_DIRECT_DEBIT;
        } else if (tabIndex === TAB_INDEX_CREDIT_CARD) {
            return PAYMENT_METHOD_CREDIT_CARD;
        } else {
            return 'NO PAYMENT METHOD SELECTED';
        }
    };

    /**
     * handle first time card added details by pushing
     * conversion to the data layer
     * requested by digital minds
     */
    const pushMarketingData = () => {
        // don't push if they already had a payment method
        if (defaultPaymentMethod) {
            return;
        }
        pushPaymentDetailsToGTM(accountType, tariffPlan);
    };

    const hasStripeError = (result: SetupIntentResult): result is { error: StripeError } => {
        return !!result.error;
    };

    /**
     * Handle payment result, show errors or close tab etc
     * @param result
     */
    const handlePaymentResult = (result: SetupIntentResult) => {
        if (hasStripeError(result)) {
            // if on tab direct debit
            if (tabIndex === TAB_INDEX_DIRECT_DEBIT) {
                setDirectDebitErrors(result.error.message ?? '');
            } else if (tabIndex === TAB_INDEX_CREDIT_CARD) {
                setCardErrors(result.error.message ?? '');
            } else {
                setErrors('No tab selected');
            }

            const errorInServerFormat = {
                response: {
                    data: result.error
                }
            };

            handleUpdatingPaymentDetailsFail(errorInServerFormat);
        } else {
            if (!agencyId) return;

            pushMarketingData();
            handleUpdatePaymentDetails(agencyId, {
                paymentMethod: getPaymentMethod(tabIndex),
                setupIntent: result.setupIntent
            });

            // After the payment details have been updated, get the tariffPlan after 7.5 seconds, by then stripe should have sent a webhook and updated the plan
            // Temporarily just update the window as this component needs a refactor but that will come later
            setTimeout(() => {
                window.location.reload();
            }, 7500);
        }
    };

    const submitForm = (values: AuBankAccountElementType) => {
        if (!stripe || !elements || !agencyId) return;

        const cardElement = elements.getElement('card');
        const auBankAccountElement = elements.getElement('auBankAccount');

        handleUpdatingPaymentDetails();

        createSetupIntent(agencyId)
            .then((response: { data: { setupIntent: SetupIntent } }) => {
                const paymentIntentClientSecret = response.data.setupIntent.client_secret ?? '';

                if (tabIndex === TAB_INDEX_DIRECT_DEBIT) {
                    if (!auBankAccountElement) {
                        throw new Error('auBankAccountElement is null');
                    }
                    return stripe
                        .confirmAuBecsDebitSetup(paymentIntentClientSecret, {
                            payment_method: {
                                au_becs_debit: auBankAccountElement,
                                billing_details: {
                                    name: values.accountHolderName ?? '',
                                    email: values.accountHolderEmail ?? ''
                                }
                            }
                        })
                        .then(handlePaymentResult);
                } else {
                    if (!cardElement) {
                        throw new Error('cardElement is null');
                    }
                    return stripe
                        .confirmCardSetup(paymentIntentClientSecret, {
                            payment_method: {
                                card: cardElement
                            }
                        })
                        .then(handlePaymentResult);
                }
            })
            .catch((error: StripeError) => {
                setErrors(error.message ?? '');

                const errorInServerFormat = {
                    response: {
                        data: error.message
                    }
                };
                dispatchUpdatingPaymentDetailsFail(errorInServerFormat);
            });
    };

    const selectTab = (tabIndex: number) => {
        setTabIndex(tabIndex);
        setCardErrors('');
        setDirectDebitErrors('');
        setErrors('');
    };

    const onChangeDirectDebit = (event: StripeElementChangeEvent) => {
        setDirectDebitErrors(event.error ? event.error.message : '');
    };

    return (
        <ModalDialog
            title="Update payment details"
            isOpen={isOpen}
            closeModal={closeModal}
            className="payment-details-modal"
        >
            <Form
                onSubmit={values => submitForm(values)}
                validate={values => {
                    const errors: AuBankAccountElementType = {};

                    if (tabIndex === TAB_INDEX_DIRECT_DEBIT) {
                        if (!values.accountHolderName) {
                            errors.accountHolderName = 'Required';
                        }
                        if (!values.accountHolderEmail) {
                            errors.accountHolderEmail = 'Required';
                        }
                    }
                    return errors;
                }}
            >
                {({ handleSubmit }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <Tabs selectedIndex={tabIndex} onSelect={selectTab}>
                                {!isNewZealand && (
                                    <TabList>
                                        <Tab>
                                            <CreditCardSharpIcon>Credit card</CreditCardSharpIcon>
                                            Credit card
                                        </Tab>
                                        <Tab>
                                            <AccountBalanceSharpIcon>Direct debit</AccountBalanceSharpIcon>
                                            Direct debit
                                        </Tab>
                                    </TabList>
                                )}
                                <TabPanel>
                                    <div className="payment-method-container panel">
                                        <div className="panel-header">
                                            <p className="payment-method-label">
                                                <label htmlFor="card-element">Default credit card:</label>
                                            </p>
                                        </div>
                                        <div className="panel-body">
                                            <CardElement
                                                options={{
                                                    iconStyle: 'solid',
                                                    style: {
                                                        base: {
                                                            fontSize: '1rem',
                                                            '::placeholder': {
                                                                color: '#7b8387'
                                                            },
                                                            ':-webkit-autofill': {
                                                                color: '#7b8387'
                                                            }
                                                        }
                                                    }
                                                }}
                                            />
                                            {cardErrors && <div className="generic-form-error">{cardErrors}</div>}
                                        </div>
                                    </div>
                                </TabPanel>

                                <TabPanel>
                                    <div className="payment-method-container panel">
                                        <div className="panel-header">
                                            <p className="payment-method-label">Default bank account:</p>
                                        </div>
                                        <div className="panel-body">
                                            <div className="billing-details">
                                                <FormText
                                                    name={`accountHolderName`}
                                                    label="Account holder name"
                                                    required
                                                    validateOnTouch={true}
                                                />
                                                <FormEmail
                                                    name={`accountHolderEmail`}
                                                    label="Account holder email"
                                                    required
                                                    validateOnTouch={true}
                                                />
                                            </div>
                                            <AuBankAccountElement
                                                options={{
                                                    iconStyle: 'solid',
                                                    style: {
                                                        base: {
                                                            fontSize: '1rem',
                                                            '::placeholder': {
                                                                color: '#7b8387'
                                                            },
                                                            ':-webkit-autofill': {
                                                                color: '#7b8387'
                                                            },
                                                            backgroundColor: '#ECF2FF'
                                                        }
                                                    }
                                                }}
                                                onChange={onChangeDirectDebit}
                                            />
                                            {directDebitErrors && (
                                                <div className="generic-form-error">{directDebitErrors}</div>
                                            )}
                                            <div className="mandate-acceptance">
                                                By providing your bank account details and confirming this payment, you
                                                agree to this Direct Debit Request and the&nbsp;
                                                <a
                                                    href="https://stripe.com/au-becs-dd-service-agreement/legal"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Direct Debit Request service agreement
                                                </a>
                                                , and authorise Stripe Payments Australia Pty Ltd ACN 160 180 343 Direct
                                                Debit User ID number 507156 (“Stripe”) to debit your account through the
                                                Bulk Electronic Clearing System (BECS) on behalf of Stripe Press (the
                                                "Merchant") for any amounts separately communicated to you by the
                                                Merchant. You certify that you are either an account holder or an
                                                authorised signatory on the account listed above.
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                            </Tabs>

                            <div className="button btn-spinner">
                                <button
                                    type="submit"
                                    disabled={isUpdatingPaymentDetails}
                                    onClick={handleResetPaymentState}
                                >
                                    {isUpdatingPaymentDetails && <span className="savestatus-spinner-white" />}
                                    Update
                                </button>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </ModalDialog>
    );
};

export default ChangePaymentDetailsModal;
