import { capitalize } from '../../utils/generalUtils';
import LinkOffSharpIcon from '@flk-mui-icons/LinkOffSharp';
import * as PropTypes from 'prop-types';
import React from 'react';

export const IntegrationPlaceholder = props => {
    return (
        <div className="integration-container for-lg-modal">
            <div className="integration header-container">
                <label>
                    <p>
                        <b>{capitalize(props.label)}:</b>
                    </p>
                </label>
            </div>
        </div>
    );
};

IntegrationPlaceholder.propTypes = {
    label: PropTypes.any.isRequired
};
