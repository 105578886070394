import React, { ReactNode } from 'react';
import cx from 'classnames';
import * as RadixCollapsible from '@radix-ui/react-collapsible';

import styles from './MuiAccordion.module.scss';

type MuiAccordionSummaryProps = {
    'aria-controls'?: string;
    className?: string;
    expandIcon?: ReactNode;
    id?: string;
    children?: ReactNode;
    onClick?: (event: React.MouseEvent) => void;
    expandIconClassName?: string;
};

/**
 * @deprecated This component is deprecated. Please contact the Design System team for the appropriate replacement component.
 */
export const MuiAccordionSummary = ({
    'aria-controls': ariaControls,
    className,
    expandIcon,
    id,
    children,
    expandIconClassName
}: MuiAccordionSummaryProps) => {
    return (
        <RadixCollapsible.Trigger className={cx(styles.trigger, className)} id={id} aria-controls={ariaControls}>
            <div className={styles.triggerContent}>{children}</div>
            <div className={cx(styles.expandIcon, expandIconClassName)}>{expandIcon}</div>
        </RadixCollapsible.Trigger>
    );
};

type MuiAccordionActionsProps = {
    className?: string;
    children?: ReactNode;
};

/**
 * @deprecated This component is deprecated. Please contact the Design System team for the appropriate replacement component.
 */
export const MuiAccordionActions = ({ className, children }: MuiAccordionActionsProps) => {
    return <div className={cx(styles.actions, className)}>{children}</div>;
};

type MuiAccordionDetailsProps = {
    className?: string;
    children?: ReactNode;
    actions?: ReactNode;
    id?: string;
};

/**
 * @deprecated This component is deprecated. Please contact the Design System team for the appropriate replacement component.
 */
export const MuiAccordionDetails = ({ className, children, actions, id }: MuiAccordionDetailsProps) => {
    return (
        <RadixCollapsible.Content className={styles.content} id={id} aria-labelledby={id}>
            <div className={cx(styles.children, className)}>{children}</div>
            {actions}
        </RadixCollapsible.Content>
    );
};

type MuiAccordionProps = {
    className?: string;
    expanded?: boolean;
    onChange?: (expanded: boolean) => void;
    children?: ReactNode;
};

/**
 * @deprecated This component is deprecated. Please contact the Design System team for the appropriate replacement component.
 */
export const MuiAccordion = ({ className, expanded, onChange, children }: MuiAccordionProps) => {
    // Separate actions from other children
    const childrenArray = React.Children.toArray(children);
    const actions = childrenArray.find(child => React.isValidElement(child) && child.type === MuiAccordionActions);
    const summary = childrenArray.find(child => React.isValidElement(child) && child.type === MuiAccordionSummary);
    const ariaControls = React.isValidElement(summary) ? summary.props['aria-controls'] : undefined;

    const otherChildren = childrenArray.filter(
        child => React.isValidElement(child) && child.type !== MuiAccordionActions
    );

    // Find the details component and clone it with actions
    const modifiedChildren = React.Children.map(otherChildren, child => {
        if (React.isValidElement(child) && child.type === MuiAccordionDetails) {
            return React.cloneElement(child, {
                ...child.props,
                actions: actions,
                id: ariaControls
            });
        }
        return child;
    });

    return (
        <RadixCollapsible.Root className={cx(styles.root, className)} open={expanded} onOpenChange={onChange}>
            {modifiedChildren}
        </RadixCollapsible.Root>
    );
};
