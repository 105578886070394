import Email from '@flk-mui-icons/EmailSharp';
import ReactTooltip from 'react-tooltip';
import VisibilitySharpIcon from '@flk-mui-icons/VisibilitySharp';
import LaunchSharpIcon from '@flk-mui-icons/LaunchSharp';
import * as React from 'react';
import '../../sass/buttonGroup.scss';

const renderIcon = icon => {
    if (icon === 'view') {
        return <VisibilitySharpIcon />;
    } else if (icon === 'send') {
        return <Email />;
    } else if (icon === 'open') {
        return <LaunchSharpIcon />;
    } else if (icon === 'busy') {
        return <span className="button-group-spinner" />;
    } else {
        return null;
    }
};

const ButtonGroup = ({ title, buttonList, singleButton, buttonListClass, toolTip }) => {
    return (
        <React.Fragment>
            {toolTip && (
                <ReactTooltip id={toolTip} class="tooltip" place="bottom" effect="solid" globalEventOff="wheel">
                    <p>{toolTip}</p>
                </ReactTooltip>
            )}
            <div
                data-tip={true}
                data-for={toolTip}
                className={`footer-button-group ${singleButton ? 'single-button' : ''}`}
            >
                <h4>{title}</h4>
                <div className={`button-list ${buttonListClass}`}>
                    {buttonList.map((buttonItem, index) => {
                        return (
                            <button
                                key={index}
                                className={buttonItem.className}
                                onClick={buttonItem.onClick}
                                disabled={buttonItem.disabled}
                                type={buttonItem.type ? buttonItem.type : 'button'}
                                data-test={`${buttonItem.dataTest}-${index}`}
                            >
                                {renderIcon(buttonItem.icon)}
                                {buttonItem.label}
                                {buttonItem.loading && <span className="loading-spinner" />}
                            </button>
                        );
                    })}
                </div>
            </div>
        </React.Fragment>
    );
};

export default ButtonGroup;
