import React, { memo, useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { cloneDeep, has } from 'lodash';
import * as Lease from '../../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../../selectors/lease';
import { getPmDetailsRepairs } from '../../../../selectors/lease/pmLease';

import { FormTextCurrency, FormTextMultiline, FormTextRegular } from '../../../../components/form/FormText';
import '../../../../sass/management/detailsRepairs.scss';
import { FormRadioGroup } from '../../../../components/form/FormRadioGroup';
import { booleanToString } from '../../../../utils/agreementUtils';
import {
    YES_LABEL,
    NO_LABEL,
    AGENT_LABEL,
    LANDLORD_LABEL,
    SMOKE_ALARM_TYPE_NON_COMPLIANT,
    SMOKE_ALARM_TYPE_COMPLIANT
} from '../../../../config';

const PM_DETAIL_REPAIRS_FORM = 'pmDetailsRepairsForm';
const initState = {
    smokeAlarmsMaintainedBy: LANDLORD_LABEL,
    smokeAlarmsCompliantType: SMOKE_ALARM_TYPE_COMPLIANT,
    smokeAlarmCompanyName: '',
    smokeAlarmAmount: '',
    maximumExpenditure: '',
    strataCommunityTitle: NO_LABEL
};

const DetailsRepairs = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxPmDetailsRepairs = useSelector(getPmDetailsRepairs);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [pmDetailsRepairs, setPmDetailsRepairs] = useState(reduxPmDetailsRepairs || initState);

    useEffect(() => {
        const clonedDetailsRepairs = cloneDeep(pmDetailsRepairs);
        clonedDetailsRepairs.smokeAlarms = booleanToString(clonedDetailsRepairs.smokeAlarms);
        setPmDetailsRepairs(clonedDetailsRepairs);
    }, []);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(PM_DETAIL_REPAIRS_FORM).dispatchEvent(
                new Event('submit', {
                    cancelable: true,
                    bubbles: true
                })
            );
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updatePmDetailRepairs(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.pmDetailsRepairs')) {
                    return error.response.data.errors.pmDetailsRepairs;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const updatePmDetailRepairs = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/pm-details-repairs`, data);
    };

    return (
        <div className="detailsRepairs">
            <Form onSubmit={submitForm} initialValues={pmDetailsRepairs}>
                {({ handleSubmit, values, form }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={PM_DETAIL_REPAIRS_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <div>
                                <FormTextRegular name="councilArea" label="Council area" required />
                                <FormRadioGroup
                                    label="Strata or community plan"
                                    required
                                    name="strataCommunityTitle"
                                    value={values.strataCommunityTitle}
                                    data={[
                                        {
                                            label: YES_LABEL,
                                            value: YES_LABEL
                                        },
                                        {
                                            label: NO_LABEL,
                                            value: NO_LABEL
                                        }
                                    ]}
                                />
                                {values.strataCommunityTitle === YES_LABEL && (
                                    <div className="form-section">
                                        <div className="form-section__body">
                                            <div className="full-width">
                                                <FormTextRegular
                                                    name="strataManagerName"
                                                    label="Strata managing agent"
                                                    required
                                                />
                                            </div>
                                            <div className="full-width">
                                                <FormTextRegular name="strataPlanNo" label="Strata plan number" />
                                            </div>
                                            <div className="full-width">
                                                <FormTextRegular name="strataAddress" label="Address" />
                                            </div>
                                            <div className="full-width">
                                                <FormTextRegular name="strataPhone" label="Phone" />
                                            </div>
                                            <div className="full-width">
                                                <FormTextRegular name="strataEmail" label="Email" required />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <FormTextMultiline
                                    name="specialMatters"
                                    label="Special or other matters relating to the Property:"
                                />
                                <h3>Repairs</h3>
                                <FormTextCurrency
                                    name="maximumExpenditure"
                                    label="Maximum expenditure allowed without Landlord’s approval"
                                    required
                                    value={values.maximumExpenditure}
                                />
                                <FormRadioGroup
                                    label="Does the Property have a swimming pool?"
                                    name="haveSwimmingPool"
                                    value={values.haveSwimmingPool}
                                    required
                                    data={[
                                        {
                                            label: YES_LABEL,
                                            value: YES_LABEL
                                        },
                                        {
                                            label: NO_LABEL,
                                            value: NO_LABEL
                                        }
                                    ]}
                                />
                                <FormRadioGroup
                                    label="Smoke Alarms (residential)"
                                    name="smokeAlarmsCompliantType"
                                    value={values.smokeAlarmsCompliantType}
                                    required
                                    data={[
                                        {
                                            label: SMOKE_ALARM_TYPE_COMPLIANT,
                                            value: SMOKE_ALARM_TYPE_COMPLIANT
                                        },
                                        {
                                            label: SMOKE_ALARM_TYPE_NON_COMPLIANT,
                                            value: SMOKE_ALARM_TYPE_NON_COMPLIANT
                                        }
                                    ]}
                                />
                                <FormRadioGroup
                                    label="Smoke alarms to be maintained & serviced by"
                                    required
                                    name={'smokeAlarmsMaintainedBy'}
                                    value={values.smokeAlarmsMaintainedBy}
                                    radioGroupClass="wrapped-radio-group"
                                    data={[
                                        {
                                            label: LANDLORD_LABEL,
                                            value: LANDLORD_LABEL
                                        },
                                        {
                                            label: AGENT_LABEL,
                                            value: AGENT_LABEL
                                        },
                                        {
                                            label: 'Strata',
                                            value: 'Strata'
                                        },
                                        {
                                            label: 'Company utilized by the agent for Smoke Alarms',
                                            value: 'Company'
                                        }
                                    ]}
                                />
                                {values?.smokeAlarmsMaintainedBy === 'Company' && (
                                    <div>
                                        <FormTextRegular name="smokeAlarmCompanyName" label="Company Name" required />
                                        <FormTextCurrency name="smokeAlarmAmount" label="Annual costs" precision="2" />
                                    </div>
                                )}
                                <h3 className="margin-bottom-1rem">Landlord Repairs and Maintenance</h3>
                                <FormRadioGroup
                                    label="Are there repairs/maintenance required prior to the first tenancy by the landlord?"
                                    name="isRepairRequiredBeforeTenancy"
                                    required
                                    value={values.isRepairRequiredBeforeTenancy}
                                    data={[
                                        {
                                            label: YES_LABEL,
                                            value: YES_LABEL
                                        },
                                        {
                                            label: NO_LABEL,
                                            value: NO_LABEL
                                        }
                                    ]}
                                />
                                {values.isRepairRequiredBeforeTenancy === YES_LABEL && (
                                    <div className="form-section">
                                        <div className="form-section__body">
                                            <div className="full-width">
                                                <FormTextMultiline
                                                    name="repairBeforeTenancyDetails"
                                                    label="Details of the repairs and/or maintenance"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <FormRadioGroup
                                    label="Is there repairs/maintenance required on a regular basis by the landlord?"
                                    name={'isRegularRepairRequired'}
                                    required
                                    value={values.isRegularRepairRequired}
                                    data={[
                                        {
                                            label: YES_LABEL,
                                            value: YES_LABEL
                                        },
                                        {
                                            label: NO_LABEL,
                                            value: NO_LABEL
                                        }
                                    ]}
                                />
                                {values.isRegularRepairRequired === YES_LABEL && (
                                    <div className="form-section">
                                        <div className="form-section__body">
                                            <div className="full-width">
                                                <FormTextMultiline
                                                    name="regularRepairDetails"
                                                    label="Details of the repairs and/or maintenance"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(DetailsRepairs);
