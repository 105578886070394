import React, { memo, useEffect, useRef, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { confirmAlert } from 'react-confirm-alert';
import { findIndex, cloneDeep, isEmpty } from 'lodash';
import axios from 'axios';
import { addDays } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { useSelector, useDispatch } from 'react-redux';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import ModalDialog from '../../../common/components/ModalDialog';
import {
    addOrReplaceKeyInList,
    closeFlkAKeyForm,
    removeKeyInList,
    setCurrentKey,
    changeFlkAKeyFormEditable,
    changeCurrentKeyImage
} from '../../../actions/flkAKey';
import { getAgencyTimezoneFromUser } from '../../../utils/dateUtils';

import {
    getIsOpenFlkAKeyForm,
    getCurrentKey,
    getKeyFormIsEditable,
    getCurrentKeyImages,
    getCurrentKeyImageUrls
} from '../../../selectors/flkAKey';
import { getUserInfo } from '../../../selectors/user';
import { getAgencyPropertyMeIntegration, getAgencyPropertyTreeIntegration } from '../../../selectors/agency';

import LocationAndAddressSelection from '../LocationAndAddressSelection';
import ContactSearch from '../../../components/search/ContactSearch';
import FlkAKeyStaffGroup from './FlkAKeyStaffGroup';
import FlkAKeyHeader from './FlkAKeyHeader';
import { FormTextMultiline, FormTextRegular } from '../../../components/form/FormText';
import { SelectField } from '../../../components/form/FormSelect';
import { FormPhone } from '../../../components/form/FormPhone';
import DatePickerInAgencyTimeZone from '../../../components/form/DatePickerInAgencyTimeZone';
import QRCode from 'react-qr-code';
import ThumbDisplay from '../../../components/dashboard/image/ThumbDisplay';
import ReactTooltip from 'react-tooltip';
import { InfoSharp } from '@flk-mui-icons';
import '../../../sass/documentModalDialog.scss';
import '../../../sass/flkAKeyForm.scss';
import Button from '../../../common/components/Button';
import {
    FLK_A_KEY_STATUS_CHECKED_OUT,
    FLK_A_KEY_STATUS_DRAFT,
    FLK_A_KEY_STATUS_OVERDUE,
    FLK_A_KEY_STATUS_RETURNED
} from '../../../actions/dashboard';
import {
    HIDE_MESSAGE_TIME,
    FORM_SUBMIT_TYPE_SAVE,
    FORM_SUBMIT_TYPE_SEND,
    SELECT_OPTION_OTHER,
    contactTypeList,
    reasonForLoanList,
    DUE_DATE_TODAY,
    DUE_DATE_TOMORROW,
    DUE_DATE_END_OF_WEEK
} from '../../../config';
import FlkAKeyCheckOutHistoryModal from './FlkAKeyCheckOutHistoryModal';
import FlkAKeyHistoryDetails from './FlkAKeyHistoryDetails';
import {
    MuiAccordion,
    MuiAccordionSummary,
    MuiAccordionDetails
} from '../../../common/components/mui-accordion/MuiAccordion';
import ExpandMoreIcon from '@flk-mui-icons/ExpandMoreSharp';
import * as validators from '../../../components/Validate';
import { FLK_A_KEY } from '../../../constants/constants';
import { useHistory } from 'react-router-dom';

const emptyStaff = {
    name: '',
    email: '',
    subscribedToCheckoutNotifications: true,
    subscribedToReturnNotifications: true,
    subscribedToSmsReplyNotifications: true,
    subscribedToOverdueNotifications: true,
    isEditMode: true,
    expanded: true
};

const initialValues = {
    watchlist: {
        staff: []
    }
};

const FlkAKeyForm = () => {
    const dispatch = useDispatch();

    const formValuesRef = React.useRef(null);
    const formRef = useRef(null);
    const history = useHistory();
    const isOpenFlkAKeyForm = useSelector(getIsOpenFlkAKeyForm);
    const currentKey = useSelector(getCurrentKey);
    const loggedInUser = useSelector(getUserInfo);
    const timeZone = getAgencyTimezoneFromUser(loggedInUser);
    const propertyMeIntegration = useSelector(getAgencyPropertyMeIntegration);
    const isEditable = useSelector(getKeyFormIsEditable);
    const propertyTreeIntegration = useSelector(getAgencyPropertyTreeIntegration);
    const imageUrls = useSelector(getCurrentKeyImageUrls);
    const images = useSelector(getCurrentKeyImages);

    const isIntegrationActive = propertyMeIntegration || propertyTreeIntegration;

    const [formData, setFormData] = useState(initialValues);
    const [locationAndAddressSelected, setLocationAndAddressSelected] = useState(false);
    const [integrationPropertyId, setIntegrationPropertyId] = useState(null);
    const [integration, setIntegration] = useState(null);
    const [addressError, setAddressError] = useState('');
    const [dirty, setDirty] = useState(false);
    const [emailError, setEmailError] = useState(undefined);

    const [isSendingFlkAKey, setIsSendingFlkAKey] = useState(false);
    const [isSavingFlkAKey, setIsSavingFlkAKey] = useState(false);
    const [isSendingFlkAKeySuccess, setIsSendingFlkAKeySuccess] = useState(false);
    const [successSaveMessage, setSuccessSaveMessage] = useState('Key checked out successfully');
    const [failSaveMessage, setFailSaveMessage] = useState('Key check out failed');
    const [isSendingFlkAKeyFail, setIsSendingFlkAKeyFail] = useState(false);

    const [viewCheckOutHistory, setViewCheckOutHistory] = useState(false);

    const [accessToken, setAccessToken] = useState(null);

    useEffect(() => {
        if (currentKey) {
            axios.get(`/api/flk-a-key/${currentKey.id}/access-token`).then(response => {
                setAccessToken(response.data.accessToken);
            });
        }
    }, [currentKey]);

    useEffect(() => {
        if (currentKey && isOpenFlkAKeyForm) {
            window.history.pushState({}, '', `/dashboard/flk-a-key/${currentKey.status}/${currentKey.id}`);
        }
        if (currentKey && currentKey.integration && currentKey.integration.id) {
            setIntegrationPropertyId(currentKey.integration.id);
            setIntegration(currentKey.integration);
        } else {
            setIntegrationPropertyId(null);
            setIntegration(null);
        }
        if (currentKey) {
            setLocationAndAddressSelected(true);
        }
        setFormData(
            currentKey
                ? {
                      address: currentKey.address,
                      watchlist: {
                          staff: currentKey.watchlist && currentKey.watchlist.staff ? currentKey.watchlist.staff : []
                      },
                      addressDetails: currentKey.addressDetails,
                      key: currentKey.key,
                      company: currentKey.company,
                      contactType: currentKey.contactType,
                      otherContactType: currentKey.otherContactType,
                      name: currentKey.name,
                      mobile: currentKey.mobile,
                      dueDate: currentKey.dueDate ? new Date(currentKey.dueDate) : null,
                      reasonForLoan: currentKey.reasonForLoan,
                      otherReasonForLoan: currentKey.otherReasonForLoan,
                      notes: currentKey.notes,
                      disabledKey: currentKey.disabledKey
                  }
                : initialValues
        );
        if (!currentKey) {
            setLocationAndAddressSelected(false);
        }
    }, [isOpenFlkAKeyForm, currentKey]);

    const handleCloseModal = () => {
        if (dirty) {
            confirmAlert({
                title: '',
                message: 'You have un-saved changes do you want to save before you exit?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            formRef.current.dispatchEvent(
                                new Event('submit', {
                                    bubbles: true
                                })
                            );
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {
                            dispatch(closeFlkAKeyForm());
                        }
                    }
                ]
            });
        } else {
            if (currentKey) {
                window.history.pushState({}, '', `/dashboard/flk-a-key/${currentKey.status}`);
            }
            setAccessToken(null);
            dispatch(closeFlkAKeyForm());
        }
    };

    const handleCheckOutKeyOrEdit = (data, submitType) => {
        if (submitType === FORM_SUBMIT_TYPE_SEND) {
            setIsSendingFlkAKey(true);
            return axios.post(`/api/flk-a-key/${currentKey.id}/check-out`, data);
        } else {
            setIsSavingFlkAKey(true);
            return axios.put(`/api/flk-a-key/${currentKey.id}/edit`, data);
        }
    };

    const handleSubmit = values => {
        setIsSendingFlkAKeySuccess(false);
        setIsSendingFlkAKeyFail(false);
        const data = cloneDeep(values);
        data.integrationPropertyId = integrationPropertyId;
        data.images = images;
        const submitType = data.submitType === FORM_SUBMIT_TYPE_SEND ? FORM_SUBMIT_TYPE_SEND : FORM_SUBMIT_TYPE_SAVE;
        return handleCheckOutKeyOrEdit(data, submitType)
            .then(resp => {
                setDirty(false);
                if (submitType === FORM_SUBMIT_TYPE_SEND) {
                    setIsSendingFlkAKey(false);
                    setIsSendingFlkAKeySuccess(true);
                    setSuccessSaveMessage('Key checked out successfully');
                    history.push(`/dashboard/flk-a-key/${FLK_A_KEY_STATUS_CHECKED_OUT}`);
                } else if (submitType === FORM_SUBMIT_TYPE_SAVE) {
                    setIsSavingFlkAKey(false);
                    setIsSendingFlkAKeySuccess(true);
                    setSuccessSaveMessage('Key saved successfully');
                    history.push(`/dashboard/flk-a-key/${FLK_A_KEY_STATUS_DRAFT}`);
                }

                if (
                    currentKey.status === resp.data.flkAKey.status ||
                    [FLK_A_KEY_STATUS_DRAFT, FLK_A_KEY_STATUS_CHECKED_OUT].includes(currentKey.status)
                ) {
                    dispatch(addOrReplaceKeyInList(resp.data.flkAKey));
                } else {
                    dispatch(removeKeyInList(resp.data.flkAKey));
                }
                dispatch(setCurrentKey(resp.data.flkAKey));
                setTimeout(() => {
                    setIsSendingFlkAKeySuccess(false);
                    dispatch(closeFlkAKeyForm());
                }, HIDE_MESSAGE_TIME);
            })
            .catch(err => {
                setIsSendingFlkAKey(false);
                setIsSavingFlkAKey(false);
                setIsSendingFlkAKeyFail(true);
                if (submitType === FORM_SUBMIT_TYPE_SEND) {
                    setFailSaveMessage('Key check out failed');
                } else if (submitType === FORM_SUBMIT_TYPE_SAVE) {
                    setFailSaveMessage('Key save failed');
                }
                return err.response.data.errors;
            });
    };

    const confirmDetails = values => {
        setIsSendingFlkAKey(true);
        const data = cloneDeep(values);
        data.integrationPropertyId = integrationPropertyId;
        return axios
            .post('/api/flk-a-key/create-draft', data)
            .then(response => {
                setIsSendingFlkAKey(false);
                dispatch(setCurrentKey(response.data.flkAKey));
                dispatch(changeFlkAKeyFormEditable(true));
            })
            .catch(err => {
                setIsSendingFlkAKey(false);
                return err.response.data.errors;
            });
    };

    const handleSearchSelect = (result, values) => {
        const data = cloneDeep(values);
        const emptyIndex = findIndex(data.watchlist.staff, staff => !staff.name);
        if (emptyIndex > -1) {
            data.watchlist.staff[emptyIndex].name = result.fullName;
            data.watchlist.staff[emptyIndex].email = result.email;
        } else {
            data.watchlist.staff.push({
                name: result.fullName,
                email: result.email,
                subscribedToCheckoutNotifications: true,
                subscribedToReturnNotifications: true,
                subscribedToSmsReplyNotifications: true,
                subscribedToOverdueNotifications: true,
                isEditMode: true
            });
        }
        setFormData(data);
    };

    const handleBorrowerSearchSelect = (result, values) => {
        const data = cloneDeep(values);
        data.company = result.corporationName;
        data.name = result.fullName;
        if (result.mobile) {
            data.mobile = result.mobile;
        }
        const contactTypeIndexInList = findIndex(contactTypeList, ['value', result.role]);
        if (contactTypeIndexInList > -1) {
            data.contactType = result.role;
            data.otherContactType = '';
        } else {
            data.contactType = SELECT_OPTION_OTHER;
            data.otherContactType = result.role;
        }
        setFormData(data);
    };

    const removeStaff = (values, index) => {
        setDirty(true);
        let newState = cloneDeep(values);
        return setFormData({
            ...newState,
            watchlist: {
                staff: [...newState.watchlist.staff.slice(0, index), ...newState.watchlist.staff.slice(index + 1)]
            }
        });
    };

    const enableStaffEdit = (values, index) => {
        if (!validators.isCorrectEmail(values.watchlist.staff[index].email) && values.watchlist.staff[index].expanded) {
            setEmailError('Incorrect email format');
        } else {
            if (!emailError) {
                setDirty(true);
                const newState = cloneDeep(values);
                newState.watchlist.staff[index].isEditMode = true;
                newState.watchlist.staff[index].expanded = !newState.watchlist.staff[index].expanded;
                setEmailError(undefined);
                return setFormData(newState);
            }
        }
    };

    const saveStaffEdit = (values, index) => {
        if (validators.isCorrectEmail(values.watchlist.staff[index].email)) {
            setDirty(true);
            const newState = cloneDeep(values);
            newState.watchlist.staff[index].isEditMode = false;
            newState.watchlist.staff[index].expanded = false;
            setEmailError(undefined);
            return setFormData(newState);
        } else {
            setEmailError('Incorrect email format');
        }
    };

    const changeDueDate = (dateType, values) => {
        setDirty(true);
        let newState = cloneDeep(values);
        let dueDate = new Date();
        dueDate.setHours(17, 0, 0, 0);
        if (dateType === DUE_DATE_TOMORROW) {
            dueDate = addDays(dueDate, 1);
        } else if (dateType === DUE_DATE_END_OF_WEEK) {
            // Friday of current week
            dueDate = addDays(dueDate, 5 - dueDate.getDay());
        }
        return setFormData({
            ...newState,
            dueDate: zonedTimeToUtc(dueDate, timeZone)
        });
    };

    // Address and integration functions
    const handleAddressChange = (setValue, address) => {
        setValue('address', address);
    };
    const setUpdateAddressDetails = (address, details, values) => {
        if (!address) {
            setAddressError('Address is required');
        } else {
            setAddressError('');
        }
        let data = cloneDeep(values);
        data.address = address;
        data.addressDetails = details;
        setFormData(data);
        setIntegrationPropertyId(null);
        setIntegration(null);
    };
    const setAddressDetails = (address, details, values) => {
        let data = cloneDeep(values);
        data.address = address;
        data.addressDetails = details;
        setIntegrationPropertyId(null);
        setIntegration(null);
        setFormData(data);
    };
    const setIntegrationProperty = propertyDetails => {
        let data = cloneDeep(initialValues);
        if (propertyDetails) {
            data.address = propertyDetails.address;
            data.key = propertyDetails.keyNumber || '';
            setFormData(data);
            setIntegrationPropertyId(propertyDetails.id);
            setIntegration(propertyDetails);
        } else {
            setFormData(data);
        }
    };

    const removeImage = index => {
        setDirty(true);
        confirmAlert({
            title: '',
            message: 'This will delete the photo of the key. Are you sure you want to do this?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let compressedImageUrls = cloneDeep(imageUrls);
                        let editedIimages = cloneDeep(images);
                        compressedImageUrls = [
                            ...compressedImageUrls.slice(0, index),
                            ...compressedImageUrls.slice(index + 1)
                        ];
                        editedIimages = [...editedIimages.slice(0, index), ...editedIimages.slice(index + 1)];
                        dispatch(changeCurrentKeyImage(compressedImageUrls, editedIimages));
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    };

    function changeForm(form) {
        if (!isEmpty(form.dirtyFields)) {
            setDirty(true);
        }
    }

    return (
        <React.Fragment>
            <ModalDialog
                title="FLK a Key"
                isOpen={isOpenFlkAKeyForm}
                closeModal={handleCloseModal}
                shouldCloseOnOverlayClick={false}
                className={`document-centre-modal document-modal-dialog tenant flk-a-key ${
                    locationAndAddressSelected && location ? 'with-header' : 'without-header'
                }`}
                // Quick fix for search results being cut off by modal
                allowOverflow={!locationAndAddressSelected}
            >
                <Form
                    initialValues={formData}
                    mutators={{
                        ...arrayMutators
                    }}
                    onSubmit={handleSubmit}
                >
                    {({
                        handleSubmit,
                        values,
                        form,
                        form: {
                            mutators: { push }
                        }
                    }) => {
                        formValuesRef.current = values;
                        return (
                            <form onSubmit={handleSubmit} noValidate id="create-flk-a-key-form" ref={formRef}>
                                <FormSpy onChange={state => changeForm(state)} />
                                {locationAndAddressSelected && (
                                    <div>
                                        {currentKey && (
                                            <FlkAKeyHeader
                                                parentSelector="create-flk-a-key-form"
                                                confirmDetails={confirmDetails}
                                                address={values.address}
                                                addressError={addressError}
                                                handleAddressChange={address =>
                                                    handleAddressChange(form.change, address)
                                                }
                                                setAddressDetails={(address, details) =>
                                                    setUpdateAddressDetails(address, details, values)
                                                }
                                                disabled={integrationPropertyId || !isEditable}
                                                agentName={currentKey.agent.fullName}
                                                integration={integration}
                                                viewOnlyIntegration={false}
                                                currentKey={currentKey}
                                                unLinkIntegrationProperty={() => {
                                                    setIntegration(null);
                                                    setIntegrationPropertyId(null);
                                                    dispatch(
                                                        addOrReplaceKeyInList({ ...currentKey, integration: null })
                                                    );
                                                }}
                                                linkIntegrationProperty={data => {
                                                    setIntegration(data);
                                                    setIntegrationPropertyId(data.id);
                                                    dispatch(
                                                        addOrReplaceKeyInList({ ...currentKey, integration: data })
                                                    );
                                                }}
                                            />
                                        )}
                                        {currentKey.status !== FLK_A_KEY_STATUS_RETURNED && (
                                            <React.Fragment>
                                                <FormTextRegular
                                                    name="key"
                                                    label="Key Number"
                                                    disabled={
                                                        currentKey.status !== FLK_A_KEY_STATUS_DRAFT ||
                                                        currentKey.disabledKey
                                                    }
                                                    required
                                                />
                                                <div className="header-tooltip">
                                                    <h2>Key watchlist</h2>
                                                    <InfoSharp
                                                        className={`tooltip-info-icon text-gray`}
                                                        data-tip
                                                        data-for="watchlist-tooltip"
                                                    />
                                                    <ReactTooltip
                                                        id="watchlist-tooltip"
                                                        class="tooltip"
                                                        place="bottom"
                                                        effect="solid"
                                                        globalEventOff="wheel"
                                                    >
                                                        <div>
                                                            <div className="info-segment">
                                                                Keep your property managers and sales staff up to date
                                                                when there’s activity on a key for a property they are
                                                                managing or selling.
                                                            </div>
                                                        </div>
                                                    </ReactTooltip>
                                                </div>
                                                <ContactSearch
                                                    onSelect={result => handleSearchSelect(result, values)}
                                                    allowStaffSearch={true}
                                                    disabled={!isEditable}
                                                    placeholder={'Find a staff member'}
                                                />
                                                <br />
                                                <div className="tenants">
                                                    <FieldArray
                                                        name="watchlist.staff"
                                                        initialValue={values.watchlist ? values.watchlist.staff : []}
                                                    >
                                                        {({ fields }) =>
                                                            fields.map((name, index) => (
                                                                <MuiAccordion
                                                                    key={index}
                                                                    onChange={() => {
                                                                        enableStaffEdit(values, index);
                                                                    }}
                                                                    expanded={
                                                                        values.watchlist.staff[index].expanded === true
                                                                    }
                                                                    className="flk-a-key-staff-accordion"
                                                                >
                                                                    <MuiAccordionSummary
                                                                        expandIcon={<ExpandMoreIcon />}
                                                                    >
                                                                        <div className="accordion-summary-text">
                                                                            {`${values.watchlist.staff[index].name}`}{' '}
                                                                            {values.watchlist.staff[index].email &&
                                                                                `<${values.watchlist.staff[index].email}>`}
                                                                        </div>
                                                                    </MuiAccordionSummary>
                                                                    <MuiAccordionDetails>
                                                                        <FlkAKeyStaffGroup
                                                                            index={index}
                                                                            removeStaff={index => {
                                                                                removeStaff(values, index);
                                                                            }}
                                                                            values={values}
                                                                            disabled={!isEditable}
                                                                            enableStaffEdit={index =>
                                                                                enableStaffEdit(values, index)
                                                                            }
                                                                            saveStaffEdit={index =>
                                                                                saveStaffEdit(values, index)
                                                                            }
                                                                            emailError={emailError}
                                                                        />
                                                                    </MuiAccordionDetails>
                                                                </MuiAccordion>
                                                            ))
                                                        }
                                                    </FieldArray>
                                                    <br />
                                                    {isEditable && (
                                                        <div className="button" data-tip={true}>
                                                            <button
                                                                disabled={!isEditable}
                                                                className="add-client"
                                                                type="button"
                                                                onClick={() => {
                                                                    setEmailError('');
                                                                    push('watchlist.staff', emptyStaff);
                                                                }}
                                                            >
                                                                Add new watcher
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                                <h2>Key Borrower Details</h2>
                                                <div className="form-section">
                                                    {isIntegrationActive && (
                                                        <React.Fragment>
                                                            <ContactSearch
                                                                onSelect={result =>
                                                                    handleBorrowerSearchSelect(result, values)
                                                                }
                                                                contactType="flk_a_key"
                                                                disabled={!isEditable}
                                                                placeholder={'Find an agency contact'}
                                                            />
                                                            <br />
                                                        </React.Fragment>
                                                    )}
                                                    <div className="other-select-group">
                                                        <SelectField
                                                            label="Contact Type"
                                                            name="contactType"
                                                            options={contactTypeList}
                                                            disabled={!isEditable}
                                                            required
                                                        />
                                                        {values.contactType === SELECT_OPTION_OTHER && (
                                                            <FormTextRegular
                                                                name="otherContactType"
                                                                label="If Other"
                                                                disabled={!isEditable}
                                                                required
                                                            />
                                                        )}
                                                    </div>
                                                    <FormTextRegular
                                                        name="name"
                                                        label="Name"
                                                        disabled={!isEditable}
                                                        required
                                                    />
                                                    <FormPhone
                                                        name="mobile"
                                                        className="mobile"
                                                        label="Mobile"
                                                        disabled={!isEditable}
                                                        required
                                                    />
                                                    <FormTextRegular
                                                        name="company"
                                                        label="Company"
                                                        disabled={!isEditable}
                                                    />
                                                </div>
                                                <br />
                                                <DatePickerInAgencyTimeZone
                                                    label="Expected Key Return Due Date "
                                                    name="dueDate"
                                                    showTimeSelect={true}
                                                    selected={values.dueDate}
                                                    popperPlacement="top-start"
                                                    disabled={!isEditable}
                                                    required
                                                />
                                                {isEditable && (
                                                    <div className="date-picker-button-group">
                                                        <Button
                                                            onClick={() => changeDueDate(DUE_DATE_TODAY, values)}
                                                            type="button"
                                                            disabled={!isEditable}
                                                        >
                                                            Today 5pm
                                                        </Button>
                                                        <Button
                                                            onClick={() => changeDueDate(DUE_DATE_TOMORROW, values)}
                                                            type="button"
                                                            disabled={!isEditable}
                                                        >
                                                            Tomorrow 5pm
                                                        </Button>
                                                        <Button
                                                            onClick={() => changeDueDate(DUE_DATE_END_OF_WEEK, values)}
                                                            type="button"
                                                            disabled={!isEditable}
                                                        >
                                                            End of week 5pm
                                                        </Button>
                                                    </div>
                                                )}
                                                <div className="other-select-group">
                                                    <SelectField
                                                        label="Reason for Key Checkout"
                                                        name="reasonForLoan"
                                                        options={reasonForLoanList}
                                                        disabled={!isEditable}
                                                        required
                                                    />
                                                    {values.reasonForLoan === SELECT_OPTION_OTHER && (
                                                        <FormTextRegular
                                                            name="otherReasonForLoan"
                                                            label="If Other"
                                                            disabled={!isEditable}
                                                        />
                                                    )}
                                                </div>
                                                <FormTextMultiline name="notes" label="Notes" disabled={!isEditable} />
                                                {currentKey && accessToken && (
                                                    <div className="photo-group">
                                                        <h2>Photos</h2>
                                                        {isEditable && <p>Scan to upload photo</p>}
                                                        <div className="qr-and-image">
                                                            {isEditable && (
                                                                <QRCode
                                                                    value={`${LETS_URL}/#/upload-image/flk-a-key/${accessToken}?key=${values.key}`}
                                                                    size={200}
                                                                    className="qr-code"
                                                                />
                                                            )}
                                                            <div className="image-group">
                                                                {Array.isArray(imageUrls) &&
                                                                    imageUrls.map((image, index) => (
                                                                        <ThumbDisplay
                                                                            key={index}
                                                                            imageSrc={image}
                                                                            imageTitle={`key image ${index + 1}`}
                                                                            canRemove={isEditable}
                                                                            removeImage={() => removeImage(index)}
                                                                            isUpdating={isSendingFlkAKey}
                                                                        />
                                                                    ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {currentKey?.key && currentKey?.address && (
                                                    <React.Fragment>
                                                        <h2>Check out history</h2>
                                                        <Button
                                                            onClick={() => setViewCheckOutHistory(true)}
                                                            type="button"
                                                        >
                                                            View
                                                        </Button>
                                                    </React.Fragment>
                                                )}
                                                <div className="button-row single">
                                                    {isSendingFlkAKeySuccess && (
                                                        <span className="savestatus-saved">
                                                            <span className="icon savestatus-icon" />
                                                            {successSaveMessage}
                                                        </span>
                                                    )}
                                                    {isSendingFlkAKeyFail && (
                                                        <span className="savestatus-failed">
                                                            <span className="icon savestatus-icon" />
                                                            {failSaveMessage}
                                                        </span>
                                                    )}
                                                </div>
                                                {[FLK_A_KEY_STATUS_DRAFT, FLK_A_KEY_STATUS_RETURNED].includes(
                                                    currentKey.status
                                                ) && (
                                                    <div className="button-row">
                                                        <div className="button-footer-docs">
                                                            <Button
                                                                secondary
                                                                height={40}
                                                                type="submit"
                                                                disabled={isSavingFlkAKey || isSendingFlkAKey}
                                                                loading={isSavingFlkAKey}
                                                                onClick={() =>
                                                                    form.change('submitType', FORM_SUBMIT_TYPE_SAVE)
                                                                }
                                                            >
                                                                Save
                                                            </Button>
                                                        </div>
                                                        <div className="button-footer-docs">
                                                            <Button
                                                                primary
                                                                className="btn-terminate"
                                                                height={40}
                                                                type="submit"
                                                                disabled={isSavingFlkAKey || isSendingFlkAKey}
                                                                loading={isSendingFlkAKey || !isEditable}
                                                                onClick={() =>
                                                                    form.change('submitType', FORM_SUBMIT_TYPE_SEND)
                                                                }
                                                            >
                                                                Check Out
                                                            </Button>
                                                        </div>
                                                    </div>
                                                )}
                                                {[FLK_A_KEY_STATUS_CHECKED_OUT, FLK_A_KEY_STATUS_OVERDUE].includes(
                                                    currentKey.status
                                                ) && (
                                                    <div className="button-row">
                                                        <div className="button-footer-docs">
                                                            <Button
                                                                secondary
                                                                height={40}
                                                                type="button"
                                                                disabled={isSendingFlkAKey}
                                                                onClick={handleCloseModal}
                                                            >
                                                                {isSendingFlkAKey && (
                                                                    <span className="savestatus-spinner-await-completion" />
                                                                )}
                                                                Cancel
                                                            </Button>
                                                        </div>
                                                        <div className="button-footer-docs">
                                                            <Button
                                                                primary
                                                                height={40}
                                                                type="submit"
                                                                disabled={isSendingFlkAKey || !isEditable}
                                                                onClick={() =>
                                                                    form.change('submitType', FORM_SUBMIT_TYPE_SAVE)
                                                                }
                                                            >
                                                                {isSendingFlkAKey && (
                                                                    <span className="savestatus-spinner-await-completion" />
                                                                )}
                                                                Save changes
                                                            </Button>
                                                        </div>
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        )}
                                        {currentKey.status === FLK_A_KEY_STATUS_RETURNED && (
                                            <React.Fragment>
                                                <FlkAKeyHistoryDetails currentHistoryDetails={currentKey} />
                                                <h2>Check out history</h2>
                                                <Button onClick={() => setViewCheckOutHistory(true)} type="button">
                                                    View
                                                </Button>
                                            </React.Fragment>
                                        )}
                                    </div>
                                )}
                                {!locationAndAddressSelected && (
                                    <LocationAndAddressSelection
                                        docType={FLK_A_KEY}
                                        hideLocation={true}
                                        closeModal={handleCloseModal}
                                        confirmDetails={() => confirmDetails(values)}
                                        address={values.address}
                                        handleAddressChange={address => handleAddressChange(form.change, address)}
                                        setAddressDetails={(address, details) =>
                                            setAddressDetails(address, details, values)
                                        }
                                        setIntegrationProperty={property => setIntegrationProperty(property)}
                                        isCreating={isSendingFlkAKey}
                                        allowEmptyTenancy={1}
                                    />
                                )}
                                {viewCheckOutHistory && (
                                    <FlkAKeyCheckOutHistoryModal
                                        viewCheckOutHistory={viewCheckOutHistory}
                                        closeModal={() => setViewCheckOutHistory(false)}
                                        currentKey={currentKey}
                                    />
                                )}
                            </form>
                        );
                    }}
                </Form>
            </ModalDialog>
        </React.Fragment>
    );
};
export default memo(FlkAKeyForm);
