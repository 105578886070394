import React, { memo } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import { lowerCase } from 'lodash';

import { AddBoxSharp, HelpOutlineSharp } from '@flk-mui-icons';
import CancelIcon from '@flk-mui-icons/CancelSharp';
import EditSharpIcon from '@flk-mui-icons/EditSharp';
import CloneIcon from '@flk-mui-icons/FileCopySharp';
import RetractIcon from '@flk-mui-icons/HighlightOffSharp';
import { ReactComponent as DeleteIcon } from '../../../assets/images/icons/trashcan.svg';
import { ReactComponent as SignIcon } from '../../../assets/images/icons/icon_sign.svg';
import { ReactComponent as RenewIcon } from '../../../assets/images/icons/icon_refresh.svg';
import { ReactComponent as ArchiveIcon } from '../../../assets/images/icons/icon_archive.svg';

import { openRetractLeaseConfirmModal } from '../../actions/confirmModals';
import * as dashboardActions from '../../actions/dashboard';
import * as leaseActions from '../../actions/lease';
import * as renewalActions from '../../actions/renewal';
import { isUserRoleAdmin } from '../../selectors/user';
import { getLeaseType } from '../../selectors/lease';

import Dropdown from '../../common/components/dropdown/Dropdown';
import DropdownItem from '../../common/components/dropdown/DropdownItem';

import { useOpenAgreement } from '../../hooks/useOpenAgreement';

import { LEASE_TYPE_PROPERTY_MANAGEMENT, LEASE_TYPE_RESIDENTIAL } from '../../config';
import {
    isLeaseTypePm,
    isLeaseTypeResidentialTenancy,
    isLeaseTypeSales,
    isLongTermRenewalSubLease
} from '../../utils/agreementUtils';
import { getLabel, CANCEL_SIGNING_MESSAGE } from '../../utils/labelUtils';
import { disableLeaseDeletion } from '../../utils/agreementUtils';
import styles from './LeaseDropdown.module.scss';
import Tooltip from '@app/common/components/tooltips/Tooltip';

type LeaseDropdownProps = React.PropsWithChildren<{
    // TODO: Is there a lease type?
    lease: any;
    leaseStatus: string;
    isCompletedLease?: boolean;
    isRenewalsDue?: boolean;
    hideResendButton?: boolean;
}>;

const LeaseDropdown: React.FC<LeaseDropdownProps> = ({
    lease,
    leaseStatus,
    isCompletedLease,
    isRenewalsDue,
    hideResendButton,
    children
}) => {
    const dispatch = useDispatch();
    const isUserAdmin = useSelector(isUserRoleAdmin);
    const leaseType = useSelector(getLeaseType);
    const { openModalInfoAgreement } = useOpenAgreement();

    const leaseId = lease.id;

    const shouldDisplayRemoveOption =
        leaseStatus === dashboardActions.LEASE_STATUS_DRAFT ||
        leaseStatus === dashboardActions.LEASE_STATUS_AWAITING_COMPLETION ||
        (leaseStatus === dashboardActions.LEASE_STATUS_ARCHIVED && isUserAdmin) ||
        leaseStatus === dashboardActions.LEASE_STATUS_COMPLETE;

    const moveToArchiveStatus = () => {
        dispatch(dashboardActions.updateAgreementLoadingState(leaseId));
        dispatch(leaseActions.moveToArchive(leaseId));
    };

    const moveToAwaitingRenewalStatus = () => {
        dispatch(dashboardActions.updateAgreementLoadingState(leaseId));
        dispatch(leaseActions.moveToAwaitingRenewal(leaseId));
    };

    const cloneAgreement = () => {
        if (isLeaseTypeResidentialTenancy(leaseType)) {
            dispatch(leaseActions.openCloneResTenForm(leaseId, LEASE_TYPE_RESIDENTIAL));
        } else {
            dispatch(dashboardActions.cloneAgreement(leaseId));
        }
    };

    /**
     * Cancel signing of document
     * After canceling lease agreement will be moved to the DRAFT status.
     * All signatures will also be removed
     */
    function cancelSigningWithConfirmation() {
        confirmAlert({
            title: '',
            message: CANCEL_SIGNING_MESSAGE,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        dispatch(dashboardActions.updateAgreementLoadingState(leaseId));
                        dispatch(dashboardActions.closeSigning(leaseId));
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }

    const cancelRenewalWithConfirmation = () => {
        confirmAlert({
            title: '',
            message: 'Are you sure you want to cancel the renewal?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        dispatch(renewalActions.rejectRenewal(leaseId));
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    };

    const deleteWithConfirmation = () => {
        confirmAlert({
            title: '',
            message: 'Are you sure you want to delete this agreement?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        dispatch(dashboardActions.updateAgreementLoadingState(leaseId));
                        dispatch(dashboardActions.deleteAgreement(leaseId));
                        dispatch(dashboardActions.updateAgreementToRemoveLater(leaseId));
                        dispatch(dashboardActions.closeAgreementInfoModal());
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    };

    const openRenewalIntentionModal = () => {
        dispatch(dashboardActions.openIntentionRenewalModal(leaseId, lease));
    };

    const openLeaseRenewalModal = () => {
        // The lease extension does not work for SA when location is not populated so add it here
        dispatch(leaseActions.setLocation(lease.location));
        dispatch(renewalActions.checkRenewal(lease.id));
        dispatch(renewalActions.openLeaseRenewalModal(lease));
    };

    const openEditTenantsModal = () => {
        dispatch(dashboardActions.openEditTenantsModal(lease));
    };

    const openEditLandlordsModal = () => {
        dispatch(dashboardActions.openEditLandlordsModal(lease));
    };
    const openEditVendorsModal = () => {
        dispatch(dashboardActions.openEditVendorsModal(lease));
    };
    const openExtendExpiryModal = () => {
        dispatch(dashboardActions.openExtendExpiryModal(lease));
    };

    function retractOfferOfRenewal() {
        dispatch(openRetractLeaseConfirmModal(lease));
    }

    const isRenewalDisplayCondition = leaseStatus === dashboardActions.LEASE_STATUS_AWAITING_RENEWAL || !!isRenewalsDue;

    return (
        <Dropdown
            trigger={children}
            dropdownItems={[
                {
                    key: 'cloneAgreement',
                    component: (
                        <DropdownItem
                            key="cloneAgreement"
                            icon={<CloneIcon className={styles.icon} />}
                            text="Clone agreement"
                            onClick={cloneAgreement}
                        />
                    ),
                    showItemCondition: leaseStatus !== dashboardActions.LEASE_STATUS_AWAITING_RENEWAL
                },
                {
                    component: (
                        <DropdownItem
                            key="editTenants"
                            icon={<EditSharpIcon className={styles.icon} />}
                            text="Edit tenants"
                            onClick={openEditTenantsModal}
                        />
                    ),
                    showItemCondition:
                        leaseStatus === dashboardActions.LEASE_STATUS_SENT_SIGNING &&
                        isLeaseTypeResidentialTenancy(leaseType)
                },
                {
                    component: (
                        <DropdownItem
                            key="editLandlords"
                            icon={<EditSharpIcon className={styles.icon} />}
                            text={`Edit ${getLabel('landlords', lease.location, LEASE_TYPE_PROPERTY_MANAGEMENT)}`}
                            onClick={openEditLandlordsModal}
                        />
                    ),
                    showItemCondition:
                        leaseStatus === dashboardActions.LEASE_STATUS_SENT_SIGNING && isLeaseTypePm(leaseType)
                },
                {
                    component: (
                        <DropdownItem
                            key="editVendors"
                            icon={<EditSharpIcon className={styles.icon} />}
                            text="Edit vendors"
                            onClick={openEditVendorsModal}
                        />
                    ),
                    showItemCondition:
                        leaseStatus === dashboardActions.LEASE_STATUS_SENT_SIGNING && isLeaseTypeSales(leaseType)
                },
                {
                    component: (
                        <DropdownItem
                            icon={<AddBoxSharp className={styles.icon} />}
                            text={
                                isLongTermRenewalSubLease(lease.subLeaseType) ? 'Extend offer expiry' : 'Extend expiry'
                            }
                            onClick={openExtendExpiryModal}
                        />
                    ),
                    showItemCondition:
                        leaseStatus === dashboardActions.LEASE_STATUS_SENT_SIGNING &&
                        lease.signingExpire <= 0 &&
                        (isLeaseTypeResidentialTenancy(leaseType) || isLeaseTypePm(leaseType)) &&
                        !lease.isManuallyShareLinks
                },
                {
                    component: (
                        <DropdownItem
                            icon={<SignIcon className={styles.icon} />}
                            text="Re-send for signing"
                            onClick={() => openModalInfoAgreement(lease, leaseType)}
                        />
                    ),
                    showItemCondition:
                        leaseStatus === dashboardActions.LEASE_STATUS_SENT_SIGNING &&
                        !hideResendButton &&
                        !lease.isManuallyShareLinks &&
                        isLeaseTypePm(leaseType)
                },
                {
                    component: (
                        <DropdownItem
                            icon={<CancelIcon className={cx(styles.icon, styles.errorIcon)} />}
                            text="Move to drafts"
                            onClick={cancelSigningWithConfirmation}
                        />
                    ),
                    showItemCondition: leaseStatus === dashboardActions.LEASE_STATUS_SENT_SIGNING
                },
                {
                    component: (
                        <DropdownItem
                            icon={<RetractIcon className={cx(styles.icon, styles.errorIcon)} />}
                            text="Retract offer of lease"
                            onClick={retractOfferOfRenewal}
                        />
                    ),
                    showItemCondition:
                        leaseStatus === dashboardActions.LEASE_STATUS_SENT_SIGNING &&
                        isLeaseTypeResidentialTenancy(leaseType)
                },
                {
                    component: (
                        <DropdownItem
                            icon={<CancelIcon className={cx(styles.icon, styles.errorIcon)} />}
                            text="Cancel renewal"
                            onClick={cancelRenewalWithConfirmation}
                        />
                    ),
                    showItemCondition: isRenewalDisplayCondition
                },
                {
                    component: (
                        <DropdownItem
                            icon={<RenewIcon className={styles.icon} />}
                            text="Renew agreement"
                            onClick={openLeaseRenewalModal}
                        />
                    ),
                    showItemCondition: isRenewalDisplayCondition
                },
                {
                    component: (
                        <>
                            <DropdownItem
                                icon={<DeleteIcon className={cx(styles.icon, styles.errorIcon)} />}
                                text="Delete agreement"
                                onClick={deleteWithConfirmation}
                                disabled={disableLeaseDeletion(isUserAdmin, leaseStatus)}
                                className={disableLeaseDeletion(isUserAdmin, leaseStatus) ? styles.disabled : ''}
                                dataFor="delete"
                                dataTip={`Team members aren't allowed to delete agreements in ${lowerCase(
                                    leaseStatus
                                )}. Please contact your admin to assist`}
                            />
                            {disableLeaseDeletion(isUserAdmin, leaseStatus) && (
                                <Tooltip place="bottom" effect="solid" id="delete" className={styles.toolTip} />
                            )}
                        </>
                    ),
                    showItemCondition: shouldDisplayRemoveOption
                },
                {
                    component: (
                        <DropdownItem
                            icon={<ArchiveIcon className={styles.icon} />}
                            text="Move to complete"
                            onClick={moveToArchiveStatus}
                        />
                    ),
                    showItemCondition:
                        leaseStatus === dashboardActions.LEASE_STATUS_DRAFT &&
                        !isLongTermRenewalSubLease(lease.subLeaseType) &&
                        !!isCompletedLease
                },
                {
                    component: (
                        <DropdownItem
                            icon={<HelpOutlineSharp className={styles.icon} />}
                            text="Ask for renewal intent"
                            onClick={openRenewalIntentionModal}
                        />
                    ),
                    showItemCondition:
                        isLeaseTypeResidentialTenancy(leaseType) &&
                        leaseStatus === dashboardActions.LEASE_STATUS_AWAITING_RENEWAL
                },
                {
                    component: (
                        <DropdownItem
                            icon={<RenewIcon className={styles.icon} />}
                            text="Move to awaiting renewal"
                            onClick={moveToAwaitingRenewalStatus}
                        />
                    ),
                    showItemCondition:
                        (leaseStatus === dashboardActions.LEASE_STATUS_ARCHIVED ||
                            leaseStatus === dashboardActions.LEASE_STATUS_COMPLETE) &&
                        isLeaseTypeResidentialTenancy(leaseType) &&
                        !isLongTermRenewalSubLease(lease.subLeaseType)
                }
            ]}
        />
    );
};

export default memo(LeaseDropdown);
