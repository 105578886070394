import React, { forwardRef, memo, useState, useEffect, useImperativeHandle } from 'react';
import { getId } from '@app/utils/getId';
import { Form, FormSpy } from 'react-final-form';
import { map, isEmpty, find, has } from 'lodash';
import { FormTextRegular } from '../../../components/form/FormText';
import { CheckboxCheck } from '../../../components/form/FormCheckboxCheck';
import { SelectField } from '../../../components/form/FormSelect';
import { services as servicesList } from '../../../config';
import * as validators from '../../../components/Validate';
import '../../../sass/connections.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
    getConnections,
    getLocation,
    getLeaseType,
    getLeaseInfoAgent,
    getTenants,
    getStep,
    getDirtyStep,
    getValidationErrors
} from '../../../selectors/lease';
import { getConnectionCompanies } from '../../../selectors/connections';
import { getTeam } from '../../../selectors/agency';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { setDirtyStep, updateSubmitTypeSuccess, formSubmitFail } from '../../../actions/lease';
import axios from 'axios';
import * as Lease from '../../../reducers/lease';
import { FormRadioGroup } from '../../../components/form/FormRadioGroup';
import { getResTenLabel } from '../../../utils/labelUtils';

const CONNECTIONS_FORM = 'connectionsForm';

const setAllServicesToSameValues = enabled => {
    let services = {};
    map(servicesList, value => {
        services[value.inputName] = enabled;
    });

    return services;
};

const getUserOptions = users => {
    return map(users, (value, key) => {
        return {
            value: value.id,
            label: value.fullName
        };
    });
};

const Integrations = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const validationErrors = useSelector(getValidationErrors);
    const leaseType = useSelector(getLeaseType);
    const location = useSelector(getLocation);
    const reduxConnections = useSelector(getConnections);
    const reduxConnectionCompanies = useSelector(getConnectionCompanies);
    const agent = useSelector(getLeaseInfoAgent);
    const team = useSelector(getTeam);
    const tenants = useSelector(getTenants);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    let [addEmailFieldError, setAddEmailFieldError] = useState('');
    let [connectionsEnabledError, setConnectionsEnabledError] = useState(null);

    const formatConnections = () => {
        let connections = {
            connectionsEnabled: reduxConnections.connectionsEnabled,
            connectionCompany: reduxConnections.connectionCompany,
            additionalEmails: reduxConnections.additionalEmails,
            propertyManager: reduxConnections.propertyManager,
            email: reduxConnections.email,
            services: {
                ...reduxConnections.services
            }
        };

        // If services are not set, get all the default services and set the value to false
        if (isEmpty(connections.services)) {
            connections.services = setAllServicesToSameValues(false);
        }

        if (isEmpty(connections.connectionCompany) && !isEmpty(connectionCompanies)) {
            connections.connectionCompany = connectionCompanies[0];
        }

        if (isEmpty(connections.propertyManager)) {
            if (agent) {
                connections.propertyManager = agent.id;
            }
        }

        return connections;
    };

    let [connections, setConnections] = useState();
    let [connectionCompanies, setConnectionCompanies] = useState(reduxConnectionCompanies);
    let [userOptions, setUserOptions] = useState(reduxConnectionCompanies);

    useEffect(() => {
        setUserOptions(getUserOptions(team));
        if (reduxConnectionCompanies) {
            const connectionCompanies = [];
            reduxConnectionCompanies.forEach(company =>
                connectionCompanies.push({
                    value: getId(company),
                    label: company.name
                })
            );
            setConnectionCompanies(connectionCompanies);
        }
    }, [validationErrors, reduxConnectionCompanies]);

    useEffect(() => {
        if (reduxConnections) {
            setConnections(formatConnections());
        }
    }, [reduxConnections]);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(CONNECTIONS_FORM).dispatchEvent(
                new Event('submit', {
                    cancelable: true,
                    bubbles: true
                })
            );
        }
    }));

    const getPrimaryTenantName = () => {
        let tenant = find(tenants, { isPrimaryTenant: true });

        if (isEmpty(tenant)) {
            return `The primary ${getResTenLabel('tenantLower', location)} has not been selected.`;
        }

        if (tenant.middleName) {
            return `${tenant.firstName} ${tenant.middleName} ${tenant.secondName}`;
        }

        return `${tenant.firstName} ${tenant.secondName}`;
    };

    const validate = value => {
        if (isEmpty(value)) {
            return false;
        }

        if (!validators.isCorrectEmail(value)) {
            setAddEmailFieldError('Wrong email format');
            return false;
        }

        return true;
    };

    const addEmail = (email, push) => {
        setAddEmailFieldError('');

        // Check that the field is valid before adding it to the list
        let isValid = validate(email);
        if (!isValid) {
            return;
        }

        push('additionalEmails', email);
    };

    const pressEnter = (event, push) => {
        let value = event.target.value;
        // Only if the user pressed enter
        if (event.keyCode === 13) {
            // Add email to the list and wipe out the value of the field
            addEmail(value, push);
        }
    };

    const handleBlur = (event, push) => {
        let value = event.target.value;

        // Add email to the list and wipe out the value of the field
        addEmail(value, push);
    };

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const updateConnections = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/connections`, data);
    };

    const submitForm = values => {
        /**
         * Store the ref of the component so it can be used in this function
         * We need this otherwise the promise below doesn't have access to the ref
         */
        const currentRef = ref.current;
        /**
         * Grab the bypassFormValidation that was set from the parent component: LeaseAgreementForm.js
         */
        values.bypassFormValidation = currentRef.bypassFormValidation;
        setConnectionsEnabledError(null);
        return updateConnections(values)
            .then(result => {
                result.isLeaseEditingFinished = !currentRef.bypassFormValidation;
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_CONNECTIONS_SUCCESS)).then(() => {
                    /**
                     * Callback after submit this form so that the parent component can take an action
                     */
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (
                    has(error, 'response.data.errors.connections') &&
                    error.response.data.errors.connections.connectionsEnabled
                ) {
                    setConnectionsEnabledError(error.response.data.errors.connections.connectionsEnabled);
                } else if (has(error, 'response.data.errors.connections')) {
                    return error.response.data.errors.connections;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                } else {
                    throw error;
                }
            });
    };

    return (
        <div className="connections">
            <Form
                onSubmit={submitForm}
                initialValues={connections}
                mutators={{
                    ...arrayMutators
                }}
            >
                {({
                    handleSubmit,
                    values,
                    form,
                    form: {
                        mutators: { push }
                    }
                }) => {
                    const selectedConnectionCompany = reduxConnectionCompanies.find(
                        company => getId(company) === values.connectionCompany
                    );

                    return (
                        <form id={CONNECTIONS_FORM} onSubmit={handleSubmit} noValidate>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <CheckboxCheck name="connectionsEnabled" label="Enable Connections" />
                            {values.connectionsEnabled && (
                                <div>
                                    <p className="primary-tenant">
                                        {`Primary ${getResTenLabel('tenant', location)}:`}{' '}
                                        <strong>{getPrimaryTenantName()}</strong>
                                    </p>

                                    <div className="radioGroupList">
                                        <div className="radioGroup">
                                            <FormRadioGroup
                                                label="Choose Connections Company"
                                                name={`connectionCompany`}
                                                className={'radioGroupConnections'}
                                                data={connectionCompanies}
                                                value={values.connectionCompany}
                                            />
                                        </div>
                                    </div>
                                    {(!selectedConnectionCompany?.apiConfig ||
                                        selectedConnectionCompany?.apiConfig.active === false) &&
                                        !selectedConnectionCompany?.apiV2 && (
                                            <>
                                                <p className="gray-label">Send copy of email to:</p>
                                                <ul className="emails-list">
                                                    <FieldArray
                                                        name="additionalEmails"
                                                        initialValue={values.additionalEmails}
                                                    >
                                                        {({ fields }) => (
                                                            <React.Fragment>
                                                                {fields.map((name, index) => {
                                                                    return (
                                                                        <li key={index}>
                                                                            {fields.value[index]}
                                                                            <button
                                                                                className="btn-delete"
                                                                                type="button"
                                                                                onClick={() => fields.remove(index)}
                                                                            />
                                                                        </li>
                                                                    );
                                                                })}
                                                            </React.Fragment>
                                                        )}
                                                    </FieldArray>
                                                </ul>

                                                <FormTextRegular
                                                    name="email"
                                                    label="Enter email and press enter"
                                                    onKeyDown={event => pressEnter(event, push)}
                                                    onBlur={event => handleBlur(event, push)}
                                                />
                                            </>
                                        )}

                                    <p className="gray-label">Referring property manager</p>
                                    <SelectField name="propertyManager" options={userOptions} selectClassName="sel-3" />
                                    <p className="gray-label">Choose Services</p>
                                    {connectionsEnabledError && (
                                        <p className="FormInput">
                                            <span className="FormError">{connectionsEnabledError}</span>
                                        </p>
                                    )}

                                    {map(servicesList, value => {
                                        return (
                                            <CheckboxCheck
                                                className={`test-services-${value.inputName}`}
                                                key={value.inputName}
                                                name={`services.${value.inputName}`}
                                                label={value.title}
                                            />
                                        );
                                    })}
                                    <p className="text-warning">
                                        <span className="icon icon-warning" /> Only connections with a{' '}
                                        <i className="marker success" />{' '}
                                        {`next to them will be visible to the ${getResTenLabel(
                                            'tenantLower',
                                            location
                                        )}`}
                                    </p>
                                </div>
                            )}
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(Integrations);
