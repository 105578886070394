import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../sass/notificationsPanel.scss';
import { formatDateTime, getAgencyTimezoneFromUser } from '../../utils/dateUtils';
import { setActiveRequest, showForm } from '../../reducers/leaseWithRequest';
import { getInfoLease } from '../../actions/lease';
import { getNotifications } from '../../selectors/notication';
import { getDashboard } from '../../selectors/dashboard';
import { useNotificationAdded } from '../../hooks/useNotificationAdded';
import { getUserAgency } from '../../selectors/agency';
import { getUserInfo } from '../../selectors/user';
import {
    clearResolvedNotifications,
    socketUpdateOrAddNotification,
    socketRemoveNotification
} from '../../actions/notification';
import { useNotificationUpdated } from '../../hooks/useNotificationUpdated';
import ClearAllSharpIcon from '@flk-mui-icons/ClearAllSharp';
import { useOpenAgreement } from '../../hooks/useOpenAgreement';
import { useNotificationRemoved } from '../../hooks/useNotificationRemoved';
import {
    DOCUMENT_READ_ONLY_MODE,
    DOCUMENT_RENT_INCREASE,
    DOCUMENT_TERMINATION_NOTICE,
    REQUEST_TO_CHANGE_STATUS_NEW,
    REQUEST_TO_CHANGE_STATUS_RESOLVED,
    REQUEST_TO_CHANGE_STATUS_UNRESOLVED
} from '../../config';
import { openRentIncreaseLeaseListModal, openTerminateLeaseListModal } from '../../actions/terminateLease';
import { getInfoDocument, openDocumentForm } from '../../actions/document';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
const STAND_ALONE_PDF_GENERATION = 'stand_alone_document_pdf_generation';

const NOTIFICATION_SECTION_48_TEXT = 'section 48(1) notice';

const NotificationsPanel = ({ isOpen, userInfo }) => {
    const dispatch = useDispatch();
    const notifications = useSelector(getNotifications);
    const agency = useSelector(getUserAgency);

    useNotificationAdded(agency, item => {
        if (item.data.receivers && item.data.receivers.length > 0) {
            // if the notification has receivers, then we want to check if the logged in user is one of those receivers, otherwise we don't want to show the notification
            if (item.data.receivers.includes(userInfo.id)) {
                dispatch(socketUpdateOrAddNotification(item.data));
            }
        } else {
            dispatch(socketUpdateOrAddNotification(item.data));
        }
    });
    useNotificationUpdated(agency, item => {
        if (item.data.receivers && item.data.receivers.length > 0) {
            if (item.data.receivers.includes(userInfo.id)) {
                dispatch(socketUpdateOrAddNotification(item.data));
            }
        } else {
            dispatch(socketUpdateOrAddNotification(item.data));
        }
    });
    useNotificationRemoved(agency, item => {
        if (item.previousItemData && item.previousItemData.receivers && item.previousItemData.receivers.length > 0) {
            if (item.previousItemData.receivers.includes(userInfo.id)) {
                dispatch(socketRemoveNotification(item.previousItemData));
            }
        } else {
            dispatch(socketRemoveNotification(item.previousItemData));
        }
    });

    const onClearResolvedNotifications = () => {
        dispatch(clearResolvedNotifications());
    };

    return (
        <div className={isOpen ? 'notifications-panel on' : 'notifications-panel'}>
            {!notifications.length && (
                <div className="notifications-panel-content">
                    <div className="nothing">You don't have notifications for now</div>
                </div>
            )}

            {!!notifications.length && (
                <React.Fragment>
                    <a className="special-link" onClick={onClearResolvedNotifications}>
                        <ClearAllSharpIcon /> Clear resolved notifications
                    </a>
                    <div className="notifications-panel-content">
                        {notifications.map((notification, index) => {
                            const status =
                                notification.status !== REQUEST_TO_CHANGE_STATUS_RESOLVED
                                    ? 'notifications-icon icon-exclamation'
                                    : ' notifications-icon icon-check';

                            switch (notification.notificationType) {
                                case 'change_request':
                                    return (
                                        <ChangeRequestNotification
                                            key={index}
                                            status={status}
                                            notification={notification}
                                        />
                                    );
                                case 'lease_document_pdf_generation':
                                    return (
                                        <DocumentPdfGenerationErrorNotification
                                            key={index}
                                            status={status}
                                            notification={notification}
                                            userInfo={userInfo}
                                        />
                                    );
                                case STAND_ALONE_PDF_GENERATION:
                                    return (
                                        <StandAloneDocumentPdfGenerationErrorNotification
                                            key={index}
                                            status={status}
                                            notification={notification}
                                            userInfo={userInfo}
                                        />
                                    );
                                case 'finish_completion':
                                    return (
                                        <FinishCompletionNotification
                                            key={index}
                                            status={status}
                                            notification={notification}
                                            userInfo={userInfo}
                                        />
                                    );
                                default:
                                    return (
                                        <ChangeRequestNotification
                                            key={index}
                                            status={status}
                                            notification={notification}
                                        />
                                    );
                            }
                        })}
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};

function ResolveButton({ notification }) {
    const dispatch = useDispatch();

    const STATUS_RESOLVE = 'resolve';
    const STATUS_UNRESOLVE = 'unresolve';

    function handleResolveRequest(status) {
        return axios
            .put(`api/agency/notification/${notification.id}/${status}`)
            .then(result => {
                dispatch(socketUpdateOrAddNotification(result.notification));
            })
            .catch(error => {
                if (window.rollbar) {
                    window.rollbar.error(
                        'Something went wrong resolving notification',
                        {
                            error_message: error.message,
                            status: 'error',
                            env: ENVIRONMENT
                        },
                        error
                    );
                }
            });
    }

    return (
        <div className="flex">
            <span className="resolve-status">
                {notification.status === REQUEST_TO_CHANGE_STATUS_NEW && (
                    <button className="resolve-button" onClick={() => handleResolveRequest(STATUS_RESOLVE)}>
                        resolve
                    </button>
                )}
                {notification.status === REQUEST_TO_CHANGE_STATUS_RESOLVED && (
                    <button className="resolved-button" onClick={() => handleResolveRequest(STATUS_UNRESOLVE)}>
                        resolved
                    </button>
                )}
                {notification.status === REQUEST_TO_CHANGE_STATUS_UNRESOLVED && (
                    <button className="unresolved-button" onClick={() => handleResolveRequest(STATUS_RESOLVE)}>
                        unresolved
                    </button>
                )}
            </span>
        </div>
    );
}

const ChangeRequestNotification = ({ status, notification }) => {
    const { leaseType, tenantName, leaseAgreementStep, requestMessage, updated } = notification;
    const stepName = leaseAgreementStep?.stepName;

    const dispatch = useDispatch();

    const dashboard = useSelector(getDashboard);
    const loggedInUser = useSelector(getUserInfo);

    const openNotification = notification => {
        if (notification.appPath) {
            sessionStorage['returnUrl'] = window.location.href;
            window.location.href = notification.appPath;
        } else {
            dashboard.isNotificationPanelOpen = false;
            dispatch(setActiveRequest(notification));
            dispatch(getInfoLease(notification.lease.id)).then(() => {
                dispatch(showForm());
            });
        }
    };

    return (
        <div
            className="notifications-item"
            onClick={() => {
                openNotification(notification);
            }}
        >
            <span className={status} />
            <div className="notifications-item-title">{leaseType}</div>
            <strong>{tenantName}</strong> requested changes{stepName ? ` on step ${stepName}` : ''}.
            <br />
            {requestMessage}
            <span className="notifications-time">
                {formatDateTime(updated, getAgencyTimezoneFromUser(loggedInUser))}
            </span>
        </div>
    );
};

const FinishCompletionNotification = ({ status, notification }) => {
    const { message, lease, updated } = notification;
    const { openModalInfoAgreement } = useOpenAgreement();
    const loggedInUser = useSelector(getUserInfo);

    const openNotification = lease => {
        openModalInfoAgreement(lease, lease.leaseType);
    };

    return (
        <div
            className="notifications-item"
            onClick={() => {
                openNotification(lease);
            }}
        >
            <span className={status} />
            <div className="notifications-item-title">{lease.leaseType} - Error</div>
            <strong>{lease.address}</strong> - {message}
            <br />
            <span className="notifications-time">
                {formatDateTime(updated, getAgencyTimezoneFromUser(loggedInUser))}
            </span>
            <ResolveButton notification={notification} />
        </div>
    );
};

const DocumentPdfGenerationErrorNotification = ({ status, notification }) => {
    const dashboard = useSelector(getDashboard);
    const { message, lease, updated } = notification;
    const dispatch = useDispatch();
    const { openModalInfoAgreement } = useOpenAgreement();
    const loggedInUser = useSelector(getUserInfo);

    const openNotification = () => {
        dashboard.isNotificationPanelOpen = false;
        dispatch(getInfoLease(notification.lease.id)).then(() => {
            if (message.includes(NOTIFICATION_SECTION_48_TEXT)) {
                openModalInfoAgreement(lease, lease.leaseType);
            } else if (notification.docType) {
                if (notification.docType === DOCUMENT_TERMINATION_NOTICE) {
                    dispatch(openTerminateLeaseListModal());
                } else if (notification.docType === DOCUMENT_RENT_INCREASE) {
                    dispatch(openRentIncreaseLeaseListModal());
                } else {
                    openModalInfoAgreement(lease, lease.leaseType);
                }
            }
        });
    };

    return (
        <div
            className="notifications-item"
            onClick={event => {
                // Dont open when someone clicks on the resolve button
                if (
                    !event.target.classList.contains('resolved-button') &&
                    !event.target.classList.contains('resolve-button') &&
                    !event.target.classList.contains('unresolved-button')
                ) {
                    openNotification(lease);
                }
            }}
        >
            <span className={status} />
            <div className="notifications-item-title">PDF Generation Error</div>
            <strong>{lease ? lease.address : ''}</strong> - {message}
            <br />
            <span className="notifications-time">
                {formatDateTime(updated, getAgencyTimezoneFromUser(loggedInUser))}
            </span>
            <ResolveButton notification={notification} />
        </div>
    );
};

const StandAloneDocumentPdfGenerationErrorNotification = ({ status, notification }) => {
    const { message, updated } = notification;
    const dashboard = useSelector(getDashboard);
    const loggedInUser = useSelector(getUserInfo);
    const dispatch = useDispatch();
    const history = useHistory();

    const openNotification = notification => {
        dashboard.isNotificationPanelOpen = false;
        dispatch(getInfoDocument(notification.documentId)).then(response => {
            if (response.error) {
                alert('Problem getting document, please contact FLK');
            } else {
                history.push(`/dashboard/documents/${response.data.doc.docType}/${response.data.doc.status}`);
                dispatch(openDocumentForm(response.data.doc.docType, response.data.doc, DOCUMENT_READ_ONLY_MODE));
            }
        });
    };

    return (
        <div
            className="notifications-item"
            onClick={() => {
                openNotification(notification);
            }}
        >
            <span className={status} />
            <div className="notifications-item-title">PDF Generation Error</div>
            {message}
            <br />
            <span className="notifications-time">
                {formatDateTime(updated, getAgencyTimezoneFromUser(loggedInUser))}
            </span>
            <ResolveButton notification={notification} />
        </div>
    );
};

export default memo(NotificationsPanel);
