import React, { memo, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import { has, cloneDeep, isEmpty, pullAt, isString, indexOf } from 'lodash';
import { SA_STATE, VIC_STATE } from '../../../config';
import { CheckboxCheck } from '../../../components/form/FormCheckboxCheck';
import { FormTextRegular } from '../../../components/form/FormText';
import { FormRadioGroup } from '../../../components/form/FormRadioGroup';
import TenantPersonGroup from '../../../components/lease/mainScreen/common/TenantPersonGroup';

import * as validators from '../../../components/Validate';
import '../../../sass/tenant.scss';
import {
    getLocation,
    getStep,
    getDirtyStep,
    getAddress,
    getTenant,
    getValidationErrors,
    getLeaseType
} from '../../../selectors/lease';

import '../../../sass/tenant.scss';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../actions/lease';
import axios from 'axios';
import * as Lease from '../../../reducers/lease';
import { getResTenLabel } from '../../../utils/labelUtils';

const maxTenants = 8;

let tenantDefault = {
    firstName: '',
    middleName: '',
    secondName: '',
    phone: '',
    email: '',
    extraContacts: [],
    isPrimaryTenant: false,
    ANC: '',
    postcode: '',
    address: ''
};

const initialState = {
    tenant: {
        isLeaseAddress: false,
        address: '',
        tenants: [tenantDefault],
        servicesOfNoticesSelected: 'address',
        servicesOfNoticesEmails: [],
        servicesOfNoticesEmail: ''
    },
    addEmailFieldError: '',
    usingCustomEmailAddresses: false
};
const TENANT_FORM = 'tenantForm';

const Tenant = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const validationErrors = useSelector(getValidationErrors);
    const address = useSelector(getAddress);
    const reduxTenant = useSelector(getTenant);

    const [tenant, setTenant] = useState(initialState.tenant);
    const [errors, setErrors] = useState({});
    const [usingCustomEmailAddresses, setUsingCustomEmailAddresses] = useState(false);
    const [addEmailFieldError, setAddEmailFieldError] = useState('');
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(TENANT_FORM).dispatchEvent(
                new Event('submit', {
                    cancelable: true,
                    bubbles: true
                })
            );
        }
    }));

    useEffect(() => {
        if (reduxTenant) {
            setTenant(reduxTenant);
            if (reduxTenant.isServicesOfNoticesEmail) {
                const newTenant = { ...reduxTenant };
                newTenant.servicesOfNoticesSelected = 'emails';
                setTenant(newTenant);
                // If tenant email list and Service Notices email list is different, then
                // Service Notices email should be custom emails.
                if (reduxTenant.emailAddressesForServiceNotices !== reduxTenant.tenantsListEmailText) {
                    setUsingCustomEmailAddresses(true);
                    const newTenant = { ...reduxTenant };
                    newTenant.servicesOfNoticesEmail = '';
                    newTenant.servicesOfNoticesSelected = 'emails';
                    setTenant(newTenant);
                }
            } else {
                setTenant({ ...reduxTenant, servicesOfNoticesSelected: 'address' });
            }
        }
    }, []);

    const validate = value => {
        if (isEmpty(value)) {
            return false;
        }

        if (!validators.isCorrectEmail(value)) {
            setAddEmailFieldError('Wrong email format');
            return false;
        }

        return true;
    };

    const addEmail = (email, values) => {
        setAddEmailFieldError('');

        // Check that the field is valid before adding it to the list
        let isValid = validate(email);
        if (!isValid) {
            return;
        }

        let emails = [...tenant.servicesOfNoticesEmails];
        emails.push(email);

        //set the form to understand there have been custom values added
        setTenant({ ...values, servicesOfNoticesEmails: emails, servicesOfNoticesEmail: '' });
        setUsingCustomEmailAddresses(true);
    };

    const removeEmail = (values, index) => {
        let emails = [...tenant.servicesOfNoticesEmails];

        pullAt(emails, index);

        setTenant({ ...values, servicesOfNoticesEmails: emails });
        setUsingCustomEmailAddresses(true);
    };

    const pressEnter = (event, values) => {
        let value = event.target.value;
        // Only if the user pressed enter
        if (event.keyCode === 13) {
            // Add email to the list and wipe out the value of the field
            addEmail(value, values);
        }
    };

    const handlePrimaryTenantSelect = (index, value, values, form) => {
        let length = values.tenants.length;

        if (!value) {
            return;
        }

        for (let i = 0; i < length; i++) {
            if (index !== i) {
                form.change(`tenants[${i}].isPrimaryTenant`, false);
            }
        }
    };
    const updateTenant = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/tenant`, data);
    };

    const submitForm = formValues => {
        setErrors({});
        let values = cloneDeep(formValues);
        values.tenants.forEach(tenant => {
            if (typeof tenant.email === 'undefined') {
                tenant.email = '';
            }
            /**
             * We need to popualte this field if it doesn't exist otherwise it won't get removed on deletion
             */
            if (!tenant.extraContacts) {
                tenant.extraContacts = [];
            }
        });
        if (values.servicesOfNoticesSelected === 'address' && values.isLeaseAddress) {
            values.address = address;
        }

        values.isServicesOfNoticesAddress = values.servicesOfNoticesSelected === 'address';
        values.isServicesOfNoticesEmail = values.servicesOfNoticesSelected === 'emails';
        /**
         * Store the ref of the component so it can be used in this function
         * We need this otherwise the promise below doesn't have access to the ref
         */
        const currentRef = ref.current;
        /**
         * Grab the bypassFormValidation that was set from the parent component: LeaseAgreementForm.js
         */
        values.bypassFormValidation = currentRef.bypassFormValidation;
        let tenantValues = cloneDeep(values);
        return updateTenant(tenantValues)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_TENANT_SUCCESS)).then(() => {
                    /**
                     * Callback after submit this form so that the parent component can take an action
                     */
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.tenant')) {
                    if (error.response.data.errors.tenant.servicesOfNoticesEmails) {
                        setErrors({
                            servicesOfNoticesEmails: error.response.data.errors.tenant.servicesOfNoticesEmails
                        });
                    } else if (error.response.data.errors.tenant.tenants) {
                        setErrors({
                            tenants: error.response.data.errors.tenant.tenants
                        });
                    }
                    return error.response.data.errors.tenant;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };
    const handleEmailChange = (e, index, values) => {
        const data = cloneDeep(values);
        if (!usingCustomEmailAddresses) {
            const servicesOfNoticesEmails = [];
            data.tenants[index].email = e.target.value;
            data.tenants.forEach(tenant => {
                if (tenant.email) {
                    servicesOfNoticesEmails.push(tenant.email);
                }
            });
            setTenant({ ...data, servicesOfNoticesEmails });
        } else {
            // this part is to update email list when update tenant email after agent use custom email address
            const emailIndex = indexOf(data.servicesOfNoticesEmails, data.tenants[index].email);
            if (emailIndex >= 0) {
                data.servicesOfNoticesEmails[emailIndex] = e.target.value;
            } else {
                data.servicesOfNoticesEmails.push(e.target.value);
            }
            data.tenants[index].email = e.target.value;
            setTenant({ ...data });
        }
    };
    const removeTenant = (index, values, email) => {
        const data = cloneDeep(values);
        const emailIndex = indexOf(data.servicesOfNoticesEmails, email);
        if (emailIndex >= 0) {
            data.servicesOfNoticesEmails = [
                ...data.servicesOfNoticesEmails.slice(0, emailIndex),
                ...data.servicesOfNoticesEmails.slice(emailIndex + 1)
            ];
        }
        data.tenants = [...data.tenants.slice(0, index), ...data.tenants.slice(index + 1)];
        setTenant({ ...data });
    };

    return (
        <div className="tenant">
            <Form
                onSubmit={submitForm}
                initialValues={tenant}
                mutators={{
                    ...arrayMutators
                }}
            >
                {({
                    handleSubmit,
                    values,
                    form,
                    form: {
                        mutators: { push }
                    }
                }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={TENANT_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <div className="tenants">
                                {validationErrors &&
                                    validationErrors.tenant &&
                                    validationErrors.tenant.isPrimaryTenant && (
                                        <p className="FormInput">
                                            <span className="FormError">{validationErrors.tenant.isPrimaryTenant}</span>
                                        </p>
                                    )}
                                <FieldArray name="tenants" initialValue={tenant.tenants}>
                                    {({ fields }) => (
                                        <React.Fragment>
                                            {fields.map((name, index) => {
                                                return (
                                                    <TenantPersonGroup
                                                        push={push}
                                                        key={index}
                                                        index={index}
                                                        tenant={fields.value[index]}
                                                        removeTenant={() => {
                                                            // remove tenant email from service of notice emails and remove tenant
                                                            removeTenant(index, values, fields.value[index].email);
                                                        }}
                                                        handlePrimaryTenantSelect={(index, value) => {
                                                            handlePrimaryTenantSelect(index, value, values, form);
                                                        }}
                                                        handleEmailChange={(e, index) =>
                                                            handleEmailChange(e, index, values)
                                                        }
                                                        location={location}
                                                        form={form}
                                                    />
                                                );
                                            })}
                                        </React.Fragment>
                                    )}
                                </FieldArray>
                            </div>
                            {has(errors, 'tenants') && isString(errors.tenants) && (
                                <div className="has-error custom-error-message">{errors.tenants}</div>
                            )}
                            {tenant.tenants.length >= maxTenants ? (
                                <div className="add-tenant">
                                    <p>A maximum of eight tenants may be added to the lease agreement</p>
                                </div>
                            ) : (
                                <div className="button">
                                    <button
                                        className="add-tenant"
                                        type="button"
                                        onClick={() => {
                                            push('tenants', tenantDefault);
                                        }}
                                    >
                                        {getResTenLabel('addTenant', location)}
                                    </button>
                                </div>
                            )}
                            {![VIC_STATE, SA_STATE].includes(location) && (
                                <FormRadioGroup
                                    label={getResTenLabel('servicesOfNotices', location)}
                                    name={'servicesOfNoticesSelected'}
                                    data={[
                                        {
                                            label: getResTenLabel('servicesOfNoticesAddress', location),
                                            value: 'address',
                                            className: 'services-of-notices-selected-0'
                                        },
                                        {
                                            label: getResTenLabel('servicesOfNoticesEmail', location),
                                            value: 'emails',
                                            className: 'services-of-notices-selected-1'
                                        }
                                    ]}
                                />
                            )}
                            {values.servicesOfNoticesSelected === 'address' &&
                                ![VIC_STATE, SA_STATE].includes(location) && (
                                    <div>
                                        <FormTextRegular
                                            name="address"
                                            label={getResTenLabel('addressForServicesOfNotices', location)}
                                            disabled={values.isLeaseAddress}
                                        />
                                        <div className="form-checkbox">
                                            <CheckboxCheck
                                                value={true}
                                                name="isLeaseAddress"
                                                label="same as address of premises"
                                                onClick={value => {
                                                    value
                                                        ? form.change('address', address)
                                                        : form.change('address', '');
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}

                            {values.servicesOfNoticesSelected === 'emails' &&
                                ![VIC_STATE, SA_STATE].includes(location) && (
                                    <div>
                                        {location === SA_STATE && (
                                            <p>Email address for service of documents relating to the Tenancy:</p>
                                        )}
                                        {!!values.servicesOfNoticesEmails.length && (
                                            <ul className="emails-list">
                                                {values.servicesOfNoticesEmails.map((email, index) => (
                                                    <li className="column-box" key={index}>
                                                        <div className="individual-email-follow-up">{email}</div>
                                                        <button
                                                            className="btn-delete"
                                                            type="button"
                                                            onClick={() => removeEmail(values, index)}
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                        {errors && errors.servicesOfNoticesEmails && (
                                            <div className="has-error">{errors.servicesOfNoticesEmails}</div>
                                        )}
                                        <FormTextRegular
                                            name="servicesOfNoticesEmail"
                                            label="Enter email and press enter"
                                            onKeyDown={e => pressEnter(e, values)}
                                        />
                                        {addEmailFieldError && <p className="has-error">{addEmailFieldError}</p>}
                                    </div>
                                )}
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});
export default memo(Tenant);
