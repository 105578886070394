import React, { memo, useState, useEffect, useImperativeHandle, useMemo } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { cloneDeep, has } from 'lodash';
import { ToWords } from 'to-words';
import { SelectField } from '../../../components/form/FormSelect';
import { FormTextRegular } from '../../../components/form/FormText';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import '../../../sass/occupants.scss';
import { forwardRef } from 'react';
import { setDirtyStep, updateSubmitTypeSuccess, removeOccupantsSuccess, setMaxOccupants } from '../../../actions/lease';
import { useDispatch, useSelector } from 'react-redux';
import { getDirtyStep, getStep, getOccupants, getTenants, getLeaseInfo, getLocation } from '../../../selectors/lease';
import axios from 'axios';
import * as Lease from '../../../reducers/lease';
import { getResTenLabel } from '../../../utils/labelUtils';
import { isLeaseTypeResidentialTenancy, isLeaseLocationNSW } from '../../../utils/agreementUtils';
const OCCUPANTS_FORM = 'occupantsForm';

const DEFAULT_OCCUPANT_COUNT = 8
const NSW_RTA_OCCUPANT_COUNT = 16;

const Occupants = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);
    const reduxOccupants = useSelector(getOccupants);
    const reduxTenants = useSelector(getTenants);
    const reduxLeaseInfo = useSelector(getLeaseInfo);
    const location = useSelector(getLocation);

    let [occupants, setOccupants] = useState(reduxOccupants);
    let [isRemovingOccupantFail, setIsRemovingOccupantFail] = useState(false);
    let [submitErrorMessage, setSubmitErrorMessage] = useState('');

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(OCCUPANTS_FORM).dispatchEvent(
                new Event('submit', {
                    cancelable: true,
                    bubbles: true
                })
            );
        }
    }));

    useEffect(() => {
        setIsRemovingOccupantFail(false);
        const occupants = getPermanentOccupants(reduxTenants);
        setOccupants(occupants);
    }, [reduxTenants]);

    const getPermanentOccupants = tenants => {
        const tempOccupants = [];
        tenants.map(tenant => {
            if (!tenant.isRemovedFromOccupant) {
                tempOccupants.push({
                    name: tenant.isCorporation ? tenant.corporationName : `${tenant.firstName} ${tenant.secondName}`,
                    id: tenant.id
                });
            }
        });
        if (occupants.maxOccupants) {
            occupants.occupantsList.forEach(element => {
                if (!element.id) {
                    tempOccupants.push(element);
                }
            });
        }
        return {
            maxOccupants: tempOccupants.length,
            occupantsList: tempOccupants
        };
    };

    const changeMaxOccupantsValue = value => {
        let clonedOccupants = cloneDeep(occupants);
        clonedOccupants.maxOccupants = value;
        const occupantsCount = clonedOccupants.occupantsList.length;

        if (value < occupantsCount) {
            clonedOccupants.occupantsList.splice(value, occupantsCount);
        } else {
            const diff = value - occupantsCount;
            clonedOccupants.occupantsList = [
                ...clonedOccupants.occupantsList,
                ...Array(diff).fill({ name: '', id: null })
            ];
        }

        if (value) {
            setOccupants(clonedOccupants);
        }
    };

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };
    const setMaxOccupants = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const removeOccupantRequest = tenantId => {
        return axios.post(`api/agency/lease/${reduxLeaseInfo.id}/remove-occupant`, { tenantId });
    };

    const removeOccupant = (fields, form, maxOccupants, index, values) => {
        setIsRemovingOccupantFail(false);
        if (fields.value[index].id) {
            removeOccupantRequest(fields.value[index].id)
                .then(result => {
                    setOccupants(values);
                    dispatch(removeOccupantsSuccess(result));
                })
                .catch(() => {
                    setIsRemovingOccupantFail(true);
                });
        } else {
            fields.remove(index);
            form.change('maxOccupants', --maxOccupants);
        }
    };

    const updateOccupants = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/occupants`, data);
    };

    const submitForm = values => {
        setSubmitErrorMessage('');
        /**
         * Store the ref of the component so it can be used in this function
         * We need this otherwise the promise below doesn't have access to the ref
         */
        const currentRef = ref.current;
        /**
         * Grab the bypassFormValidation that was set from the parent component: LeaseAgreementForm.js
         */
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updateOccupants(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_OCCUPANTS_SUCCESS)).then(() => {
                    /**
                     * Callback after submit this form so that the parent component can take an action
                     */
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.maxOccupants.occupantsList')) {
                    setSubmitErrorMessage(error.response.data.errors.maxOccupants.occupantsList);
                }
            });
    };

    const maxOccupantsOptions = useMemo(() => {
        const occupantCount = isLeaseTypeResidentialTenancy(reduxLeaseInfo.leaseType) && isLeaseLocationNSW(location) 
            ? NSW_RTA_OCCUPANT_COUNT 
            : DEFAULT_OCCUPANT_COUNT;
        const toWords = new ToWords({ localeCode: 'en-US' });
        return Array.from({length: occupantCount}, (_, i) => {
            return { 
                value: i + 1, label: toWords.convert(i + 1), disabled: false 
            }
        })
    }, [reduxLeaseInfo.leaseType, location])

    return (
        <div className="occupants">
            <Form
                onSubmit={submitForm}
                initialValues={occupants}
                mutators={{
                    ...arrayMutators
                }}
            >
                {({
                    handleSubmit,
                    form,
                    values,
                    form: {
                        mutators: { push }
                    }
                }) => {
                    return (
                        <form id={OCCUPANTS_FORM} onSubmit={handleSubmit} noValidate>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <SelectField
                                label={getResTenLabel('maxNumberOfOccupants', location)}
                                name="maxOccupants"
                                options={maxOccupantsOptions}
                                onChange={selectedValueObject => changeMaxOccupantsValue(selectedValueObject.value)}
                                selectClassName="sel-4"
                            />
                            <p className="title">List all {getResTenLabel('occupants', location)}:</p>
                            {isRemovingOccupantFail && (
                                <p className="FormInput">
                                    <span className="FormError"> Remove occupant has not finished, try again</span>
                                </p>
                            )}
                            <FieldArray name="occupantsList" initialValue={occupants.occupantsList}>
                                {({ fields }) => (
                                    <React.Fragment>
                                        {fields.map((name, index) => {
                                            return (
                                                <div className="nowrap fields-full-width-for-mobile" key={index}>
                                                    <FormTextRegular
                                                        value={fields.value[index]}
                                                        name={`occupantsList[${index}].name`}
                                                        label={`${getResTenLabel('occupant', location)} #${index + 1}`}
                                                        title={fields.value[index].id && `Please update name in Tenant`}
                                                        disabled={fields.value[index].id}
                                                    />
                                                    <div
                                                        className={`occupants-${
                                                            values.maxOccupants === 1 && fields.value[0].id
                                                                ? 'edit'
                                                                : 'remove'
                                                        } toggle`}
                                                        onClick={() =>
                                                            removeOccupant(
                                                                fields,
                                                                form,
                                                                values.maxOccupants,
                                                                index,
                                                                values
                                                            )
                                                        }
                                                    >
                                                        <span className="span-remove">
                                                            {values.maxOccupants === 1 && fields.value[0].id
                                                                ? 'Edit'
                                                                : 'Remove'}
                                                        </span>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </React.Fragment>
                                )}
                            </FieldArray>
                            {submitErrorMessage && (
                                <p className="FormInput">
                                    <span className="FormError"> {submitErrorMessage}</span>
                                </p>
                            )}
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(Occupants);
