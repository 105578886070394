import React, { forwardRef, useState, useImperativeHandle, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { has } from 'lodash';
import { Form, FormSpy } from 'react-final-form';

import { updateSubmitTypeSuccess, setDirtyStep, formSubmitFail } from '../../../actions/lease';
import * as Lease from '../../../reducers/lease';

import { getLocation, getSecurityDeposit, getStep, getDirtyStep, getLeaseType } from '../../../selectors/lease';
import { FormTextRegular } from '../../../components/form/FormText';

const SECURITY_DEPOSIT_FORM = 'securityDepositForm';
const initState = {
    securityDeposit: '',
    bankGuarantee: ''
};

function SecurityDeposit(props, ref) {
    const dispatch = useDispatch();
    const reduxSecurityDeposit = useSelector(getSecurityDeposit);
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [securityDeposit, setSecurityDeposit] = useState(initState);

    useEffect(() => {
        setSecurityDeposit({
            ...initState,
            ...reduxSecurityDeposit
        });
    }, []);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(SECURITY_DEPOSIT_FORM).dispatchEvent(
                new Event('submit', {
                    cancelable: true,
                    bubbles: true
                })
            );
        }
    }));

    const updateSecurityDeposit = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/security-deposit`, data);
    };

    const submitForm = values => {
        /**
         * Store the ref of the component so it can be used in this function
         * We need this otherwise the promise below doesn't have access to the ref
         */
        const currentRef = ref.current;

        /**
         * Grab the bypassFormValidation that was set from the parent component: LeaseAgreementForm.js
         */
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updateSecurityDeposit(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    /**
                     * Callback after submit this form so that the parent component can take an action
                     */
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.securityDeposit')) {
                    return error.response.data.errors.securityDeposit;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    return (
        <div className="securityDeposit">
            <Form onSubmit={submitForm} initialValues={securityDeposit}>
                {({ handleSubmit, values, form }) => {
                    return (
                        <form onSubmit={handleSubmit} id={SECURITY_DEPOSIT_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <FormTextRegular
                                name="securityDeposit"
                                label="The amount of the security deposit:"
                                required
                            />
                            <FormTextRegular
                                name="bankGuarantee"
                                label="The amount of the bank guarantee is:"
                                required
                            />
                        </form>
                    );
                }}
            </Form>
        </div>
    );
}

export default memo(forwardRef(SecurityDeposit));
