import React, { memo, useState, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { cloneDeep, has } from 'lodash';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';

import * as Lease from '../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../selectors/lease';
import { getDisclosureOfRebates } from '../../../selectors/lease/pmLease';

import { QLD_STATE } from '../../../config';
import { isLeaseTypePm, isLeaseTypeSales } from '../../../utils/agreementUtils';
import { RebateGroup } from '../../../components/lease/mainScreen/common/sales/RebateGroup';

import '../../../sass/conditions.scss';
import { ReactComponent as plusIcon } from '../../../../assets/images/icons/plus.svg';
import { getLabel } from '../../../utils/labelUtils';

const QLD_MAX_REBATES_LIMIT = 6;
const PM_DISCLOSURE_REBATES_FORM = 'disclosureOfRebatesForm';
const initState = {
    rebateList: []
};

const DisclosureRebate = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxDisclosureOfRebates = useSelector(getDisclosureOfRebates);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [disclosureOfRebates, setDisclosureOfRebates] = useState(reduxDisclosureOfRebates || initState);
    const [error, setError] = useState(false);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(PM_DISCLOSURE_REBATES_FORM).dispatchEvent(
                new Event('submit', {
                    cancelable: true,
                    bubbles: true
                })
            );
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updateDisclosureOfRebates(values)
            .then(result => {
                if ((isLeaseTypePm(leaseType) || isLeaseTypeSales(leaseType)) && location === QLD_STATE) {
                    result.isLeaseEditingFinished = false;
                } else {
                    result.isLeaseEditingFinished = !currentRef.bypassFormValidation;
                }
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.disclosureOfRebates')) {
                    return error.response.data.errors.disclosureOfRebates;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const updateDisclosureOfRebates = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/rebate-requests`, data);
    };

    const removeRebate = (values, index) => {
        let newDisclosureRebates = cloneDeep(values);

        newDisclosureRebates.rebateList.splice(index, 1);

        setDisclosureOfRebates(newDisclosureRebates);
    };

    return (
        <div className="rebates-block">
            <h3>{getLabel('disclosureTitle', location, leaseType)}</h3>
            <div className="conditions full-width">
                <Form
                    onSubmit={submitForm}
                    initialValues={disclosureOfRebates}
                    mutators={{
                        ...arrayMutators
                    }}
                >
                    {({
                        handleSubmit,
                        values,
                        form,
                        form: {
                            mutators: { push }
                        }
                    }) => {
                        return (
                            <form onSubmit={handleSubmit} noValidate id={PM_DISCLOSURE_REBATES_FORM}>
                                <FormSpy
                                    subscription={{ values: true }}
                                    onChange={state => handleFormDirtyChange(state.values, form)}
                                />
                                {!values.onePageVersion && (
                                    <div>
                                        <FieldArray name="rebateList" initialValue={disclosureOfRebates.rebateList}>
                                            {({ fields }) => (
                                                <React.Fragment>
                                                    {fields.map((name, index) => {
                                                        return (
                                                            <RebateGroup
                                                                index={index}
                                                                item={fields.value[index]}
                                                                key={`${index}`}
                                                                removeRebate={index => removeRebate(values, index)}
                                                                maxLength={location === QLD_STATE ? '60' : undefined}
                                                                location={location}
                                                                leaseType={leaseType}
                                                            />
                                                        );
                                                    })}
                                                </React.Fragment>
                                            )}
                                        </FieldArray>
                                        {/* in PM QLD disclosure Of Rebates is limit to maximum of 6 because of fixed PDF layout */}
                                        {!(
                                            isLeaseTypePm(leaseType) &&
                                            location === QLD_STATE &&
                                            disclosureOfRebates.rebateList.length >= QLD_MAX_REBATES_LIMIT
                                        ) && (
                                            <div className="button">
                                                <button
                                                    className="mobile add-item"
                                                    type="button"
                                                    onClick={() =>
                                                        push('rebateList', {
                                                            source: '',
                                                            amount: '$'
                                                        })
                                                    }
                                                >
                                                    <img src={plusIcon} className="for-sm-modal" />
                                                    Add rebate
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                )}
                                {error && <div className="clause-list-error">{error}</div>}
                            </form>
                        );
                    }}
                </Form>
            </div>
        </div>
    );
});

export default memo(DisclosureRebate);
