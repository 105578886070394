import React, { memo, useEffect, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { FormApi } from 'final-form';
import { confirmAlert } from 'react-confirm-alert';
import { isEmpty, uniq, remove, cloneDeep } from 'lodash';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { openNoSignatureModal } from '../../../../actions/user.js';

import { closeEntryNoticeForm, addOrReplaceDocumentInList } from '../../../../actions/document.js';
import {
    HIDE_MESSAGE_TIME,
    DELIVERY_TO_AGENT_AND_TENANT,
    DELIVERY_TYPE_EMAIL,
    FORM_SUBMIT_TYPE_SEND,
    CLOSE_MODAL_MESSAGE,
    ENTRY_NOTICE
} from '../../../../config';

import TenantPersonGroup from '../../../../components/document/TenantPersonGroup.js';
import EntryPartyGroup from '../../../../components/document/EntryPartyGroup.js';
import { FormTextMultiline, FormTextRegular } from '../../../../components/form/FormText.js';
import { FormRadioGroup } from '../../../../components/form/FormRadioGroup.js';
import { SelectField } from '../../../../components/form/FormSelect.js';
import DatePickerInAgencyTimeZone from '../../../../components/form/DatePickerInAgencyTimeZone.js';
import ModalDialog from '../../../../common/components/ModalDialog.js';
import * as validators from '../../../../components/Validate';
import EditSenderConfirmation from '../../../../components/modals/document/EditSenderConfirmation';

import { getAgentSignature } from '../../../../selectors/completion/index.js';
import { getUserInfo } from '../../../../selectors/user.js';

import '../../../../sass/documentModalDialog.scss';
import { getCurrentDocument, getDocumentEditMode } from '../../../../selectors/document/index.js';
import { parseISO } from 'date-fns';
import { isDocumentEditMode, isDocumentReadOnlyMode } from '../../../../utils/generalUtils.js';
import { getAgencyTimezoneDate, formatDateStandard, getAgencyTimezoneFromUser } from '../../../../utils/dateUtils.js';
import LocationAndAddressSelection from '../../LocationAndAddressSelection.js';
import DocumentHeader from '../../DocumentHeader.js';
import { getIsOpenEntryNoticeModal } from '../../../../selectors/document/entryNotice';
import EntryNoticeWithoutLeaseFooter from './EntryNoticeWithoutLeaseFooter';
import DeliveryTypeComponent from '../../shared/DeliveryTypeComponent.js';
import { CloseSharp } from '@flk-mui-icons';
import ContactSearch from '../../../../components/search/ContactSearch.js';
import EntryReasons from './EntryReasons';
import { useMutation } from '@tanstack/react-query';
import { DEFINE_ME } from '../../../../types/utilityTypes.js';
import * as dashboard from '../../../../actions/dashboard.js';
import { scrollToError } from '../../../../utils/finalFormUtils';
import Banner from '../../../../common/components/Banner.js';
import { isLeaseLocationNSW, isLeaseLocationQLD } from '../../../../utils/agreementUtils.js';
import { WarningMessage } from './WarningMessage';
import { useLeaseTypeEnabled } from '@app/hooks/useLeaseTypeEnabled';
import { CheckboxCheck } from '@app/components/form/FormCheckboxCheck';
import { User } from '@app/types/User';
import { EntryNotice } from '@app/types/EntryNotice';

const ENTRY_NOTICE_API = 'api/document/entry-notice';

const calculateEndTime = (startTime: string | null | undefined) => {
    if (!startTime || typeof startTime !== 'string') {
        return '';
    }

    const [hours, minutes] = startTime.split(':').map(Number);
    if (isNaN(hours) || isNaN(minutes)) {
        return '';
    }

    const newHours = (hours + 2) % 24;
    return `${String(newHours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};

const generateTimeOfEntryOptions = (from = 0, to = 24, interval = 15) => {
    const options = [];
    for (let hour = from; hour < to; hour++) {
        for (let minute = 0; minute < 60; minute += interval) {
            const label = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
            options.push({ label, value: label });
        }
    }
    return options;
};

const timeOfEntryOptions = generateTimeOfEntryOptions(6, 22, 15);

const EntryNoticeWithoutLeaseForm = () => {
    const { allowedStates } = useLeaseTypeEnabled();
    const entryNoticeAllowedStates = allowedStates(ENTRY_NOTICE);

    const dispatch = useDispatch();
    const formValuesRef = React.useRef(null);
    const isOpenEntryNoticeModal = useSelector(getIsOpenEntryNoticeModal);
    const loggedInUser: User = useSelector(getUserInfo);
    const agentSignature = useSelector(getAgentSignature);
    const currentDocument: EntryNotice = useSelector(getCurrentDocument);
    const documentEditMode = useSelector(getDocumentEditMode);

    const [currentDocumentId, setCurrentDocumentId] = useState<string | null>(
        currentDocument ? currentDocument.id : null
    );
    const [location, setLocation] = useState<string | null>(null);
    const [locationAndAddressSelected, setLocationAndAddressSelected] = useState<boolean>(false);
    const [addEmailFieldError, setAddEmailFieldError] = useState<string>('');
    const [formData, setFormData] = useState<EntryNotice | object>({});
    const [integrationPropertyId, setIntegrationPropertyId] = useState<string | null>(null);
    const [integration, setIntegration] = useState<DEFINE_ME>(null);
    const [addressError, setAddressError] = useState<string>('');
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [isSaveDraft, setIsSaveDraft] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [showEditSenderConfirmationModel, setShowEditSenderConfirmationModel] = useState(false);
    const [agent, setAgent] = useState({
        value: currentDocument && currentDocument.agent ? currentDocument.agent.id : '',
        label: currentDocument && currentDocument.agent ? currentDocument.agent.fullName : ''
    });

    const handleFormDirtyChange = (form: FormApi) => {
        if (form.getState().dirty && !isDirty) {
            setIsDirty(true);
        }
    };

    useEffect(() => {
        if (currentDocument && isOpenEntryNoticeModal) {
            if (currentDocument?.id) {
                // During cloning, there's a currentDocument but there's no id so we don't need to push new in history
                window.history.pushState({}, '', `/dashboard/document/${currentDocument.id}`);
            }
        }
        if (currentDocument && currentDocument.integrationPropertyId && currentDocument.address) {
            setIntegrationPropertyId(currentDocument.integrationPropertyId);
            setIntegration({ address: currentDocument.address });
        } else {
            setIntegrationPropertyId(null);
            setIntegration(null);
        }
        if (currentDocument) {
            setLocation(currentDocument.location);
            setLocationAndAddressSelected(true);
            setCurrentDocumentId(currentDocument.id);
        }
        setFormData(
            currentDocument
                ? {
                      ...currentDocument,
                      dateOfEntry: currentDocument.dateOfEntry
                          ? parseISO(currentDocument.dateOfEntry)
                          : parseISO(new Date().toISOString()),
                      dateOfNotice:
                          currentDocument.dateOfNotice && currentDocument.status !== dashboard.LEASE_STATUS_DRAFT
                              ? parseISO(currentDocument.dateOfNotice)
                              : parseISO(new Date().toISOString()),
                      tenant: currentDocument.tenant ? currentDocument.tenant : { tenants: [{ firstName: '' }] },
                      entryParty: currentDocument.entryParty
                          ? currentDocument.entryParty
                          : { parties: [{ name: '', email: '', phone: '' }] },
                      agentName: currentDocument.agent.fullName,

                      // The FormRadioGroup component requires a string value for the selected option
                      // so we need to convert the boolean value to a string.
                      // As the value is coming from the database, it is a boolean value.
                      isTwoHourPeriod: currentDocument.isTwoHourPeriod ? 'true' : 'false',
                      endOfTwoHourPeriod: currentDocument.endOfTwoHourPeriod || '',
                      disabled: currentDocument.completion && isDocumentReadOnlyMode(documentEditMode)
                  }
                : getResetFormData()
        );

        if (!currentDocument) {
            setLocationAndAddressSelected(false);
        }
    }, [isOpenEntryNoticeModal, currentDocument]);

    const closeModal = () => {
        setFormData(getResetFormData());
        setIsDirty(false);
        dispatch(closeEntryNoticeForm());
        setCurrentDocumentId(null);
        if (currentDocument) {
            window.history.pushState(
                {},
                '',
                `/dashboard/documents/${currentDocument.docType}/${currentDocument.status}`
            );
        }
    };

    const handleCloseModal = () => {
        if (isDirty) {
            confirmAlert({
                title: '',
                message: CLOSE_MODAL_MESSAGE,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            const values = formValuesRef.current;
                            saveDraft(values);
                            closeModal();
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {
                            closeModal();
                        }
                    },
                    {
                        className: 'close close-modal',
                        // @ts-ignore
                        label: <CloseSharp>Close</CloseSharp>,
                        onClick: () => {}
                    }
                ]
            });
        } else {
            closeModal();
        }
    };

    const getResetFormData = () => {
        setLocation(loggedInUser?.agency?.details?.location || null);
        return {
            location: location,
            agentName: loggedInUser.fullName,
            address: '',
            addressDetails: '',
            isLeaseAddress: true,
            otherAddress: '',
            tenant: {
                tenants: [
                    {
                        firstName: '',
                        middleName: '',
                        secondName: '',
                        email: ''
                    }
                ]
            },
            entryParty: {
                parties: [
                    {
                        name: '',
                        email: '',
                        phone: ''
                    }
                ]
            },
            emailAddresses: [],
            dateOfNotice: parseISO(new Date().toISOString()),
            isTwoHourPeriod: 'false',
            endOfTwoHourPeriod: ''
        };
    };

    const prepareValuesForSave = (values: EntryNotice) => {
        const data = cloneDeep(values);
        if (data.deliveryType !== DELIVERY_TYPE_EMAIL || data.emailTo !== DELIVERY_TO_AGENT_AND_TENANT) {
            data.emailAddresses = [loggedInUser.email];
            data.ccEmailAddresses = [];
        }
        data.integrationPropertyId = integrationPropertyId;
        return data;
    };

    const previewPdf = (values: DEFINE_ME) => {
        setIsSaveDraft(true);
        saveDraft(values, true);
    };

    const viewPdf = (docId: string) => {
        window.open(`/reader/document/entry-notice/${docId}`, '_blank');
    };

    const handleSubmit = (values: DEFINE_ME) => {
        if (values.submitType === FORM_SUBMIT_TYPE_SEND) {
            if (!values.ignoreAgentValidation && loggedInUser.id !== agent.value) {
                console.log('LoggedInUserId:', loggedInUser.id, 'Agent:', agent.value, 'CurrentDocument:', currentDocument)
                setShowEditSenderConfirmationModel(true);
                return;
            }
            setIsSaveDraft(false);
            return sendDocument(values);
        } else {
            setIsSaveDraft(true);
            return saveDraft(values);
        }
    };
    const saveDraftMutation = useMutation(
        data => {
            return axios.post(`${ENTRY_NOTICE_API}${currentDocumentId ? `/${currentDocumentId}/save` : ''}`, data);
        },
        {
            onSuccess: (response, data) => {
                setAgent(response.data?.entryNotice?.agent);
                setCurrentDocumentId(response.data.entryNotice.id);
                setIsDirty(false);
                dispatch(addOrReplaceDocumentInList(response.data.entryNotice));
                setLocationAndAddressSelected(true);
                if (data.previewAfterSave) {
                    viewPdf(response.data.entryNotice.id);
                }
            }
        }
    );

    const saveDraft = (values: DEFINE_ME, previewAfterSave = false) => {
        const data = prepareValuesForSave(values);
        data.previewAfterSave = previewAfterSave;
        saveDraftMutation.reset();
        sendMutation.reset();
        saveDraftMutation.mutate(data);
    };

    const sendMutation = useMutation(
        (data: DEFINE_ME) => {
            return axios.post(`${ENTRY_NOTICE_API}${currentDocumentId ? `/${currentDocumentId}/send` : '/send'}`, data);
        },
        {
            onMutate: () => {
                setIsLoading(true);
            },
            onSuccess: () => {
                setIsDirty(false);
                setTimeout(() => {
                    closeModal();
                    setIsLoading(false); // Stop loading after the modal is closed
                }, HIDE_MESSAGE_TIME);
            },
            onError: () => {
                setIsLoading(false); // Stop loading on error
            }
        }
    );

    const sendDocument = async (values: DEFINE_ME) => {
        const data: DEFINE_ME = prepareValuesForSave(values);
        if (validateEmailList(data)) {
            if (!isEmpty(agentSignature)) {
                try {
                    saveDraftMutation.reset();
                    sendMutation.reset();
                    await sendMutation.mutateAsync(data);
                } catch (error) {
                    setTimeout(() => {
                        scrollToError();
                    }, 1000);
                    return error.response.data.errors;
                }
            } else {
                closeModal();
                dispatch(openNoSignatureModal());
            }
        }
    };

    // Change tenant details functions
    const addTenantPerson = (values: DEFINE_ME) => {
        const data = cloneDeep(values);
        const newTenant = { firstName: '', middleName: '', secondName: '', email: '' };
        if (data.tenant?.tenants) {
            data.tenant.tenants.push(newTenant);
        } else {
            data.tenant = {
                tenants: [newTenant]
            };
        }
        setFormData(data);
    };
    const removeTenantPerson = (index: number, values: DEFINE_ME, disabled: boolean) => {
        if (!disabled) {
            const data = cloneDeep(values);
            const emailAddresses = remove(data.emailAddresses, function removeEmail(n) {
                return n !== data.tenant.tenants[index].email;
            });
            data.tenant.tenants = [...data.tenant.tenants.slice(0, index), ...data.tenant.tenants.slice(index + 1)];
            data.emailAddresses = emailAddresses;
            setFormData(data);
        }
    };
    const onChangeEmail = (value: DEFINE_ME, index: number, values: DEFINE_ME, setValue: DEFINE_ME) => {
        // change email list only email to is agentAndTenant
        if (values.emailTo === DELIVERY_TO_AGENT_AND_TENANT) {
            // set changed value to form values
            values.tenant.tenants[index].email = value.target.value;
            emailAgentAndTenantHandler(values, setValue);
        }
    };

    // Change party details functions
    const addEntryParty = (values: DEFINE_ME) => {
        const data = cloneDeep(values);
        const newParty = { name: '', phone: '', type: '', email: '' };
        if (data.entryParty?.parties) {
            data.entryParty.parties.push(newParty);
        } else {
            data.entryParty = {
                parties: [newParty]
            };
        }
        setFormData(data);
    };
    const removeEntryParty = (index: number, values: DEFINE_ME, disabled: boolean) => {
        if (!disabled) {
            const data = cloneDeep(values);
            data.entryParty.parties = [
                ...data.entryParty.parties.slice(0, index),
                ...data.entryParty.parties.slice(index + 1)
            ];
            setFormData(data);
        }
    };

    // Delivery email change functions
    const emailAgentAndTenantHandler = (values: DEFINE_ME, setValue: DEFINE_ME) => {
        values.emailTo = DELIVERY_TO_AGENT_AND_TENANT;
        const emailAddresses = [];
        values.tenant.tenants.forEach(tenant => {
            if (tenant.email && validateEmail(tenant.email)) {
                emailAddresses.push(tenant.email);
            }
        });
        values.emailAddresses = uniq(emailAddresses);
        values.ccEmailAddresses = [loggedInUser.email];
        setValue('emailAddresses', values.emailAddresses);
        setValue('ccEmailAddresses', values.ccEmailAddresses);
        setFormData(values);
    };

    const validateEmail = (value: DEFINE_ME) => {
        if (isEmpty(value)) {
            return false;
        }

        if (!validators.isCorrectEmail(value)) {
            setAddEmailFieldError('Wrong email format');
            return false;
        }
        setAddEmailFieldError(undefined);
        return true;
    };
    const validateEmailList = (values: DEFINE_ME) => {
        if (values.deliveryType === DELIVERY_TYPE_EMAIL) {
            if (values.emailAddresses.length === 0) {
                setAddEmailFieldError('Please enter at least one email address');
                return false;
            }
        }
        setAddEmailFieldError('');
        return true;
    };

    // Address and integration functions
    const handleAddressChange = (setValue: DEFINE_ME, address: string) => {
        setValue('address', address);
    };
    const setUpdateAddressDetails = (address, details, values) => {
        if (!address) {
            setAddressError('Address is required');
        } else {
            setAddressError('');
        }
        const data = cloneDeep(values);
        data.address = address;
        data.location = location;
        data.addressDetails = details;
        setFormData(data);
        setIntegrationPropertyId(null);
        setIntegration(null);
    };
    const confirmDetails = () => {
        const values = formValuesRef.current;
        saveDraft(values);
    };
    const setAddressDetails = (address: string, details: DEFINE_ME, values: DEFINE_ME) => {
        const data = cloneDeep(values);
        data.address = address;
        data.location = location;
        data.addressDetails = details;
        setIntegrationPropertyId(null);
        setIntegration(null);
        setFormData(data);
    };
    const setIntegrationProperty = (propertyDetails: DEFINE_ME) => {
        setAgent({
            value: loggedInUser.id,
            label: loggedInUser.fullName
        });
        if (propertyDetails) {
            setFormData({
                location: location,
                address: propertyDetails.address,
                agentName: loggedInUser.fullName,
                isLeaseAddress: true,
                tenant: { tenants: propertyDetails.tenants },
                entryParty: { parties: [{ name: '', email: '', phone: '' }] },
                dateOfNotice: parseISO(new Date().toISOString())
            });
            setIntegrationPropertyId(propertyDetails.id);
            setIntegration(propertyDetails);
        } else {
            setFormData(getResetFormData());
        }
    };

    const isLastPartyDataFilled = (data: DEFINE_ME, lastClientIndex: number) => {
        return (
            !data.entryParty?.parties[lastClientIndex].name &&
            !data.entryParty?.parties[lastClientIndex].email &&
            !data.entryParty?.parties[lastClientIndex].phone
        );
    };

    function handleSearchSelect(result: DEFINE_ME, values: DEFINE_ME) {
        const data = cloneDeep(values);
        const lastClientIndex = data.entryParty?.parties.length - 1;

        // If last client's data is not filled, then add new data to that client
        // Otherwise create a new client
        if (isLastPartyDataFilled(data, lastClientIndex)) {
            data.entryParty.parties[lastClientIndex] = {
                name: result.fullName || result.corporationName,
                email: result?.email,
                phone: result.phone || result.mobile
            };
        } else {
            data.entryParty?.parties.push({
                name: result.fullName || result.corporationName,
                email: result?.email,
                phone: result.phone || result.mobile
            });
        }
        setFormData({
            ...data
        });
        setIsDirty(true);
    }

    return (
        <React.Fragment>
            <ModalDialog
                title={locationAndAddressSelected ? 'Entry Notice' : 'Create new Entry Notice for'}
                isOpen={isOpenEntryNoticeModal}
                closeModal={handleCloseModal}
                shouldCloseOnOverlayClick={false}
                className={`document-centre-modal document-modal-dialog tenant ${
                    locationAndAddressSelected && location ? 'with-header' : 'without-header'
                }`}
                hideCloseButton={sendMutation.isLoading || saveDraftMutation.isLoading}
                // Quick fix for search results being cut off by modal
                allowOverflow={!locationAndAddressSelected}
            >
                <Form initialValues={formData} onSubmit={handleSubmit}>
                    {({ handleSubmit, values, form }) => {
                        // set the values to the ref so that the close modal can access these values
                        formValuesRef.current = values;
                        return (
                            <form onSubmit={handleSubmit} noValidate id="create-entry-form">
                                <FormSpy subscription={{ values: true }} onChange={() => handleFormDirtyChange(form)} />
                                <FormSpy
                                    onChange={({ values }) => {
                                        // Set the date of notice to the current date if deliveryType is 'email'
                                        if (values.deliveryType === 'email') {
                                            const timezone = getAgencyTimezoneFromUser(loggedInUser);
                                            const dateOfNotice = formatDateStandard(new Date(), timezone);
                                            form.change('dateOfNotice', dateOfNotice);
                                        }
                                    }}
                                />
                                <FormSpy
                                    subscription={{ values: true }}
                                    onChange={({ values }) => {
                                        // Calculate end time when time of entry is changed and two hour period is selected
                                        if (values.isTwoHourPeriod === 'true') {
                                            const endTime = calculateEndTime(values.timeOfEntry);
                                            form.change('endOfTwoHourPeriod', endTime);
                                        } else {
                                            // Clean the endOfTwoHourPeriod when time of entry is changed to time of entry
                                            form.change('endOfTwoHourPeriod', '');
                                        }
                                    }}
                                />
                                {locationAndAddressSelected && location && (
                                    <div className="entry-notice">
                                        <DocumentHeader
                                            parentSelector="create-entry-form"
                                            confirmDetails={confirmDetails}
                                            address={values.address}
                                            addressError={addressError}
                                            handleAddressChange={address => handleAddressChange(form.change, address)}
                                            setAddressDetails={(address, details) =>
                                                setUpdateAddressDetails(address, details, values)
                                            }
                                            disabled={isDocumentReadOnlyMode(documentEditMode) || integrationPropertyId}
                                            agentName={values.agentName}
                                            integration={integration}
                                            viewOnlyIntegration={true}
                                            doc={currentDocument}
                                            hideMoreOptions={currentDocument && !currentDocument.completion}
                                            setAssignedAgent={setAgent}
                                        />
                                        <div>
                                            <h4>Tenant</h4>
                                            {isLeaseLocationQLD(location) && (
                                                <div>
                                                    <CheckboxCheck
                                                        value={true}
                                                        name="isLeaseAddress"
                                                        label="Tenants nominated address is the address of premises"
                                                    />
                                                    {!values?.isLeaseAddress && (
                                                        <FormTextRegular name="otherAddress" label="Other address" />
                                                    )}
                                                </div>
                                            )}
                                            <div className="tenants">
                                                {formData?.tenant?.tenants?.map((tenant, index) => (
                                                    <TenantPersonGroup
                                                        key={index}
                                                        index={index}
                                                        removeTenant={index =>
                                                            removeTenantPerson(index, values, values.disabled)
                                                        }
                                                        showHeader={index === 0}
                                                        onChangeEmail={(value, index) =>
                                                            onChangeEmail(value, index, values, form.change)
                                                        }
                                                        disabled={values.disabled}
                                                    />
                                                ))}
                                                {isDocumentEditMode(documentEditMode) && (
                                                    <div className="button">
                                                        <button
                                                            className="add-tenant"
                                                            type="button"
                                                            onClick={() => addTenantPerson(values)}
                                                        >
                                                            Add tenant
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                            <h4>Party entering the Premises</h4>
                                            <ContactSearch
                                                searchBarClassName="contact-search"
                                                onSelect={result => handleSearchSelect(result, values)}
                                                contactType={ENTRY_NOTICE}
                                                allowStaffSearch={true}
                                                disabled={values.disabled}
                                            />
                                            <div className="tenants">
                                                {formData?.entryParty?.parties?.map((_p, index) => (
                                                    <EntryPartyGroup
                                                        key={index}
                                                        index={index}
                                                        removeEntryParty={index =>
                                                            removeEntryParty(index, values, values.disabled)
                                                        }
                                                        showHeader={index === 0}
                                                        disabled={values.disabled}
                                                        values={values}
                                                        required
                                                    />
                                                ))}
                                                {isDocumentEditMode(documentEditMode) && (
                                                    <div className="button">
                                                        <button
                                                            className="add-party"
                                                            type="button"
                                                            onClick={() => addEntryParty(values)}
                                                        >
                                                            Add party
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                            <h4>Details of the entry</h4>

                                            {isLeaseLocationNSW(location) && (
                                                <div className="entryDetails">
                                                    <Banner
                                                        title="Note"
                                                        message="As per the NSW RTA, entry may be at any time between 8am and 8pm on any day except the weekend, unless the Tenant otherwise agrees."
                                                        hideCloseButton={true}
                                                        messageTextClassName="entryDetailsNoteMessage"
                                                    />
                                                </div>
                                            )}
                                            <EntryReasons
                                                values={values}
                                                disabled={values.disabled}
                                                location={location}
                                            />

                                            {isLeaseLocationQLD(location) && (
                                                <div className="entryDetails">
                                                    <Banner
                                                        title="Note"
                                                        message="Entry on Sundays, public holidays or after 6pm, and before 8am, is only allowed if the tenant agrees."
                                                        hideCloseButton={true}
                                                        messageTextClassName="entryDetailsNoteMessage"
                                                    />
                                                </div>
                                            )}
                                            <div className="nowrap large">
                                                <div className="calender">
                                                    <DatePickerInAgencyTimeZone
                                                        label="Date of planned entry"
                                                        name="dateOfEntry"
                                                        selected={values.dateOfEntry}
                                                        disabled={values.disabled}
                                                        required
                                                        minValue={getAgencyTimezoneDate(new Date(), loggedInUser)}
                                                    />
                                                </div>
                                                {isLeaseLocationNSW(location) && (
                                                    <FormTextRegular
                                                        name="timeOfEntry"
                                                        label="Time of planned entry"
                                                        disabled={values.disabled}
                                                    />
                                                )}
                                            </div>

                                            <WarningMessage values={values} location={location} />

                                            {isLeaseLocationQLD(location) && (
                                                <div>
                                                    <FormRadioGroup
                                                        label="Time of planned entry will be:"
                                                        name="isTwoHourPeriod"
                                                        disabled={values.disabled}
                                                        data={[
                                                            {
                                                                label: 'Time of entry',
                                                                value: 'false',
                                                                disabled: values.disabled
                                                            },
                                                            {
                                                                label: 'Two hour period',
                                                                value: 'true',
                                                                disabled: values.disabled
                                                            }
                                                        ]}
                                                        value={values.isTwoHourPeriod}
                                                    />

                                                    <div className="formBox-column">
                                                        <div className="nowrap large">
                                                            <SelectField
                                                                label={`${
                                                                    values.isTwoHourPeriod === 'true' ? 'From' : ''
                                                                }`}
                                                                containerClassName="timeOfEntry"
                                                                name={`timeOfEntry`}
                                                                options={timeOfEntryOptions}
                                                                placeholder="Enter time"
                                                                required
                                                                isSearchable
                                                                disabled={values.disabled}
                                                            />
                                                            {values.isTwoHourPeriod === 'true' ? (
                                                                <FormTextRegular
                                                                    required={false}
                                                                    label="To"
                                                                    name="endOfTwoHourPeriod"
                                                                    disabled
                                                                    value={values.endOfTwoHourPeriod}
                                                                />
                                                            ) : (
                                                                <div /> // Empty div to keep the layout consistent
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {isLeaseLocationNSW(location) && (
                                                <FormTextMultiline
                                                    name="comments"
                                                    label="Comments/instructions"
                                                    disabled={values.disabled}
                                                    isChatGpt={true}
                                                    gpt_fieldName="comments"
                                                    gpt_isDocument={true}
                                                    gpt_documentId={currentDocumentId}
                                                />
                                            )}
                                            <DeliveryTypeComponent
                                                emailAgentAndTenantHandler={emailAgentAndTenantHandler}
                                                addEmailFieldError={addEmailFieldError}
                                                values={values}
                                                form={form}
                                            />
                                            <div className="calender">
                                                <DatePickerInAgencyTimeZone
                                                    label="Date of the notice"
                                                    name="dateOfNotice"
                                                    selected={values.dateOfNotice}
                                                    minValue={getAgencyTimezoneDate(new Date(), loggedInUser)}
                                                    disabled={values.disabled || values.deliveryType === 'email'}
                                                />
                                            </div>
                                            <br />
                                            <EntryNoticeWithoutLeaseFooter
                                                isSendingEntryNotice={isLoading || saveDraftMutation.isLoading}
                                                isSendingEntryNoticeFail={
                                                    sendMutation.isError || saveDraftMutation.isError
                                                }
                                                currentDocument={currentDocument}
                                                documentEditMode={documentEditMode}
                                                previewPdf={() => previewPdf(values)}
                                                viewPdf={viewPdf}
                                                form={form}
                                                isSaveDraft={isSaveDraft}
                                            />
                                        </div>
                                    </div>
                                )}
                                {!locationAndAddressSelected && (
                                    <LocationAndAddressSelection
                                        location={location}
                                        allowedStates={entryNoticeAllowedStates}
                                        setLocation={setLocation}
                                        closeModal={closeModal}
                                        confirmDetails={confirmDetails}
                                        address={values.address}
                                        handleAddressChange={address => handleAddressChange(form.change, address)}
                                        setAddressDetails={(address, details) =>
                                            setAddressDetails(address, details, values)
                                        }
                                        setIntegrationProperty={property => setIntegrationProperty(property)}
                                        isCreating={sendMutation.isLoading || saveDraftMutation.isLoading}
                                    />
                                )}
                                <EditSenderConfirmation
                                    isOpen={showEditSenderConfirmationModel}
                                    close={() => setShowEditSenderConfirmationModel(false)}
                                    primaryHandler={() => {
                                        setIsSaveDraft(false);
                                        setShowEditSenderConfirmationModel(false);
                                        form.change('ignoreAgentValidation', true);
                                        form.submit();
                                        // sendDocument(formValuesRef.current);
                                    }}
                                    secondaryHandler={() => setShowEditSenderConfirmationModel(false)}
                                    currentAgentName={agent.label}
                                    loggedUserInfo={{
                                        firstName: loggedInUser?.firstName,
                                        fullName: loggedInUser?.fullName
                                    }}
                                    isLoading={false}
                                    docType={ENTRY_NOTICE}
                                />
                            </form>
                        );
                    }}
                </Form>
            </ModalDialog>
        </React.Fragment>
    );
};
export default memo(EntryNoticeWithoutLeaseForm);
