import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import cx from 'classnames';
import React from 'react';

import Button from '@app/common/components/Button.js';
import Icon, { Icons } from '@app/common/components/Icon';
import AccordionItem from '@app/containers/dashboard/documents/buildADoc/components/AccordionItem';
import { Spinner, SpinnerSizes } from '@app/common/design-system/spinner';

import styles from './ReorderableAccordionItem.module.scss';

type ReorderableAccordionItemProps = {
    onDelete: () => void;
    isOpen: boolean;
    isDeletable: boolean;
    isDragging?: boolean;
    isReordering?: boolean;
    id: string;
    number: number;
    collapsedContent: React.ReactNode;
    expandedHeader?: React.ReactNode;
    children: React.ReactNode;
    isDeleteDisabled?: boolean;
    isDeleteLoading?: boolean;
};

const ReorderableAccordionItem = React.forwardRef<HTMLDivElement, ReorderableAccordionItemProps>(
    (
        {
            isDeletable,
            onDelete,
            isOpen,
            isDragging,
            isReordering,
            id,
            children,
            collapsedContent,
            expandedHeader,
            isDeleteDisabled,
            isDeleteLoading
        },
        ref
    ) => {
        const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
            id: id
        });

        return (
            <div
                className={styles.dragContainer}
                ref={setNodeRef}
                style={{ '--dragTransform': CSS.Translate.toString(transform), '--dragTransition': transition }}
            >
                {isReordering && (
                    <Button
                        type="button"
                        {...attributes}
                        {...listeners}
                        className={cx(styles.dragHandle, {
                            [styles.dragging]: isDragging
                        })}
                    >
                        <Icon icon={Icons.DRAG} />
                    </Button>
                )}
                <AccordionItem
                    ref={ref}
                    className={cx(styles.item, { [styles.dragging]: isDragging })}
                    isOpen={!isReordering && isOpen}
                    key={id}
                    value={id}
                    isExpandDisabled={isReordering}
                    collapsedContent={collapsedContent}
                    expandedHeader={expandedHeader}
                >
                    <div className={styles.body}>
                        {children}
                        {isDeletable && (
                            <Button
                                className={styles.deleteButton}
                                quaternary
                                type="button"
                                onClick={() => onDelete()}
                                startIcon={<Icon icon={Icons.TRASH} />}
                                disabled={isDeleteDisabled || isDeleteLoading}
                            >
                                Delete
                                {isDeleteLoading && <Spinner className={styles.spinner} size={SpinnerSizes.SMALL} />}
                            </Button>
                        )}
                    </div>
                </AccordionItem>
            </div>
        );
    }
);

export default ReorderableAccordionItem;
