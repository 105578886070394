import * as React from 'react';
import ModalDialog from '../../../common/components/ModalDialog';
import { useState } from 'react';
import ClearSharpIcon from '@flk-mui-icons/ClearSharp';
import '../../../sass/dashboard/ThumbDisplay.scss';

export function ThumbDisplay({ imageSrc, imageTitle, canRemove, removeImage, isUpdating }) {
    let [showLargeImage, setShowLargeImage] = useState(false);

    function showImage() {
        setShowLargeImage(true);
    }

    function hideImage() {
        setShowLargeImage(false);
    }

    return (
        <div>
            <div className={'imageContainerThumb'}>
                {canRemove && (
                    <button type="button" onClick={removeImage} className="remove-btn" disabled={isUpdating}>
                        <ClearSharpIcon className="cross-icon" />
                    </button>
                )}
                <img
                    title={imageTitle ? imageTitle : 'Image'}
                    src={imageSrc}
                    onClick={() => {
                        showImage();
                    }}
                    className={isUpdating ? 'updating' : ''}
                />
            </div>
            <ModalDialog
                title={imageTitle ? imageTitle : 'Image'}
                isOpen={showLargeImage}
                closeModal={hideImage}
                shouldCloseOnOverlayClick={false}
                className="modal-thumb-display"
            >
                <div className={'imageContainerLarge'}>
                    <img src={imageSrc} />
                </div>
            </ModalDialog>
        </div>
    );
}

export default ThumbDisplay;
