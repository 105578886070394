import React, { memo, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { cloneDeep, has } from 'lodash';

import * as Lease from '../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../selectors/lease';
import { getPriceAndFee, getAgreementTerm } from '../../../selectors/lease/salesLease';
import { isLeaseLocationNSW, isLeaseLocationQLD } from '../../../utils/agreementUtils';

import NswPriceAndFee from './NSW/PriceAndFee';
import QldPriceAndFee from './QLD/PriceAndFee';

import '../../../sass/pricefee.scss';
import { PRICE_TYPE_RESERVE, SERVICE_TYPE_AUCTION } from '../../../config';

const percentageOfValue = (val, percent) => {
    const amnt = (percent / 100) * val;
    return isNaN(amnt) ? '' : parseFloat(amnt).toFixed(2);
};

const PRICE_FEES_FORM = 'priceAndFeeForm';
const RANGE_PRICE_ERROR_MESSAGE = 'It is legally not allowed to have a difference in the range of larger than 10%';

const PriceAndFee = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxPriceAndFee = useSelector(getPriceAndFee);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);
    const agreementTerm = useSelector(getAgreementTerm);

    const [priceAndFee, setPriceAndFee] = useState(reduxPriceAndFee || {});
    const [opinionRangeFail, setOpinionRangeFail] = useState(false);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(PRICE_FEES_FORM).dispatchEvent(
                new Event('submit', {
                    cancelable: true,
                    bubbles: true
                })
            );
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        handleFormChange(values);
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updatePriceAndFee(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_PRICE_AND_FEE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.priceAndFee')) {
                    return error.response.data.errors.priceAndFee;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const updatePriceAndFee = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/price-and-fee`, data);
    };

    useEffect(() => {
        let data = cloneDeep(priceAndFee);
        data.auctionDate = data.auctionDate && data.auctionDate !== '' ? new Date(data.auctionDate) : null;
        if (isLeaseLocationQLD(location) && !data.priceType && agreementTerm.serviceType === SERVICE_TYPE_AUCTION) {
            data.priceType = PRICE_TYPE_RESERVE;
        }
        setPriceAndFee(data);
    }, []);

    const handleRangeChange = () => {
        setOpinionRangeFail('');
    };

    const handleFormChange = values => {
        const {
            opinionPriceFrom,
            opinionPriceTo,
            commissionPercent,
            isRange,
            exactPrice,
            reservePrice,
            priceType
        } = values;
        if (opinionPriceFrom || exactPrice || priceType === PRICE_TYPE_RESERVE) {
            validateOpinionPrice(parseFloat(opinionPriceFrom), parseFloat(opinionPriceTo));
            let fromPrice = !isRange ? exactPrice : opinionPriceFrom;
            if (isLeaseLocationQLD(location) && priceType === PRICE_TYPE_RESERVE) {
                fromPrice = reservePrice;
            }
            const withCommisions = {
                ...values,
                exactPrice: isRange ? 0 : exactPrice,
                opinionPriceFrom: isRange ? opinionPriceFrom : 0,
                opinionPriceTo: isRange ? opinionPriceTo : 0,
                commissionFrom: percentageOfValue(fromPrice, commissionPercent),
                commissionTo: percentageOfValue(opinionPriceTo, commissionPercent)
            };
            setPriceAndFee(withCommisions);
        } else {
            setPriceAndFee(values);
        }
    };

    const validateOpinionPrice = (opinionPriceFrom, opinionPriceTo) => {
        if (!isLeaseLocationQLD(location) && opinionPriceFrom && opinionPriceTo) {
            //Validate the difference is not more than 10%
            if (opinionPriceFrom < opinionPriceTo && opinionPriceTo > opinionPriceFrom * 1.1) {
                setOpinionRangeFail(RANGE_PRICE_ERROR_MESSAGE);
            } else {
                setOpinionRangeFail('');
            }
        }
    };

    return (
        <div className="form-container price-and-fee">
            <Form onSubmit={submitForm} initialValues={priceAndFee}>
                {({ handleSubmit, values, form }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={PRICE_FEES_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            {isLeaseLocationNSW(location) && (
                                <NswPriceAndFee
                                    values={values}
                                    handleRangeChange={handleRangeChange}
                                    opinionRangeFail={opinionRangeFail}
                                />
                            )}
                            {isLeaseLocationQLD(location) && (
                                <QldPriceAndFee
                                    values={values}
                                    handleRangeChange={handleRangeChange}
                                    opinionRangeFail={opinionRangeFail}
                                />
                            )}
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(PriceAndFee);
