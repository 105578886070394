import ReactTooltip from 'react-tooltip';
import * as PropTypes from 'prop-types';
import React from 'react';
import EditSharpIcon from '@flk-mui-icons/EditSharp';

function EditButton(props) {
    return (
        <div className={`btn ${props.className}`} data-test={props.dataTest}>
            {props.toolTip && (
                <ReactTooltip
                    id={props.id ? props.id : 'edit'}
                    class="tooltip"
                    place={props.toolTipPlace ? props.toolTipPlace : 'top'}
                    effect="solid"
                    globalEventOff="wheel"
                >
                    <p>{props.toolTip}</p>
                </ReactTooltip>
            )}
            <span>
                <EditSharpIcon
                    data-tip={true}
                    data-for={props.id ? props.id : 'edit'}
                    onClick={props.onClick}
                    disabled={props.disabled}
                />
            </span>
        </div>
    );
}

EditButton.propTypes = {
    onClick: PropTypes.func,
    toolTip: PropTypes.string
};

export default EditButton;
