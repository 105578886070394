import ReactTooltip from 'react-tooltip';
import React, { memo } from 'react';
import * as PropTypes from 'prop-types';
import { getClientFromLeaseType } from '../../../utils/agreementUtils';
import { InfoSharp } from '@flk-mui-icons';

const Links = props => {
    return (
        <React.Fragment>
            <InfoSharp className="tooltip-info-icon text-gray " data-tip={true} data-for="defaultDocsLinksInfo" />
            <ReactTooltip
                id="defaultDocsLinksInfo"
                class="tooltip"
                place="bottom"
                effect="solid"
                globalEventOff="wheel"
            >
                <div>
                    <div className="info-segment">
                        It could include links to condition reports, bond payments, office questionnaire's, google
                        <br />
                        review's, their property managers profile or Vcard, or a link to your social media profile.
                    </div>
                    <div className="info-segment">
                        You can choose if you would like it to be included in the Email sent to the{' '}
                        {getClientFromLeaseType(props.leaseType, props.location)}
                        <br />
                        when the sms is sent (so it will be seen before they sign), show on the mobile screen after
                        <br />
                        they finish signing (it will be seen after they sign) or both.
                        <br />
                        <br />
                    </div>
                </div>
            </ReactTooltip>
        </React.Fragment>
    );
};

Links.propTypes = { leaseType: PropTypes.string };

export default memo(Links);
