import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useOnClickOutside from 'use-onclickoutside';
import ReactTooltip from 'react-tooltip';
import { MoreHorizSharp, CloseSharp, ArrowBackIosSharp } from '@flk-mui-icons';
import { confirmAlert } from 'react-confirm-alert';
import { NSW_STATE, REQUEST_TO_CHANGE_STATUS_RESOLVED } from '../../../config';
import * as dashboard from '../../../actions/dashboard';
import {
    LEASE_STATUS_DRAFT,
    openIntentionRenewalModal,
    updateAgreementLoadingState,
    updateAgreementToRemoveLater
} from '../../../actions/dashboard';

import { getUserInfo, isUserRoleAdmin } from '../../../selectors/user';

import PopupLeaseMore from '../../../components/popups/PopupLeaseMore';
import AuditTrailOpenButton from '../../../common/components/AuditTrailOpenButton';
import NotesOpenButton from '../../../common/components/NotesOpenButton';
import { HeaderLeft } from './HeaderLeft';

import {
    getV2RoutePath,
    isLeaseTypePm,
    isLeaseTypeResidentialTenancy,
    isLeaseTypeSales,
    mapLeaseStatusToCategory,
    mappingsToFrontEnd
} from '../../../utils/agreementUtils';
import { LEASE_TYPE_RESIDENTIAL } from '../../../config';

import '../../../sass/agreementHeader.scss';
import '../../../sass/reactConfirmAlert.scss';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { moveToArchive, moveToAwaitingRenewal } from '../../../actions/lease';
import * as lease from '../../../actions/lease';
import { CANCEL_SIGNING_MESSAGE } from '../../../utils/labelUtils';
import { isAccountEnabled } from '../../../utils/userUtils';
import axios from 'axios';

/**
 * Header of lease agreement modal window
 */
const AgreementHeader = props => {
    const dispatch = useDispatch();
    const [morePopup, setMorePopup] = useState(false);
    const isUserAdmin = useSelector(isUserRoleAdmin);
    const loggedInUser = useSelector(getUserInfo);

    const { leaseAllInfo, closeAgreementInfoModal, openSummaryView } = props;

    const leaseId = leaseAllInfo.id;

    const ref = React.useRef(null);
    useOnClickOutside(ref, () => {
        closeMorePopupList();
    });

    const closeMorePopupList = () => {
        setMorePopup(false);
    };

    const toggleMorePopup = () => {
        // Hide all tooltips
        ReactTooltip.hide();
        setMorePopup(!morePopup);
    };

    useEffect(() => {
        const close = e => {
            if (e.keyCode === 27) {
                props.closeAgreementInfoModal();
                window.history.pushState(
                    {},
                    '',
                    `/dashboard/agreements/${mappingsToFrontEnd[leaseAllInfo.leaseType]}/${mapLeaseStatusToCategory(
                        leaseAllInfo.status
                    )}`
                );
            }
        };
        document.addEventListener('keydown', close);
        return () => document.removeEventListener('keydown', close);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const moveToArchiveStatus = e => {
        e.stopPropagation();
        dispatch(updateAgreementLoadingState(leaseId));
        dispatch(moveToArchive(leaseId));
        closeMorePopupList();
    };

    const moveToAwaitingRenewalStatus = e => {
        e.stopPropagation();
        dispatch(updateAgreementLoadingState(leaseId));
        dispatch(moveToAwaitingRenewal(leaseId));
        closeMorePopupList();
    };

    const cloneAgreement = e => {
        e.stopPropagation();
        const { leaseType, location } = leaseAllInfo;
        const canUpgrade =
            (isLeaseTypeSales(leaseType) && location === NSW_STATE) ||
            (isLeaseTypePm(leaseType) && location === NSW_STATE);
        if (canUpgrade) {
            const agreementPath = getV2RoutePath(leaseType);
            const agreementLocation = location.toLowerCase();
            axios
                .post(`/api/v2/app/dashboard/${agreementPath}/${agreementLocation}/${leaseAllInfo.id}/clone`)
                .then(response => {
                    window.location.href = `/apps/${agreementPath}/${agreementLocation}/${response.data.id}`;
                });
        } else if (isLeaseTypeResidentialTenancy(leaseType)) {
            dispatch(lease.openCloneResTenForm(leaseId, LEASE_TYPE_RESIDENTIAL));
        } else {
            dispatch(dashboard.cloneAgreement(leaseId));
        }
        closeMorePopupList();
    };

    const handleInitiateRenewalIntention = e => {
        e.stopPropagation();
        dispatch(openIntentionRenewalModal(leaseId));
        closeMorePopupList();
    };

    const deleteAgreement = () => {
        confirmAlert({
            title: '',
            message: 'Are you sure want to delete this agreement?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        props.deleteAgreement(leaseId);
                        closeMorePopupList();
                        dispatch(updateAgreementLoadingState(leaseId));
                        dispatch(updateAgreementToRemoveLater(leaseId));
                        /**
                         * Temporarily here because it is possible the lease is not in Sync yet so we just want it to dissapear automatically
                         */
                        props.closeAgreementInfoModal();
                        window.history.pushState(
                            {},
                            '',
                            `/dashboard/agreements/${
                                mappingsToFrontEnd[leaseAllInfo.leaseType]
                            }/${mapLeaseStatusToCategory(leaseAllInfo.status)}`
                        );
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    };

    const cancelSigning = () => {
        confirmAlert({
            title: '',
            message: CANCEL_SIGNING_MESSAGE,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        props.cancelSigning(leaseId);
                        closeMorePopupList();
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    };

    const openCancelRenewalModal = () => {
        closeMorePopupList();

        confirmAlert({
            title: '',
            message: 'Are you sure you want to cancel the renewal?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        props.rejectRenewal(leaseId);
                        props.closeAgreementInfoModalWithoutAnyOtherAction();
                        window.history.pushState(
                            {},
                            '',
                            `/dashboard/agreements/${
                                mappingsToFrontEnd[leaseAllInfo.leaseType]
                            }/${mapLeaseStatusToCategory(leaseAllInfo.status)}`
                        );
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    };

    const openLeaseRenewalModal = () => {
        props.openLeaseRenewalModal(leaseAllInfo);
    };

    const hasUnresolved = () => {
        const { leaseAllInfo } = props;

        if (!leaseAllInfo.changeRequestList) {
            return false;
        }
        if (!leaseAllInfo.changeRequestList.length) {
            return false;
        }

        return getUnresolvedCount() > 0;
    };

    const getUnresolvedCount = () => {
        const {
            leaseAllInfo: { changeRequestList }
        } = props;
        let unresolved = changeRequestList.filter(item => item.status !== REQUEST_TO_CHANGE_STATUS_RESOLVED);
        return unresolved.length;
    };

    const getUnresolvedText = () => {
        let count = getUnresolvedCount();
        if (count === 1) {
            return 'There is an unresolved request.';
        }

        return `There are ${count} unresolved requests.`;
    };

    const showEditTenants = event => {
        event.stopPropagation();
        dispatch(dashboard.openEditTenantsModal(leaseAllInfo));
        closeMorePopupList();
    };
    const showEditLandlords = event => {
        event.stopPropagation();
        dispatch(dashboard.openEditLandlordsModal(leaseAllInfo));
        closeMorePopupList();
    };
    const showEditVendors = event => {
        event.stopPropagation();
        dispatch(dashboard.openEditVendorsModal(leaseAllInfo));
        closeMorePopupList();
    };
    const showExtendExpiry = event => {
        event.stopPropagation();
        dispatch(dashboard.openExtendExpiryModal(leaseAllInfo));
        setMorePopup(false);
    };
    return (
        <div className="agreement-header">
            <div className="header-content-info-agreement">
                {leaseAllInfo && leaseAllInfo.agent && (
                    <HeaderLeft leaseAllInfo={leaseAllInfo} isCompletedLease={props.isCompletedLease} />
                )}

                <div className="header-right">
                    <div className="header-right-wrapper" ref={ref}>
                        <NotesOpenButton leaseId={leaseAllInfo.id} showText={false} />
                        {leaseAllInfo.status !== LEASE_STATUS_DRAFT &&
                            leaseAllInfo.auditTrail &&
                            leaseAllInfo.auditTrail.length > 0 && (
                                <AuditTrailOpenButton leaseId={leaseAllInfo.id} showText={false} />
                            )}
                        {isAccountEnabled(loggedInUser) && (
                            <div
                                className="more header-button for-lg-modal"
                                data-tip
                                data-for="moreActions"
                                onClick={toggleMorePopup}
                            >
                                <MoreHorizSharp>More</MoreHorizSharp>
                            </div>
                        )}
                        <ReactTooltip
                            id="moreActions"
                            class="tooltip"
                            place="bottom"
                            effect="solid"
                            globalEventOff="wheel"
                        >
                            <p>More actions</p>
                        </ReactTooltip>
                        <div
                            onClick={() => {
                                if (leaseAllInfo && leaseAllInfo.status === dashboard.LEASE_STATUS_DRAFT) {
                                    openSummaryView();
                                } else {
                                    closeAgreementInfoModal();
                                    window.history.pushState(
                                        {},
                                        '',
                                        `/dashboard/agreements/${
                                            mappingsToFrontEnd[leaseAllInfo.leaseType]
                                        }/${mapLeaseStatusToCategory(leaseAllInfo.status)}`
                                    );
                                }
                            }}
                            className="close header-button for-sm-modal"
                        >
                            <ArrowBackIosSharp />
                        </div>
                        <div
                            onClick={() => {
                                closeAgreementInfoModal();
                                window.history.pushState(
                                    {},
                                    '',
                                    `/dashboard/agreements/${
                                        mappingsToFrontEnd[leaseAllInfo.leaseType]
                                    }/${mapLeaseStatusToCategory(leaseAllInfo.status)}`
                                );
                            }}
                            className="close header-button for-lg-modal"
                        >
                            <CloseSharp>Close</CloseSharp>
                        </div>
                        <PopupLeaseMore
                            hideRenewalIntentionButton={false}
                            initiateRenewalIntention={e => handleInitiateRenewalIntention(e)}
                            agreementStatus={leaseAllInfo.status}
                            leaseType={leaseAllInfo.leaseType}
                            isRenewalsDue={leaseAllInfo.awaitingRenewal}
                            moveToArchiveStatus={e => moveToArchiveStatus(e)}
                            moveToAwaitingRenewalStatus={e => moveToAwaitingRenewalStatus(e)}
                            morePopupInfoAgreement={morePopup}
                            showEditTenants={showEditTenants}
                            showEditLandlords={showEditLandlords}
                            showEditVendors={showEditVendors}
                            showExtendExpiry={showExtendExpiry}
                            cloneAgreement={cloneAgreement}
                            deleteAgreement={deleteAgreement}
                            cancelSigning={cancelSigning}
                            openLeaseRenewalModal={openLeaseRenewalModal}
                            openCancelRenewalModal={openCancelRenewalModal}
                            showResendButton={false}
                            isUserAdmin={isUserAdmin}
                            leaseId={leaseAllInfo.id}
                            closePopup={closeMorePopupList}
                            isCompletedLease={props.isCompletedLease}
                            lease={leaseAllInfo}
                        />
                    </div>
                </div>
            </div>
            {hasUnresolved() && (
                <div className="has-requests">
                    <span>
                        <span className="exclamation">!</span>
                        {getUnresolvedText()}
                    </span>
                    <button className="expand-requests" onClick={props.showChangeRequestForm}>
                        <span className="icon icon-item-arrow" />
                    </button>
                </div>
            )}
        </div>
    );
};

export default memo(AgreementHeader);
