import React, { useState, useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Td, Th, Thead, Tr } from 'reactable';
import { cloneDeep, findIndex } from 'lodash';
import { getIsOpenFlkAKeyCheckInModal } from '../../../selectors/flkAKey';
import { addOrReplaceKeyInList, closeFlkAKeyCheckInModal, removeKeyInList } from '../../../actions/flkAKey';
import ModalDialog from '../../../common/components/ModalDialog';
import Button from '../../../common/components/Button';
import { LockOpenSharp } from '@flk-mui-icons';
import { PreLoaderSmall } from '../../../common/components/PreLoaderSmall';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { FLK_A_KEY_STATUS_RETURNED } from '../../../actions/dashboard';

const FlkAKeyCheckInButton = ({ flkAKey, setError, keyList, setKeyList }) => {
    const dispatch = useDispatch();

    const { keyStatus } = useParams();
    const [isLoading, setIsLoading] = useState(false);

    const checkIn = flkAKey => {
        confirmAlert({
            title: '',
            message: `Are you sure you want to check in key number ${flkAKey.key} for ${flkAKey.address}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setError('');
                        setIsLoading(true);
                        axios
                            .post(`/api/flk-a-key/${flkAKey.id}/check-in`)
                            .then(resp => {
                                // remove key from search result
                                let newKeyList = cloneDeep(keyList);
                                const index = findIndex(newKeyList, {
                                    id: flkAKey.id
                                });
                                if (index > -1) {
                                    newKeyList = [...newKeyList.slice(0, index), ...newKeyList.slice(index + 1)];
                                }
                                setKeyList(newKeyList);
                                setIsLoading(false);
                                // Add or remove key from dashboard according to current state
                                if (keyStatus === FLK_A_KEY_STATUS_RETURNED) {
                                    dispatch(addOrReplaceKeyInList(resp.data.flkAKey));
                                } else {
                                    dispatch(removeKeyInList(resp.data.flkAKey));
                                }
                            })
                            .catch(() => {
                                setError('Something went wrong. Please try again');
                                setIsLoading(false);
                            });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    };
    return (
        <Button onClick={() => checkIn(flkAKey)} type="button">
            {isLoading && <PreLoaderSmall />}
            <LockOpenSharp />
            Check in
        </Button>
    );
};

const FlkAKeyCheckInModal = () => {
    const dispatch = useDispatch();
    const isOpenFlkAKeyCheckInModal = useSelector(getIsOpenFlkAKeyCheckInModal);

    const [keyList, setKeyList] = useState([]);
    const [error, setError] = useState('');
    const [isLoadingList, setIsLoadingList] = useState(false);

    useEffect(() => {
        setError('');
        setIsLoadingList(true);
        axios
            .get(`/api/flk-a-key/search?query=`)
            .then(resp => {
                setKeyList(resp.data.flkAKeyList);
            })
            .catch(() => {
                setError('Something went wrong. Please try again');
            })
            .finally(() => {
                setIsLoadingList(false);
            });
    }, []);

    const handleCloseModal = () => {
        dispatch(closeFlkAKeyCheckInModal());
    };

    const searchKey = e => {
        setError('');
        setIsLoadingList(true);
        axios
            .get(`/api/flk-a-key/search?query=${e.target.value}`)
            .then(resp => {
                setKeyList(resp.data.flkAKeyList);
            })
            .catch(() => {
                setError('Something went wrong. Please try again');
            })
            .finally(() => {
                setIsLoadingList(false);
            });
    };

    return (
        <ModalDialog
            title="Check In Key"
            isOpen={isOpenFlkAKeyCheckInModal}
            closeModal={handleCloseModal}
            shouldCloseOnOverlayClick={false}
            className="document-centre-modal large"
        >
            <div className="search-group">
                <div className="FormInput ">
                    <div className="form-group regular on ">
                        <label>Key Number*</label>
                        <input type="text" placeholder="Search" onChange={searchKey} />
                    </div>
                </div>
                {isLoadingList && <div className="blue-spinner-inline" />}
            </div>
            <br />
            {error && <p className="text-error">{error}</p>}
            <div className="wrapper flex-direction-column">
                <div className="templates-block table-default">
                    <Table>
                        <Thead>
                            <Th className="template-name-column" column="key">
                                KEY#
                            </Th>
                            <Th column="address">ADDRESS</Th>
                            <Th column="contact">CONTACT</Th>
                            <Th column="actions" className="actions">
                                <b>ACTIONS</b>
                            </Th>
                        </Thead>
                        {!isLoadingList &&
                            Array.isArray(keyList) &&
                            keyList.map((item, index) => (
                                <Tr key={index}>
                                    <Td column="key">{item.key}</Td>
                                    <Td column="address">{item.address}</Td>
                                    <Td column="contact">{item.name}</Td>
                                    <Td column="actions" className="actions large">
                                        <FlkAKeyCheckInButton
                                            flkAKey={item}
                                            setError={setError}
                                            keyList={keyList}
                                            setKeyList={setKeyList}
                                        />
                                    </Td>
                                </Tr>
                            ))}
                    </Table>
                </div>
            </div>
        </ModalDialog>
    );
};

export default memo(FlkAKeyCheckInModal);
