import React from 'react';
import { useQuery } from '@tanstack/react-query';
import RefreshIcon from '@flk-mui-icons/Autorenew';
import axios from 'axios';
import cx from 'classnames';
import Button from '../../../../common/components/Button.js';
import Tooltip from '../../../../common/components/tooltips/Tooltip';
import Tabs from '../../../../common/components/Tabs';
import ZoomControls from '../../../../common/components/ZoomControls';
import styles from './CreateFlkPreview.module.scss';
import parse from 'html-react-parser';
import Frame from 'react-frame-component';
import { debounce } from 'lodash';

type RefreshButtonProps = {
    onRefresh: () => void;
    isRefreshing: boolean;
    iconOnly?: boolean;
};

const RefreshButton: React.FC<RefreshButtonProps> = ({ onRefresh, isRefreshing, iconOnly }) => (
    <Button
        className={styles.refreshPreviewButton}
        onClick={onRefresh}
        startIcon={
            <RefreshIcon
                className={cx(styles.refreshIcon, {
                    [styles.spinningIcon]: isRefreshing
                })}
            />
        }
        tertiary
        disabled={isRefreshing}
    >
        {!iconOnly ? 'Refresh' : undefined}
    </Button>
);

type CreateFlkPreviewProps = {
    data: any;
    onRefreshPreview: () => void;
    isRefreshing?: boolean;
};

const CreateFlkPreview: React.FC<CreateFlkPreviewProps> = ({ data, onRefreshPreview, isRefreshing }) => {
    const initialTabId = 'htmlView';
    const [selectedTabId, setSelectedTabId] = React.useState(initialTabId);
    const [isSmallScreen, setIsSmallScreen] = React.useState(false);
    const [isMediumScreen, setIsMediumScreen] = React.useState(false);

    React.useEffect(() => {
        const handleResize = debounce(() => {
            // For screens smaller than 1300px
            const smallScreenQuery = window.matchMedia('(max-width: 81.25rem)').matches;
            // For screens between 1300px and 1440px
            const mediumScreenQuery = window.matchMedia('(max-width: 90rem)').matches;
            setIsSmallScreen(smallScreenQuery);
            setIsMediumScreen(!smallScreenQuery && mediumScreenQuery);
        });

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={styles.preview}>
            <Tabs
                tabs={[
                    {
                        id: 'htmlView',
                        title: 'Final Document',
                        component: (
                            <HtmlPreview
                                data={data}
                                refreshButton={
                                    isSmallScreen ? (
                                        <RefreshButton onRefresh={onRefreshPreview} isRefreshing={!!isRefreshing} />
                                    ) : undefined
                                }
                            />
                        )
                    },
                    {
                        id: 'clientView',
                        title: 'Sample Signing Process',
                        component: <ClientPreview />
                    }
                ]}
                initialTabId={initialTabId}
                containerClassName={styles.layout}
                tabSectionClassName={styles.header}
                tabContentClassName={styles.content}
                onSelectTab={value => setSelectedTabId(value)}
                tabSectionStartContent={
                    !isSmallScreen && selectedTabId === 'htmlView' ? (
                        <RefreshButton
                            onRefresh={onRefreshPreview}
                            isRefreshing={!!isRefreshing}
                            iconOnly={isMediumScreen}
                        />
                    ) : undefined
                }
            />
            <Tooltip effect="solid" />
        </div>
    );
};

type HtmlPreviewProps = {
    data: any;
    refreshButton?: React.ReactElement<RefreshButtonProps>;
};

const HtmlPreview: React.FC<HtmlPreviewProps> = ({ data, refreshButton }) => {
    const initialFontSize = 10;
    // fontSize controls scale of the preview to emulate zoom
    const [fontSize, setFontSize] = React.useState(initialFontSize);

    const previewQuery = useQuery({
        queryKey: ['pdfPreview', data],
        queryFn: async () => {
            const returnData = await axios.post(`/html/document/${data.id}/create_a_flk`, data);

            return returnData.data;
        },
        keepPreviousData: true
    });

    return (
        <div className={styles.htmlPreview}>
            <div className={styles.previewContainer}>
                {previewQuery.data && (
                    <Frame
                        className={styles.embeddedPreview}
                        head={
                            <style>{`html {font-size: ${fontSize}px !important; padding: 8px 28px !important;}`}</style>
                        }
                    >
                        {parse(previewQuery.data)}
                    </Frame>
                )}
            </div>
            <div className={styles.controls}>
                {refreshButton}
                <ZoomControls
                    onZoomIn={() => {
                        setFontSize(oldValue => oldValue + 1);
                    }}
                    onZoomOut={() => {
                        setFontSize(oldValue => oldValue - 1);
                    }}
                    initialZoom={initialFontSize}
                    currentZoom={fontSize}
                    minZoom={5}
                    maxZoom={20}
                />
            </div>
        </div>
    );
};

const ClientPreview = () => {
    return (
        <div className={styles.signingProcess}>
            <h1 className={styles.heading}>See how your client will view it</h1>
            <p className={styles.text}>
                When signing your document, this is the process your client will go through on their phone.
            </p>
            <iframe
                loading="eager"
                className={styles.embeddedPreview}
                src="https://www.appdemostore.com/embed?id=5234895276736512"
            />
        </div>
    );
};

export default CreateFlkPreview;
