import React from 'react';
import InfoIcon from '@flk-mui-icons/InfoSharp';
import { isEmpty } from 'lodash';
import cx from 'classnames';
import styles from './IssueNoticeToLeaveSwitch.module.scss';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../../../../../../selectors/user';
import { getLeaseInfo } from '../../../../../../../selectors/lease';
import ReactTooltip from 'react-tooltip';
import { SCHEDULE_TYPE_LATER } from '../../../../../../../config';
import { formatDateDay, getAgencyTimezoneFromUser } from '../../../../../../../utils/dateUtils';
import { CheckboxNoticeToLeave } from '../../../../../../form/FormCheckboxNoticeToLeave';

function IssueNoticeToLeaveSwitch({ changeNoticeToLeaveFlow, disabled, showTooltip, values }) {
    const loggedInUser = useSelector(getUserInfo);
    const lease = useSelector(getLeaseInfo);

    return (
        !isEmpty(lease) &&
        !isEmpty(loggedInUser) && (
            <div className={cx(styles.formSwitch, 'form-switch')}>
                <div className={styles.switchText}>
                    <p className={styles.text}>Issue Notice to Leave</p>
                    <p className={styles.subText}>
                        Prepare a Notice to Leave and send it now or schedule it for later.
                    </p>
                    <div className={cx(styles.subText, styles.tooltipText)}>
                        {showTooltip && values?.isRenewalToLeaveFlow && values?.scheduleType && (
                            <React.Fragment>
                                Lease Renewal Email Copy
                                <InfoIcon
                                    className={styles.tooltipIcon}
                                    data-tip
                                    data-for="noticeToLeaveEmailTooltip"
                                />
                                <ReactTooltip
                                    id="noticeToLeaveEmailTooltip"
                                    class={cx(styles.fixedWidthTooltip, 'tooltip')}
                                    effect="solid"
                                    globalEventOff="wheel"
                                >
                                    <div>
                                        <p>Hi [Tenant name],</p>
                                        <br />
                                        {values.scheduleType === SCHEDULE_TYPE_LATER ? (
                                            <React.Fragment>
                                                <p className={styles.paragraph}>
                                                    It's {lease.agent?.fullName} from {lease.agency?.details.agencyName}
                                                    , we are pleased to offer you a lease renewal and have just issued
                                                    your new lease to sign via SMS for {lease.address}. It was sent to
                                                    [tenant mobile number] and will come from +61 451 266 641.
                                                </p>
                                                <p className={styles.paragraph}>
                                                    The lessor's instructions are that their tenants always remain under
                                                    a fixed term lease, please see the below signing timeline
                                                    requirements.
                                                </p>
                                                <ul>
                                                    <li>
                                                        <b>Your current lease ends on the</b>{' '}
                                                        {values.vacantPossessionDate
                                                            ? formatDateDay(
                                                                  values.vacantPossessionDate,
                                                                  getAgencyTimezoneFromUser(loggedInUser)
                                                              )
                                                            : '[vacant possession date]'}{' '}
                                                        we require all tenants for this property to sign this lease by{' '}
                                                        <b>12pm on the</b>{' '}
                                                        {values.noticeToLeaveDate
                                                            ? formatDateDay(
                                                                  values.noticeToLeaveDate,
                                                                  getAgencyTimezoneFromUser(loggedInUser)
                                                              )
                                                            : '[notice to leave date]'}
                                                        .
                                                    </li>
                                                </ul>
                                                <p className={styles.paragraph}>
                                                    If the lease isn’t signed by{' '}
                                                    <b>
                                                        <u>ALL</u>
                                                    </b>{' '}
                                                    parties by the above stated time, our instructions are to issue a
                                                    Form 12 Notice to Leave.
                                                </p>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <p className={styles.paragraph}>
                                                    It's {lease?.agent.fullName} from {lease?.agency.details.agencyName}
                                                    , I have just sent you your lease to sign via SMS for{' '}
                                                    {lease.address}. It was sent to [tenant mobile number] and will come
                                                    from +61 451 266 641.
                                                </p>
                                                <p className={styles.paragraph}>
                                                    The lessor's instructions are that their tenants must always remain
                                                    under a fixed term lease.
                                                </p>
                                                <p className={styles.paragraph}>
                                                    If you decide not to sign the lease, I have attached a Form 12
                                                    "Notice to leave" that the lessor is required to issue 2 months
                                                    prior to your vacant possession date (
                                                    {values.vacantPossessionDate
                                                        ? formatDateDay(
                                                              values.vacantPossessionDate,
                                                              getAgencyTimezoneFromUser(loggedInUser)
                                                          )
                                                        : '[vacant possession date]'}
                                                    ).
                                                </p>
                                            </React.Fragment>
                                        )}
                                        <p className={styles.paragraph}>
                                            Please find attached all the documents that go with the lease agreement,
                                            this includes a copy of “form 17a”.
                                        </p>
                                        <p className={styles.paragraph}>
                                            We are required to provide you with a copy of this prior to signing this
                                            agreement.
                                        </p>
                                        <br />
                                        <p>Regards,</p>
                                        <br />
                                        <p>{loggedInUser.fullName}</p>
                                        <p>{lease.agency?.details.agencyName}</p>
                                        <p>{loggedInUser.email}</p>
                                        <p>{loggedInUser.mobile}</p>
                                    </div>
                                </ReactTooltip>
                            </React.Fragment>
                        )}
                    </div>
                </div>
                <CheckboxNoticeToLeave
                    name="isRenewalToLeaveFlow"
                    value={false}
                    onClick={value => changeNoticeToLeaveFlow(value, values)}
                    disabled={disabled}
                    dataTest={'renewal-notice-to-leave-details-switch'}
                />
            </div>
        )
    );
}

export default IssueNoticeToLeaveSwitch;
