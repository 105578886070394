import React from 'react';
import { Table, Th, Tr, Td, Thead } from 'reactable';

import ProgressBlock from '../../../../components/dashboard/agreementList/cells/progressBlock';
import LeaseDropdown from '../../../dropdowns/LeaseDropdown';
import ExpiredBlock from '../cells/ExpiredBlock';
import AgentLogo from '../../../AgentLogo';
import renewalsIconSvg from '../../../../../assets/images/icons/renewals-icon.svg?url';
import { formatDateTime, getAgencyTimezoneFromUser } from '../../../../utils/dateUtils';
import { getUserInfo } from '../../../../selectors/user';
import { useSelector } from 'react-redux';
import { getLabel, getSubLeaseTypeLabel } from '../../../../utils/labelUtils';
import {
    isLeaseTypeSales,
    isLeaseTypeResidentialTenancy,
    hasSaExtensions,
    hasNoticeToLeaveLeases,
    getTenantNames,
    isLeaseTypePm
} from '../../../../utils/agreementUtils';
import { differenceInHours, parseISO, setHours } from 'date-fns';
import { SCHEDULE_TYPE_LATER } from '../../../../config';
import { isAccountEnabled } from '../../../../utils/userUtils';

const TableSentForSigning = ({ agreementList, leaseStatus, leaseType, showModalInfoAgreement }) => {
    const loggedInUser = useSelector(getUserInfo);
    const signorsExpireHeader = !isLeaseTypeSales(leaseType) ? 'expiring' : 'agent';
    const hasSaExtensionAgreements = hasSaExtensions(agreementList);
    const hasNoticeToLeaveLeaseAgreements = hasNoticeToLeaveLeases(agreementList);

    const signingExpireContent = lease => {
        return isLeaseTypeSales(leaseType) ? (
            lease?.agent?.fullName
        ) : !lease.isManuallyShareLinks ? (
            <ExpiredBlock signingExpire={lease.signingExpire} deadline={lease.deadline} status={lease.status} />
        ) : null;
    };

    return (
        <Table>
            <Thead>
                <Th column="agentLogo"> </Th>
                <Th column="isRenewal"> </Th>
                <Th column="address">address</Th>
                {hasSaExtensionAgreements && <Th column="subLeaseType">lease type</Th>}
                <Th column="signingExpire">{signorsExpireHeader}</Th>
                <Th column="tenants">{getLabel('signorsHeader', null, leaseType)}</Th>
                <Th column="sentForSigning">sent for signing</Th>
                {hasNoticeToLeaveLeaseAgreements && <Th column="offerDeadline">offer of lease deadline</Th>}
                <Th column="signs">signatures</Th>
                <Th column="action">&nbsp;</Th>
            </Thead>
            {agreementList &&
                agreementList.map((lease, key) => {
                    const loadingClassName = lease.isLoading ? 'loading' : '';

                    return (
                        <Tr
                            key={key}
                            className={`showmodal ${loadingClassName}`}
                            onClick={() => showModalInfoAgreement(lease)}
                        >
                            <Td column="agentLogo">
                                <AgentLogo agent={lease.agent} />
                            </Td>
                            {lease.isRenewal && (
                                <Th column="isRenewal">
                                    <img src={renewalsIconSvg} alt="Is Renewal" />
                                </Th>
                            )}
                            <Td column="address">
                                <b>{lease.address}</b>
                            </Td>
                            {hasSaExtensionAgreements && isLeaseTypeResidentialTenancy(leaseType) && (
                                <Td column="subLeaseType">{getSubLeaseTypeLabel(lease.subLeaseType)}</Td>
                            )}
                            <Td column="signingExpire">{signingExpireContent(lease)}</Td>
                            <Td column="tenants">{getTenantNames(lease)}</Td>
                            <Td column="sentForSigning">
                                {formatDateTime(lease.sentForSigning, getAgencyTimezoneFromUser(loggedInUser))}
                            </Td>
                            {hasNoticeToLeaveLeaseAgreements && (
                                <Td column="offerDeadline">
                                    {lease?.renewalNoticeToLeaveDetails?.isRenewalToLeaveFlow &&
                                    lease.renewalNoticeToLeaveDetails.scheduleType === SCHEDULE_TYPE_LATER ? (
                                        <ExpiredBlock
                                            signingExpire={differenceInHours(
                                                setHours(
                                                    parseISO(lease.renewalNoticeToLeaveDetails.noticeToLeaveDate),
                                                    12
                                                ),
                                                new Date()
                                            )}
                                            deadline={differenceInHours(
                                                setHours(
                                                    parseISO(lease.renewalNoticeToLeaveDetails.noticeToLeaveDate),
                                                    12
                                                ),
                                                parseISO(lease.sentForSigning)
                                            )}
                                            status={lease.status}
                                        />
                                    ) : null}
                                </Td>
                            )}
                            <Td column="signs">
                                <ProgressBlock
                                    keys={key}
                                    signatures={lease.signatures}
                                    status={leaseStatus}
                                    leaseType={leaseType}
                                    subLeaseType={lease.subLeaseType}
                                    location={lease.location}
                                />
                            </Td>
                            {isAccountEnabled(loggedInUser) && (
                                <Td column="action">
                                    <div className="action">
                                        {lease.hasRequest && <span className="request" />}
                                        <LeaseDropdown lease={lease} leaseStatus={leaseStatus}>
                                            <div className="more-action" />
                                        </LeaseDropdown>
                                        <span className="arrow-right" />
                                    </div>
                                </Td>
                            )}
                        </Tr>
                    );
                })}
        </Table>
    );
};

export default TableSentForSigning;
