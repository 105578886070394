import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import ModalDialog from '../../common/components/ModalDialog';
import Button from '../../common/components/Button';

import '../../sass/modals/modalSignatureRequest.scss';
import QRCode from 'react-qr-code';
import { useDispatch, useSelector } from 'react-redux';
import { getUserInfo } from '../../selectors/user';
import { requestSignatureUpdate, requestSignatureUpdateURL, requestSignatureClear } from '../../actions/signatures';
import CheckCircleIcon from '@flk-mui-icons/CheckCircle';
import ErrorIcon from '@flk-mui-icons/Error';
import { getSignatures } from '../../selectors/signatures';
import { useEffect } from 'react';
import useDoubleSendGuard, { clearDoubleSendGuard } from '../../hooks/useDoubleSendGuard';
import useResendCounter from '../../hooks/useResendCounter';

const ModalSignatureRequest = ({ isEdit, isOpen, closeModal }) => {
    const phone = useSelector(getUserInfo).phone;
    const signatureState = useSelector(getSignatures);
    const dispatch = useDispatch();
    const sentRef = useRef(null);
    const { resendWaitSeconds, startResendCounter } = useResendCounter();
    const doubleSendGuard = useDoubleSendGuard();

    const sentSuccessfully = signatureState.requestToAddSignatureSent;
    const isLoadingSMS = signatureState.isSendingRequestToAddSignature;
    const sendFailed = signatureState.requestToAddSignatureFailed;

    const isLoadingURL = signatureState.isSendingRequestSignatureURL;
    const urlSuccess = signatureState.requestSignatureURLSuccess;
    const urlFailed = signatureState.requestSignatureURLFailed;
    const url = signatureState.signatureURL;

    useEffect(() => {
        if (!sentRef.current) {
            sentRef.current = { sentSuccessfully };
        } else if (sentSuccessfully !== sentRef.current.sentSuccessfully) {
            sentRef.current = { sentSuccessfully };

            if (sentSuccessfully) {
                setTimeout(() => {
                    dispatch(requestSignatureClear());
                    startResendCounter();
                }, 3000);
            }
        }
    }, [sentSuccessfully, startResendCounter, dispatch]);

    useEffect(() => {
        if (sendFailed) {
            clearDoubleSendGuard();
        }
    }, [sendFailed]);

    const renderRightButton = () => {
        return (
            <Button primary className="btn-save-signature" height={40} onClick={closeModal}>
                OK
            </Button>
        );
    };

    useEffect(() => {
        getSignatureURL();
    }, []);

    const sendSignatureSMS = () => doubleSendGuard(() => {
        if (sentSuccessfully) {
            return;
        }

        dispatch(requestSignatureUpdate());
    });

    const getSignatureURL = () => {
        dispatch(requestSignatureUpdateURL());
    };

    return (
        <ModalDialog
            isOpen={isOpen}
            closeModal={closeModal}
            rightButton={renderRightButton()}
            showModalHeader={false}
            shouldCloseOnOverlayClick={false}
            className="modal-signature-request"
        >
            {isEdit && (
                <div>
                    <p className="modal-signature-is-edit-text">
                        <strong>Please note: Creating a new signature will delete your existing one</strong>
                    </p>
                </div>
            )}
            <div>
                <p>Scan the QR Code below OR press 'Send SMS' to receive a text message with a link</p>
            </div>

            <div className="modal-signature-request-container">
                <div className="signature-options-container">
                    {isLoadingURL && <span className="loading-spinner" />}
                    {!isLoadingURL && urlSuccess && <QRCode value={url} size={200} className="qr-code" />}
                    {!isLoadingURL && urlFailed && (
                        <p className="error-message">
                            <strong>Something went wrong, please try again or contact support for help</strong>
                        </p>
                    )}
                </div>
                <p>OR</p>
                <div className="signature-options-container">
                    <Button
                        className={`${sentSuccessfully ? 'success' : ''} ${sendFailed ? 'error' : ''} `}
                        primary
                        disabled={isLoadingSMS || resendWaitSeconds > 0}
                        onClick={sendSignatureSMS}
                    >
                        {isLoadingSMS && <span className="loading-spinner" />}
                        {!sentSuccessfully && !sendFailed && !isLoadingSMS && (
                            <p>{resendWaitSeconds === 0 ? 'Send SMS' : `Resend in ${resendWaitSeconds} seconds`}</p>
                        )}
                        {sentSuccessfully && !isLoadingSMS && (
                            <div className="button-text-send-sms">
                                <CheckCircleIcon className="icon-margin" color="inherit" />
                                SMS Sent
                            </div>
                        )}
                        {sendFailed && !isLoadingSMS && (
                            <div className="button-text-send-sms">
                                <ErrorIcon className="icon-margin" color="inherit" />
                                Try again
                            </div>
                        )}
                    </Button>
                </div>
            </div>
            <p>Text messages will be sent to: {phone}</p>
            {sendFailed && !isLoadingSMS && (
                <p className="error-message">
                    <strong>Something went wrong, please try again or contact support for help</strong>
                </p>
            )}
        </ModalDialog>
    );
};

ModalSignatureRequest.propTypes = {
    isOpen: PropTypes.bool,
    closeModal: PropTypes.func
};

ModalSignatureRequest.defaultProps = {
    isOpen: false,
    closeModal: () => {}
};

export default ModalSignatureRequest;
