import React from 'react';
import { FormTextRegular, HiddenInput } from '../form/FormText';
import ClearSharpIcon from '@flk-mui-icons/ClearSharp';
import { CheckboxCheck } from '../form/FormCheckboxCheck';
import { FormPhone } from '../form/FormPhone';
import { includes } from 'lodash';
import { CLIENT_TYPE_TO, CLIENT_AGENT, CLIENT_CUSTOM, CLIENT_FIELD_LABEL_MOBILE, CLIENT_OTHER } from '../../config';
import { useAccountProvider } from '../providers/AccountProvider';
import { isAgencyUserAccount, isBusinessUserAccount } from '../../utils/userUtils';

const CreateFlkPersonGroup = ({
    index,
    removePerson,
    disabled,
    values,
    addOther,
    removeOther,
    type,
    removedFields,
    addRemovedField,
    handlePrimaryClient,
    label
}) => {
    const accountType = useAccountProvider();

    const canShowField = (field, client) => {
        let prerequisiteCondition;
        if (field.prerequisite && field.prerequisite.length > 0) {
            prerequisiteCondition = field.prerequisite.map(conditions => {
                let iterationValue = true;
                for (let label in conditions) {
                    if (conditions[label] !== client[label]) {
                        iterationValue = false;
                    }
                }
                return iterationValue;
            });
        }
        if (prerequisiteCondition) {
            return includes(prerequisiteCondition, true);
        }
        return true;
    };

    let header;
    if (index === 0) {
        header = (
            <h3 className="form-section__heading">
                {label} {index + 1}
            </h3>
        );
    } else {
        header = (
            <span className="form-section__header">
                <h3 className="form-section__heading">
                    {label} {index + 1}
                </h3>
                <button type="button" onClick={disabled ? () => {} : () => removePerson(index)}>
                    <span className="span-remove">Remove</span>
                </button>
            </span>
        );
    }
    return (
        <div className="form-section create-flk-person-group">
            {header}
            <div className="form-section__body">
                <HiddenInput name="source" />
                {values[type].label !== CLIENT_AGENT && type === CLIENT_TYPE_TO && (
                    <CheckboxCheck
                        name={`${type}.clients[${index}].isPrimaryClient`}
                        label={`is primary ${label}`}
                        onClick={value => handlePrimaryClient(index, value)}
                        disabled={disabled}
                    />
                )}
                {isAgencyUserAccount(accountType) &&
                    values[type].label !== CLIENT_AGENT &&
                    values[type].label !== CLIENT_CUSTOM && (
                        <CheckboxCheck
                            name={`${type}.clients[${index}].isCorporation`}
                            label={`${values[type].label} is a corporation`}
                            disabled={disabled}
                        />
                    )}

                {isBusinessUserAccount(accountType) && values[type].label === CLIENT_OTHER && (
                    <CheckboxCheck
                        name={`${type}.clients[${index}].isCorporation`}
                        label="Client is a corporation"
                        disabled={disabled}
                    />
                )}
                <div className="input-grid">
                    {values[type].clients[index].data.map((field, fieldIndex) => {
                        if (field.other) {
                            return (
                                <div className="input-grid__row">
                                    <div className="input-grid__item">
                                        <FormTextRegular
                                            containerClassName="input-grid__item__input"
                                            key={fieldIndex}
                                            name={`${type}.clients[${index}].data[${fieldIndex}].label`}
                                            label="Label"
                                            required={field.mandatory}
                                            disabled={disabled}
                                        />
                                    </div>
                                    <div className="input-grid__item">
                                        <FormTextRegular
                                            containerClassName="input-grid__item__input"
                                            key={fieldIndex}
                                            name={`${type}.clients[${index}].data[${fieldIndex}].value`}
                                            label="Value"
                                            required={field.mandatory}
                                            disabled={disabled}
                                        />
                                        <ClearSharpIcon
                                            className={`input-grid__item__icon ${disabled ? 'disabled' : ''}`}
                                            onClick={() => removeOther(index, fieldIndex)}
                                        />
                                    </div>
                                </div>
                            );
                        }
                        if (canShowField(field, values[type].clients[index])) {
                            if (field.label === CLIENT_FIELD_LABEL_MOBILE) {
                                return (
                                    <div key={fieldIndex} className="input-grid__item">
                                        <FormPhone
                                            containerClassName="input-grid__item__input"
                                            name={`${type}.clients[${index}].data[${fieldIndex}].value`}
                                            className={`${type}-clients-${index}-mobile`}
                                            label={field.label}
                                            required={field.mandatory}
                                            disabled={disabled}
                                        />
                                        {!field.mandatory && (
                                            <ClearSharpIcon
                                                className={`input-grid__item__icon ${disabled ? 'disabled' : ''}`}
                                                onClick={() => removeOther(index, fieldIndex)}
                                            />
                                        )}
                                    </div>
                                );
                            } else {
                                return (
                                    <div key={fieldIndex} className="input-grid__item">
                                        <FormTextRegular
                                            containerClassName="input-grid__item__input"
                                            name={`${type}.clients[${index}].data[${fieldIndex}].value`}
                                            label={field.label}
                                            required={field.mandatory}
                                            disabled={disabled}
                                        />
                                        {!field.mandatory && (
                                            <ClearSharpIcon
                                                className={`input-grid__item__icon ${disabled ? 'disabled' : ''}`}
                                                onClick={() => removeOther(index, fieldIndex)}
                                            />
                                        )}
                                    </div>
                                );
                            }
                        }
                    })}
                </div>
                <div className="button">
                    <button className="add-tenant" type="button" disabled={disabled} onClick={() => addOther(index)}>
                        Add Other
                    </button>
                </div>
                {removedFields &&
                    removedFields.map((removedField, removedFieldIndex) => {
                        return (
                            <div className="button" key={removedFieldIndex}>
                                <button
                                    className="add-tenant"
                                    type="button"
                                    disabled={disabled}
                                    onClick={() => addRemovedField(index, removedField)}
                                >
                                    Restore {removedField.label}
                                </button>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default CreateFlkPersonGroup;
