import React, { memo, useState, forwardRef, useImperativeHandle, RefObject } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { cloneDeep, has } from 'lodash';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';

import * as Lease from '../../../../reducers/lease.js';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../../actions/lease.js';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../../selectors/lease/index.js';
import { getThirdPartyPayment } from '../../../../selectors/lease/pmLease.js';

import '../../../../sass/outgoings.scss';
import { ReactComponent as plusIcon } from '../../../../../assets/images/icons/plus.svg';
import { FormRadioGroupTable } from '../../../../components/form/FormRadioGroupTable';
import { FormTextRegular } from '../../../../components/form/FormText.js';
import { FormRadioGroup } from '../../../../components/form/FormRadioGroup.js';
import { OUTGOING_AGENT_TO_PAY, OUTGOING_NOT_APPLICABLE, OUTGOING_OWNER_TO_INSTRUCT } from '../../../../config';
import { DEFINE_ME } from '../../../../types/utilityTypes';
import { ThirdPartyPaymentType } from '../../../../types/pm';
import Button from '../../../../common/components/Button.js';

const THIRD_PARTY_PAYMENT_FORM = 'thirdPartyPaymentsForm';

const radioGroupTableData = {
    labelsTitle: 'Fees & Expenses',
    answers: [
        {
            value: OUTGOING_OWNER_TO_INSTRUCT,
            label: OUTGOING_OWNER_TO_INSTRUCT
        },
        {
            value: OUTGOING_AGENT_TO_PAY,
            label: OUTGOING_AGENT_TO_PAY
        },
        {
            value: OUTGOING_NOT_APPLICABLE,
            label: OUTGOING_NOT_APPLICABLE
        }
    ],
    sections: [
        {
            name: 'ratesUtilitiesStrata',
            label: 'Rates/Utilities/Strata',
            options: [
                {
                    name: 'professionalFees',
                    label: 'Professional Fees (in accordance with Item 7)'
                },
                {
                    name: 'marketingExpenses',
                    label: 'Marketing Expenses (in accordance with Item 8)'
                }
            ],
            // Override main answer options for this section
            answers: [
                {},
                {
                    value: OUTGOING_AGENT_TO_PAY,
                    label: OUTGOING_AGENT_TO_PAY
                }
            ]
        },
        {
            name: 'serviceMaintenance',
            label: 'Service/Maintenance',
            options: [
                {
                    name: 'maintenanceCosts',
                    label: 'Maintenance Costs'
                },
                {
                    name: 'councilRates',
                    label: 'Council Rates'
                },
                {
                    name: 'waterAndSewerageCosts',
                    label: 'Water and Sewerage Costs'
                },
                {
                    name: 'landTax',
                    label: 'Land Tax'
                },
                {
                    name: 'emergencyServicesLevy',
                    label: 'Emergency Services Levy'
                },
                {
                    name: 'swimmingPoolMaintenance',
                    label: 'Swimming Pool Maintenance'
                },
                {
                    name: 'gardeningMaintenance',
                    label: 'Gardening Maintenance'
                },
                {
                    name: 'strataCommunityLevies',
                    label: 'Strata/Community Levies'
                },
                {
                    name: 'testingAndInspection',
                    label: 'Plant, Equipment and Services testing and inspection'
                }
            ]
        },
        {
            name: 'insurance',
            label: 'Insurance',
            options: [
                {
                    name: 'landlordInsurance',
                    label: 'Landlord Insurance'
                },
                {
                    name: 'buildingInsurance',
                    label: 'Building Insurance'
                }
            ]
        }
    ]
};

const initState: ThirdPartyPaymentType = {
    professionalFees: OUTGOING_AGENT_TO_PAY,
    marketingExpenses: OUTGOING_AGENT_TO_PAY,
    otherThirdPartyPayments: []
};

type FormData = ThirdPartyPaymentType & {
    bypassFormValidation?: boolean;
};

interface LayoutProps {
    leaseId: string;
    callbackAfterSubmit: () => void;
    bypassFormValidation?: boolean;
}

interface FormRefObject {
    submitStep: () => void;
    callbackAfterSubmit?: () => void;
    bypassFormValidation?: boolean;
}

const ThirdPartyPaymentLayout = forwardRef<FormRefObject, LayoutProps>((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxThirdPartyPayment: ThirdPartyPaymentType = useSelector(getThirdPartyPayment);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [pmOutgoings, setThirdPartyPayment] = useState(reduxThirdPartyPayment || initState);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(THIRD_PARTY_PAYMENT_FORM)?.dispatchEvent(
                new Event('submit', {
                    cancelable: true,
                    bubbles: true
                })
            );
        }
    }));

    function handleFormDirtyChange(values: FormData, form: DEFINE_ME) {
        if (form.getState().dirty && dirtyStep !== step) {
            dispatch(setDirtyStep(step));
        }
    }

    function submitForm(values: FormData) {
        const refObject = ref as RefObject<FormRefObject>;
        const currentRef = refObject.current;
        values.bypassFormValidation = currentRef?.bypassFormValidation;
        return updateThirdPartyPayment(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef && currentRef.callbackAfterSubmit) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.thirdPartyPayment')) {
                    return error.response.data.errors.thirdPartyPayment;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    }

    function updateThirdPartyPayment(data: FormData) {
        return axios.post(`/api/agency/lease/${props.leaseId}/third-party-payment`, data);
    }

    function addOtherThirdPartyPayment(values: FormData) {
        const newOutgoings = cloneDeep(values);

        if (newOutgoings.otherThirdPartyPayments) {
            newOutgoings.otherThirdPartyPayments.push({
                description: ''
            });
        } else {
            newOutgoings.otherThirdPartyPayments = [];
            newOutgoings.otherThirdPartyPayments.push({
                description: ''
            });
        }
        setThirdPartyPayment(newOutgoings);
    }

    function removeOtherThirdPartyPayment(values: FormData, index: number) {
        const newOutgoings = cloneDeep(values);

        newOutgoings.otherThirdPartyPayments = [
            ...newOutgoings.otherThirdPartyPayments.slice(0, index),
            ...newOutgoings.otherThirdPartyPayments.slice(index + 1)
        ];
        setThirdPartyPayment(newOutgoings);
    }

    return (
        <div className="form-container outgoings mobile-multi-select-wrapper">
            <Form
                onSubmit={submitForm}
                initialValues={pmOutgoings}
                mutators={{
                    ...arrayMutators
                }}
            >
                {({ handleSubmit, form, values }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={THIRD_PARTY_PAYMENT_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={(state: { values: FormData }) => handleFormDirtyChange(state.values, form)}
                            />
                            <h3 className="margin-bottom-1rem">Third Party Payment Authority</h3>
                            <p className="margin-bottom-1rem">
                                What outgoing payments would you like the Landlord to authorise and instruct you to pay
                                from monies received on behalf of the Landlord?*
                            </p>
                            <FormRadioGroupTable data={radioGroupTableData} form={form} validateOnTouch={false} />
                            <FieldArray
                                name="otherThirdPartyPayments"
                                initialValue={pmOutgoings.otherThirdPartyPayments}
                            >
                                {({ fields }) => (
                                    <React.Fragment>
                                        {fields.map((name, index) => {
                                            return (
                                                <div key={`${index}`} className="item-condition">
                                                    <div className="clauses">
                                                        <div className="clauses-header">
                                                            <h3>Outgoing {index + 1}</h3>
                                                            <div
                                                                className="toggle"
                                                                onClick={() =>
                                                                    removeOtherThirdPartyPayment(values, index)
                                                                }
                                                            >
                                                                <span className="span-remove">Remove</span>
                                                            </div>
                                                        </div>
                                                        <div className="formBox-column">
                                                            <FormTextRegular
                                                                name={`otherThirdPartyPayments[${index}].description`}
                                                                label="Description"
                                                                required
                                                            />
                                                            <FormRadioGroup
                                                                label=""
                                                                name={`otherThirdPartyPayments[${index}].type`}
                                                                radioGroupClass="wrapped-radio-group"
                                                                value={values.publicAuction}
                                                                data={[
                                                                    {
                                                                        label: OUTGOING_AGENT_TO_PAY,
                                                                        value: OUTGOING_AGENT_TO_PAY
                                                                    },
                                                                    {
                                                                        label: OUTGOING_OWNER_TO_INSTRUCT,
                                                                        value: OUTGOING_OWNER_TO_INSTRUCT
                                                                    }
                                                                ]}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </React.Fragment>
                                )}
                            </FieldArray>
                            <div className="button add-item-button-container">
                                <Button
                                    className="mobile add-item"
                                    type="button"
                                    onClick={() => addOtherThirdPartyPayment(values)}
                                >
                                    <img src={plusIcon} className="for-sm-modal" />
                                    Add outgoing
                                </Button>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(ThirdPartyPaymentLayout);
