import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import useOnClickOutside from 'use-onclickoutside';
import { MoreHorizSharp, CloseSharp, ArrowBackIosSharp } from '@flk-mui-icons';
import { confirmAlert } from 'react-confirm-alert';

import PopupLeaseMore from '../../components/popups/PopupLeaseMore';
import { HeaderLeft } from '../dashboard/InfoAgreementComponents/HeaderLeft';
import { updateAgreementLoadingState, updateAgreementToRemoveLater } from '../../actions/dashboard';
import * as dashboard from '../../actions/dashboard';
import { getUserInfo, isUserRoleAdmin } from '../../selectors/user';
import { LEASE_TYPE_RESIDENTIAL, NSW_STATE } from '../../config';
import {
    getV2RoutePath,
    isLeaseTypePm,
    isLeaseTypeResidentialTenancy,
    isLeaseTypeSales
} from '../../utils/agreementUtils';

import '../../sass/headerLease.scss';
import { isAccountEnabled } from '../../utils/userUtils';
import axios from 'axios';

/**
 * Tom Somerville:
 * As far as I understand this header is used for not-completed draft agreements only
 */
const HeaderLease = props => {
    const dispatch = useDispatch();
    const [morePopup, setMorePopup] = useState(false);
    const isUserAdmin = useSelector(isUserRoleAdmin);
    const loggedInUser = useSelector(getUserInfo);

    const ref = React.useRef(null);
    useOnClickOutside(ref, () => {
        setMorePopup(false);
    });

    useEffect(() => {
        const close = e => {
            if (e.keyCode === 27) {
                closeModal();
            }
        };
        document.addEventListener('keydown', close);
        return () => document.removeEventListener('keydown', close);
    }, []);

    const toggleMorePopup = () => {
        // Hide all tooltips
        ReactTooltip.hide();
        setMorePopup(!morePopup);
    };

    const handleCloneAgreement = () => {
        const leaseId = props.leaseAllInfo.id;
        const { leaseType, location } = props.leaseAllInfo;
        const canUpgrade =
            (isLeaseTypeSales(leaseType) && location === NSW_STATE) ||
            (isLeaseTypePm(leaseType) && location === NSW_STATE);
        if (canUpgrade) {
            const agreementPath = getV2RoutePath(leaseType);
            const agreementLocation = location.toLowerCase();
            axios
                .post(`/api/v2/app/dashboard/${agreementPath}/${agreementLocation}/${leaseId}/clone`)
                .then(response => {
                    window.location.href = `/apps/${agreementPath}/${agreementLocation}/${response.data.id}`;
                });
        } else if (isLeaseTypeResidentialTenancy(props.leaseAllInfo.leaseType)) {
            props.openCloneResTenForm(leaseId, LEASE_TYPE_RESIDENTIAL);
        } else {
            props.cloneAgreement(leaseId);
        }
        setMorePopup(!morePopup);
    };

    const deleteAgreement = () => {
        confirmAlert({
            title: '',
            message: 'Are you sure want to delete this agreement?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        const leaseId = props.leaseAllInfo.id;
                        props.deleteAgreement(leaseId);
                        setMorePopup(!morePopup);
                        dispatch(updateAgreementLoadingState(leaseId));
                        dispatch(updateAgreementToRemoveLater(leaseId));
                        props.closeModal();
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    };

    const closePopup = () => {
        setMorePopup(false);
        props.closeModal();
    };

    const closeModal = () => {
        const userInitiated = true;
        props.closeModal(userInitiated);
    };

    const showEditTenants = event => {
        event.stopPropagation();
        dispatch(dashboard.openEditTenantsModal(props.leaseAllInfo));
        setMorePopup(false);
    };
    const showEditLandlords = event => {
        event.stopPropagation();
        dispatch(dashboard.openEditLandlordsModal(props.leaseAllInfo));
        setMorePopup(false);
    };
    const showEditVendors = event => {
        event.stopPropagation();
        dispatch(dashboard.openEditVendorsModal(props.leaseAllInfo));
        setMorePopup(false);
    };
    const showExtendExpiry = event => {
        event.stopPropagation();
        dispatch(dashboard.openExtendExpiryModal(props.leaseAllInfo));
        setMorePopup(false);
    };

    const isNone = !morePopup ? 'none' : 'block';

    return (
        <header>
            <div className="close-popup" style={{ display: isNone }} onClick={() => setMorePopup(false)} />
            <div className="header-content">
                {props.leaseAllInfo && props.leaseAllInfo.agent && <HeaderLeft leaseAllInfo={props.leaseAllInfo} />}
                <div className="header-right">
                    <div className="header-right-wrapper" ref={ref}>
                        {isAccountEnabled(loggedInUser) && (
                            <div className="more header-button for-lg-modal" onClick={toggleMorePopup}>
                                <MoreHorizSharp>More</MoreHorizSharp>
                            </div>
                        )}
                        <div onClick={() => closeModal()} className="close header-button for-sm-modal">
                            <ArrowBackIosSharp />
                        </div>
                        <div
                            onClick={() => closeModal()}
                            className="close header-button for-lg-modal"
                            data-test="template model close button"
                        >
                            <CloseSharp>Close</CloseSharp>
                        </div>
                        <PopupLeaseMore
                            leaseType={props.leaseAllInfo.leaseType}
                            cloneAgreement={handleCloneAgreement}
                            deleteAgreement={deleteAgreement}
                            leaseId={props.leaseAllInfo.id}
                            closePopup={closePopup}
                            showEditTenants={showEditTenants}
                            showEditLandlords={showEditLandlords}
                            showEditVendors={showEditVendors}
                            showExtendExpiry={showExtendExpiry}
                            morePopupInfoAgreement={morePopup}
                            agreementStatus={props.leaseAllInfo.status}
                            isUserAdmin={isUserAdmin}
                            isCompletedLease={props.isCompletedLease}
                            lease={props.leaseAllInfo}
                        />
                    </div>
                </div>
            </div>
        </header>
    );
};

export default HeaderLease;
