import React from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import { PatternFormat } from 'react-number-format';
import cx from 'classnames';

import { getError } from '@app/utils/formUtils.js';

type MaskedInputFieldProps = {
    name: string;
    label?: string;
    required?: boolean;
    disabled?: boolean;
    mask: string;
    className?: string;
    validateOnTouch?: boolean;
    containerClassName?: string;
    dataTest?: string;
    labelHint?: string;
};

type MaskedInputProps = FieldRenderProps<string, HTMLElement> & MaskedInputFieldProps;

const MASK_CHAR = '_';
const PATTERN_CHAR = '1';

const MaskedInput: React.FC<MaskedInputProps> = ({
    input,
    meta,
    disabled,
    labelHint,
    label,
    required,
    name,
    className,
    mask,
    validateOnTouch,
    containerClassName,
    dataTest,
    ...rest
}) => {
    return (
        <div
            className={cx('FormInput', containerClassName, {
                'FormInput--error': getError(meta, validateOnTouch)
            })}
        >
            <div className={input.value ? 'form-group on' : 'form-group'}>
                <label className={cx({ FormError: !!getError(meta, validateOnTouch), disabled: disabled })}>
                    {label}
                    {required ? '*' : null}
                </label>
                {labelHint && <label className={'label-hint'}>{labelHint}</label>}
                <PatternFormat
                    {...input}
                    {...rest}
                    type="text"
                    placeholder={meta.active ? mask.replace(new RegExp(PATTERN_CHAR, 'g'), MASK_CHAR) : undefined}
                    format={mask}
                    className={cx(className, 'visible-input-placeholder')}
                    name={name}
                    disabled={disabled}
                    patternChar={PATTERN_CHAR}
                    mask={MASK_CHAR}
                    data-test={dataTest}
                />
                {getError(meta, validateOnTouch) && (
                    <span className={'FormError'}>{getError(meta, validateOnTouch)}</span>
                )}
            </div>
        </div>
    );
};

export const FormMaskedText: React.FC<MaskedInputFieldProps> = ({
    name,
    label,
    required,
    disabled,
    mask,
    className,
    validateOnTouch,
    containerClassName,
    dataTest
}) => {
    return (
        <Field
            name={name}
            label={label}
            required={required}
            disabled={disabled}
            mask={mask}
            className={className}
            validateOnTouch={validateOnTouch}
            containerClassName={containerClassName}
            dataTest={dataTest}
            component={MaskedInput}
            parse={value => (value === '' ? null : value)} // this is needed so it will submit empty fields as a string '' see https://github.com/final-form/react-final-form/issues/130
        />
    );
};
