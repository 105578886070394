import React, { memo, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { cloneDeep, has } from 'lodash';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';

import * as Lease from '../../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../../selectors/lease';

import { TenantPaymentGroup } from '../../../../components/lease/mainScreen/common/pm/TenantPaymentGroup';
import { FormRadioGroup } from '../../../../components/form/FormRadioGroup';
import { getValidationErrors, getTenantPayments } from '../../../../selectors/lease/pmLease';
import { FormResponsiveNumbers } from '../../../../components/form/responsive/FormResponsiveNumbers';
import '../../../../sass/tenantPayments.scss';
import { ReactComponent as plusIcon } from '../../../../../assets/images/icons/plus.svg';
import { booleanToString } from '../../../../utils/agreementUtils';

const PM_TENANT_PAYMENTS_FORM = 'tenantPaymentsForm';
const initState = {
    electricity: 'no',
    electricityProportion: 100,
    gas: 'no',
    gasProportion: 100,
    telephone: 'no',
    telephoneProportion: 100,
    serviceList: []
};

const TenantPayments = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxTenantPayments = useSelector(getTenantPayments);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [tenantPayments, setTenantPayments] = useState(reduxTenantPayments || initState);

    useEffect(() => {
        if (reduxTenantPayments) {
            let clonedTenantPayments = cloneDeep(reduxTenantPayments);
            clonedTenantPayments = booleanToString(clonedTenantPayments);
            setTenantPayments(clonedTenantPayments);
        }
    }, [reduxTenantPayments]);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(PM_TENANT_PAYMENTS_FORM).dispatchEvent(
                new Event('submit', {
                    cancelable: true,
                    bubbles: true
                })
            );
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updateTenantPayments(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.tenantPayments')) {
                    return error.response.data.errors.tenantPayments;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const updateTenantPayments = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/tenant-payments`, data);
    };

    const removeOtherService = (values, index) => {
        let newTenantPayments = cloneDeep(values);

        newTenantPayments.serviceList = [
            ...newTenantPayments.serviceList.slice(0, index),
            ...newTenantPayments.serviceList.slice(index + 1)
        ];
        setTenantPayments(newTenantPayments);
    };

    return (
        <div className="form-container tenant-payment">
            <Form
                onSubmit={submitForm}
                initialValues={tenantPayments}
                mutators={{
                    ...arrayMutators
                }}
            >
                {({
                    handleSubmit,
                    values,
                    form,
                    form: {
                        mutators: { push }
                    }
                }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={PM_TENANT_PAYMENTS_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <div>
                                <h4>The tenant must pay for the following services:</h4>
                                <p>(If Yes, and not separately metered, specify proportion.)</p>
                                <FormRadioGroup
                                    label="Electricity"
                                    name="electricity"
                                    className="tenant-payment-radio"
                                    isYesNoGroup={true}
                                    value={values.electricity}
                                    data={[
                                        {
                                            label: 'Yes',
                                            className: 'electricity-answer-yes',
                                            id: 'electricity-answer-yes',
                                            value: 'yes'
                                        },
                                        {
                                            label: 'No',
                                            className: 'electricity-answer-no',
                                            id: 'electricity-answer-no',
                                            value: 'no'
                                        }
                                    ]}
                                />
                                {values.electricity === 'yes' && (
                                    <FormResponsiveNumbers
                                        name="electricityProportion"
                                        label="Proportion"
                                        options={[25, 50, 100]}
                                        otherLabel="Other"
                                        postFix="%"
                                        form={form}
                                    />
                                )}
                                <FormRadioGroup
                                    label="Gas"
                                    name="gas"
                                    className="tenant-payment-radio"
                                    isYesNoGroup={true}
                                    value={values.gas}
                                    data={[
                                        {
                                            label: 'Yes',
                                            className: 'gas-answer-yes',
                                            id: 'gas-answer-yes',
                                            value: 'yes'
                                        },
                                        {
                                            label: 'No',
                                            className: 'gas-answer-no',
                                            id: 'gas-answer-no',
                                            value: 'no'
                                        }
                                    ]}
                                />
                                {values.gas === 'yes' && (
                                    <FormResponsiveNumbers
                                        name="gasProportion"
                                        label="Proportion"
                                        options={[25, 50, 100]}
                                        otherLabel="Other"
                                        postFix="%"
                                        form={form}
                                    />
                                )}
                                <FormRadioGroup
                                    label="Telephone"
                                    name="telephone"
                                    className="tenant-payment-radio"
                                    isYesNoGroup={true}
                                    value={values.telephone}
                                    data={[
                                        {
                                            label: 'Yes',
                                            className: 'telephone-answer-yes',
                                            id: 'telephone-answer-yes',
                                            value: 'yes'
                                        },
                                        {
                                            label: 'No',
                                            className: 'telephone-answer-no',
                                            id: 'telephone-answer-no',
                                            value: 'no'
                                        }
                                    ]}
                                />
                                {values.telephone === 'yes' && (
                                    <FormResponsiveNumbers
                                        name="telephoneProportion"
                                        label="Proportion"
                                        options={[25, 50, 100]}
                                        otherLabel="Other"
                                        postFix="%"
                                        form={form}
                                    />
                                )}
                            </div>
                            <FieldArray name="serviceList" initialValue={tenantPayments.serviceList}>
                                {({ fields }) => (
                                    <React.Fragment>
                                        {fields.map((name, index) => {
                                            return (
                                                <TenantPaymentGroup
                                                    index={index}
                                                    key={`${index}`}
                                                    removeOtherService={index => removeOtherService(values, index)}
                                                    form={form}
                                                />
                                            );
                                        })}
                                    </React.Fragment>
                                )}
                            </FieldArray>
                            <div className="button">
                                <button
                                    className="mobile add-item"
                                    type="button"
                                    onClick={() =>
                                        push('serviceList', {
                                            description: '',
                                            proportion: 100
                                        })
                                    }
                                >
                                    <img src={plusIcon} className="for-sm-modal" />
                                    Add service
                                </button>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default TenantPayments;
