import React, { memo, useImperativeHandle, useState, forwardRef } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { FormTextMultiline } from '../../../components/form/FormText';
import { CheckboxCheck } from '../../../components/form/FormCheckboxCheck';

import '../../../sass/inclusions.scss';
import * as agreementUtils from '../../../utils/agreementUtils';
import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../actions/lease';
import { useDispatch, useSelector } from 'react-redux';
import { getDirtyStep, getLeaseType, getStep, getInclusions, getLocation } from '../../../selectors/lease';
import * as Lease from '../../../reducers/lease';
import axios from 'axios';
import { has } from 'lodash';
import { FormRadioGroup } from '../../../components/form/FormRadioGroup';
import { NO_LABEL, QLD_STATE, YES_LABEL } from '../../../config';
const INCLUSIONS_FORM = 'inclusionsForm';

const Inclusions = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);
    const leaseType = useSelector(getLeaseType);
    const location = useSelector(getLocation);
    const reduxInclusions = useSelector(getInclusions);

    let [formState, setFormState] = useState({
        ...reduxInclusions,
        inclusions: reduxInclusions.inclusions,
        isFurnished: reduxInclusions.isFurnished
    });

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(INCLUSIONS_FORM).dispatchEvent(
                new Event('submit', {
                    cancelable: true,
                    bubbles: true
                })
            );
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const updateInclusions = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/inclusions`, data);
    };

    const submitForm = values => {
        /**
         * Store the ref of the component so it can be used in this function
         * We need this otherwise the promise below doesn't have access to the ref
         */
        const currentRef = ref.current;
        /**
         * Grab the bypassFormValidation that was set from the parent component: LeaseAgreementForm.js
         */
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updateInclusions(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_INCLUSIONS_SUCCESS)).then(() => {
                    /**
                     * Callback after submit this form so that the parent component can take an action
                     */
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.inclusions')) {
                    return error.response.data.errors.inclusions;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    return (
        <div className="inclusions">
            <Form onSubmit={submitForm} initialValues={formState}>
                {({ handleSubmit, form, values }) => {
                    return (
                        <form id={INCLUSIONS_FORM} onSubmit={handleSubmit} noValidate>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <p className="help-text big with-margin">List all inclusions (comma-separated)</p>
                            <FormTextMultiline name="inclusions" label="Inclusions" />
                            {agreementUtils.isLeaseTypeResidentialTenancy(leaseType) && (
                                <CheckboxCheck
                                    className={'is-furnished'}
                                    name="isFurnished"
                                    label="This property is furnished"
                                />
                            )}
                            {agreementUtils.isLeaseTypeResidentialTenancy(leaseType) && location === QLD_STATE && (
                                <>
                                    <FormRadioGroup
                                        label="Does the rental premise have additional repair orders or inclusions:"
                                        name="hasAdditionalRepairOrders"
                                        className="full-width"
                                        data={[
                                            {
                                                label: YES_LABEL,
                                                value: YES_LABEL
                                            },
                                            {
                                                label: NO_LABEL,
                                                value: NO_LABEL
                                            }
                                        ]}
                                    />
                                    {values.hasAdditionalRepairOrders === YES_LABEL && (
                                        <FormTextMultiline
                                            name="currentRepairOrdersDetails"
                                            label="Details of current repair orders for the rental premises or inclusions"
                                            required
                                        />
                                    )}
                                </>
                            )}
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(Inclusions);
