import React, { memo, useState, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { has } from 'lodash';
import arrayMutators from 'final-form-arrays';

import * as Lease from '../../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../../selectors/lease';
import { getPmRepairs } from '../../../../selectors/lease/pmLease';

import { FormTextCurrency } from '../../../../components/form/FormText';

import '../../../../sass/contacts.scss';

const PM_REPAIRS_FORM = 'pmRepairsForm';
const initState = {
    expenditureLimit: ''
};

function PmContactsLayout(props, ref) {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxPmRepairs = useSelector(getPmRepairs);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [pmRepairs] = useState(reduxPmRepairs || initState);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(PM_REPAIRS_FORM).dispatchEvent(
                new Event('submit', {
                    cancelable: true,
                    bubbles: true
                })
            );
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updatePmRepairs(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.pmRepairs')) {
                    return error.response.data.errors.pmRepairs;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const updatePmRepairs = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/repairs`, data);
    };

    return (
        <div className="contacts">
            <Form onSubmit={submitForm} initialValues={pmRepairs} mutators={{ ...arrayMutators }}>
                {({ handleSubmit, form }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={PM_REPAIRS_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <FormTextCurrency
                                name="expenditureLimit"
                                label={'Expenditure limit on cost of repairs before approval required'}
                                required
                            />
                        </form>
                    );
                }}
            </Form>
        </div>
    );
}

export default memo(forwardRef(PmContactsLayout));
