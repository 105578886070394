import React, { memo, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { cloneDeep, has } from 'lodash';

import * as Lease from '../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../selectors/lease';
import { getMarketingAndAdvertising } from '../../../selectors/lease/pmLease';
import * as agreementUtils from '../../../utils/agreementUtils';
import { QLD_STATE, NSW_STATE } from '../../../config';

import SalesNswMarketingAndAdvertising from '../sales/NSW/MarketingAndAdvertising';
import SalesQldMarketingAndAdvertising from '../sales/QLD/MarketingAndAdvertising';
import PmNswMarketingAndAdvertising from '../pm/NSW/MarketingAndAdvertising';
import PmQldMarketingAndAdvertising from '../pm/QLD/MarketingAndAdvertising';

import '../../../sass/marketingAndAdvertising.scss';

const MARKETING_ADVERTISING_FORM = 'marketingAndAdvertisingForm';
const emptyMarketingPlan = {
    description: '',
    cost: ''
};
const initState = {
    marketingEstimate: '',
    marketingPayable: '',
    isMarketingPlan: false,
    marketingPlans: [],
    isAgencyDatabase: false,
    isSignboard: false,
    isListWebsites: false,
    websiteList: '',
    isOther: false,
    otherText: '',
    authorisedAdvertisingAmount: '$'
};

const MarketingAndAdvertising = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxMarketingAndAdvertising = useSelector(getMarketingAndAdvertising);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [marketingAndAdvertising, setMarketingAndAdvertising] = useState(reduxMarketingAndAdvertising || initState);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(MARKETING_ADVERTISING_FORM).dispatchEvent(
                new Event('submit', {
                    cancelable: true,
                    bubbles: true
                })
            );
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (values.isMarketingPlan) {
            calculateRunningCosts(values);
        }
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updateMarketingAndAdvertising(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.marketingAndAdvertising')) {
                    return error.response.data.errors.marketingAndAdvertising;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const updateMarketingAndAdvertising = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/marketing-and-advertising`, data);
    };

    useEffect(() => {
        if (marketingAndAdvertising.isMarketingPlan) {
            calculateRunningCosts(marketingAndAdvertising);
        }
    }, []);

    const removeItem = (values, index) => {
        const marketingPlans = cloneDeep(values.marketingPlans);
        setMarketingAndAdvertising({
            ...values,
            marketingPlans: [...marketingPlans.slice(0, index), ...marketingPlans.slice(index + 1)]
        });
    };

    const handleIsPlanSelect = (values, isMarketingPlan) => {
        if (isMarketingPlan && values.marketingPlans.length === 0) {
            addPlan(values);
        } else {
            removePlans(values);
        }
    };

    const removePlans = values => {
        setMarketingAndAdvertising({
            ...values,
            isMarketingPlan: false,
            marketingPlans: [],
            marketingEstimate: 0
        });
    };

    const addPlan = values => {
        setMarketingAndAdvertising({
            ...values,
            isMarketingPlan: true,
            marketingPlans: [...values.marketingPlans, { ...emptyMarketingPlan }],
            marketingEstimate: 0
        });
    };

    const calculateRunningCosts = formValues => {
        let totalCost = 0;
        if (formValues.marketingPlans && formValues.marketingPlans.length > 0) {
            formValues.marketingPlans.forEach(plan => {
                if (plan.cost) {
                    totalCost += parseFloat(plan.cost);
                }
            });
        }
        if (formValues.marketingPlans) {
            setMarketingAndAdvertising({ ...formValues, marketingEstimate: totalCost.toFixed(2) });
        }
    };

    return (
        <div className="form-container mobile-multi-select-wrapper">
            <Form onSubmit={submitForm} initialValues={marketingAndAdvertising}>
                {({ handleSubmit, form, values }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={MARKETING_ADVERTISING_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            {agreementUtils.isLeaseTypeSales(leaseType) && location === NSW_STATE && (
                                <SalesNswMarketingAndAdvertising
                                    values={values}
                                    handleIsPlanSelect={handleIsPlanSelect}
                                    removeItem={removeItem}
                                    addPlan={addPlan}
                                    marketingAndAdvertising={marketingAndAdvertising}
                                />
                            )}
                            {agreementUtils.isLeaseTypeSales(leaseType) && location === QLD_STATE && (
                                <SalesQldMarketingAndAdvertising
                                    values={values}
                                    handleIsPlanSelect={handleIsPlanSelect}
                                    removeItem={removeItem}
                                    addPlan={addPlan}
                                    marketingAndAdvertising={marketingAndAdvertising}
                                />
                            )}
                            {agreementUtils.isLeaseTypePm(leaseType) && location === NSW_STATE && (
                                <PmNswMarketingAndAdvertising values={values} />
                            )}
                            {agreementUtils.isLeaseTypePm(leaseType) && location === QLD_STATE && (
                                <PmQldMarketingAndAdvertising />
                            )}
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(MarketingAndAdvertising);
