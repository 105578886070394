import React from 'react';
import { Table, Th, Td, Thead, Tr } from 'reactable';
import LeaseDropdown from '../../../dropdowns/LeaseDropdown';
import AgentLogo from '../../../AgentLogo';
import renewalsIcon from '../../../../../assets/images/icons/renewals-icon.svg?url';
import { formatDateStandard, getAgencyTimezoneFromUser } from '../../../../utils/dateUtils';
import { getUserInfo } from '../../../../selectors/user';
import { useSelector } from 'react-redux';
import { isAccountEnabled } from '../../../../utils/userUtils';

const TableCancelled = ({ leaseStatus, agreementList, showModalInfoAgreement }) => {
    const loggedInUser = useSelector(getUserInfo);
    const tableHead = () => {
        return (
            <Thead>
                <Th column="agentLogo"> </Th>
                <Th column="isRenewal"> </Th>
                <Th column="address">address</Th>
                <Th column="tenants">tenant(s)</Th>
                <Th column="cancellationDate">Cancellation Date</Th>
                <Th column="startDate">lease start date</Th>
                <Th column="action">&nbsp;</Th>
            </Thead>
        );
    };

    return (
        <Table>
            {tableHead()}
            {agreementList?.map((lease, key) => {
                const className = lease.isLoading ? 'loading' : '';
                return (
                    <Tr
                        key={key}
                        className={`showmodal ${className}`}
                        onClick={() => {
                            showModalInfoAgreement(lease);
                        }}
                    >
                        <Td column="agentLogo">
                            <AgentLogo agent={lease.agent} />
                        </Td>
                        {lease.isRenewal && (
                            <Td column="isRenewal">
                                <img src={renewalsIcon} />
                            </Td>
                        )}
                        <Td column="address">
                            <b>{lease.address}</b>
                        </Td>
                        <Td column="tenants">{lease.tenant ? lease.tenant.tenantsListText : '-'}</Td>
                        <Td column="cancellationDate">
                            {formatDateStandard(lease.cancelledDate, getAgencyTimezoneFromUser(loggedInUser))}
                        </Td>
                        <Td column="startDate">
                            {lease?.term?.startDate
                                ? formatDateStandard(lease.term.startDate, getAgencyTimezoneFromUser(loggedInUser))
                                : '-'}
                        </Td>
                        {isAccountEnabled(loggedInUser) && (
                            <Td column="action">
                                <div className="action">
                                    <LeaseDropdown lease={lease} leaseStatus={leaseStatus}>
                                        <div className="more-action" />
                                    </LeaseDropdown>
                                    <span className="arrow-right" />
                                </div>
                            </Td>
                        )}
                    </Tr>
                );
            })}
        </Table>
    );
};

export default TableCancelled;
