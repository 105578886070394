import React, { useState, useEffect } from 'react';
import { LockSharp, LockOpenSharp } from '@flk-mui-icons';
import ModalDialog from './../../common/components/ModalDialog';
import { getTeam } from './../../selectors/agency';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import Button from '../../common/components/Button';
import axios from 'axios';
import { addOrReplaceDocumentInList, setCurrentDocument } from '../../actions/document';
import MultiSelect from '../../common/components/MultiSelect';
import { isEmpty } from 'lodash';
import { isDocumentStatusDraft } from '../../utils/generalUtils';

const AccessListModal = ({ doc, isOpenLockedModal, closeModal, saveDoc }) => {
    const dispatch = useDispatch();

    const team = useSelector(getTeam);
    const [isLocked, setIsLocked] = useState(doc.locked);
    const [selected, setSelected] = useState(isEmpty(doc.visibilityList) ? [doc.agent.id] : doc.visibilityList);
    const [isSending, setIsSending] = useState(false);
    const [isSendingSuccess, setIsSendingSuccess] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (doc) {
            setSelected(isEmpty(doc.visibilityList) ? [doc.agent.id] : doc.visibilityList);
        }
    }, [doc]);

    const changeLockState = data => {
        axios
            .put(`/api/document/${doc.id}/visibility`, data)
            .then(response => {
                setIsSending(false);
                setIsSendingSuccess(true);
                dispatch(addOrReplaceDocumentInList(response.data.doc));
                dispatch(setCurrentDocument(response.data.doc));
            })
            .catch(err => {
                setIsSending(false);
                setIsSendingSuccess(false);
                setError('Something went wrong. Please try again.');
            });
    };

    const handleSubmit = () => {
        setIsSending(true);
        setIsSendingSuccess(false);
        setError(false);
        const data = { locked: isLocked, visibilityList: selected };
        if (isDocumentStatusDraft(doc.status)) {
            saveDoc().then(() => {
                changeLockState(data);
            });
        } else {
            changeLockState(data);
        }
    };

    const customSelectionBoxHeader = selected => {
        let teamMemberNumber = selected.length;
        if (teamMemberNumber > 1) {
            return teamMemberNumber + ' team members selected';
        } else if (teamMemberNumber > 0) {
            return teamMemberNumber + ' team member selected';
        }
        return 'Select team members';
    };

    const handleMultiSelection = teamMembers => {
        if (teamMembers.length === 0) {
            setSelected([doc.agent.id]);
        } else {
            setSelected(teamMembers);
        }
    };

    const getAgentNamesByIds = () => {
        const optionsList = [];
        team.forEach(value => {
            if (value.id != doc.agent.id) {
                optionsList.push({
                    value: value.id,
                    label: value.fullName,
                    disabled: false
                });
            }
        });
        optionsList.unshift({
            value: doc.agent.id,
            label: doc.agent.fullName,
            disabled: true
        });
        return optionsList;
    };
    const options = getAgentNamesByIds();

    return (
        <ModalDialog
            title="Visibility"
            isOpen={isOpenLockedModal}
            closeModal={() => {
                setIsSendingSuccess(false);
                setError('');
                closeModal();
            }}
            className="visibility-modal"
            bodyClassName="visibility-modal__body"
            zIndex={10}
        >
            <Form onSubmit={values => handleSubmit(values)}>
                {({ handleSubmit }) => {
                    return (
                        <form noValidate onSubmit={values => handleSubmit(values)} id="visibility-form">
                            <div className="questions-container__header">
                                <div
                                    className={`questions-container__header--select ${isLocked ? 'active' : ''}`}
                                    onClick={() => setIsLocked(true)}
                                >
                                    <span className="questions-container__header--select-item">
                                        <LockSharp />
                                    </span>
                                    <div>Locked</div>
                                </div>
                                <div
                                    className={`questions-container__header--select ${isLocked ? '' : 'active'}`}
                                    onClick={() => setIsLocked(false)}
                                >
                                    <span className="questions-container__header--select-item">
                                        <LockOpenSharp />
                                    </span>
                                    <div>Unlocked</div>
                                </div>
                            </div>
                            {isLocked && (
                                <div>
                                    <p>Please select team members who can access this document</p>
                                    <br />
                                    {team && team.length > 0 && (
                                        <div className="selectAgents">
                                            <MultiSelect
                                                options={options}
                                                selected={selected}
                                                onSelectedChanged={handleMultiSelection}
                                                selectAllLabel="All members"
                                                valueRenderer={customSelectionBoxHeader}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                            <div className="button-group">
                                {error && <p className="error-message">{error}</p>}
                                {isSendingSuccess && (
                                    <p className="button-result savestatus-saved">
                                        Updated successfully
                                        <span className="icon savestatus-icon" />
                                    </p>
                                )}
                                <Button type="submit" primary className="save-button" disabled={isSending}>
                                    {isSending && <span className="savestatus-spinner-await-completion" />}
                                    Save
                                </Button>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </ModalDialog>
    );
};

export default AccessListModal;
