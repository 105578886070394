import React from 'react';
import { Form } from 'react-final-form';
import Modal from 'react-modal';
import { ReactComponent as FlkLogo } from '../../../../../../../assets/images/logo.svg';
import Button from '../../../../../../common/components/Button';
import Card, { CardStyles } from '../../../../../../common/components/cards/Card';
import * as validators from '../../../../../../components/Validate';
import { FormEmail } from '../../../../../../components/form/FormEmail';
import { FormPassword } from '../../../../../../components/form/FormPassword';
import { FormTextRegular } from '../../../../../../components/form/FormText';

import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { has } from 'lodash';
import Icon, { Icons } from '../../../../../../common/components/Icon';
import { ONBOARDING_TRACKING_PREFIX } from '../../../../../../constants/constants';
import styles from './SignupModal.module.scss';

const SIGNUP_BUTTON_TEXT = 'Email verification link';

const VerifyEmailMessage = ({ emailAddress, docId }) => {
    const resendVerifyEmail = useMutation(() => {
        return axios.post(`/api/onboarding/custom-document/${docId}/resend-verify-email`);
    });

    return (
        <div className={styles.verifyContainer} data-automation-id={`${ONBOARDING_TRACKING_PREFIX}-verify`}>
            <FlkLogo className={styles.flkLogo} />
            <strong>Profile created! Lastly, please check your inbox and verify your email address to finish.</strong>
            <div>
                Email sent to <strong>{emailAddress}</strong>
            </div>
            <Button primary loading={resendVerifyEmail.isLoading} onClick={() => resendVerifyEmail.mutate()} data-automation-id={`${ONBOARDING_TRACKING_PREFIX}-verify-resend-button`}>
                Resend Email
            </Button>
        </div>
    );
};

const SignupForm = ({ onSubmit }) => {
    const validate = values => {
        const errors = {};
        if (!values.firstName) {
            errors.firstName = 'First name is required';
        } else if (!validators.isCorrectNameOrSurname(values.firstName)) {
            errors.firstName = 'First name is not valid';
        }

        if (!values.secondName) {
            errors.secondName = 'Last name is required';
        } else if (!validators.isCorrectNameOrSurname(values.secondName)) {
            errors.secondName = 'Last name is not valid';
        }

        if (!values.email) {
            errors.email = 'Email is required';
        }

        if (!values.password) {
            errors.password = 'Password is required';
        }

        if (!values.repeatPassword) {
            errors.repeatPassword = 'Confirm password is required';
        }
        if (values.password && values.repeatPassword && values.password !== values.repeatPassword) {
            errors.repeatPassword = 'Password and confirm password do not match';
        }
        return errors;
    };

    return (
        <Form onSubmit={onSubmit} validate={validate}>
            {({ handleSubmit, submitting }) => (
                <form id="onboardingForm" onSubmit={handleSubmit} className={styles.signUpForm} noValidate data-automation-id={`${ONBOARDING_TRACKING_PREFIX}-signup-form`}>
                    <h1 className={styles.heading}>Last step! Verify yourself to send your document(s).</h1>
                    <FormTextRegular
                        containerClassName={styles.formField}
                        name="firstName"
                        label="First name"
                        required
                        validateOnTouch
                    />
                    <FormTextRegular
                        containerClassName={styles.formField}
                        name="secondName"
                        label="Last name"
                        required
                        validateOnTouch
                    />
                    <FormEmail
                        containerClassName={styles.formField}
                        name="email"
                        label="Email"
                        required
                        validateOnTouch
                    />
                    <FormPassword
                        containerClassName={styles.formField}
                        name="password"
                        label="Password"
                        validateOnTouch
                        required
                    />
                    <FormPassword
                        containerClassName={styles.formField}
                        name="repeatPassword"
                        label="Confirm password"
                        validateOnTouch
                        required
                    />
                    <div className={styles.terms}>
                        By clicking '{SIGNUP_BUTTON_TEXT}'' you agree to our{' '}
                        <a
                            href="https://www.flkitover.com/terms-conditions/"
                            rel="noopener noreferrer"
                            target="_blank"
                            className={styles.link}
                        >
                            terms of service
                        </a>{' '}
                        and{' '}
                        <a
                            href="https://www.flkitover.com/privacy-policy/"
                            rel="noopener noreferrer"
                            target="_blank"
                            className={styles.link}
                        >
                            privacy policy
                        </a>
                    </div>
                    <Button type="submit" primary className={styles.formButton} loading={submitting} data-automation-id={`${ONBOARDING_TRACKING_PREFIX}-signup-form-submit-button`}>
                        {SIGNUP_BUTTON_TEXT}
                    </Button>
                </form>
            )}
        </Form>
    );
};

const SignUpStep = {
    SIGNUP: 0,
    VERIFY_EMAIL: 1
};

const SignupModal = ({ isOpen, close, docId }) => {
    const [signupStep, setSignupStep] = React.useState(SignUpStep.SIGNUP);
    const [email, setEmail] = React.useState('');

    const signup = useMutation(
        data => {
            return axios.post(`/api/onboarding/custom-document/${docId}/register-and-verify-email`, data);
        },
        {
            onSuccess: (_, data) => {
                setSignupStep(SignUpStep.VERIFY_EMAIL);
                setEmail(data.email);
            }
        }
    );

    const renderContents = () => {
        switch (signupStep) {
            case SignUpStep.SIGNUP:
                return (
                    <>
                        <Button className={styles.closeButton} onClick={close} data-automation-id={`${ONBOARDING_TRACKING_PREFIX}-signup-form-close-button`}>
                            <Icon className={styles.closeIcon} icon={Icons.CLOSE} />
                        </Button>
                        <SignupForm
                            onSubmit={async values => {
                                const valuesWithCompanyName = {
                                    ...values,
                                    companyName: `${values.firstName} ${values.secondName}`
                                };
                                try {
                                    await signup.mutateAsync(valuesWithCompanyName);
                                } catch (error) {
                                    let err = {};
                                    if (has(error, 'response.data.errors')) {
                                        err = error.response.data.errors;
                                    }
                                    return err;
                                }
                            }}
                        />
                    </>
                );
            case SignUpStep.VERIFY_EMAIL:
                return <VerifyEmailMessage emailAddress={email} docId={docId} />;
        }
    };

    return (
        <Modal
            overlayClassName={styles.signupModalOverlay}
            className={styles.signupModal}
            isOpen={isOpen}
            onRequestClose={close}
        >
            <Card style={CardStyles.SQUARE} className={styles.successNotification}>
                <Icon className={styles.successIcon} icon={Icons.CHECK} /> Document(s) ready to send...
            </Card>
            <Card className={styles.signupContainer}>{renderContents()}</Card>
        </Modal>
    );
};

export default SignupModal;
