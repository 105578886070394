import React from 'react';
import { getId } from '@app/utils/getId';
import { useQuery } from '@tanstack/react-query';
import { connect } from 'react-redux';
import { openResendLandlordInformationStatementModal } from '../../../../../actions/resendLandlordInformationStatement';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import AgreementSignatures from '../../../../../containers/dashboard/AgreementSignatures';
import {
    NSW_STATE,
    QLD_STATE,
    VIC_STATE,
    SA_STATE,
    LANDLORD_INFORMATION_STATEMENT_STATUS_NOT_SENT,
    LANDLORD_INFORMATION_STATEMENT_READY_TO_BE_SENT
} from '../../../../../config';
import { getSignorLinks } from '../../../../../actions/lease';
import '../../../../../sass/infoAgreement.scss';
import {
    LEASE_STATUS_AWAITING_COMPLETION,
    LEASE_STATUS_AWAITING_RENEWAL,
    LEASE_STATUS_DRAFT,
    LEASE_STATUS_SENT_SIGNING
} from '../../../../../actions/dashboard';
import {
    renderLandlordInformationStatementStatus,
    isCompletedAndValidated,
    getResTenStepNo
} from '../../../../../utils/agreementUtils';
import * as PropTypes from 'prop-types';
import { getResTenLabel } from '../../../../../utils/labelUtils';
import { signorLinkExists } from '../../../../../utils/signorUtils';
import CheckCircleSharpIcon from '@flk-mui-icons/CheckCircleSharp';
import CancelIcon from '@flk-mui-icons/CancelSharp';
import RenewalIntentionIndicator from '../../../agreementList/tables/TableRenewals/RenewalIntentionIndicator';
import { getResponse, NO_RESPONSE } from '../../../../../utils/renewalUtils';
import { phoneToSpacedPhone } from '../../../../../utils/generalUtils';
import AgreementResidentialComplianceChecklist from './AgreementResidentialComplianceChecklist.tsx';
import useClipboard from '../../../../../hooks/useClipboard.ts';

const LandlordResidence = props => {
    if (!props.landlordPerson.theLandlordResidesIn) {
        return false;
    }
    return (
        <React.Fragment>
            {props.leaseAllInfo.location === NSW_STATE && (
                <div className="data-string">
                    <span className="data-contact">Landlord resides in:</span>
                    <p className="text">
                        {props.landlordPerson.theLandlordResidesIn === 'NSW'
                            ? props.landlordPerson.theLandlordResidesIn
                            : props.landlordPerson.otherResidence}
                    </p>
                </div>
            )}
        </React.Fragment>
    );
};

LandlordResidence.propTypes = {
    leaseAllInfo: PropTypes.any,
    landlordPerson: PropTypes.any
};

function AgreementResidentialSignors(props) {
    const renderLandlordOtherDetails = (leaseAllInfo, landlordPerson) => {
        if (landlordPerson.isCorporation) {
            return (
                <React.Fragment>
                    {landlordPerson.postcode && (
                        <div className="data-string">
                            <span className="data-contact">Postcode</span>
                            <p className="text">{landlordPerson.postcode}</p>
                        </div>
                    )}
                    {landlordPerson.ABN && (
                        <div className="data-string">
                            <span className="data-contact">ABN</span>
                            <p className="text">{landlordPerson.ABN}</p>
                        </div>
                    )}
                    {landlordPerson.ACN && (
                        <div className="data-string">
                            <span className="data-contact">ACN</span>
                            <p className="text">{landlordPerson.ACN}</p>
                        </div>
                    )}
                    {landlordPerson.other && (
                        <div className="data-string">
                            <span className="data-contact">Other</span>
                            <p className="text">{landlordPerson.other}</p>
                        </div>
                    )}
                    <LandlordResidence leaseAllInfo={leaseAllInfo} landlordPerson={landlordPerson} />
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    {leaseAllInfo.location === NSW_STATE && landlordPerson.phone && (
                        <div className="data-string">
                            <span className="data-contact">Landlord contact details:</span>
                            {landlordPerson.phone && <p className="text">{landlordPerson.phone}</p>}
                        </div>
                    )}
                    {leaseAllInfo.location === QLD_STATE && (
                        <div className="data-string">
                            <span className="data-contact">Ph</span>
                            <p className="text">{leaseAllInfo.landlord.phone}</p>
                        </div>
                    )}
                    <LandlordResidence leaseAllInfo={leaseAllInfo} landlordPerson={landlordPerson} />
                </React.Fragment>
            );
        }
    };
    const canResendLandlordStatement = leaseAllInfo => {
        if (leaseAllInfo.landlord) {
            // if Manually Acknowledged can't resend
            let canResend = !leaseAllInfo.landlord.informationStatementManuallyAcknowledged;
            // if all LL comfirmed then can't resend
            if (canResend) {
                canResend = false;
                leaseAllInfo.landlord.persons.forEach(person => {
                    if (person.landlordInformationStatementStatus !== 'confirmed') {
                        canResend = true;
                    }
                });
            }
            return canResend;
        }
        return false;
    };
    const renderLandlordInformationLastSent = (leaseAllInfo, item) => {
        return (
            item.landlordInformationStatementStatusSent && (
                <div className="data-string">
                    <span className="data-contact">Last sent:</span>
                    <p className="text">
                        {item.landlordInformationStatementStatusSentFormatted} (
                        {item.sendMethod === 'sms' ? item.mobile : item.email})
                    </p>
                </div>
            )
        );
    };

    const hasSentLandlordStatement = leaseAllInfo => {
        if (leaseAllInfo.landlord) {
            let isSent = false;
            leaseAllInfo.landlord.persons.forEach(person => {
                if (
                    person.landlordInformationStatementStatus !== LANDLORD_INFORMATION_STATEMENT_STATUS_NOT_SENT &&
                    person.landlordInformationStatementStatus !== LANDLORD_INFORMATION_STATEMENT_READY_TO_BE_SENT
                ) {
                    isSent = true;
                }
            });
            return isSent;
        }
        return false;
    };
    const { leaseAllInfo } = props;
    const shouldShowSignatures = [LEASE_STATUS_SENT_SIGNING, LEASE_STATUS_AWAITING_COMPLETION].includes(
        leaseAllInfo.status
    );
    const { data: signorLinks } = useQuery(['signorLinks', leaseAllInfo], async () => {
        if (leaseAllInfo.status === LEASE_STATUS_SENT_SIGNING) {
            const promise = getSignorLinks(leaseAllInfo.id).promise;
            const response = await promise;
            return response.data.signorsWithLinks;
        }
        return [];
    });
    const { copyToClipboard } = useClipboard();
    const copyLinkToClipboard = userId => {
        if (signorLinks) {
            const matchingSignor = signorLinks.find(signor => getId(signor) === userId);
            if (matchingSignor) {
                copyToClipboard(matchingSignor.signorLink, 'Link Copied!');
            }
        }
    };

    return (
        <div>
            {shouldShowSignatures && <AgreementSignatures location={leaseAllInfo.location} />}
            {leaseAllInfo.status !== LEASE_STATUS_DRAFT && (
                <AgreementResidentialComplianceChecklist leaseAllInfo={leaseAllInfo} />
            )}
            {leaseAllInfo.landlord && (
                <AgreementSection
                    title={getResTenLabel('landlord', leaseAllInfo.location)}
                    step={getResTenStepNo(leaseAllInfo.location, 'landlord', leaseAllInfo.subLeaseType)}
                    leaseAllInfo={leaseAllInfo}
                >
                    {leaseAllInfo.landlord.persons.map((item, index) => {
                        return (
                            <div className="data data-row landlord-info" key={index}>
                                {leaseAllInfo.location === NSW_STATE && (
                                    <div className="landlord-info-status">
                                        {renderLandlordInformationStatementStatus(
                                            item.landlordInformationStatementStatus,
                                            leaseAllInfo.landlord.informationStatementManuallyAcknowledged,
                                            item.sendMethod,
                                            index
                                        )}
                                    </div>
                                )}
                                <div>
                                    {item.isCorporation && <p className="name">{item.corporationName}</p>}
                                    {!item.isCorporation && (
                                        <p className="name">
                                            {item.firstName} {item.secondName}
                                        </p>
                                    )}
                                    {leaseAllInfo.location === SA_STATE && (
                                        <p className="text">
                                            Address for service of documents (which cannot be the Agent's address for
                                            service) is {item.isCorporation ? item.corporationAddress : item.address}
                                        </p>
                                    )}
                                    {leaseAllInfo.landlord && (
                                        <div>
                                            {[NSW_STATE, QLD_STATE].includes(leaseAllInfo.location) && (
                                                <div className="data-string">
                                                    {item.isCorporation && (
                                                        <React.Fragment>
                                                            <span className="data-contact">Address</span>
                                                            <p className="text">{item.corporationAddress}</p>
                                                        </React.Fragment>
                                                    )}
                                                    {!item.isCorporation && <p className="text">{item.address}</p>}
                                                </div>
                                            )}
                                            {(![NSW_STATE, SA_STATE, QLD_STATE].includes(leaseAllInfo.location) ||
                                                (leaseAllInfo.location === QLD_STATE && !item.isCorporation)) &&
                                                leaseAllInfo.landlord.noticeEmailAddress && (
                                                    <div className="data-string">
                                                        <span className="data-contact">E</span>
                                                        <p className="text">
                                                            {leaseAllInfo.landlord.noticeEmailAddress}
                                                        </p>
                                                    </div>
                                                )}
                                            {renderLandlordOtherDetails(leaseAllInfo, item)}
                                            {leaseAllInfo.location === NSW_STATE &&
                                                renderLandlordInformationLastSent(leaseAllInfo, item)}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                    {isCompletedAndValidated(leaseAllInfo, leaseAllInfo.leaseType, leaseAllInfo.location) &&
                        leaseAllInfo.location === NSW_STATE &&
                        leaseAllInfo.status !== LEASE_STATUS_AWAITING_RENEWAL &&
                        canResendLandlordStatement(leaseAllInfo) && (
                            <div className="landlord-information-button">
                                <span
                                    className="special-link"
                                    onClick={props.openResendLandlordInformationStatementModal}
                                >
                                    {hasSentLandlordStatement(leaseAllInfo) ? 'Resend ' : 'Send '}
                                    Landlord Information Statement
                                </span>
                            </div>
                        )}
                </AgreementSection>
            )}
            <AgreementSection
                title={getResTenLabel('tenants', leaseAllInfo.location)}
                step={getResTenStepNo(leaseAllInfo.location, 'tenant', leaseAllInfo.subLeaseType)}
                leaseAllInfo={leaseAllInfo}
            >
                {leaseAllInfo.tenant &&
                    leaseAllInfo.tenant.tenants.map((item, i) => {
                        return (
                            <div className="data data-row" key={i}>
                                {item.isCorporation && (
                                    <React.Fragment>
                                        <p className="name">{item.corporationName}</p>
                                        <p className="name">{item.corporationAddress}</p>
                                    </React.Fragment>
                                )}
                                <p className="name">{`${item.tenantFullName}${
                                    item.isCorporation ? ` (${item.roleDescription})` : ''
                                }`}</p>
                                {leaseAllInfo.location === VIC_STATE && (
                                    <div className="data-string">
                                        <span className="data-contact">Current address</span>
                                        <p className="text">{item.address}</p>
                                    </div>
                                )}
                                <div className="data-string">
                                    <span className="data-contact">Ph</span>
                                    <p className="text">{item.phone}</p>
                                </div>
                                <div className="data-string">
                                    <span className="data-contact">E</span>
                                    <p className="text">{item.email}</p>
                                </div>
                                {item.ABN && (
                                    <div className="data-string">
                                        <span className="data-contact">ABN</span>
                                        <p className="text">{item.ABN}</p>
                                    </div>
                                )}
                                {item.ACN && (
                                    <div className="data-string">
                                        <span className="data-contact">ACN</span>
                                        <p className="text">{item.ACN}</p>
                                    </div>
                                )}
                                {leaseAllInfo.location === VIC_STATE && (
                                    <React.Fragment>
                                        {(item.givesPermissionForElectronicNotices === true ||
                                            item.givesPermissionForElectronicNotices === false) && (
                                            <div className="data-string">
                                                <p className="text">Answer to electronic services of notices:</p>
                                            </div>
                                        )}
                                        <div className="data-string">
                                            {item.givesPermissionForElectronicNotices === true && (
                                                <div>
                                                    <p className="electronic-yes-icon">
                                                        <CheckCircleSharpIcon />
                                                        Yes ({item.emailForElectronicNotices})
                                                    </p>
                                                </div>
                                            )}
                                            {item.givesPermissionForElectronicNotices === false && (
                                                <div>
                                                    <p className="electronic-no-icon">
                                                        <CancelIcon />
                                                        No
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </React.Fragment>
                                )}
                                {leaseAllInfo.location === QLD_STATE && (
                                    <>
                                        <div className="data-string">
                                            <span className="data-contact">Emergency Contact Name</span>
                                            <p className="text">{item.emergencyContactFullnamesAnswer}</p>
                                        </div>
                                        <div className="data-string">
                                            <span className="data-contact">Emergency Email</span>
                                            <p className="text">{item.emergencyContactEmailAnswer}</p>
                                        </div>
                                        <div className="data-string">
                                            <span className="data-contact">Emergency Contact Phone</span>
                                            <p className="text">{item.emergencyContactPhoneAnswer}</p>
                                        </div>
                                    </>
                                )}
                                {item.extraContacts &&
                                    item.extraContacts.map((detail, index) => (
                                        <div className="data-string" key={index}>
                                            <span className="data-contact">Other</span>
                                            <p className="text">{detail.contactValue}</p>
                                        </div>
                                    ))}
                                {leaseAllInfo.status === LEASE_STATUS_SENT_SIGNING &&
                                    signorLinkExists(signorLinks, item.id) && (
                                        <div className="special-link" onClick={() => copyLinkToClipboard(item.id)}>
                                            Copy signing link
                                        </div>
                                    )}
                            </div>
                        );
                    })}
            </AgreementSection>
            {leaseAllInfo.renewalIntention && (
                <AgreementSection title={'Renewal Intention information'} leaseAllInfo={leaseAllInfo}>
                    <div className="data data-row flex">
                        <RenewalIntentionIndicator
                            renewalIntention={leaseAllInfo.renewalIntention}
                            prefix="summary-screen"
                        />
                        <div>
                            <div className="data-string">
                                <p className="text">
                                    <b>{leaseAllInfo.renewalIntention.firstName}</b> (
                                    {phoneToSpacedPhone(leaseAllInfo.renewalIntention.tenantNumber)})
                                </p>
                            </div>
                            <div className="data-string">
                                <span className="data-contact">Sent</span>
                                <p className="text">{leaseAllInfo.renewalIntention.sentFormatted}</p>
                            </div>
                            <div className="data-string">
                                <span className="data-contact">Response</span>
                                <p className="text">{getResponse(leaseAllInfo.renewalIntention)}</p>
                            </div>
                            {leaseAllInfo.renewalIntention.isTenantRenewing &&
                                leaseAllInfo.renewalIntention.isTenantRenewing !== NO_RESPONSE && (
                                    <div className="data-string">
                                        <span className="data-contact">Date of response</span>
                                        <p className="text">
                                            {leaseAllInfo.renewalIntention.responseReceivedAtFormatted}
                                        </p>
                                    </div>
                                )}
                        </div>
                    </div>
                </AgreementSection>
            )}
        </div>
    );
}

export default connect(null, {
    openResendLandlordInformationStatementModal
})(AgreementResidentialSignors);
