import React from 'react';
import cx from 'classnames';
import { Field, FieldRenderProps, useField } from 'react-final-form';

import { getFormError } from '../../../../../utils/finalFormUtils';
import Icon, { Icons } from '../../../../../common/components/Icon';

import styles from './RadioGroup.module.scss';

type RadioItemData = {
    label: string;
    value: string;
};

type RadioItemProps = RadioItemData & FieldRenderProps<string, HTMLElement>;

const RadioItem: React.FC<RadioItemProps> = ({ label, input }) => {
    return (
        <label className={cx(styles.radioItem, { [styles.checked]: input.checked })}>
            <Icon className={styles.indicator} icon={input.checked ? Icons.ACTIVE_RADIO : Icons.INACTIVE_RADIO} />
            {label}
            <input className={styles.hiddenInput} type="radio" {...input} />
        </label>
    );
};

type RadioGroupProps = {
    name: string;
    label: string;
    data: RadioItemData[];
    className?: string;
    disabled?: boolean;
    validateOnTouch?: boolean;
};

const RadioGroup: React.FC<RadioGroupProps> = ({ data, name, label, className, disabled, validateOnTouch }) => {
    const field = useField(name, { subscription: { error: true, touched: true } });
    const error = getFormError(field.meta, validateOnTouch);

    return (
        <div className={cx(styles.container, className, { [styles.error]: !!error })} data-has-error={!!error}>
            <div className={styles.radioGroupLabel}>{label}</div>
            <div className={cx(styles.radioGroup, { [styles.disabled]: disabled })}>
                {data.map(radioItem => (
                    <Field
                        disabled={disabled}
                        key={radioItem.value}
                        type="radio"
                        label={radioItem.label}
                        name={name}
                        value={radioItem.value}
                        component={RadioItem}
                    />
                ))}
            </div>
            {!!error && <div className={styles.error}>{error}</div>}
        </div>
    );
};

export default RadioGroup;
