import { CONSOLE_INTEGRATION } from '@app/constants/featureFlags';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import React, { useCallback, useState } from 'react';
import { Form } from 'react-final-form';
import styles from './ConsoleIntegration.module.scss';
import cx from 'classnames';

import Button from '../../../common/components/Button.js';
import { CheckboxCheck } from '@app/components/form/FormCheckboxCheck';
import { FormTextRegular } from '@app/components/form/FormText';
import { PreLoader } from '@app/common/components/PreLoader';
import SaveChangesButton from './InspectionManagerIntegration/SaveChangesButton';
import useToast from '@app/hooks/useToast';

import { useDispatch, useSelector } from 'react-redux';
import { AgencyIntegrationType } from './InspectionManagerIntegration/types';
import { getUserAgency, getAgencyIntegrations } from '@app/selectors/agency';
import { updateReapitConsoleIntegration } from '@app/actions/user';
import useAwake from '@app/hooks/useAwake';
import { HIDE_MESSAGE_TIME } from '@app/config';
import { ToastTypes } from '@app/common/components/Toast';

const ConsoleIntegration = () => {
    const dispatch = useDispatch();
    const { addNewToast } = useToast();
    const [isConnectionInstructionOpen, toggleConnectionInstruction] = useState<boolean>(false);
    const agency = useSelector<unknown, { id?: number }>(getUserAgency);
    const { isAwake, wakeup } = useAwake(HIDE_MESSAGE_TIME);
    const integrations = useSelector<unknown, AgencyIntegrationType>(getAgencyIntegrations);

    const reapitConsole = integrations?.reapitConsole;
    const isAgencyLoading = !agency;
    const isLoading = !!reapitConsole?.loading;
    const isDisconnecting = !!reapitConsole?.disconnecting;
    const hasConnection = !!reapitConsole?.enabled;
    const hasError = !!reapitConsole?.hasError;
    const isDisabled = isLoading || isDisconnecting;

    const onSubmit = useCallback(
        values => {
            dispatch(
                updateReapitConsoleIntegration({
                    agencyId: agency?.id,
                    data: values,
                    onEnd: () => wakeup(),
                    onSuccess: () =>
                        addNewToast(
                            'Console has been successfully integrated to your Agency account.',
                            ToastTypes.SUCCESS,
                            true
                        ),
                    onError: () => addNewToast("Integration didn't complete", ToastTypes.ERROR, true)
                })
            );
        },
        [dispatch, wakeup, agency, addNewToast]
    );

    const disconnectInspectionManager = useCallback(() => {
        dispatch(
            updateReapitConsoleIntegration({
                agencyId: agency?.id,
                data: { deleteMe: true, connected: true },
                options: { disconnecting: true },
                onSuccess: () =>
                    addNewToast(
                        'Console has been successfully disconnected to your Agency account.',
                        ToastTypes.SUCCESS,
                        true
                    ),
                onError: () =>
                    addNewToast(
                        'There is an error disconnecting your account integration. Please try again.',
                        ToastTypes.ERROR,
                        true
                    )
            })
        );
    }, [dispatch, agency, addNewToast]);

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={{ shouldPushFinishedDocuments: reapitConsole?.shouldPushFinishedDocuments }}
        >
            {({ handleSubmit }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <div className={`panel ${styles.consoleContainer}`}>
                            <div className="panel-header">
                                <span>CONSOLE</span>
                                {hasConnection && (
                                    <span className={cx(styles.connectedStatus, 'savestatus-saved')}>
                                        Connected <span className={cx(styles.noMargin, 'icon savestatus-icon')} />
                                    </span>
                                )}
                            </div>
                            {isAgencyLoading && <PreLoader />}
                            <div className="panel-body">
                                <div className="margin-bottom-1rem">
                                    Easily sync property and contact data directly into Console.
                                </div>
                                <CheckboxCheck
                                    disabled={isDisabled}
                                    name="shouldPushFinishedDocuments"
                                    label="Automatically store completed agreements and documents in Console"
                                />
                                {!hasConnection && (
                                    <>
                                        <FormTextRegular
                                            name="apiKey"
                                            label="API Key"
                                            disabled={isDisabled}
                                            required={true}
                                        />
                                        <div>
                                            <div className={styles.question}>
                                                <span />
                                                <a
                                                    onClick={() => toggleConnectionInstruction((s: boolean) => !s)}
                                                    className="special-link"
                                                >
                                                    Connection instructions
                                                </a>
                                            </div>
                                            {isConnectionInstructionOpen && (
                                                <div>
                                                    <ol>
                                                        <li>
                                                            Log in to <strong>Console Cloud</strong>.
                                                        </li>
                                                        <li>
                                                            Go to the <strong>Marketplace</strong> and click on the{' '}
                                                            <strong>FLK it over app</strong> under{' '}
                                                            <strong>Forms</strong>.
                                                        </li>
                                                        <li>
                                                            Click Add to <strong>Console Cloud</strong>.
                                                        </li>
                                                        <li>
                                                            Select <strong>Existing Client</strong>.
                                                        </li>
                                                        <li>
                                                            Copy the key provided by <strong>Console Cloud</strong> and
                                                            paste it in the field above, then click{' '}
                                                            <strong>Save Changes</strong>.
                                                        </li>
                                                        <li>Your integration is now enabled.</li>
                                                    </ol>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                            <div
                                className={cx(
                                    styles.consitentPaddings,
                                    hasConnection ? 'panel-footer' : 'right-panel-footer'
                                )}
                            >
                                {hasConnection && (
                                    <Button
                                        onClick={disconnectInspectionManager}
                                        loading={false}
                                        className={styles.disableButton}
                                        spinnerClassName={styles.spinner}
                                    >
                                        Disconnect
                                    </Button>
                                )}
                                <SaveChangesButton
                                    hasError={hasError}
                                    isLoading={isLoading}
                                    isThrottled={isAwake}
                                    disabled={isDisconnecting}
                                    failMessage="Invalid API Key"
                                />
                            </div>
                        </div>
                    </form>
                );
            }}
        </Form>
    );
};

export default ConsoleIntegration;
