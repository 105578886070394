import React, { useState, useEffect } from 'react';
import Pagination from 'react-js-pagination';
import { Table, Td, Th, Thead, Tr } from 'reactable';
import DeleteButton from '../../../../common/components/DeleteButton';
import CloneButton from '../../../../common/components/CloneButton';
import EditButton from '../../../../common/components/EditButton';
import ViewButton from '../../../../common/components/ViewButton';
import TemplateVisibility from './TemplateVisibility';
import { getLeaseTypeForTemplateType } from '../../../../utils/agreementUtils';
import { cloneDeep } from 'lodash';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';

import { TEMPLATE_SCREEN_V2 } from '../../../../constants/featureFlags';
import {
    TEMPLATE_TYPE_COMMERCIAL_PROPERTY_MANAGEMENT,
    TEMPLATE_TYPE_HOLIDAY_LETTING,
    TEMPLATE_TYPE_PROPERTY_MANAGEMENT,
    TEMPLATE_TYPE_SALES
} from '../../../../config';

const SORT_OPTION_CREATED = 'created';
const SORT_OPTION_TEMPLATE_NAME = 'templateName';
const SORT_OPTION_LOCATION = 'location';
const SORT_OPTION_DOCUMENT_TITLE = 'documentTitle';

const TemplateTable = props => {
    let {
        templateType,
        isAdmin,
        templates,
        templatePagination,
        templatesSorting,
        createNewTemplate,
        deleteTemplate,
        editTemplate,
        viewTemplate,
        cloneTemplate,
        changePage,
        isDocumentTemplate,
        blockActions,
        addVisibilitySwitch,
        changeSortOptions
    } = props;

    const isTemplateScreenV2Active = useFeatureFlag(TEMPLATE_SCREEN_V2);

    useEffect(() => {
        if (templatesSorting) {
            setSortOptions({ [templatesSorting.field]: templatesSorting.direction });
        }
    }, [templatesSorting]);

    const [sortOptions, setSortOptions] = useState({});

    const onChangeSort = item => {
        const oldSort = cloneDeep(sortOptions);
        let newSort = {};
        switch (oldSort[item]) {
            // if current sort option is 'asc' then next option is 'desc'
            case 'asc': {
                newSort[item] = 'desc';
                break;
            }
            // if current sort option is 'desc' then next option is no sort
            case 'desc': {
                newSort = { [SORT_OPTION_CREATED]: 'desc' };
                break;
            }
            // default means we do not have any sort option. then next sort option is 'asc'
            default: {
                newSort[item] = 'asc';
                break;
            }
        }

        setSortOptions(newSort);
        changeSortOptions(newSort);
    };

    const getClassName = item => {
        let newClassName = 'no-sort';
        if (sortOptions[item] === 'asc') {
            newClassName = 'sort-asc';
        } else if (sortOptions[item] === 'desc') {
            newClassName = 'sort-desc';
        }
        return newClassName;
    };

    return (
        <div className="wrapper flex-direction-column">
            <div className="templates-block table-sort table-default">
                <div className="button-row table-head">
                    <h2>{getLeaseTypeForTemplateType(templateType)} Templates</h2>
                    {isAdmin && !blockActions && !isTemplateScreenV2Active && (
                        <div
                            className="btn-add"
                            onClick={() => {
                                createNewTemplate();
                            }}
                        >
                            Add Template
                        </div>
                    )}
                </div>
                <Table>
                    <Thead>
                        <Th className="template-name-column" column="name">
                            <div
                                className={`table-header-sort ${getClassName(SORT_OPTION_TEMPLATE_NAME)}`}
                                onClick={() => onChangeSort(SORT_OPTION_TEMPLATE_NAME)}
                            >
                                Template name
                            </div>
                        </Th>
                        {!isDocumentTemplate && (
                            <Th column="state">
                                <div
                                    className={`table-header-sort ${getClassName(SORT_OPTION_LOCATION)}`}
                                    onClick={() => onChangeSort(SORT_OPTION_LOCATION)}
                                >
                                    State
                                </div>
                            </Th>
                        )}
                        {isDocumentTemplate && (
                            <Th column="title">
                                <div
                                    className={`table-header-sort ${getClassName(SORT_OPTION_DOCUMENT_TITLE)}`}
                                    onClick={() => onChangeSort(SORT_OPTION_DOCUMENT_TITLE)}
                                >
                                    Document Title
                                </div>
                            </Th>
                        )}
                        {((isAdmin && !blockActions) || blockActions) && (
                            <Th column="actions" className="actions">
                                <b>Actions</b>
                            </Th>
                        )}
                    </Thead>
                    {templates &&
                        templates.map((item, index) => (
                            <Tr key={index}>
                                <Td column="name">
                                    <span className="capitalize">{`${item.templateName}`}</span>
                                </Td>
                                {!isDocumentTemplate && <Td column="state">{item.location}</Td>}
                                {isDocumentTemplate && <Td column="title">{item.documentTitle}</Td>}
                                {isAdmin && !blockActions && (
                                    <Td column="actions" className="actions">
                                        <div className="flex">
                                            <EditButton
                                                onClick={() => {
                                                    editTemplate(item);
                                                }}
                                                toolTip="Edit template"
                                                className="edit-btn"
                                                dataTest={`${item.templateName}-edit-btn`}
                                            />
                                            {![
                                                TEMPLATE_TYPE_HOLIDAY_LETTING,
                                                TEMPLATE_TYPE_SALES,
                                                TEMPLATE_TYPE_PROPERTY_MANAGEMENT,
                                                TEMPLATE_TYPE_COMMERCIAL_PROPERTY_MANAGEMENT
                                            ].includes(templateType) && (
                                                <CloneButton
                                                    onClick={() => {
                                                        cloneTemplate(item);
                                                    }}
                                                    toolTip="Clone template"
                                                    className="clone-btn"
                                                    dataTest={`${item.templateName}-clone-btn`}
                                                />
                                            )}
                                            <DeleteButton
                                                onClick={() => {
                                                    deleteTemplate(item);
                                                }}
                                                toolTip="Remove template"
                                                className="delete-btn"
                                                dataTest={`${item.templateName}-delete-btn`}
                                            />
                                            {addVisibilitySwitch && (
                                                <TemplateVisibility
                                                    item={item}
                                                    dataTest={`${item.templateName}-switch-btn`}
                                                />
                                            )}
                                        </div>
                                    </Td>
                                )}
                                {blockActions && (
                                    <Td column="actions" className="actions">
                                        <div className="flex">
                                            <ViewButton
                                                onClick={() => {
                                                    viewTemplate(item);
                                                }}
                                                toolTip="View template"
                                                className="view-btn"
                                            />

                                            {isAdmin && addVisibilitySwitch && (
                                                <TemplateVisibility item={item} dataTest="switch-btn" />
                                            )}
                                        </div>
                                    </Td>
                                )}
                            </Tr>
                        ))}
                </Table>
            </div>
            <div className="wrapper-pagination">
                <Pagination
                    hideDisabled
                    activePage={templatePagination.page ? templatePagination.page : 1}
                    itemsCountPerPage={templatePagination.limit ? templatePagination.limit : 0}
                    totalItemsCount={templatePagination.total ? templatePagination.total : 0}
                    pageRangeDisplayed={3}
                    onChange={e => changePage(e)}
                    lastPageText={<div className="last-icon" />}
                    firstPageText={<div className="first-icon" />}
                    nextPageText="..."
                    prevPageText="..."
                />
                {templatePagination.limit < templatePagination.total ? (
                    <p className="pagination-text">
                        Page {templatePagination.page} of {templatePagination.pages}
                    </p>
                ) : (
                    <p />
                )}
            </div>
        </div>
    );
};

export default TemplateTable;
