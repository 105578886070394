import React, { memo, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { cloneDeep, has } from 'lodash';

import * as Lease from '../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../selectors/lease';
import { getLandlordsSolicitor } from '../../../selectors/lease/salesLease';

import { FormMaskedText } from '@app/components/form/FormMaskedText';
import { FormTextRegular, FormTextMultiline } from '../../../components/form/FormText';
import { CheckboxCheck } from '../../../components/form/FormCheckboxCheck';
import { FormPhone } from '../../../components/form/FormPhone';

import '../../../sass/landlordsSolicitor.scss';
import { isLeaseLocationNSW, isLeaseLocationQLD } from '../../../utils/agreementUtils';

const LANDLORD_SOLICITOR_FORM = 'LandlordsSolicitorForm';

const LandlordsSolicitor = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxLandlordsSolicitor = useSelector(getLandlordsSolicitor);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [landlordsSolicitor, setLandlordsSolicitor] = useState(reduxLandlordsSolicitor || {});

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(LANDLORD_SOLICITOR_FORM).dispatchEvent(
                new Event('submit', {
                    cancelable: true,
                    bubbles: true
                })
            );
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updateLandlordsSolicitor(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_LANDLORD_SOLICITOR_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.landlordsSolicitor')) {
                    return error.response.data.errors.landlordsSolicitor;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const updateLandlordsSolicitor = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/landlords-solicitor`, data);
    };

    return (
        <div className="form-container landlords-solicitor">
            <Form onSubmit={submitForm} initialValues={landlordsSolicitor}>
                {({ values, handleSubmit, form }) => {
                    let disabled = !!values && values.doNotHaveSolicitor === true;
                    return (
                        <form onSubmit={handleSubmit} noValidate id={LANDLORD_SOLICITOR_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <div className="formBox">
                                <div className="nowrap fields-full-width-for-mobile">
                                    <FormTextRegular
                                        name="firstName"
                                        label="First Name"
                                        required={isLeaseLocationQLD(location)}
                                        disabled={disabled}
                                    />
                                    <FormTextRegular name="middleName" label="Middle Name" disabled={disabled} />
                                </div>
                                <div className="formBox-column">
                                    <FormTextRegular
                                        name="secondName"
                                        label="Last Name"
                                        required={isLeaseLocationQLD(location)}
                                        disabled={disabled}
                                    />
                                    <FormTextRegular name="companyName" label="Company Name" disabled={disabled} />
                                    <FormTextRegular
                                        name="address"
                                        label="Address"
                                        required={isLeaseLocationNSW(location)}
                                        disabled={disabled}
                                    />
                                    {isLeaseLocationNSW(location) && (
                                        <FormPhone name="fax" label="Fax" disabled={disabled} />
                                    )}
                                    <FormTextRegular
                                        name="phone"
                                        label="Phone"
                                        required={isLeaseLocationQLD(location)}
                                        disabled={disabled}
                                    />
                                    {isLeaseLocationNSW(location) && (
                                        <FormPhone name="mobile" label="Mobile" disabled={disabled} />
                                    )}
                                    <FormTextRegular
                                        name="email"
                                        label="Email"
                                        required={isLeaseLocationQLD(location)}
                                        disabled={disabled}
                                    />
                                    {isLeaseLocationNSW(location) && (
                                        <FormTextMultiline
                                            name="other"
                                            label="Document service address"
                                            disabled={disabled}
                                        />
                                    )}
                                    {isLeaseLocationQLD(location) && (
                                        <FormMaskedText
                                            name="ABN"
                                            label="ABN"
                                            mask="11 111 111 111"
                                            disabled={disabled}
                                        />
                                    )}
                                    <CheckboxCheck
                                        dataTest="vendorSolicitorCheckbox"
                                        name="doNotHaveSolicitor"
                                        label="To be advised"
                                    />
                                </div>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(LandlordsSolicitor);
