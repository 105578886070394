import React, { useEffect, useState } from 'react';
import { cloneDeep, uniqBy, remove, toLower, find, findIndex, isEmpty, pull, includes, concat } from 'lodash';
import { FormTextRegular, FormTextMultiline } from '../../../components/form/FormText';
import { CheckboxCheck } from '../../../components/form/FormCheckboxCheck';
import { FormRadioGroup } from '../../../components/form/FormRadioGroup';
import ReactTooltip from 'react-tooltip';
import { InfoSharp } from '@flk-mui-icons';
import AccountCircleSharp from '@flk-mui-icons/AccountCircleSharp';
import HouseSharp from '@flk-mui-icons/HouseSharp';
import {
    MuiAccordion,
    MuiAccordionSummary,
    MuiAccordionDetails,
    MuiAccordionActions
} from '../../../common/components/mui-accordion/MuiAccordion';
import ExpandMoreIcon from '@flk-mui-icons/ExpandMore';
import {
    PARTY_AGENT,
    PARTY_LANDLORD,
    PARTY_NONE,
    DETAILS_OPTION_OPTIONAL,
    DETAILS_OPTION_REQUIRED,
    DETAILS_OPTION_NO_DETAILS,
    ANSWER_YES,
    ANSWER_DETAILS,
    ANSWER_FILE,
    defaultAnswerOptions
} from '../../../config';

import '../../../sass/form-question-group.scss';
import Button from '../../../common/components/Button';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragHandleSharpIcon from '@flk-mui-icons/DragHandleSharp';
import { CheckboxButtonGroup } from '../../../components/form/FormCheckboxButtonGroup';

const QuestionGroup = ({
    values,
    setFormData,
    error,
    haveSelectAll,
    canAddNewQuestions,
    setIsDirty,
    defaultOpenedQuestions
}) => {
    const [openedQuestions, setOpenedQuestions] = useState(defaultOpenedQuestions ? defaultOpenedQuestions : []);
    const [showQuestionError, setShowQuestionError] = useState({});
    const [isReOrder, setIsReOrder] = useState(false);

    useEffect(() => {
        setShowQuestionError({});
        if (error && error.questions) {
            const errorIndex = error.questions.map((question, index) => {
                if (!isEmpty(question)) {
                    return index;
                }
            });
            setOpenedQuestions(concat(openedQuestions, errorIndex));
        }
    }, [error]);

    useEffect(() => {
        setOpenedQuestions(defaultOpenedQuestions ? defaultOpenedQuestions : []);
    }, [defaultOpenedQuestions]);

    const getDefaultOptions = (list, key, disabled) => {
        const data = [];
        list.forEach(element => {
            data.push({
                label: element.answer,
                id: `questions${key}.answer-${element.answer}`,
                value: element.answer,
                name: `option-${toLower(element.answer.replace(/ /g, '_'))}`,
                detailsOption: element.detailsOption,
                disabled
            });
        });
        return data;
    };

    const showDetailsOptions = question => {
        const option = find(question.answerOptionsSelected, { answer: ANSWER_YES });
        return !!option;
    };

    const getYesOptionIndex = question => {
        return findIndex(question.answerOptionsSelected, { answer: ANSWER_YES });
    };

    const hasDetailsInput = (values, index) => {
        let hasdetails = false;
        if (values.questions) {
            if (values.questions[index]) {
                const answerOption = find(values.questions[index].answerOptions, {
                    answer: values.questions[index].answer
                });
                if (
                    answerOption &&
                    answerOption.detailsOption &&
                    (answerOption.detailsOption === DETAILS_OPTION_REQUIRED ||
                        answerOption.detailsOption === DETAILS_OPTION_OPTIONAL)
                ) {
                    hasdetails = true;
                }
            }
        }
        return hasdetails;
    };

    const handleOptionSelect = (index, values, option, value) => {
        let newState = cloneDeep(values);
        if (!newState.questions[index].answerOptionsSelected) {
            newState.questions[index].answerOptionsSelected = [];
        }
        if (value) {
            let detailsOption = DETAILS_OPTION_NO_DETAILS;
            let uploadFile = false;
            if (option.value === ANSWER_DETAILS) {
                detailsOption = DETAILS_OPTION_REQUIRED;
            } else if (option.value === ANSWER_YES) {
                detailsOption = DETAILS_OPTION_OPTIONAL;
            } else if (option.value === ANSWER_FILE) {
                detailsOption = DETAILS_OPTION_OPTIONAL;
                uploadFile = true;
            }
            newState.questions[index].answerOptionsSelected.push({
                answer: option.value,
                detailsOption,
                uploadFile,
                // this index will use to keep the same order when we push and pull
                index: findIndex(defaultAnswerOptions, { answer: option.value })
            });
        } else {
            remove(newState.questions[index].answerOptionsSelected, function (n) {
                return n.answer === option.value;
            });
        }
        uniqBy(newState.questions[index].answerOptionsSelected);
        newState.questions[index][option.name] = value;
        setFormData({
            ...newState
        });
    };

    const selectAll = (values, answer) => {
        let newState = cloneDeep(values);
        newState.questions.map(question => {
            question.partyToAnswer = answer;
        });
        const firstNotAnsweredQuestion = newState.questions.findIndex(question => !questionsAnswered(question));
        if (firstNotAnsweredQuestion !== 1) {
            setOpenedQuestions([firstNotAnsweredQuestion]);
        }
        setIsDirty(true);
        setFormData(newState);
    };

    const addNewQuestion = values => {
        let newState = cloneDeep(values);
        newState.questions.push({
            questionId: newState.questions.length,
            description: '',
            answer: '',
            partyToAnswer: PARTY_NONE,
            answerOptions: defaultAnswerOptions,
            noAgentAnswerQuestion: true
        });
        setFormData({
            ...newState
        });
        moveToNextQuestion(newState.questions.length - 2);
    };

    const handlerequireClientActionSelect = (index, values, value) => {
        let newState = cloneDeep(values);
        if (value) {
            newState.questions[index].partyToAnswer = PARTY_LANDLORD;
        } else {
            newState.questions[index].partyToAnswer = PARTY_NONE;
        }
        newState.questions[index].requireClientAction = value;
        setIsDirty(true);
        setFormData({
            ...newState
        });
    };

    const openQuestion = questionIndex => {
        if (isReOrder) {
            return;
        }
        const newOpenedQuestions = cloneDeep(openedQuestions);

        if (includes(newOpenedQuestions, questionIndex)) {
            pull(newOpenedQuestions, questionIndex);
        } else {
            newOpenedQuestions.push(questionIndex);
        }
        setOpenedQuestions(newOpenedQuestions);
    };

    const clickNext = (questionIndex, values) => {
        const tempErrorObject = cloneDeep(showQuestionError);
        if (questionsAnswered(values.questions[questionIndex])) {
            tempErrorObject[questionIndex] = false;
            moveToNextQuestion(questionIndex);
        } else {
            tempErrorObject[questionIndex] = 'Please make a selection and click Next';
            if (
                values.questions[questionIndex].partyToAnswer === PARTY_AGENT &&
                values.questions[questionIndex].answer
            ) {
                tempErrorObject[questionIndex] = 'Please fill required fields and click Next';
            }
        }
        setShowQuestionError(tempErrorObject);
    };

    const moveToNextQuestion = questionIndex => {
        const newOpenedQuestions = cloneDeep(openedQuestions);
        pull(newOpenedQuestions, questionIndex);
        newOpenedQuestions.push(questionIndex + 1);
        setOpenedQuestions(newOpenedQuestions);
        const questionClass = `question-${questionIndex}`;
        const elementList = document.getElementsByClassName(questionClass);
        if (elementList && elementList.length > 0) {
            elementList[0].scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
        }
    };

    const removeClause = (questionIndex, values) => {
        let newState = cloneDeep(values);
        newState.questions = [
            ...newState.questions.slice(0, questionIndex),
            ...newState.questions.slice(questionIndex + 1)
        ];
        setFormData({
            ...newState
        });
    };

    const questionsAnswered = question => {
        let answered = true;
        if (question.noAgentAnswerQuestion) {
            if (!question.title || !question.description) {
                answered = false;
            } else if (question.partyToAnswer === PARTY_LANDLORD) {
                answered = question.answerOptionsSelected && question.answerOptionsSelected.length > 0;
            }
        } else {
            if (question.optional) {
                answered = true;
                if (question.answer && question.partyToAnswer === PARTY_AGENT) {
                    const option = find(question.answerOptions, {
                        answer: question.answer
                    });
                    if (option && option.detailsOption === DETAILS_OPTION_REQUIRED && !question.details) {
                        answered = false;
                    }
                }
            } else if (!question.answer) {
                answered = false;

                if (question.partyToAnswer === PARTY_LANDLORD) {
                    answered = question.answerOptionsSelected.length > 0;
                }
            } else {
                if (question.partyToAnswer === PARTY_AGENT) {
                    const option = find(question.answerOptions, {
                        answer: question.answer
                    });
                    if (option && option.detailsOption === DETAILS_OPTION_REQUIRED && !question.details) {
                        answered = false;
                    }
                } else {
                    if (question.partyToAnswer === PARTY_AGENT) {
                        const option = find(question.answerOptionsSelected, {
                            answer: question.answer
                        });
                        if (option && option.detailsOption === DETAILS_OPTION_REQUIRED && !question.details) {
                            answered = false;
                        }
                    } else {
                        answered = question.answerOptionsSelected.length > 0;
                    }
                }
            }
        }
        return answered;
    };

    const onDragEnd = result => {
        // if there is no drop destination do nothing
        if (!result.destination) {
            return;
        }
        // if drag and drop in same place do nothing
        if (result.destination.index === result.source.index) {
            return;
        }

        const oldIndex = result.source.index;
        const newIndex = result.destination.index;

        let newState = cloneDeep(values);
        const reOrderedList = Array.from(newState.questions);
        const [removed] = reOrderedList.splice(oldIndex, 1);
        reOrderedList.splice(newIndex, 0, removed);

        newState.questions = reOrderedList;
        setIsDirty(true);
        setFormData({
            ...newState
        });
    };

    return (
        <React.Fragment>
            <div className="questions-container forced-mobile-view">
                {haveSelectAll && (
                    <div className="questions-container__header">
                        <div
                            className={`questions-container__header--select ${values.disabled ? 'disabled' : ''}`}
                            onClick={() => selectAll(values, PARTY_AGENT)}
                        >
                            <span className="questions-container__header--select-item">
                                <AccountCircleSharp />
                            </span>
                            <div className="questions-container__header--select-item">Agent</div>
                            <div>Select all</div>
                        </div>
                        <div
                            className={`questions-container__header--select ${values.disabled ? 'disabled' : ''}`}
                            onClick={() => selectAll(values, PARTY_LANDLORD)}
                        >
                            <span className="questions-container__header--select-item">
                                <HouseSharp></HouseSharp>
                            </span>
                            <div className="questions-container__header--select-item">Rental Provider</div>
                            <div>Select all</div>
                        </div>
                    </div>
                )}
                {values.questions && (
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="list">
                            {provided => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    {values.questions.map((question, index) => {
                                        return (
                                            <Draggable
                                                draggableId={`dragId-${index}`}
                                                index={index}
                                                key={index}
                                                isDragDisabled={true}
                                            >
                                                {(provided, snapshot) => (
                                                    <MuiAccordion
                                                        key={index}
                                                        expanded={includes(openedQuestions, index)}
                                                        onChange={() => openQuestion(index)}
                                                        className={`question-${index} question-accordion`}
                                                        {...provided.dragHandleProps}
                                                        {...provided.draggableProps}
                                                    >
                                                        <MuiAccordionSummary
                                                            expandIcon={
                                                                !isReOrder ? (
                                                                    <ExpandMoreIcon
                                                                        className={`expand-icon ${
                                                                            questionsAnswered(question) ? 'success' : ''
                                                                        }`}
                                                                    />
                                                                ) : null
                                                            }
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            className="accordion-summary"
                                                            expandIconClassName="question-accordion__expandIcon"
                                                        >
                                                            {isReOrder && (
                                                                <div
                                                                    className={`${
                                                                        snapshot.isDragging ? 'dragging' : ''
                                                                    } drag-hanndler`}
                                                                >
                                                                    <DragHandleSharpIcon />
                                                                </div>
                                                            )}
                                                            <div className="accordion-summary-text large-font">
                                                                {question.noAgentAnswerQuestion &&
                                                                    !includes(openedQuestions, index) && (
                                                                        <React.Fragment>
                                                                            {question.title
                                                                                ? question.title
                                                                                : 'No title'}
                                                                        </React.Fragment>
                                                                    )}
                                                                {question.noAgentAnswerQuestion
                                                                    ? ''
                                                                    : `${index + 1}. ${question.questionText}`}
                                                                {question.note && (
                                                                    <React.Fragment>
                                                                        <InfoSharp
                                                                            className="tooltip-info-icon text-gray"
                                                                            data-tip
                                                                            data-for={`${index}-tooltip`}
                                                                        />
                                                                        <ReactTooltip
                                                                            id={`${index}-tooltip`}
                                                                            class="tooltip"
                                                                            place="bottom"
                                                                            effect="solid"
                                                                            globalEventOff="wheel"
                                                                        >
                                                                            <div>
                                                                                <div className="info-segment">
                                                                                    {question.note}
                                                                                </div>
                                                                            </div>
                                                                        </ReactTooltip>
                                                                    </React.Fragment>
                                                                )}
                                                            </div>
                                                        </MuiAccordionSummary>
                                                        <MuiAccordionDetails
                                                            className={`accordion-details ${
                                                                question.noAgentAnswerQuestion ? 'without-summary' : ''
                                                            }`}
                                                        >
                                                            <div className="questions-container__question">
                                                                {question.noAgentAnswerQuestion && (
                                                                    <React.Fragment>
                                                                        <FormTextRegular
                                                                            name={`questions[${index}].title`}
                                                                            label="Title"
                                                                            disabled={values.disabled}
                                                                            required
                                                                            onClick={event => event.stopPropagation()}
                                                                            onFocus={event => event.stopPropagation()}
                                                                        />
                                                                        <FormTextRegular
                                                                            name={`questions[${index}].subTitle`}
                                                                            label="Sub title"
                                                                            disabled={values.disabled}
                                                                        />
                                                                        <FormTextMultiline
                                                                            name={`questions[${index}].description`}
                                                                            label="Description"
                                                                            disabled={values.disabled}
                                                                            required
                                                                        />
                                                                    </React.Fragment>
                                                                )}
                                                                {question.newQuestion && (
                                                                    <FormTextMultiline
                                                                        name={`questions[${index}].questionText`}
                                                                        label="Question"
                                                                        disabled={values.disabled}
                                                                        required
                                                                    />
                                                                )}
                                                                <ReactTooltip
                                                                    id="rental-provider-answer"
                                                                    class="tooltip"
                                                                    place="bottom"
                                                                    effect="solid"
                                                                    globalEventOff="wheel"
                                                                >
                                                                    <p>
                                                                        If you would like the rental provider to answer
                                                                        a question, we have pre-selected the most
                                                                        logical answers for you.
                                                                    </p>
                                                                </ReactTooltip>
                                                                {!question.noAgentAnswerQuestion && (
                                                                    <div className="questions-container__question--to-answer">
                                                                        <div className="text-gray">
                                                                            Who will answer the question
                                                                            <InfoSharp
                                                                                className="tooltip-info-icon"
                                                                                data-tip
                                                                                data-for="rental-provider-answer"
                                                                            />
                                                                        </div>
                                                                        <FormRadioGroup
                                                                            label=""
                                                                            radioGroupClass="button-group-for-select"
                                                                            isYesNoGroup={true}
                                                                            name={`questions[${index}].partyToAnswer`}
                                                                            disabled={values.disabled}
                                                                            data={[
                                                                                {
                                                                                    label: 'Agent',
                                                                                    value: 'agent',
                                                                                    disabled: values.disabled
                                                                                },
                                                                                {
                                                                                    label: 'Rental Provider',
                                                                                    value: 'landlord',
                                                                                    disabled: values.disabled
                                                                                }
                                                                            ]}
                                                                            value={
                                                                                values.questions[index].partyToAnswer
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                                <div>
                                                                    <div className="question-group forced-mobile-view">
                                                                        {question.noAgentAnswerQuestion && (
                                                                            <div className="questions-container__question--to-answer">
                                                                                <CheckboxCheck
                                                                                    className="mobile-multi-select-enabled fit-content"
                                                                                    name={`questions[${index}].requireClientAction`}
                                                                                    label="Require client action"
                                                                                    value={
                                                                                        values.questions[index]
                                                                                            .requireClientSction
                                                                                    }
                                                                                    disabled={values.disabled}
                                                                                    onClick={value =>
                                                                                        handlerequireClientActionSelect(
                                                                                            index,
                                                                                            values,
                                                                                            value
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        )}
                                                                        {values.questions &&
                                                                            values.questions[index] &&
                                                                            values.questions[index].partyToAnswer ===
                                                                                PARTY_AGENT && (
                                                                                <React.Fragment>
                                                                                    <div className="text-gray">
                                                                                        Select Answer
                                                                                    </div>
                                                                                    <FormRadioGroup
                                                                                        label=""
                                                                                        radioGroupClass="button-group-for-select"
                                                                                        isYesNoGroup={true}
                                                                                        name={`questions[${index}].answer`}
                                                                                        disabled={values.disabled}
                                                                                        data={getDefaultOptions(
                                                                                            question.answerOptions,
                                                                                            index,
                                                                                            values.disabled
                                                                                        )}
                                                                                        value={
                                                                                            values.questions[index]
                                                                                                .answer
                                                                                        }
                                                                                    />
                                                                                    {hasDetailsInput(values, index) && (
                                                                                        <FormTextRegular
                                                                                            name={`questions[${index}].details`}
                                                                                            label="Details"
                                                                                            disabled={values.disabled}
                                                                                        />
                                                                                    )}
                                                                                </React.Fragment>
                                                                            )}
                                                                        {values.questions &&
                                                                            values.questions[index] &&
                                                                            values.questions[index].partyToAnswer ===
                                                                                PARTY_LANDLORD && (
                                                                                <React.Fragment>
                                                                                    <div className="text-gray">
                                                                                        Answer/reply options{' '}
                                                                                        <InfoSharp
                                                                                            className="tooltip-info-icon"
                                                                                            data-tip
                                                                                            data-for="answerReplyOption"
                                                                                        />
                                                                                        <ReactTooltip
                                                                                            id="answerReplyOption"
                                                                                            class="tooltip"
                                                                                            place="bottom"
                                                                                            effect="solid"
                                                                                            globalEventOff="wheel"
                                                                                        >
                                                                                            <div>
                                                                                                <div className="info-segment">
                                                                                                    Please select which
                                                                                                    answer/reply options
                                                                                                    you would like the
                                                                                                    client to see
                                                                                                </div>
                                                                                            </div>
                                                                                        </ReactTooltip>
                                                                                    </div>
                                                                                    <div className="multi-select-list">
                                                                                        {getDefaultOptions(
                                                                                            question.answerOptions,
                                                                                            index
                                                                                        ).map((option, optionIndex) => {
                                                                                            return (
                                                                                                <CheckboxButtonGroup
                                                                                                    key={`${index}-${optionIndex}`}
                                                                                                    name={`questions[${index}][${option.name}]`}
                                                                                                    text={option.label}
                                                                                                    value={
                                                                                                        values
                                                                                                            .questions[
                                                                                                            index
                                                                                                        ][
                                                                                                            option
                                                                                                                .agentName
                                                                                                        ]
                                                                                                    }
                                                                                                    disabled={
                                                                                                        values.disabled
                                                                                                    }
                                                                                                    onClick={value =>
                                                                                                        handleOptionSelect(
                                                                                                            index,
                                                                                                            values,
                                                                                                            option,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            );
                                                                                        })}
                                                                                    </div>
                                                                                    {error &&
                                                                                        error.questions &&
                                                                                        error.questions[index] &&
                                                                                        error.questions[index]
                                                                                            .answerOptionsSelected && (
                                                                                            <p className="error FormError">
                                                                                                {
                                                                                                    error.questions[
                                                                                                        index
                                                                                                    ]
                                                                                                        .answerOptionsSelected
                                                                                                }
                                                                                            </p>
                                                                                        )}
                                                                                    {showDetailsOptions(question) && (
                                                                                        <React.Fragment>
                                                                                            <div className="text-gray">
                                                                                                If yes, do you require
                                                                                                additional details?
                                                                                            </div>
                                                                                            <div className="option-list">
                                                                                                <FormRadioGroup
                                                                                                    label=""
                                                                                                    radioGroupClass="button-group-for-select"
                                                                                                    isYesNoGroup={true}
                                                                                                    name={`questions[${index}].answerOptionsSelected[${getYesOptionIndex(
                                                                                                        question
                                                                                                    )}].detailsOption`}
                                                                                                    disabled={
                                                                                                        values.disabled
                                                                                                    }
                                                                                                    data={[
                                                                                                        {
                                                                                                            label: 'Details optional',
                                                                                                            value: DETAILS_OPTION_OPTIONAL,
                                                                                                            disabled:
                                                                                                                values.disabled
                                                                                                        },
                                                                                                        {
                                                                                                            label: 'Details mandatory',
                                                                                                            value: DETAILS_OPTION_REQUIRED,
                                                                                                            disabled:
                                                                                                                values.disabled
                                                                                                        },
                                                                                                        {
                                                                                                            label: 'No details required',
                                                                                                            value: DETAILS_OPTION_NO_DETAILS,
                                                                                                            disabled:
                                                                                                                values.disabled
                                                                                                        }
                                                                                                    ]}
                                                                                                    value={
                                                                                                        values
                                                                                                            .questions[
                                                                                                            index
                                                                                                        ]
                                                                                                            .answerOptionsSelected[
                                                                                                            getYesOptionIndex(
                                                                                                                question
                                                                                                            )
                                                                                                        ].detailsOption
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    )}
                                                                                    {question.noAgentAnswerQuestion && (
                                                                                        <div className="questions-container__question--to-answer">
                                                                                            <CheckboxCheck
                                                                                                className="mobile-multi-select-enabled fit-content"
                                                                                                name={`questions[${index}].optional`}
                                                                                                label="A reply to this clause isn't mandatory"
                                                                                                value={
                                                                                                    values.questions[
                                                                                                        index
                                                                                                    ].optional
                                                                                                }
                                                                                                disabled={
                                                                                                    values.disabled
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    )}
                                                                                </React.Fragment>
                                                                            )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </MuiAccordionDetails>
                                                        <MuiAccordionActions>
                                                            <div className="next-button-group">
                                                                {showQuestionError[index] && (
                                                                    <p className="text-error">
                                                                        {showQuestionError[index]}
                                                                    </p>
                                                                )}
                                                                <div>
                                                                    {question.noAgentAnswerQuestion &&
                                                                        values.questions.length > 1 && (
                                                                            <Button
                                                                                className="next-button"
                                                                                type="button"
                                                                                secondary
                                                                                onClick={() =>
                                                                                    removeClause(index, values)
                                                                                }
                                                                                disabled={values.disabled}
                                                                            >
                                                                                Remove
                                                                            </Button>
                                                                        )}
                                                                    {index < values.questions.length - 1 && (
                                                                        <Button
                                                                            className="next-button"
                                                                            type="button"
                                                                            primary
                                                                            onClick={() => clickNext(index, values)}
                                                                        >
                                                                            Next
                                                                        </Button>
                                                                    )}
                                                                    {canAddNewQuestions &&
                                                                        index === values.questions.length - 1 && (
                                                                            <Button
                                                                                className="next-button"
                                                                                type="button"
                                                                                primary
                                                                                onClick={() => addNewQuestion(values)}
                                                                                disabled={values.disabled}
                                                                            >
                                                                                Add
                                                                            </Button>
                                                                        )}
                                                                </div>
                                                            </div>
                                                        </MuiAccordionActions>
                                                    </MuiAccordion>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                )}
            </div>
        </React.Fragment>
    );
};
export default QuestionGroup;
