import * as React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import cx from 'classnames';
import '../../sass/modalDialog.scss';
import { CloseSharp } from '@flk-mui-icons';

class ModalDialog extends React.Component {
    render() {
        const {
            isOpen,
            title,
            leftButton,
            rightButton,
            customBodyAndFooter,
            closeModal,
            containerClassName,
            className,
            overlayClassName,
            bodyClassName,
            showModalHeader,
            customHeader,
            customHeaderContent,
            shouldCloseOnOverlayClick,
            zIndex,
            hideCloseButton,
            parentSelector,
            modalHeadClassName,
            sideModal,
            sideModalIsOpen,
            sideModalClassName,
            dataTest,
            modalPosition,
            shouldBlockBodyScroll,
            titleClassName,
            // Quick fix to prevent dropdowns from being cut off by modal. You probably shouldn't need to use this
            allowOverflow
        } = this.props;

        return (
            <Modal
                parentSelector={() => document.getElementById(parentSelector ? parentSelector : 'main-layout')}
                ariaHideApp={false}
                isOpen={isOpen}
                style={getModalStyle(zIndex)}
                className={cx(containerClassName, 'modal-container', {
                    'top-modal': modalPosition === 'top',
                    'modal-container--allow-overflow': allowOverflow
                })}
                contentLabel="Modal"
                onRequestClose={closeModal}
                shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
                overlayClassName={cx('modal-overlay', overlayClassName)}
                bodyOpenClassName={shouldBlockBodyScroll ? 'scroll-locked' : undefined}
            >
                <div className={cx('modal-dialog', className)}>
                    {showModalHeader && (
                        <React.Fragment>
                            {customHeader && <React.Fragment>{customHeaderContent()}</React.Fragment>}
                            {!customHeader && (
                                <div className={cx('modal-head', modalHeadClassName)}>
                                    <h3 className={titleClassName}>{title}</h3>
                                    {hideCloseButton !== true && (
                                        <CloseSharp className="close-modal" onClick={closeModal} />
                                    )}
                                </div>
                            )}
                        </React.Fragment>
                    )}
                    {customBodyAndFooter && <React.Fragment>{this.props.children}</React.Fragment>}
                    {!customBodyAndFooter && (
                        <React.Fragment>
                            <div className={cx('modal-body', bodyClassName)} data-test={dataTest}>
                                {this.props.children}
                            </div>
                            {(!!leftButton || !!rightButton) && (
                                <div className="modal-footer">
                                    <div>{leftButton}</div>
                                    <div>{rightButton}</div>
                                </div>
                            )}
                        </React.Fragment>
                    )}
                </div>
                {sideModal && sideModalIsOpen && (
                    <div className={cx('side-modal', sideModalClassName)}>{sideModal}</div>
                )}
            </Modal>
        );
    }
}

const getModalStyle = zIndex => {
    return {
        overlay: {
            zIndex: zIndex
        }
    };
};

ModalDialog.propTypes = {
    isOpen: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    leftButton: PropTypes.element,
    rightButton: PropTypes.element,
    className: PropTypes.string,
    closeModal: PropTypes.func,
    showModalHeader: PropTypes.bool,
    shouldCloseOnOverlayClick: PropTypes.bool,
    zIndex: PropTypes.number,
    // Modal position defaults to center, this can be used to modify it.
    modalPosition: PropTypes.oneOf(['top'])
};

ModalDialog.defaultProps = {
    isOpen: false,
    title: '',
    className: '',
    closeModal: () => {},
    showModalHeader: true,
    shouldCloseOnOverlayClick: true,
    zIndex: 5
};

export default ModalDialog;
