import * as React from 'react';
import createMuiIcon from '../createMuiIcon';

/**
 * @deprecated This Material UI icon is deprecated. Please use an icon from our design system instead.
 * Contact the design team for the appropriate replacement icon.
 */
export default createMuiIcon(
    <path d="M9 3L5 6.99h3V14h2V6.99h3L9 3zm7 14.01V10h-2v7.01h-3L15 21l4-3.99h-3z" />,
    'ImportExportSharp'
);
