import React, { Component } from 'react';

import { Provider } from 'react-redux';
import { IntercomProvider } from 'react-use-intercom';
import { Route, Router, Switch, Redirect } from 'react-router';
import cookie from 'react-cookie';
import appHistory from './../AppHistory';
import { UpdateProvider } from '../components/providers/UpdateProvider';
import { TwilioSyncProvider } from '../components/providers/TwilioSyncProvider';
import { RollbarProvider } from '../components/providers/RollbarProvider';
import { AccountProvider } from '../components/providers/AccountProvider';
import { CountryProvider } from '../components/providers/CountryProvider.tsx';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// components
import logIn from '../containers/LogIn';
import Maintenance from '../containers/maintenance/Maintenance';
import SignUpRoutes from './signUp/SignUpRoutes';
import RequestResetPassword from '../containers/RequestResetPassword';
import ResetPassword from '../containers/ResetPassword';
import DashboardLayout from '../containers/DashboardLayout';
import SettingsAccount from '../containers/SettingsAccount';
import Welcome from '../containers/Welcome';
import CreateAccountAgent from '../containers/CreateAccountAgent';
import AgentSignaturesMain from '../containers/Signatures';
import ContactListViewer from '../containers/ContactListViewer';
import BusinessMobileLanding from '../containers/BusinessMobileLanding';
import ToastsContainer from '../containers/ToastsContainer';

import configureStore from '../store/configureStore';
import { configureAxios } from '../actions/index';

import '../actions/index';
import '../sass/global.scss';
import '../sass/icons.scss';
import EmailAuth from '../containers/EmailAuth';
import OnboardingEmailAuthSend from '../containers/OnboardingEmailAuthSend';
import ExternalCustomDocumentLandingPage from './ExternalCustomDocument/ExternalCustomDocumentLandingPage';
import axios from 'axios';
import { getHarnessTargetData, HarnessProvider } from '../components/providers/HarnessProvider';
import { ExternalScriptProvider } from '../components/providers/ExternalScriptProvider';
import UploadADocOnboarding from './dashboard/documents/FlkAPdf/signup/UploadADocOnboarding';
import OnboardingRedirect from './signUp/SignupRedirect';
import Templates from './templatesV2/index.tsx';
import { BUSINESS_MOBILE_LANDING_PATH } from '../constants/constants.ts';
import { DEFAULT_CACHE_DURATION, DEFAULT_STALE_DURATION } from '../utils/retryPolicies.ts';
import { HARNESS_TARGET_DATA } from '@app/constants/constants';

let store = configureStore();
configureAxios(store);

/**
 * Please refer to this page for explanation to defaults: https://flkitover.atlassian.net/wiki/spaces/BRI/pages/1703837697/TanStack+Query+Usage
 */
const queryClientConfig = {
    defaultOptions: {
        queries: {
            // Reties will be handled by axios-retry
            retry: false,
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            refetchOnReconnect: true,
            cacheTime: DEFAULT_CACHE_DURATION,
            staleTime: DEFAULT_STALE_DURATION
        },
        mutations: {
            // Reties will be handled by axios-retry
            retry: false
        }
    }
};

// Store harness target data in local storage
window.onbeforeunload = e => {
    const state = store.getState();
    if (!state.user.userInfo || !state.account.agency) {
        return;
    }

    sessionStorage.setItem(
        HARNESS_TARGET_DATA,
        JSON.stringify(getHarnessTargetData(state.user.userInfo, state.account.agency))
    );
};

// Create a tanstack client
const queryClient = new QueryClient(queryClientConfig);

class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <CountryProvider>
                    <UpdateProvider>
                        <RollbarProvider>
                            <QueryClientProvider client={queryClient}>
                                <IntercomProvider appId={INTERCOM_APP_ID}>
                                    <TwilioSyncProvider>
                                        <HarnessProvider>
                                            <AccountProvider>
                                                <ExternalScriptProvider>
                                                    <Router history={appHistory}>
                                                        <ToastsContainer>
                                                            <React.Fragment>
                                                                <Switch>
                                                                    <Route exact path="/" component={logIn} />
                                                                    <Route
                                                                        exact
                                                                        path="/maintenance"
                                                                        component={Maintenance}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path="/email-auth/verify/:token"
                                                                        component={EmailAuth}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path="/onboarding/verify-and-send/:token"
                                                                        component={OnboardingEmailAuthSend}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path="/onboarding/upload-a-doc/:uploadADocId?"
                                                                        component={UploadADocOnboarding}
                                                                    />
                                                                    <Route path="/signup" component={SignUpRoutes} />
                                                                    <Route
                                                                        exact
                                                                        path="/signup/business/onboarding"
                                                                        component={OnboardingRedirect}
                                                                    />
                                                                    <Route path="/welcome" component={Welcome} />
                                                                    <Route
                                                                        path={BUSINESS_MOBILE_LANDING_PATH}
                                                                        component={BusinessMobileLanding}
                                                                    />
                                                                    <Route
                                                                        path="/request-reset-password"
                                                                        component={RequestResetPassword}
                                                                    />
                                                                    <Route
                                                                        path="/reset-password/:resetToken"
                                                                        component={ResetPassword}
                                                                    />
                                                                    <Route
                                                                        path="/join-team/:id"
                                                                        component={CreateAccountAgent}
                                                                    />
                                                                    <Route
                                                                        path="/dashboard"
                                                                        component={DashboardLayout}
                                                                    />
                                                                    <Route path="/user" component={SettingsAccount} />
                                                                    <Route path="/templates" component={Templates} />
                                                                    <Route
                                                                        path="/reader/document/esp-notice/:id"
                                                                        component={props => {
                                                                            window.location.href = `${SERVERLESS_URL}/esp-notice/stand-alone/${
                                                                                props.match.params.id
                                                                            }?userFromLogin=${cookie.load(
                                                                                'userFromLogin'
                                                                            )}`;
                                                                            return null;
                                                                        }}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path="/signatures/:token"
                                                                        component={AgentSignaturesMain}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path="/external/:tempDocId"
                                                                        component={ExternalCustomDocumentLandingPage}
                                                                    />
                                                                    <Route
                                                                        path="/reader/agreement/:id/owner"
                                                                        component={props => {
                                                                            window.location.href = `${SERVERLESS_URL}/lease/${
                                                                                props.match.params.id
                                                                            }?pdf_type=forOwnerWithSignatures&userFromLogin=${cookie.load(
                                                                                'userFromLogin'
                                                                            )}`;
                                                                            return null;
                                                                        }}
                                                                    />
                                                                    <Route
                                                                        path="/reader/agreement/:id/section48"
                                                                        component={props => {
                                                                            window.location.href = `${SERVERLESS_URL}/lease/${
                                                                                props.match.params.id
                                                                            }?pdf_type=section_48_pdf&userFromLogin=${cookie.load(
                                                                                'userFromLogin'
                                                                            )}`;
                                                                            return null;
                                                                        }}
                                                                    />
                                                                    <Route
                                                                        path="/reader/agreement/:id"
                                                                        component={props => {
                                                                            window.location.href = `${SERVERLESS_URL}/lease/${
                                                                                props.match.params.id
                                                                            }?userFromLogin=${cookie.load(
                                                                                'userFromLogin'
                                                                            )}`;
                                                                            return null;
                                                                        }}
                                                                    />
                                                                    <Route
                                                                        path="/reader/document/custom-document/:id/:uploadedDocumentId"
                                                                        component={props => {
                                                                            axios
                                                                                .get(
                                                                                    `${SERVICE_URL}/api/document/custom-document-preview-inline/${props.match.params.id}/${props.match.params.uploadedDocumentId}`,
                                                                                    {
                                                                                        withCredentials: true
                                                                                    }
                                                                                )
                                                                                .then(response => {
                                                                                    if (response) {
                                                                                        window.location.href =
                                                                                            response.data.url;
                                                                                    }
                                                                                });
                                                                            return null;
                                                                        }}
                                                                    />
                                                                    <Route
                                                                        path="/reader/document/custom-document-execution/:id/:uploadedDocumentId"
                                                                        component={props => {
                                                                            axios
                                                                                .get(
                                                                                    `${SERVICE_URL}/api/document/custom-document-execution-preview-inline/${props.match.params.id}/${props.match.params.uploadedDocumentId}`,
                                                                                    {
                                                                                        withCredentials: true
                                                                                    }
                                                                                )
                                                                                .then(response => {
                                                                                    if (response) {
                                                                                        window.location.href =
                                                                                            response.data.url;
                                                                                    }
                                                                                });
                                                                            return null;
                                                                        }}
                                                                    />
                                                                    <Route
                                                                        path="/reader/document/rent-relief/:id"
                                                                        component={props => {
                                                                            window.location.href = `${SERVERLESS_URL}/rent-relief/${
                                                                                props.match.params.id
                                                                            }?userFromLogin=${cookie.load(
                                                                                'userFromLogin'
                                                                            )}`;
                                                                            return null;
                                                                        }}
                                                                    />
                                                                    <Route
                                                                        path="/reader/document/rent-increase/:id"
                                                                        component={props => {
                                                                            window.location.href = `${SERVERLESS_URL}/rentIncrease/stand-alone/${
                                                                                props.match.params.id
                                                                            }?userFromLogin=${cookie.load(
                                                                                'userFromLogin'
                                                                            )}`;
                                                                            return null;
                                                                        }}
                                                                    />
                                                                    <Route
                                                                        path="/reader/termination-notice/:leaseId/:terminationNoticeId"
                                                                        component={props => {
                                                                            window.location.href = `${SERVERLESS_URL}/termination/${
                                                                                props.match.params.leaseId
                                                                            }/${
                                                                                props.match.params.terminationNoticeId
                                                                            }?userFromLogin=${cookie.load(
                                                                                'userFromLogin'
                                                                            )}`;
                                                                            return null;
                                                                        }}
                                                                    />
                                                                    <Route
                                                                        path="/reader/document/breach-notice/:id"
                                                                        component={props => {
                                                                            window.location.href = `${SERVERLESS_URL}/breach/stand-alone/${
                                                                                props.match.params.id
                                                                            }?userFromLogin=${cookie.load(
                                                                                'userFromLogin'
                                                                            )}`;
                                                                            return null;
                                                                        }}
                                                                    />
                                                                    <Route
                                                                        path="/reader/document/intention-to-sell-notice/:id"
                                                                        component={props => {
                                                                            window.location.href = `${SERVERLESS_URL}/intention-to-sell-notice/stand-alone/${
                                                                                props.match.params.id
                                                                            }?userFromLogin=${cookie.load(
                                                                                'userFromLogin'
                                                                            )}`;
                                                                            return null;
                                                                        }}
                                                                    />
                                                                    <Route
                                                                        path="/reader/document/estimated-selling-price-notice/:id"
                                                                        component={props => {
                                                                            window.location.href = `${SERVERLESS_URL}/estimated-selling-price-notice/stand-alone/${
                                                                                props.match.params.id
                                                                            }?userFromLogin=${cookie.load(
                                                                                'userFromLogin'
                                                                            )}`;
                                                                            return null;
                                                                        }}
                                                                    />
                                                                    <Route
                                                                        path="/reader/document/entry-notice/:id"
                                                                        component={props => {
                                                                            window.location.href = `${SERVERLESS_URL}/entry-notice/stand-alone/${
                                                                                props.match.params.id
                                                                            }?userFromLogin=${cookie.load(
                                                                                'userFromLogin'
                                                                            )}`;
                                                                            return null;
                                                                        }}
                                                                    />
                                                                    <Route
                                                                        path="/reader/document/termination-notice/:id"
                                                                        component={props => {
                                                                            window.location.href = `${SERVERLESS_URL}/termination/stand-alone/${
                                                                                props.match.params.id
                                                                            }?userFromLogin=${cookie.load(
                                                                                'userFromLogin'
                                                                            )}`;
                                                                            return null;
                                                                        }}
                                                                    />
                                                                    <Route
                                                                        path="/reader/document/disclosure/:id"
                                                                        component={props => {
                                                                            window.location.href = `${SERVERLESS_URL}/disclosure/${
                                                                                props.match.params.id
                                                                            }?userFromLogin=${cookie.load(
                                                                                'userFromLogin'
                                                                            )}`;
                                                                            return null;
                                                                        }}
                                                                    />
                                                                    <Route
                                                                        path="/reader/document/create-a-flk/:id"
                                                                        component={props => {
                                                                            window.location.href = `${SERVERLESS_URL}/create-a-flk/${
                                                                                props.match.params.id
                                                                            }?userFromLogin=${cookie.load(
                                                                                'userFromLogin'
                                                                            )}`;
                                                                            return null;
                                                                        }}
                                                                    />
                                                                    <Route
                                                                        path="/reader/lease/:leaseId/rent-increase/:id"
                                                                        component={props => {
                                                                            window.location.href = `${SERVERLESS_URL}/rent-increase/${
                                                                                props.match.params.leaseId
                                                                            }/${
                                                                                props.match.params.id
                                                                            }?userFromLogin=${cookie.load(
                                                                                'userFromLogin'
                                                                            )}`;
                                                                            return null;
                                                                        }}
                                                                    />
                                                                    <Route
                                                                        path="/reader/lease/:leaseId/esp-letter/:id"
                                                                        component={props => {
                                                                            window.location.href = `${SERVERLESS_URL}/lease/${
                                                                                props.match.params.leaseId
                                                                            }/esp-letter/${
                                                                                props.match.params.id
                                                                            }?userFromLogin=${cookie.load(
                                                                                'userFromLogin'
                                                                            )}`;
                                                                            return null;
                                                                        }}
                                                                    />
                                                                    <Route
                                                                        path="/reader/default-document/:state/:documentName"
                                                                        component={props => {
                                                                            window.location.href = `${SERVICE_URL}/api/document/default/${props.match.params.state}/${props.match.params.documentName}`;
                                                                            return null;
                                                                        }}
                                                                    />
                                                                    <Route
                                                                        path="/reader/view-document/:leaseId/:docId"
                                                                        component={props => {
                                                                            window.location.href = `${SERVERLESS_URL}/lease/${
                                                                                props.match.params.leaseId
                                                                            }/${
                                                                                props.match.params.docId
                                                                            }/view-uploaded-document?userFromLogin=${cookie.load(
                                                                                'userFromLogin'
                                                                            )}`;
                                                                            return null;
                                                                        }}
                                                                    />
                                                                    <Route
                                                                        path="/reader/view-uploaded-document/:documentId/:docId"
                                                                        component={props => {
                                                                            window.location.href = `${SERVERLESS_URL}/document/${
                                                                                props.match.params.documentId
                                                                            }/${
                                                                                props.match.params.docId
                                                                            }/view-uploaded-document?userFromLogin=${cookie.load(
                                                                                'userFromLogin'
                                                                            )}`;
                                                                            return null;
                                                                        }}
                                                                    />
                                                                    <Route
                                                                        path="/reader/view-onboarding-uploaded-document/:documentId/:docId"
                                                                        component={props => {
                                                                            window.location.href = `${SERVERLESS_URL}/document/${props.match.params.documentId}/${props.match.params.docId}/view-onboarding-uploaded-document`;
                                                                            return null;
                                                                        }}
                                                                    />
                                                                    <Route
                                                                        path="/reader/view-default-agency-document/:agencyId/:docId"
                                                                        component={props => {
                                                                            window.location.href = `${SERVERLESS_URL}/document/agency/${
                                                                                props.match.params.agencyId
                                                                            }/${
                                                                                props.match.params.docId
                                                                            }/view-agency-default-document?userFromLogin=${cookie.load(
                                                                                'userFromLogin'
                                                                            )}`;
                                                                            return null;
                                                                        }}
                                                                    />
                                                                    <Route
                                                                        path="/reader/landlordAcknowledgement/:leaseId"
                                                                        component={props => {
                                                                            window.location.href = `${SERVERLESS_URL}/lease/${
                                                                                props.match.params.leaseId
                                                                            }/llis?userFromLogin=${cookie.load(
                                                                                'userFromLogin'
                                                                            )}`;
                                                                            return null;
                                                                        }}
                                                                    />
                                                                    <Route
                                                                        path="/contact-list/:contactListId"
                                                                        component={ContactListViewer}
                                                                    />
                                                                </Switch>
                                                            </React.Fragment>
                                                        </ToastsContainer>
                                                    </Router>
                                                </ExternalScriptProvider>
                                            </AccountProvider>
                                        </HarnessProvider>
                                    </TwilioSyncProvider>
                                </IntercomProvider>
                            </QueryClientProvider>
                        </RollbarProvider>
                    </UpdateProvider>
                </CountryProvider>
            </Provider>
        );
    }
}

export default App;
