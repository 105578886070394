import React, { memo, useImperativeHandle, useState, forwardRef } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { CheckboxCheck } from '../../../../components/form/FormCheckboxCheck';
import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../../actions/lease';
import { useDispatch, useSelector } from 'react-redux';
import { getDirtyStep, getLeaseType, getStep, getExecutedAsAgreement } from '../../../../selectors/lease';
import * as Lease from '../../../../reducers/lease';
import axios from 'axios';
import { has } from 'lodash';

const EXECUTED_AS_AGREEMENT_FORM = 'executedAsAgreementForm';

const ExecutedAsAgreement = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);
    const leaseType = useSelector(getLeaseType);
    const reduxExecutedAsAgreement = useSelector(getExecutedAsAgreement);

    const [formState, setFormState] = useState(reduxExecutedAsAgreement);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(EXECUTED_AS_AGREEMENT_FORM).dispatchEvent(
                new Event('submit', {
                    cancelable: true,
                    bubbles: true
                })
            );
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const updateInsurance = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/executed-as-agreement`, data);
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updateInsurance(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_EXECUTED_AS_AGREEMENT_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.executedAsAgreement')) {
                    return error.response.data.errors.executedAsAgreement;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const yesToAll = () => {
        setFormState({
            isSection48Notice: true,
            isInformationBrochure: true,
            copyOfAgreement: true,
            isPropertyConditionReport: true,
            isManualsAndInstructions: true,
            specialConditions: true,
            isStatutoryNotice: true,
            isStrataArticles: true,
            isCommunityTitle: true,
            selectAll: !formState.selectAll
        });
    };

    return (
        <div className="executed-as-agreement">
            <Form onSubmit={submitForm} initialValues={formState}>
                {({ handleSubmit, form }) => {
                    return (
                        <form id={EXECUTED_AS_AGREEMENT_FORM} onSubmit={handleSubmit} noValidate>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <p>The agent has provided the Tenant(s) the following documents:</p>
                            <br />
                            <div className="button-all-small">
                                <button type="button" onClick={yesToAll}>
                                    Yes To All
                                </button>
                            </div>
                            <br />
                            <CheckboxCheck
                                className="wrap"
                                name="isSection48Notice"
                                label="Section 48(1) Notice (prior to or at the time of signing this Agreement) - FLK it over will automatically generate this for you and email it to the tenant"
                                disabled
                            />
                            <CheckboxCheck
                                className="wrap"
                                name="copyOfAgreement"
                                label="A copy of this Agreement"
                                disabled
                            />
                            <CheckboxCheck
                                className="wrap"
                                name="isInformationBrochure"
                                label="The Residential Tenancies Act 1995 (SA) Information Brochure"
                                disabled
                            />
                            <CheckboxCheck
                                className="wrap"
                                name="isPropertyConditionReport"
                                label="Two copies of the Property Condition Report"
                                disabled
                            />
                            <CheckboxCheck
                                className="wrap"
                                name="isManualsAndInstructions"
                                label="Manufacturer’s Manuals and Instructions as an Annexure if not provided for in this Agreement"
                            />
                            <CheckboxCheck className="wrap" name="specialConditions" label="Special Conditions" />
                            <CheckboxCheck
                                className="wrap"
                                name="isStatutoryNotice"
                                label="Statutory Notice for Short Fixed Term Tenancy (if less than 90 days)"
                            />
                            <CheckboxCheck
                                className="wrap"
                                name="isStrataArticles"
                                label="Strata Articles (if applicable)"
                            />
                            <CheckboxCheck
                                className="wrap"
                                name="isCommunityTitle"
                                label="Community Title By-Laws (if applicable)"
                            />
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(ExecutedAsAgreement);
