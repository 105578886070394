import React from 'react';
import cx from 'classnames';
import { Field, FieldRenderProps, useField } from 'react-final-form';

import Icon, { Icons } from '../../../../../common/components/Icon';
import { getFormError } from '../../../../../utils/finalFormUtils';

import styles from './CheckboxGroup.module.scss';

type CheckboxData = {
    label: string;
    value: string;
};

type CheckboxProps = CheckboxData & FieldRenderProps<string, HTMLElement>;

const Checkbox: React.FC<CheckboxProps> = ({ label, input }) => {
    return (
        <label className={cx(styles.checkbox, { [styles.checked]: input.checked })}>
            <Icon className={styles.check} icon={input.checked ? Icons.ACTIVE_CHECKBOX : Icons.INACTIVE_CHECKBOX} />
            {label}
            <input className={styles.hiddenInput} type="checkbox" {...input} />
        </label>
    );
};

type CheckboxGroupProps = {
    name: string;
    className?: string;
    data: CheckboxData[];
    disabled?: boolean;
    validateOnTouch?: boolean;
};

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({ name, data, className, disabled, validateOnTouch }) => {
    const field = useField(name, { subscription: { error: true, touched: true } });
    const error = getFormError(field.meta, validateOnTouch);
    return (
        <div className={cx(styles.container, className)} data-has-error={!!error}>
            <div className={cx(styles.checkboxGroup, { [styles.disabled]: disabled })}>
                {data.map(checkboxItem => (
                    <Field
                        disabled={disabled}
                        name={name}
                        key={checkboxItem.value}
                        type="checkbox"
                        label={checkboxItem.label}
                        value={checkboxItem.value}
                        component={Checkbox}
                    />
                ))}
            </div>
            {!!error && <div className={styles.error}>{error}</div>}
        </div>
    );
};

export default CheckboxGroup;
