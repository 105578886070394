import React, { memo, useState, forwardRef, useImperativeHandle, useEffect, RefObject } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { cloneDeep, has } from 'lodash';
import arrayMutators from 'final-form-arrays';

import * as Lease from '../../../../reducers/lease.js';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../../actions/lease.js';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../../selectors/lease/index.js';
import { getPmMarketingCosts } from '../../../../selectors/lease/pmLease.js';

import '../../../../sass/pmFees.scss';
import { CheckboxCheck } from '../../../../components/form/FormCheckboxCheck.js';
import { FormTextCurrency, FormTextHidden, FormTextRegular } from '../../../../components/form/FormText.js';
import { MarketingPlanGroup } from '../../../../components/lease/mainScreen/common/MarketingPlanGroup.js';
import { PmMarketingPlanType, PmMarketingType } from '../../../../types/pm';
import { DEFINE_ME } from '../../../../types/utilityTypes';
import Button from '../../../../common/components/Button.js';
import { ControlPoint } from '@flk-mui-icons';

const PM_MARKETING_FORM = 'pmMarketingAndAdvertisingForm';

const initState: PmMarketingType = {
    mediaAdvertising: 0,
    signboard: 0,
    internet: 0,
    marketingPlans: [],
    marketingEstimate: '0.00',
    isProvideMarketingSchedule: false,
    totalMarketingCost: 0
};

const emptyMarketingPlan: PmMarketingPlanType = {
    description: '',
    cost: ''
};

type FormData = PmMarketingType & {
    bypassFormValidation?: boolean;
};

interface LayoutRef {
    leaseId: string;
    callbackAfterSubmit: () => void;
    bypassFormValidation?: boolean;
}

interface FormRefObject {
    submitStep: () => void;
    callbackAfterSubmit?: () => void;
    bypassFormValidation?: boolean;
}

const PmMarketing = forwardRef<FormRefObject, LayoutRef>((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxPmMarketingCost: FormData = useSelector(getPmMarketingCosts);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [marketingCost, setMarketingCost] = useState(reduxPmMarketingCost || initState);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(PM_MARKETING_FORM)?.dispatchEvent(
                new Event('submit', {
                    cancelable: true,
                    bubbles: true
                })
            );
        }
    }));

    useEffect(() => {
        if (!marketingCost.isProvideMarketingSchedule) {
            calculateRunningCosts(marketingCost);
        }
    }, []);

    const calculateRunningCosts = (formValues: FormData) => {
        let totalCost = formValues.mediaAdvertising! + formValues.signboard! + formValues.internet!;
        if (formValues.marketingPlans && formValues.marketingPlans.length > 0) {
            formValues.marketingPlans.forEach((plan: PmMarketingPlanType) => {
                if (plan.cost) {
                    totalCost += parseFloat(plan.cost);
                }
            });
        }
        setMarketingCost({ ...formValues, marketingEstimate: totalCost.toFixed(2) });
    };

    const handleFormDirtyChange = (values: FormData, form: DEFINE_ME) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
        calculateRunningCosts(values);
    };

    const removeItem = (values: FormData, index: number) => {
        const marketingPlans = cloneDeep(values.marketingPlans);
        setMarketingCost({
            ...values,
            marketingPlans: [...marketingPlans.slice(0, index), ...marketingPlans.slice(index + 1)]
        });
    };

    const addPlan = (values: FormData) => {
        setMarketingCost({
            ...values,
            marketingPlans: [...values.marketingPlans, { ...emptyMarketingPlan }],
            marketingEstimate: '0.00'
        });
    };
    const submitForm = (values: FormData) => {
        const refObject = ref as RefObject<FormRefObject>;
        const currentRef = refObject.current;
        values.bypassFormValidation = currentRef?.bypassFormValidation;
        return updateMarketingCost(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef && currentRef.callbackAfterSubmit) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.pmMarketingCosts')) {
                    return error.response.data.errors.pmMarketingCosts;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const updateMarketingCost = (data: FormData) => {
        return axios.post(`/api/agency/lease/${props.leaseId}/pm-marketing-costs`, data);
    };

    return (
        <div className="form-container">
            <Form
                onSubmit={submitForm}
                initialValues={marketingCost}
                mutators={{
                    ...arrayMutators
                }}
            >
                {({ handleSubmit, values, form }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={PM_MARKETING_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={(state: { values: FormData }) => handleFormDirtyChange(state.values, form)}
                            />
                            <CheckboxCheck
                                name="isProvideMarketingSchedule"
                                label="Provide marketing schedule"
                                value={values.isProvideMarketingSchedule}
                                className="margin-bottom-2rem"
                            />
                            {values.isProvideMarketingSchedule ? (
                                <>
                                    <FormTextRegular name="marketingPayable" label="Marketing is payable" required />
                                    <FormTextCurrency
                                        name="totalMarketingCost"
                                        label="Total marketing cost (including GST) $"
                                        required
                                    />
                                </>
                            ) : (
                                <>
                                    <p className="margin-bottom-1rem">All expenses listed below are inclusive of GST</p>
                                    <FormTextCurrency name="mediaAdvertising" label="Media Advertising" />
                                    <FormTextCurrency name="signboard" label="Signboard" />
                                    <FormTextCurrency name="internet" label="Internet" />
                                    <div>
                                        {marketingCost.marketingPlans?.map((_p: DEFINE_ME, index: number) => (
                                            <MarketingPlanGroup
                                                index={index}
                                                key={index}
                                                removeItem={() => removeItem(values, index)}
                                            />
                                        ))}
                                        <FormTextHidden name="marketingEstimate" />
                                        <Button
                                            className="margin-bottom-1rem"
                                            type="button"
                                            onClick={() => addPlan(values)}
                                            startIcon={<ControlPoint />}
                                        >
                                            Add marketing service
                                        </Button>
                                    </div>
                                    <p>
                                        <b>Total Marketing Cost: ${values.marketingEstimate}</b>
                                    </p>
                                </>
                            )}
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(PmMarketing);
