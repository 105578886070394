import React, { memo, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { cloneDeep, has } from 'lodash';

import * as Lease from '../../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../../selectors/lease';
import { getPropertyDetails } from '../../../../selectors/lease/salesLease';

import { FormTextMultiline, FormTextRegular } from '../../../../components/form/FormText';
import { FormYesNoCheckbox } from '../../../../components/form/responsive/FormSingleCheckbox';
import { SelectField } from '../../../../components/form/FormSelect';
import { propertyTypeOptions, SELECT_OPTION_OTHER } from '../../../../config';
import { CheckboxCheck } from '../../../../components/form/FormCheckboxCheck';

const PROPERTY_DETAILS_FORM = 'propertyDetailsForm';

const PropertyDetails = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxPropertyDetails = useSelector(getPropertyDetails);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [propertyDetails, setPropertyDetails] = useState(reduxPropertyDetails || {});

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(PROPERTY_DETAILS_FORM).dispatchEvent(
                new Event('submit', {
                    cancelable: true,
                    bubbles: true
                })
            );
        }
    }));

    const handleFormDirtyChange = form => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updatePropertyDetails(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_PROPERTY_DETAILS_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.propertyDetails')) {
                    return error.response.data.errors.propertyDetails;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const updatePropertyDetails = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/property-details`, data);
    };

    useEffect(() => {
        const newPropertyDetails = cloneDeep(propertyDetails);
        setPropertyDetails(newPropertyDetails);
    }, []);

    return (
        <div className="form-container propertyDetails">
            <Form onSubmit={submitForm} initialValues={propertyDetails}>
                {({ handleSubmit, values, form }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={PROPERTY_DETAILS_FORM}>
                            <FormSpy subscription={{ values: true }} onChange={() => handleFormDirtyChange(form)} />
                            <div className="formBox">
                                <FormTextRegular name="description" label="Description of Property" required />
                                <FormTextRegular name="titleReference" label="Title Reference" />
                                <CheckboxCheck name="isNominateStrataDetails" label="Nominate Strata Lot & Plan No" />
                                {values.isNominateStrataDetails && (
                                    <div className="form-section">
                                        <div className="form-section__body">
                                            <div className="full-width">
                                                <FormTextRegular name="planNo" label="Plan No" />
                                            </div>
                                            <div className="full-width">
                                                <FormTextRegular name="lotNo" label="Lot No" />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <p className="text-gray">
                                If left blank, the words "Not stated" will be entered on the field
                            </p>
                            <br />
                            <SelectField label="Property Type" name="propertyType" options={propertyTypeOptions} />
                            {values.propertyType === SELECT_OPTION_OTHER && (
                                <FormTextRegular name="otherPropertyType" label="If Other, please specify" required />
                            )}
                            <FormTextRegular name="age" label="Age of property" />
                            <FormTextRegular name="landSize" label="Land size" />
                            <FormTextRegular name="zoning" label="Zoning" />
                            <FormYesNoCheckbox name="isElectricalSafetySwitches" text="Electrical safety switches" />
                            <FormYesNoCheckbox name="isSmokeAlarms" text="Smoke alarms" />
                            <FormYesNoCheckbox name="isPool" text="Is there a pool?" />
                            {values.isPool && (
                                <FormYesNoCheckbox name="isPoolSafetyCertificate" text="Pool Safety Certificate" />
                            )}
                            <FormYesNoCheckbox name="isPropertyTenanted" text="Is the Property tenanted?" />
                            <FormTextMultiline
                                name="additionalComments"
                                label="Additional comments related to the Property"
                            />
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(PropertyDetails);
