import React, { useEffect } from 'react';
import { ToastTypes } from '../../common/components/Toast';
import useToast from '../../hooks/useToast';
import { useRollbarProvider } from '../../components/providers/RollbarProvider';

type SaveButtonIconProps = {
    isSaving: boolean;
    success?: boolean;
    failure?: boolean;
    successMessage?: string;
    failureMessage?: string;
    onClick?: () => void;
    withToast?: boolean;
};
/*
 * This component will also create toasts if the sucess and failure conditions are populated
 */
const SaveButtonIcon: React.FC<SaveButtonIconProps> = ({
    isSaving,
    success,
    failure,
    successMessage,
    failureMessage,
    onClick,
    withToast = true
}) => {
    const Rollbar = useRollbarProvider();
    const { addNewToast } = useToast();
    useEffect(() => {
        if (!withToast) {
            return;
        }
        if (success) {
            addNewToast(successMessage || 'Saved Succesfully', ToastTypes.SUCCESS, true);
        }
        if (failure) {
            addNewToast(failureMessage || 'Saving Failed', ToastTypes.ERROR, true);
        }
        if (success && failure) {
            Rollbar.warning('Conflicting save and failure state in save button component');
        }
    }, [success, failure]);
    return (
        <>
            {!isSaving && (
                <button type="submit" className="savestatus-button" onClick={onClick}>
                    Save changes
                </button>
            )}
            {isSaving && (
                <span>
                    <button type="submit" disabled={true} className="savestatus-button">
                        <span className="savestatus-spinner" /> Saving changes
                    </button>
                </span>
            )}
        </>
    );
};

export default SaveButtonIcon;
