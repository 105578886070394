import { includes, map, has, upperFirst, lowerCase, cloneDeep } from 'lodash';
import { setIn } from 'final-form';
import { isValid, parseISO } from 'date-fns';
import { formatDateStandard } from './dateUtils';
import { getFormError } from './finalFormUtils';
import {
    DOCUMENT_CREATE_A_FLK,
    DOCUMENT_CUSTOM,
    LEASE_TYPE_PROPERTY_MANAGEMENT,
    LEASE_TYPE_COMMERCIAL_PROPERTY_MANAGEMENT,
    LEASE_TYPE_RESIDENTIAL,
    LEASE_TYPE_SALES,
    LEASE_TYPE_COMMERCIAL_LEASE,
    CREATE_A_FLK_TEMPLATE,
    FLK_A_PDF_TEMPLATE,
    FLK_A_PDF_GLOBAL_TEMPLATE,
    CREATE_A_FLK_GLOBAL_TEMPLATE,
    SUB_LEASE_TYPE_LONG_TERM_RENEWAL,
    FLK_A_PDF_DISPLAY,
    CREATE_A_FLK_DISPLAY
} from '../config';
import { getLeaseTypeForFrontEnd, isDefaultSubLease } from './agreementUtils';

const ADDRESS_DETAILS_FIELDS = [
    'subpremise',
    'street_number',
    'route',
    'locality',
    'postal_code',
    'administrative_area_level_1'
];

export const DEADLINE_OPTIONS = [
    { value: '24', label: '24 hours' },
    { value: '48', label: '48 hours' },
    { value: '72', label: '72 hours' },
    { value: '168', label: '7 days' },
    { value: '672', label: '28 days' }
];

export const isAddressDetailsTouched = dataForm => {
    if (dataForm.dirtyFields) {
        return Object.keys(dataForm.dirtyFields).some(addressItemKey => {
            return dataForm.dirtyFields[addressItemKey] === true && ADDRESS_DETAILS_FIELDS.includes(addressItemKey);
        });
    }
    return false;
};

export const processServerErrorsWithMessages = (errors, flatten = false) => {
    let res = {};
    map(errors, (error, key) => {
        if (typeof error === 'object') {
            if (flatten) {
                res = processServerErrorsWithMessages(error);
            } else {
                res[key] = processServerErrorsWithMessages(error);
            }

            return;
        }

        if (includes(error, 'is required')) {
            res[key] = `${upperFirst(lowerCase(key))} is required`;
            return;
        }

        res[key] = error;
    });

    return res;
};

export const formatDocumentsForApi = documents => {
    let newDocuments = new FormData();

    map(documents, (value, key) => {
        newDocuments.append(`documents[${key}][documentName]`, value.documentName);
        newDocuments.append(`documents[${key}][category]`, value.category);
        newDocuments.append(`documents[${key}][file]`, value.file);

        if (has(value, 'agencyDefault')) {
            newDocuments.append(`documents[${key}][agencyDefault]`, value.agencyDefault);
        }

        if (has(value, 'leaseType')) {
            newDocuments.append(`documents[${key}][leaseType]`, value.leaseType);
        }

        if (has(value, 'fileName')) {
            newDocuments.append(`documents[${key}][fileName]`, value.fileName);
        }

        if (has(value, 'size')) {
            newDocuments.append(`documents[${key}][size]`, value.size);
        }

        if (has(value, 'file')) {
            if (value.file.size) {
                newDocuments.append(`documents[${key}][size]`, value.file.size);
            }
        }
    });

    return newDocuments;
};

export const formatCurrency = (value, withFractions = false, defaultValue = '0') => {
    if (value) {
        let minimumFractionDigits = 0;
        // if there are decimals we always want to show both digits
        // Examples:
        // 26.4 -> 26.40
        // 132 -> 132
        if (withFractions && value % 1) {
            minimumFractionDigits = 2;
        }
        return value.toLocaleString(undefined, {
            minimumFractionDigits: minimumFractionDigits,
            maximumFractionDigits: withFractions ? 2 : 0
        });
    } else {
        return defaultValue;
    }
};

export const formatCurrencyWithCurrencySign = (value, withFractions = false, minimumFractionDigits = 0) => {
    if (value) {
        if (typeof value === 'number') {
            const newValue = value.toLocaleString(undefined, {
                minimumFractionDigits,
                maximumFractionDigits: withFractions ? 2 : 0
            });
            return `$${newValue}`;
        } else {
            return value;
        }
    } else {
        return '0';
    }
};

export const formatCurrencyWithSeparator = value => {
    if (value === 'NIL') {
        return value;
    }
    if (value) {
        return `${value.startsWith('$') ? '' : '$'}${value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;
    } else {
        return '$0.00';
    }
};

export const getHeaderTitle = (leaseType, subLeaseType) => {
    let title = '';
    if (leaseType && (!subLeaseType || isDefaultSubLease(subLeaseType))) {
        const frontEndLeaseType = getLeaseTypeForFrontEnd(leaseType);
        switch (frontEndLeaseType) {
            case LEASE_TYPE_SALES:
                title = 'Sales Agreement';
                break;
            case LEASE_TYPE_RESIDENTIAL:
                title = 'Lease Agreement';
                break;
            case LEASE_TYPE_PROPERTY_MANAGEMENT:
                title = 'Property Management Agreement';
                break;
            case LEASE_TYPE_COMMERCIAL_PROPERTY_MANAGEMENT:
                title = 'Commercial Property Management Agreement';
                break;
            case DOCUMENT_CUSTOM:
                title = FLK_A_PDF_DISPLAY;
                break;
            case DOCUMENT_CREATE_A_FLK:
                title = CREATE_A_FLK_DISPLAY;
                break;
            case CREATE_A_FLK_GLOBAL_TEMPLATE:
                title = `${CREATE_A_FLK_DISPLAY} Global Template`;
                break;
            case CREATE_A_FLK_TEMPLATE:
                title = `${CREATE_A_FLK_DISPLAY} Template`;
                break;
            case FLK_A_PDF_TEMPLATE:
                title = `${FLK_A_PDF_DISPLAY} Template`;
                break;
            case FLK_A_PDF_GLOBAL_TEMPLATE:
                title = `${FLK_A_PDF_DISPLAY} Global Template`;
                break;
            case LEASE_TYPE_COMMERCIAL_LEASE:
                title = 'Commercial Lease';
                break;
            default:
                title = 'UNKNOWN';
        }
    } else if (subLeaseType) {
        switch (subLeaseType) {
            case SUB_LEASE_TYPE_LONG_TERM_RENEWAL:
                title = 'Notice of Lease Extension';
                break;
            default:
                title = 'UNKNOWN';
        }
    }
    return title;
};

/**
 * Get the error for an input, if we have frontend validation, we want to use the shouldBeTouched as this will only validate on submit or when the field was touched
 * @param meta
 * @param shouldBeTouched
 * @returns {*|boolean}
 */
export const getError = (meta, shouldBeTouched = false) => {
    return getFormError(meta, shouldBeTouched);
};

function formatDates(object1, timezone) {
    for (const key in object1) {
        if (object1.hasOwnProperty(key)) {
            if (isValid(parseISO(object1[key]))) {
                object1[key] = formatDateStandard(object1[key], timezone);
            }
        }
    }
}

/**
 * Compare object ignoring time component of dates
 * Returns true if they are not the same
 *
 * @param object1
 * @param object2
 * @param timeZone
 * @returns {boolean}
 */
export const compareObjectsIgnoreTime = (object1, object2, timeZone) => {
    const cloned1 = cloneDeep(object1);
    const cloned2 = cloneDeep(object2);
    formatDates(cloned1, timeZone);
    formatDates(cloned2, timeZone);
    return JSON.stringify(cloned1) !== JSON.stringify(cloned2);
};

export const customSelectStyle = {
    menu: provided => ({
        ...provided,
        zIndex: 100
    }),
    control: (provided, state) => ({
        ...provided,
        opacity: state.isDisabled ? 0.5 : 1,
        maxHeight: state.isMulti ? 'none' : 36,
        minHeight: 36,
        border: state.isFocused ? 'none' : 'none',
        boxShadow: state.isFocused ? 0 : 0,
        borderColor: state.isFocused ? '#d1d7e3' : '#d1d7e3',
        borderBottom: '1px solid #d1d7e3',
        '&:hover': {
            borderColor: state.isFocused ? '#d1d7e3' : '#d1d7e3'
        }
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        maxHeight: state.isMulti ? 'none' : 36,
        minHeight: 36,
        paddingLeft: 0
    }),
    singleValue: (provided, state) => ({
        ...provided,
        marginLeft: 0,
        opacity: state.isDisabled ? 0.5 : 1
    })
};

export const pluralize = (count, noun) => `${noun}${count > 1 ? 's' : ''}`;

// Creates an object of errors for final form from a yup validation error.
// Uses final-form setIn function to set the attributes in the object - is needed for array fields.
export const getErrorObjectFromYupError = validationError =>
    validationError.inner.reduce(
        (errors, innerError) => (innerError.path ? setIn(errors, innerError.path, innerError.message) : errors),
        {}
    );

/**
 * Handles value changes for an input element, emitting both form-specific and external onChange events.
 *
 * @param {object} input - The input element.
 * @param {function} onChange - The external onChange callback (optional).
 * @param {any} value - The new value to be applied.
 */
export const handleInputValueChange = (input, onChange, value) => {
    // Emit the onChange for inputs INSIDE a form.
    // Notice how we don't need the form.change; we can
    // use the Field API.
    input.onChange(value);

    // Emit the onChange for external functions.
    if (onChange) {
        onChange(value);
    }
};
