import React, { forwardRef, useState, useImperativeHandle, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { has } from 'lodash';
import { Form, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

import { updateSubmitTypeSuccess, setDirtyStep, formSubmitFail } from '../../../actions/lease';
import * as Lease from '../../../reducers/lease';

import { getLocation, getGuarantor, getStep, getDirtyStep, getLeaseType } from '../../../selectors/lease';

import { CheckboxCheck } from '../../../components/form/FormCheckboxCheck';
import FormSection from '../../../components/form/FormSection';
import { FormTextRegular } from '../../../components/form/FormText';
import { FormPhone } from '../../../components/form/FormPhone';
import ExtraContacts from '../../../components/lease/extraContacts/ExtraContacts';

const GUARANTOR_FORM = 'guarantorForm';
const initState = {
    persons: [{ firstName: '', secondName: '' }]
};

const Guarantor = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const reduxGuarantor = useSelector(getGuarantor);
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [guarantor, setGuarantor] = useState(initState);

    useEffect(() => {
        setGuarantor({
            ...initState,
            ...reduxGuarantor
        });
    }, []);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(GUARANTOR_FORM).dispatchEvent(
                new Event('submit', {
                    cancelable: true,
                    bubbles: true
                })
            );
        }
    }));

    const updateGuarantor = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/guarantor`, data);
    };

    const submitForm = values => {
        /**
         * Store the ref of the component so it can be used in this function
         * We need this otherwise the promise below doesn't have access to the ref
         */
        const currentRef = ref.current;

        /**
         * Grab the bypassFormValidation that was set from the parent component: LeaseAgreementForm.js
         */
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updateGuarantor(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    /**
                     * Callback after submit this form so that the parent component can take an action
                     */
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.guarantor')) {
                    return error.response.data.errors.guarantor;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    return (
        <div className="guarantor">
            <Form
                onSubmit={submitForm}
                initialValues={guarantor}
                mutators={{
                    ...arrayMutators
                }}
            >
                {({
                    handleSubmit,
                    values,
                    form,
                    form: {
                        mutators: { push, removeBatch }
                    }
                }) => {
                    return (
                        <form onSubmit={handleSubmit} id={GUARANTOR_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <FieldArray name="persons" initialValue={guarantor.persons}>
                                {({ fields }) => (
                                    <React.Fragment>
                                        {fields.map((name, index) => {
                                            const persons = values?.persons || [];
                                            return (
                                                <FormSection
                                                    key={index}
                                                    title={`Guarantor ${index + 1}`}
                                                    showRemoveButton={persons.length > 1}
                                                    removeButtonProps={{
                                                        onClick: () => fields.remove(index),
                                                        label: 'Remove'
                                                    }}
                                                >
                                                    <FormTextRegular
                                                        name={`persons[${index}].firstName`}
                                                        label="First Name"
                                                        required
                                                        disabled={values.noGuarantor}
                                                    />
                                                    <FormTextRegular
                                                        name={`persons[${index}].secondName`}
                                                        label="Last Name"
                                                        required
                                                        disabled={values.noGuarantor}
                                                    />
                                                    <FormTextRegular
                                                        name={`persons[${index}].address`}
                                                        label="Address"
                                                        required
                                                        disabled={values.noGuarantor}
                                                    />
                                                    <FormPhone
                                                        name={`persons[${index}].phone`}
                                                        label="Phone"
                                                        required
                                                        disabled={values.noGuarantor}
                                                        dataTest={`persons[${index}]-phone`}
                                                    />
                                                    <FormTextRegular
                                                        name={`persons[${index}].email`}
                                                        label="Email"
                                                        required
                                                        disabled={values.noGuarantor}
                                                    />
                                                    <ExtraContacts
                                                        name={`persons[${index}].extraContacts`}
                                                        initialValue={fields.value[index].extraContacts}
                                                        push={push}
                                                        index={index}
                                                        blockType="persons"
                                                        disabled={values.noGuarantor}
                                                    />
                                                </FormSection>
                                            );
                                        })}
                                    </React.Fragment>
                                )}
                            </FieldArray>
                            {!values.noGuarantor && (
                                <div className="form-section__button-group">
                                    <button
                                        type="button"
                                        className="action-button"
                                        onClick={() => {
                                            push('persons', {
                                                email: ''
                                            });
                                        }}
                                    >
                                        Add guarantor
                                    </button>
                                </div>
                            )}
                            <CheckboxCheck
                                name="noGuarantor"
                                label="No guarantor"
                                onClick={value => {
                                    if (value) {
                                        let indexes = values?.persons?.map((_, i) => i) || [];
                                        removeBatch('persons', indexes);
                                    } else {
                                        push('persons', {
                                            email: ''
                                        });
                                    }
                                }}
                            />
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(Guarantor);
