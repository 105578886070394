import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { IntegrationConnect } from './IntegrationConnect.js';
import { IntegrationDisconnect } from './IntegrationDisconnect.js';
import ModalDialog from '../../common/components/ModalDialog.js';
import IntegrationAddressSection from '../../containers/dashboard/documents/FlkAPdf/integration/IntegrationAddressSection';
import Button from '../../common/components/Button.js';
import { getUserInfo } from '../../selectors/user.js';
import { User } from '../../types/User';
import { UploadADoc } from '@app/types/UploadADoc';
import { integration as integrationCompanies } from '@app/containers/dashboard/documents/FlkAPdf/integration/EnableIntegrationSection';

import styles from './IntegrationConnectionV2.module.scss';

type Integration = {
    property: {
        addressText?: string;
        AddressText?: string;
    };
    address?: string;
    type: string;
};

type AgencyIntegrations = {
    activeIntegration: string;
};

type IntegrationConnectionV2Props = {
    integration: Integration;
    agencyIntegrations: AgencyIntegrations;
    viewOnly: boolean;
    doc: UploadADoc;
    linkIntegrationProperty: (data: FormValues) => void;
    unLinkIntegrationProperty: () => void;
};

type FormValues = {
    address: string;
    integrationPropertyId: string;
    integration: string;
    integrationType: string;
    vaultReLifeId: string;
    vaultReLifeType: string;
};

export const IntegrationConnectionV2: React.FC<IntegrationConnectionV2Props> = ({
    integration,
    agencyIntegrations,
    viewOnly = false,
    doc,
    linkIntegrationProperty,
    unLinkIntegrationProperty
}) => {
    const [error, setError] = useState('');
    const [integrationError, setIntegrationError] = useState('');
    const loggedInUser: User = useSelector(getUserInfo);

    const [isOpenIntegrationModal, setIsOpenIntegrationModal] = useState(false);

    const unlink = useCallback(() => {
        axios
            .delete(`/api/document/${doc.id}/integration`)
            .then(() => {
                unLinkIntegrationProperty();
            })
            .catch(() => {
                setError('Something went wrong, please try again.');
            });
    }, [doc.id, unLinkIntegrationProperty]);

    const propertyIsConnected =
        integration && ((integration.property && typeof integration.property === 'object') || integration.address);
    const agencyIsConnected = agencyIntegrations && agencyIntegrations.activeIntegration;

    let address =
        integration && integration.property
            ? integration.property.addressText || integration.property.AddressText
            : false;
    if (!address && integration) {
        address = integration.address;
    }

    function handleSubmit(values: FormValues) {
        const data = {
            address: values.address,
            integrationPropertyId: values.integrationPropertyId,
            integration: values.integration,
            integrationType: values.integrationType,
            vaultReLifeId: values.vaultReLifeId,
            vaultReLifeType: values.vaultReLifeType
        };
        linkIntegrationProperty(data);
        setIsOpenIntegrationModal(false);
    }

    function getIntegrationLabel(selectedIntegration: string) {
        switch (selectedIntegration) {
            case integrationCompanies.INTEGRATION_PROPERTY_ME:
                return 'PropertyMe';
            case integrationCompanies.INTEGRATION_PROPERTY_TREE:
                return 'PropertyTree';
            case integrationCompanies.INTEGRATION_MANAGED:
                return 'Managed';
            case integrationCompanies.INTEGRATION_VAULT_RE:
                return 'VaultRE';
            default:
                return selectedIntegration;
        }
    }

    return (
        <React.Fragment>
            {agencyIsConnected && (
                <div className="integration-container for-lg-modal">
                    {propertyIsConnected && (
                        <IntegrationDisconnect
                            label={getIntegrationLabel(integration.type)}
                            propertyAddress={address}
                            onUnlink={unlink}
                            viewOnly={viewOnly}
                        />
                    )}
                    {!propertyIsConnected && (
                        <IntegrationConnect
                            label="Integration"
                            onLink={() => setIsOpenIntegrationModal(true)}
                            viewOnly={viewOnly}
                        />
                    )}

                    {error && (
                        <div className="error-message">
                            <p>{error}</p>
                        </div>
                    )}
                </div>
            )}
            <ModalDialog
                title="Link with integration"
                isOpen={isOpenIntegrationModal}
                closeModal={() => setIsOpenIntegrationModal(false)}
                className={styles.integrationModal}
            >
                <Form onSubmit={handleSubmit}>
                    {({ handleSubmit, values }) => {
                        return (
                            <form noValidate onSubmit={values => handleSubmit(values)}>
                                <IntegrationAddressSection
                                    loggedInUser={loggedInUser}
                                    setIntegrationError={(err?: string) => setIntegrationError(err || '')}
                                    integrationError={integrationError}
                                />
                                <div className={styles.footer}>
                                    <Button type="button" onClick={() => setIsOpenIntegrationModal(false)}>
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        primary
                                        className={styles.submitButton}
                                        disabled={!values.address}
                                    >
                                        Save Changes
                                    </Button>
                                </div>
                            </form>
                        );
                    }}
                </Form>
            </ModalDialog>
        </React.Fragment>
    );
};
