import React from 'react';
import { InfoSharp, CloseSharp } from '@flk-mui-icons';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { updateUserLoginData } from '../../actions/user';
import { useMutation } from '@tanstack/react-query';

import styles from './Banner.module.scss';
import cx from 'classnames';
import { HashLink } from 'react-router-hash-link';

function Banner({
    title,
    message,
    link,
    linkLabel,
    bannerId,
    agencyId,
    onClick,
    hideCloseButton = false,
    messageTextClassName = ''
}) {
    const dispatch = useDispatch();

    const saveBannerCloseDetails = useMutation(
        data => {
            return axios.post(`/api/agency/${agencyId}/close-banner`, data);
        },
        {
            onSuccess: response => {
                dispatch(updateUserLoginData(response.data.user));
            }
        }
    );

    function close() {
        // After agent close the banner then we store it in DB. And never show it again
        saveBannerCloseDetails.mutate({ bannerId });
    }

    return (
        <div className={styles.banner}>
            <div className={styles.header}>
                <div className={styles.titleGroup}>
                    <InfoSharp className={styles.infoIcon} />
                    <p className={styles.title}>{title}</p>
                </div>
                {!hideCloseButton && (
                    <CloseSharp
                        className={cx(styles.closeIcon, { disabled: saveBannerCloseDetails.isLoading })}
                        onClick={close}
                    />
                )}
            </div>
            <p className={cx(styles.message, messageTextClassName)}>{message}</p>
            {link && linkLabel && (
                <HashLink className={styles.link} to={link} onClick={onClick} smooth>
                    {linkLabel}
                </HashLink>
            )}
        </div>
    );
}

export default Banner;
