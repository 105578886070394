import React from 'react';
import { FormMaskedText } from '@app/components/form/FormMaskedText';
import { FormTextRegular } from '../../../form/FormText';
import { FormPhone } from '../../../form/FormPhone';
import { FormButtonSelect } from '../../../form/responsive/FormButtonSelect';
import { lessorCorporationRolesList } from '../../../../config';
import { CheckboxCheck } from '../../../form/FormCheckboxCheck';
import ExtraContacts from '../../../lease/extraContacts/ExtraContacts';
import Warning from '../../../dashboard/warnings/Warning';

function LessorFields({ index, person, values, push }) {
    const getInputFields = () => {
        if (values.isCorporation) {
            return (
                <React.Fragment>
                    <FormTextRegular name={`persons[${index}].name`} label="Name" required />
                    <React.Fragment>
                        <FormButtonSelect
                            name={`persons[${index}].role`}
                            selectClassName={`person-${index}-role`}
                            options={lessorCorporationRolesList}
                            value={person.role}
                            label="Role"
                            required
                            dataTest={`persons[${index}]-role`}
                        />
                        {person.role === 'Other' && (
                            <FormTextRegular name={`persons[${index}].otherRole`} label="Role name" required />
                        )}
                    </React.Fragment>
                    <FormPhone
                        dataTest={`persons[${index}]-phone`}
                        name={`persons[${index}].phone`}
                        label="Phone"
                        required
                    />
                    <FormTextRegular name={`persons[${index}].email`} label="Email" required />
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <FormTextRegular name={`persons[${index}].firstName`} label="First Name" required />
                    <FormTextRegular name={`persons[${index}].secondName`} label="Last Name" required />
                    <FormTextRegular name={`persons[${index}].address`} label="Address" />
                    {values.persons[index].address && (
                        <>
                            <Warning>Any information entered here will be displayed on the lease</Warning>
                            <br />
                        </>
                    )}
                    <FormPhone dataTest={`persons[${index}]-phone`} name={`persons[${index}].phone`} label="Phone" />
                    {values.persons[index].phone && (
                        <>
                            <Warning>Any information entered here will be displayed on the lease</Warning>
                            <br />
                        </>
                    )}
                    <FormTextRegular name={`persons[${index}].email`} label="Email" />
                    {values.persons[index].email && (
                        <>
                            <Warning>Any information entered here will be displayed on the lease</Warning>
                            <br />
                        </>
                    )}
                    <FormMaskedText
                        dataTest={`persons[${index}]-abn`}
                        name={`persons[${index}].abn`}
                        label="ABN"
                        mask="11 111 111 111"
                    />
                    <FormMaskedText
                        dataTest={`persons[${index}]-acn`}
                        name={`persons[${index}].acn`}
                        label="ACN"
                        mask="111 111 111"
                    />
                    <CheckboxCheck name={`persons[${index}].isRegisteredForGST`} label="Registered for GST" />
                </React.Fragment>
            );
        }
    };
    const { extraContacts } = person;
    return (
        <>
            {getInputFields()}
            <ExtraContacts
                name={`persons[${index}].extraContacts`}
                initialValue={extraContacts}
                push={push}
                index={index}
                blockType="persons"
            />
        </>
    );
}

export default LessorFields;
