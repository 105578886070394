import React from 'react';
import { getId } from '@app/utils/getId';
import { useQuery } from '@tanstack/react-query';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getClStepNo, getClStepTitle } from '../../../../../utils/agreementUtils';
import { signorLinkExists } from '../../../../../utils/signorUtils';
import DataString from '../bloks/DataString';
import { LeaseAllInfo } from './types';
import useClipboard from '../../../../../hooks/useClipboard.ts';
import { getSignorLinks } from '../../../../../actions/lease';
import {
    LEASE_STATUS_AWAITING_COMPLETION,
    LEASE_STATUS_AWAITING_RENEWAL,
    LEASE_STATUS_DRAFT,
    LEASE_STATUS_SENT_SIGNING
} from '../../../../../actions/dashboard';

type Props = {
    leaseAllInfo: LeaseAllInfo;
};

export default function AgreementClLessee({ leaseAllInfo }: Props) {
    const { copyToClipboard } = useClipboard();
    const part = 'lessee';
    if (!leaseAllInfo?.lessee) {
        return <></>;
    }
    const { location, lessee } = leaseAllInfo;
    const { data: signorLinks } = useQuery(['signorLinks', leaseAllInfo], async () => {
        if (leaseAllInfo.status === LEASE_STATUS_SENT_SIGNING) {
            const promise = getSignorLinks(leaseAllInfo.id).promise;
            const response = await promise;
            return response.data.signorsWithLinks;
        }
        return [];
    });
    const copyLinkToClipboard = (userId) => {
        if (signorLinks) {
            const matchingSignor = signorLinks.find(signor => getId(signor) === userId);
            if (matchingSignor) {
                copyToClipboard(matchingSignor.signorLink, 'Link Copied!');
            }
        }
    };

    return (
        <div>
            <AgreementSection
                title={getClStepTitle(location, part)}
                step={getClStepNo(location, part)}
                leaseAllInfo={leaseAllInfo}
            >
                {lessee && (
                    <div>
                        {lessee.isCorporation && (
                            <>
                                <div className="data data-row">
                                    <p className="text">
                                        <span className="bold">{lessee.corporationName}</span>
                                    </p>
                                    <p>{lessee.corporationAddress}</p>
                                    <DataString title={'ABN'} text={lessee.abn} />
                                    {lessee.acn && <DataString title={'ACN'} text={lessee.acn} />}
                                    {lessee.isRegisteredForGST && <p>Registered for GST</p>}
                                </div>
                            </>
                        )}
                        {Array.isArray(lessee.persons) &&
                            lessee.persons.map((person, index) => (
                                <>
                                    <div className="data data-row" key={index}>
                                        {lessee.isCorporation && (
                                            <p className="name">{`${person.name} (${person.role})`}</p>
                                        )}
                                        {!lessee.isCorporation && <p className="name">{person.fullName}</p>}
                                        <p className="name">{person.address}</p>
                                        {[
                                            {
                                                contactType: 'Ph',
                                                contactValue: person.phone
                                            },
                                            {
                                                contactType: 'E',
                                                contactValue: person.email
                                            },
                                            {
                                                contactType: 'ABN',
                                                contactValue: person.abn
                                            },
                                            {
                                                contactType: 'ACN',
                                                contactValue: person.acn
                                            },
                                            ...person.extraContacts
                                        ]
                                            .filter(i => i.contactValue)
                                            .map((extraContact, index) => (
                                                <DataString
                                                    title={extraContact.contactType}
                                                    text={extraContact.contactValue}
                                                    key={index}
                                                />
                                            ))}
                                        {!lessee.isCorporation && person.isRegisteredForGST && (
                                            <p>Registered for GST</p>
                                        )}
                                        {leaseAllInfo.status === LEASE_STATUS_SENT_SIGNING &&
                                            signorLinkExists(signorLinks, person.id) && (
                                                <div
                                                    className="special-link"
                                                    onClick={() => copyLinkToClipboard(person.id)}
                                                >
                                                    Copy signing link
                                                </div>
                                        )}
                                    </div>
                                </>
                            ))}
                    </div>
                )}
            </AgreementSection>
        </div>
    );
}
