import * as React from 'react';
import { getId } from '@app/utils/getId';
import cx from 'classnames';
import styles from './SignorLink.module.scss';
import Card, { CardStyles } from './cards/Card';
import { Tag, TagColor, TagSize } from './tag/Tag';
import Button from './Button.js';
import Icon, { Icons } from './Icon';

interface SignorLinkProps {
    className?: string;
    signors: Array<{
        firstName: string;
        secondName: string;
        signorLink: string;
        _id: string;
    }>;
    buttonText: string;
    buttonClickHandler: (id: string) => void;
    ref?: React.RefObject<HTMLDivElement>;
}

const getSenderTagColor = (index: number): TagColor => {
    switch(index) {
        case 1:
            return TagColor.Green;
        case 2:
            return TagColor.Orange;
        case 3:
            return TagColor.Yellow;
        case 4:
            return TagColor.Blue;
        case 5:
            return TagColor.Red;
        case 6:
            return TagColor.Pink;
        case 7:
            return TagColor.Aqua;
        case 8:
            return TagColor.Mint;
        case 9:
            return TagColor.Purple;
        default:
            return TagColor.Green;
    }
};

const SignorLink: React.FC<SignorLinkProps> = ({ className, signors, buttonText, buttonClickHandler, ref }) => {
    return (
        <div className={styles.signorLinkContainer} ref={ref}>
            <div className={styles.signorLinkScrollContent}>
                <div className={cx(styles.signorLink, className)}>
                    {signors?.map((signor, index) => {
                        return (
                            <Card key={index} className={styles.card} style={CardStyles.SQUARE}>
                                <Tag
                                    className={styles.signer}
                                    text={`Signer ${index + 1}`}
                                    color={getSenderTagColor(index + 1)}
                                    size={TagSize.Small}
                                />
                                <h3 className={styles.signerName}>{`${signor.firstName} ${signor.secondName}`}</h3>
                                <Button
                                    key={getId(signor)}
                                    primary 
                                    startIcon={<Icon icon={Icons.COPY_PRIMARY} />}
                                    onClick={() => buttonClickHandler(getId(signor))}
                                    className={cx(styles.copyButton, styles.button)} 
                                >
                                    {buttonText}
                                </Button>
                            </Card>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default SignorLink;
