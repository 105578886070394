import React, { useEffect } from 'react';
import { getId } from '@app/utils/getId';
import { useQuery } from '@tanstack/react-query';

import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection.js';
import SignorLink from '../../../../../common/components/SignorLink';
import { getSignorLinks } from '../../../../../actions/lease.js';
import useClipboard from '../../../../../hooks/useClipboard';

import styles from './AgreementSignorLinks.module.scss';

interface AgreementSignorLinksProps {
    leaseAllInfo: any
}

const AgreementSignorLinks: React.FC<AgreementSignorLinksProps> = ({
    leaseAllInfo
}) => {
    const signorLinkRef = React.useRef(null);
    const { copyToClipboard } = useClipboard();
    const { data: signorLinks } = useQuery(['signorLinks', leaseAllInfo.id], async () => {
        const promise = getSignorLinks(leaseAllInfo.id).promise;
        const response = await promise;
        return response.data.signorsWithLinks;
    }, {
        onSuccess: () => {
            if (signorLinkRef && signorLinkRef.current) {
                signorLinkRef.current.scrollTop = 0;
            }
        }
    });
    const copyLinkToClipboard = (userId: string) => {
        if (signorLinks) {
            const matchingSignor = signorLinks.find(signor => getId(signor) === userId);
            if (matchingSignor) {
                copyToClipboard(matchingSignor.signorLink, 'Link Copied!', styles.copyLinkToast);
            }
        }
    };

    return(
        <AgreementSection title="Signing Links">
            <SignorLink 
                signors={signorLinks} 
                buttonText='Copy Link' 
                buttonClickHandler={(userId) => copyLinkToClipboard(userId)}
                ref={signorLinkRef}
            />
        </AgreementSection>
    );
};

export default AgreementSignorLinks;
