import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, pullAt } from 'lodash';
import Panel from '../../../common/components/Panel';
import { rentPeriods as frequency } from '../../../config';
import { SelectField } from '../../form/FormSelect';
import { Form } from 'react-final-form';
import { FormTextRegular } from '../../form/FormText';
import { useEffect, useState } from 'react';
import * as validators from '../../../components/Validate';
import axios from 'axios';
import { UPDATE_ACTIVITY_REPORT_RECEIVER_SUCCESS } from '../../../actions/types';
import { getUserAgency } from '../../../selectors/agency';
import ReactTooltip from 'react-tooltip';
import { InfoSharp } from '@flk-mui-icons';
import Button from '../../../common/components/Button';

export function Activity({ initialActivityReportSettings }) {
    const dispatch = useDispatch();
    const agency = useSelector(getUserAgency);

    const [addEmailFieldError, setAddEmailFieldError] = useState();
    const [frequencyFieldError, setFrequencyFieldError] = useState();
    const [failError, setFailError] = useState();
    const [activityReportSettings, setActivityReportSettings] = useState(initialActivityReportSettings);

    useEffect(() => {
        setActivityReportSettings(initialActivityReportSettings);
    }, [initialActivityReportSettings]);

    const updateActivityReportReceivers = function(agencyId, data) {
        axios
            .post(`api/agency/${agencyId}/activity-report-receivers`, data)
            .then(result => {
                dispatch({
                    type: UPDATE_ACTIVITY_REPORT_RECEIVER_SUCCESS,
                    result
                });
            })
            .catch(() => {
                setFailError('Failed to add reciever, admin access required, please try again');
            });
    };

    const handleKeyPress = (event, values, form) => {
        // Only if the user pressed enter
        if (event.keyCode === 13) {
            event.preventDefault();
            addActivityReportReceiver(values, form);
        }
    };

    const addActivityReportReceiver = (receiver, form) => {
        let initialSettings = initialActivityReportSettings;
        let isEmailValid = validateEmail(receiver.emailAddress);
        let isFrequencyValid = validateFrequency(receiver.frequency);
        if (isEmailValid && isFrequencyValid) {
            const newReceiver = {
                emailAddress: receiver.emailAddress,
                frequency: receiver.frequency
            };
            initialSettings.activityReportReceivers = [...initialSettings.activityReportReceivers, newReceiver];
            setActivityReportSettings(initialSettings);

            updateActivityReportReceivers(agency.id, initialSettings);

            form.change('emailAddress', undefined);
        }
    };

    const removeReceiver = index => {
        let initialSettings = initialActivityReportSettings;
        const initialArray = initialSettings.activityReportReceivers;
        pullAt(initialArray, index);
        setActivityReportSettings({ activityReportReceivers: [...initialArray] });
        updateActivityReportReceivers(agency.id, initialSettings);
    };

    const validateEmail = value => {
        let valid = true;
        if (isEmpty(value)) {
            setAddEmailFieldError('Email address required');
            valid = false;
            return valid;
        } else {
            setAddEmailFieldError(undefined);
        }

        if (!validators.isCorrectEmail(value)) {
            setAddEmailFieldError('Wrong email format');
            valid = false;
            return valid;
        } else {
            setAddEmailFieldError(undefined);
        }

        return valid;
    };

    const validateFrequency = value => {
        if (isEmpty(value)) {
            setFrequencyFieldError('Please select frequency');
            return false;
        } else {
            setFrequencyFieldError(undefined);
        }
        return true;
    };

    return (
        <div>
            <Panel title="Activity report" className="activity-report">
                <ReactTooltip id="activityInfo" class="tooltip" place="bottom" effect="solid" globalEventOff="wheel">
                    For residential tenancy agreements, it will include:
                    <ul>
                        <li>Address</li>
                        <li>Rental amount</li>
                        <li>Rental increase (if applicable)</li>
                        <li>Lease start date</li>
                        <li>Staff member responsible</li>
                    </ul>
                </ReactTooltip>
                <p className="text-gray">
                    This report will be sent to you weekly, fortnightly or monthly showing all of your completed
                    documents for the chosen timeframe.
                </p>
                <p className="text-gray">This is a great way to get a snapshot of all your successful activity.</p>
                <p className="text-gray">
                    <br />
                    <span data-tip={true} data-for="activityInfo">
                        More info
                        <InfoSharp className="tooltip-info-icon" />
                    </span>
                </p>
                <br />
                <br />
                <Form onSubmit={() => {}}>
                    {({ values, form }) => {
                        return (
                            <form>
                                <div>
                                    <p className="help-text">Frequency</p>
                                    <SelectField name="frequency" options={frequency} selectClassName="sel-5" />
                                </div>
                                {frequencyFieldError && <p className="has-error">{frequencyFieldError}</p>}
                                <FormTextRegular
                                    name="emailAddress"
                                    label="Enter email and press enter"
                                    onKeyDown={e => handleKeyPress(e, values, form)}
                                />
                                {addEmailFieldError && <p className="has-error">{addEmailFieldError}</p>}
                                {failError && <p className="has-error">{failError}</p>}
                                <Button primary type="button" onClick={() => addActivityReportReceiver(values, form)}>
                                    Subscribe
                                </Button>
                            </form>
                        );
                    }}
                </Form>
                {activityReportSettings.activityReportReceivers.length > 0 && (
                    <React.Fragment>
                        <h3>Subscribers</h3>
                        <ul className="emails-list">
                            {activityReportSettings.activityReportReceivers.map((activityReportSetting, index) => (
                                <li className="column-box" key={index}>
                                    <div
                                        className="individual-email-activity"
                                        title={activityReportSetting.emailAddress}
                                    >
                                        {activityReportSetting.emailAddress}
                                    </div>
                                    <div className="individual-frequency">
                                        {activityReportSetting.frequency}
                                        <button
                                            className="btn-delete"
                                            type="button"
                                            onClick={() => removeReceiver(index)}
                                        />
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </React.Fragment>
                )}
            </Panel>
        </div>
    );
}

export default Activity;
