import React, { useEffect, useState, memo } from 'react';
import { isTokenValid } from '../../actions/signatures';
import { getUserInfo } from '../../actions/user';

import Notice from './components/Notice';
import Header from './components/Header';

import '../../sass/agentSignatures/main.scss';
import turnPhoneImage from '../../../assets/images/turn_phone.svg?url';
import {
    getIsCheckingToken,
    getShouldLogOut,
    getIsTokenValid,
    getSignatureAddSuccess,
    getIsSendingRequest
} from '../../selectors/signatures';
import Expired from './scenes/Expired';
import Logout from './scenes/Logout';
import AgentSignature from './scenes/AgentSignature';
import Success from './scenes/Success';
import { useDispatch, useSelector } from 'react-redux';
import appHistory from '../../AppHistory';
import { setLoginRedirect } from '../../actions/login';

const Signatures = props => {
    const EXPIRED = 'EXPIRED';
    const AGENT = 'AGENT';
    const SUCCESS = 'SUCCESS';
    const LOGOUT = 'LOGOUT';

    const shouldLogOut = useSelector(getShouldLogOut);
    const tokenValid = useSelector(getIsTokenValid);
    const isCheckingToken = useSelector(getIsCheckingToken);
    const signatureAddSuccess = useSelector(getSignatureAddSuccess);
    const isSendingRequest = useSelector(getIsSendingRequest);

    const dispatch = useDispatch();

    let [display, setDisplay] = useState('None');
    let [showHeader, setShowHeader] = useState(false);
    let [step, setStep] = useState(null);

    useEffect(() => {
        let token = props.match.params.token;
        dispatch(setLoginRedirect(`${appHistory.location.pathname}${appHistory.location.search}`));
        dispatch(getUserInfo());
        if (!token) {
            setDisplay(EXPIRED);
            setShowHeader(false);
        } else {
            dispatch(isTokenValid(token));
        }
    }, [dispatch, props.match.params.token]);

    useEffect(() => {
        if (isCheckingToken != null && !isCheckingToken && !signatureAddSuccess) {
            if (shouldLogOut) {
                setDisplay(LOGOUT);
            } else if (tokenValid) {
                setDisplay(AGENT);
                setStep(1);
            } else {
                setDisplay(EXPIRED);
            }
            setShowHeader(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, isCheckingToken, shouldLogOut, tokenValid]);

    useEffect(() => {
        if (!isSendingRequest) {
            if (signatureAddSuccess) {
                setDisplay(SUCCESS);
            }
        }
    }, [signatureAddSuccess, isSendingRequest]);

    return (
        <div className="agent-signature wrap image">
            <div className="portrait">
                <img className="landscape-img" src={turnPhoneImage} alt="" />
                <div className="landscape-text">
                    <Notice>Please turn your device</Notice>
                </div>
            </div>

            <Header step={step} show={showHeader} />
            {display && display === EXPIRED && <Expired />}
            {display && display === AGENT && <AgentSignature />}
            {display && display === SUCCESS && <Success />}
            {display && display === LOGOUT && <Logout />}
        </div>
    );
};

export default memo(Signatures);
