import React from 'react';
import { getId } from '@app/utils/getId';
import { useQuery } from '@tanstack/react-query';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getSalesStepNo, getSalesStepTitle } from '../../../../../utils/agreementUtils';
import { signorLinkExists } from '../../../../../utils/signorUtils';
import ThumbDisplay from '../../../image/ThumbDisplay';
import '../../../../../sass/infoAgreement.scss';
import useClipboard from '../../../../../hooks/useClipboard.ts';
import { getSignorLinks } from '../../../../../actions/lease';
import {
    LEASE_STATUS_AWAITING_COMPLETION,
    LEASE_STATUS_AWAITING_RENEWAL,
    LEASE_STATUS_DRAFT,
    LEASE_STATUS_SENT_SIGNING
} from '../../../../../actions/dashboard';

export default function AgreementSalesSignatory({ leaseAllInfo }) {
    const partName = 'signatory';
    const { location } = leaseAllInfo;
    const { data: signorLinks } = useQuery(['signorLinks', leaseAllInfo], async () => {
        if (leaseAllInfo.status === LEASE_STATUS_SENT_SIGNING) {
            const promise = getSignorLinks(leaseAllInfo.id).promise;
            const response = await promise;
            return response.data.signorsWithLinks;
        }
        return [];
    });
    const { copyToClipboard } = useClipboard();
    const copyLinkToClipboard = (userId) => {
        if (signorLinks) {
            const matchingSignor = signorLinks.find(signor => getId(signor) === userId);
            if (matchingSignor) {
                copyToClipboard(matchingSignor.signorLink, 'Link Copied!');
            }
        }
    };   

    return (
        <AgreementSection
            title={getSalesStepTitle(location, partName)}
            step={getSalesStepNo(location, partName)}
            leaseAllInfo={leaseAllInfo}
        >
            {leaseAllInfo.signatory?.signatories?.map((item, i) => {
                return (
                    <div className="data data-half-width" key={i}>
                        {item.isCorporation && (
                            <React.Fragment>
                                <p className="name">{item.corporationName}</p>
                                <p className="name">{item.corporationAddress}</p>
                            </React.Fragment>
                        )}
                        <p className="name">{`${item.signatoryFullName}${
                            item.isCorporation ? ` (${item.roleDescription})` : ''
                        }`}</p>
                        {item.phoneNumber && <p className="phoneNumber">{item.phoneNumber}</p>}
                        <p className="phone">{item.phone}</p>
                        <p className="email">{item.email}</p>
                        <p className="address">{item.address}</p>
                        {item.ABN && <p>ABN: {item.ABN}</p>}
                        {item.ACN && <p>ACN: {item.ACN}</p>}
                        {item.isRegisteredForGST && <p>Registered for GST</p>}
                        {item.isPrimaryVendor && <p>Primary client</p>}
                        <p>Required to upload proof of identity: {leaseAllInfo.signatory.salesIdentificationRequired ? 'YES' : 'NO'}</p>
                        <p className="image-url">
                            {item.ownerImage && (
                                <ThumbDisplay imageSrc={item.ownerImage.base64Image} imageTitle={'Vendor Id'} />
                            )}
                        </p>
                        {leaseAllInfo.status === LEASE_STATUS_SENT_SIGNING  
                        && signorLinkExists(signorLinks, item.id)
                        && <div className="special-link" onClick={() => copyLinkToClipboard(item.id)}>Copy signing link</div> }
                    </div>
                );
            })}
            
        </AgreementSection>
    );
}
