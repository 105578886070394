import React, { memo, useState, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { cloneDeep, has } from 'lodash';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';

import * as Lease from '../../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../../selectors/lease';
import { getDisclosureOfRebates } from '../../../../selectors/lease/pmLease';

import { CommissionGroup } from './CommissionGroup';
import { RebateGroup } from './RebateGroup';
import { CheckboxCheck } from '../../../../components/form/FormCheckboxCheck';

import '../../../../sass/conditions.scss';
import { ReactComponent as plusIcon } from '../../../../../assets/images/icons/plus.svg';
import { getLabel } from '../../../../utils/labelUtils';

const PM_DISCLOSURE_REBATES_FORM = 'disclosureOfRebatesForm';
const initState = {
    rebateList: []
};

function DisclosureRebateLayout(props, ref) {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxDisclosureOfRebates = useSelector(getDisclosureOfRebates);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [disclosureOfRebates, setDisclosureOfRebates] = useState(reduxDisclosureOfRebates || initState);
    const [error, setError] = useState(false);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(PM_DISCLOSURE_REBATES_FORM).dispatchEvent(
                new Event('submit', {
                    cancelable: true,
                    bubbles: true
                })
            );
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return axios
            .post(`/api/agency/lease/${props.leaseId}/rebate-requests`, values)
            .then(result => {
                result.isLeaseEditingFinished = false;
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.disclosureOfRebates')) {
                    return error.response.data.errors.disclosureOfRebates;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const removeCommission = (values, index) => {
        let newDisclosureRebates = cloneDeep(values);

        newDisclosureRebates.commissionList.splice(index, 1);

        setDisclosureOfRebates(newDisclosureRebates);
    };

    const removeRebate = (values, index) => {
        let newDisclosureRebates = cloneDeep(values);

        newDisclosureRebates.rebateList.splice(index, 1);

        setDisclosureOfRebates(newDisclosureRebates);
    };

    return (
        <div className="rebates-block">
            <h3>{getLabel('disclosureTitle', location, leaseType)}</h3>
            <div className="conditions full-width">
                <Form
                    onSubmit={submitForm}
                    initialValues={{
                        ...disclosureOfRebates,
                        isAgentEntitled: disclosureOfRebates.rebateList?.length > 0 || false,
                        isAgentCommissionShared: disclosureOfRebates.commissionList?.length > 0 || false
                    }}
                    mutators={{
                        ...arrayMutators
                    }}
                >
                    {({
                        handleSubmit,
                        values,
                        form,
                        form: {
                            mutators: { push, removeBatch }
                        }
                    }) => {
                        return (
                            <form onSubmit={handleSubmit} noValidate id={PM_DISCLOSURE_REBATES_FORM}>
                                <FormSpy
                                    subscription={{ values: true }}
                                    onChange={state => handleFormDirtyChange(state.values, form)}
                                />
                                <React.Fragment>
                                    <h4 className="section-heading">Disclosure 1 - Rebate Statement</h4>
                                    <div className="section-subheading">Section 48A, Estate Agents Act 1980 VIC</div>
                                    <CheckboxCheck
                                        label="The Agent will be, or is likely to be, entitled to any rebates. A rebate includes any discount, commission, or the other benefit, and includes non-monetary benefits."
                                        name="isAgentEntitled"
                                        className="isAgentEntitled mobile-multi-select-enabled"
                                        onClick={value => {
                                            if (!value) {
                                                let indexes = values?.rebateList?.map((_, i) => i) || [];
                                                removeBatch('rebateList', indexes);
                                            }
                                        }}
                                    />
                                    {values.isAgentEntitled && (
                                        <React.Fragment>
                                            <FieldArray name="rebateList" initialValue={disclosureOfRebates.rebateList}>
                                                {({ fields }) => (
                                                    <React.Fragment>
                                                        {fields.map((name, index) => {
                                                            return (
                                                                <RebateGroup
                                                                    index={index}
                                                                    item={fields.value[index]}
                                                                    key={`${index}`}
                                                                    removeRebate={index => removeRebate(values, index)}
                                                                />
                                                            );
                                                        })}
                                                    </React.Fragment>
                                                )}
                                            </FieldArray>
                                            <div className="button">
                                                <button
                                                    className="mobile add-item"
                                                    type="button"
                                                    onClick={() =>
                                                        push('rebateList', {
                                                            source: '',
                                                            amount: '$'
                                                        })
                                                    }
                                                >
                                                    <img src={plusIcon} className="for-sm-modal" />
                                                    Add rebate
                                                </button>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                                <React.Fragment>
                                    <h4 className="section-heading">Disclosure 2 - Notice of Commission</h4>
                                    <div className="section-subheading">Section 48A, Estate Agents Act 1980 VIC</div>
                                    <CheckboxCheck
                                        label="The Agent’s Commission and/or Letting Fee will be shared with other people (other than a licensed estate agent or an agent’s representative employed by the Agent, or a licensed agent who is in partnership with the Agent)."
                                        name="isAgentCommissionShared"
                                        className="isAgentCommissionShared mobile-multi-select-enabled"
                                        onClick={value => {
                                            if (!value) {
                                                let indexes = values?.commissionList?.map((_, i) => i) || [];
                                                removeBatch('commissionList', indexes);
                                            }
                                        }}
                                    />
                                    {values.isAgentCommissionShared && (
                                        <React.Fragment>
                                            <FieldArray
                                                name="commissionList"
                                                initialValue={disclosureOfRebates.commissionList}
                                            >
                                                {({ fields }) => (
                                                    <React.Fragment>
                                                        {fields.map((name, index) => {
                                                            return (
                                                                <CommissionGroup
                                                                    index={index}
                                                                    item={fields.value[index]}
                                                                    key={`${index}`}
                                                                    removeCommission={index =>
                                                                        removeCommission(values, index)
                                                                    }
                                                                />
                                                            );
                                                        })}
                                                    </React.Fragment>
                                                )}
                                            </FieldArray>
                                            <div className="button">
                                                <button
                                                    className="mobile add-item"
                                                    type="button"
                                                    onClick={() =>
                                                        push('commissionList', {
                                                            source: '',
                                                            amount: null
                                                        })
                                                    }
                                                >
                                                    <img src={plusIcon} className="for-sm-modal" />
                                                    Add commission
                                                </button>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                                {error && <div className="clause-list-error">{error}</div>}
                            </form>
                        );
                    }}
                </Form>
            </div>
        </div>
    );
}

export default memo(forwardRef(DisclosureRebateLayout));
