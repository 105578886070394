import React, { memo, useEffect } from 'react';

import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import '../../../sass/react-tabs.scss';

import Templates from './Templates';
import {
    LEASE_TYPE_COMMERCIAL_PROPERTY_MANAGEMENT,
    LEASE_TYPE_HOLIDAY_LETTING,
    LEASE_TYPE_PROPERTY_MANAGEMENT,
    LEASE_TYPE_SALES,
    TEMPLATE_TYPE_COMMERCIAL_PROPERTY_MANAGEMENT,
    TEMPLATE_TYPE_HOLIDAY_LETTING,
    TEMPLATE_TYPE_PROPERTY_MANAGEMENT,
    TEMPLATE_TYPE_SALES
} from '../../../config';

import { NSW_HOLIDAY_LETTING } from '../../../constants/featureFlags';
import { useCachedFeatureFlag } from '@app/hooks/useCachedFeatureFlag';

const TemplateTabs = ({ onTabChange }) => {
    const isNswHolidayLettingActive = useCachedFeatureFlag(NSW_HOLIDAY_LETTING);

    useEffect(() => {
        if (onTabChange) {
            onTabChange(TEMPLATE_TYPE_SALES);
        }
    }, []);
    return (
        <div className="settings template-tabs">
            <Tabs
                onSelect={index => {
                    if (onTabChange) {
                        const templateTypes = {
                            0: TEMPLATE_TYPE_SALES,
                            1: TEMPLATE_TYPE_PROPERTY_MANAGEMENT,
                            2: TEMPLATE_TYPE_HOLIDAY_LETTING,
                            3: TEMPLATE_TYPE_COMMERCIAL_PROPERTY_MANAGEMENT
                        };

                        const templateType = templateTypes[index] || null;
                        onTabChange(templateType);
                    }
                }}
            >
                <TabList>
                    <Tab>
                        <span className="icon">
                            <i className={`icon-${LEASE_TYPE_SALES}`} />
                        </span>
                        <span>Sales</span>
                    </Tab>
                    <Tab>
                        <span className="icon">
                            <i className={`icon-${LEASE_TYPE_PROPERTY_MANAGEMENT}`} />
                        </span>
                        <span>Property Management</span>
                    </Tab>
                    {isNswHolidayLettingActive && (
                        <Tab>
                            <span className="icon">
                                <i className={`icon-${LEASE_TYPE_HOLIDAY_LETTING}`} />
                            </span>
                            <span>Holiday letting</span>
                        </Tab>
                    )}
                    <Tab>
                        <span className="icon">
                            <i className={`icon-${LEASE_TYPE_PROPERTY_MANAGEMENT}`} />
                        </span>
                        <span className="truncated-span">Commercial Property Management</span>
                    </Tab>
                </TabList>
                <TabPanel>
                    <div className="wrapper">
                        <Templates leaseType={TEMPLATE_TYPE_SALES} />
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="wrapper">
                        <Templates leaseType={TEMPLATE_TYPE_PROPERTY_MANAGEMENT} />
                    </div>
                </TabPanel>
                {isNswHolidayLettingActive && (
                    <TabPanel>
                        <div className="wrapper">
                            <Templates leaseType={TEMPLATE_TYPE_HOLIDAY_LETTING} />
                        </div>
                    </TabPanel>
                )}
                <TabPanel>
                    <div className="wrapper">
                        <Templates leaseType={TEMPLATE_TYPE_COMMERCIAL_PROPERTY_MANAGEMENT} />
                    </div>
                </TabPanel>
            </Tabs>
        </div>
    );
};

export default memo(TemplateTabs);
