import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import { isEmpty, cloneDeep, sortBy, filter, find } from 'lodash';
import axios from 'axios';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { confirmAlert } from 'react-confirm-alert';

import {
    closeDisclosureDocumentModal,
    refreshDocumentList,
    addOrReplaceDocumentInList,
    removeDocumentInList
} from '../../../../actions/document';
import { LEASE_STATUS_DRAFT } from '../../../../actions/dashboard';
import { openNoSignatureModal } from '../../../../actions/user';

import DisclosureRenterGroup from '../../../../components/document/DisclosureRenterGroup';
import DisclosureRentalProviderGroup from '../../../../components/document/DisclosureRentalProviderGroup';
import ModalDialog from '../../../../common/components/ModalDialog';
import LocationAndAddressSelection from '../../LocationAndAddressSelection';
import DocumentHeader from '../../DocumentHeader';
import QuestionGroup from '../QuestionGroup';
import AnswersGroup from './AnswersGroup';
import DisclosureDocumentFormFooter from './DisclosureDocumentFormFooter';
import { HelpSharp, CloseSharp } from '@flk-mui-icons';
import WistiaEmbed from '../../../../common/components/WistiaEmbed';

import {
    CLOSE_MODAL_MESSAGE,
    DISCLOSURE_DOCUMENT,
    getAvailableStates,
    HIDE_MESSAGE_TIME,
    PARTY_LANDLORD,
    FORM_SUBMIT_TYPE_SEND
} from '../../../../config';
import {
    isDocumentEditMode,
    isDocumentReadOnlyMode,
    isDocumentStatusSentForSigning,
    isDocumentStatusDraft,
    isDocumentAwaitingCompletion,
    isDocumentComplete
} from '../../../../utils/generalUtils';
import { setQuestionsToFormFromBackend } from '../../../../utils/questionsUtils';

import { getUserInfo } from '../../../../selectors/user';
import { getAgentSignature } from '../../../../selectors/completion';
import { getSelectedAddressEdited } from '../../../../selectors/lease/addresses';
import { getCurrentDocument, getDocumentEditMode } from '../../../../selectors/document';
import { getIsOpenDisclosureDocumentModal } from '../../../../selectors/document/discloserDocument';

import '../../../../sass/questionsDocument.scss';

const VIC_DISCLOSURE_SAVE_API = 'api/document/disclosure';
const VIC_DISCLOSURE_SEND_API = 'api/document/send-disclosure';

const DisclosureDocumentForm = ({ availableQuestions }) => {
    const dispatch = useDispatch();
    const formValuesRef = React.useRef(null);
    const allowedStates = getAvailableStates(DISCLOSURE_DOCUMENT);

    const isOpen = useSelector(getIsOpenDisclosureDocumentModal);

    const selectedAddressEdited = useSelector(getSelectedAddressEdited);
    const loggedInUser = useSelector(getUserInfo);
    const agentSignature = useSelector(getAgentSignature);
    const currentDocument = useSelector(getCurrentDocument);
    const documentEditMode = useSelector(getDocumentEditMode);

    const [isSubmitSuccessfull, setIsSubmitSuccessfull] = useState(false);
    const [isSubmitFail, setIsSubmitFail] = useState(false);
    const [isSendingDisclosure, setIsSendingDisclosure] = useState(false);
    const [integrationPropertyId, setIntegrationPropertyId] = useState(null);
    const [integration, setIntegration] = useState(null);

    const [formData, setFormData] = useState({});
    const [location, setLocation] = useState(null);
    const [locationAndAddressSelected, setLocationAndAddressSelected] = useState(false);
    const [addressError, setAddressError] = useState('');
    const [error, setError] = useState('');
    const [questions, setQuestions] = useState([]);
    const [isDirty, setIsDirty] = useState(false);
    const [isSaveDraft, setIsSaveDraft] = useState(false);

    const handleFormDirtyChange = form => {
        if (form.getState().dirty && !isDirty) {
            setIsDirty(true);
        }
    };

    useEffect(() => {
        if (currentDocument && isOpen) {
            window.history.pushState({}, '', `/dashboard/document/${currentDocument.id}`);
        }
    }, [isOpen]);

    useEffect(() => {
        getQuestionsAndSetAsFormData();
    }, []);

    useEffect(() => {
        if (currentDocument && currentDocument.integration && currentDocument.integration.id) {
            setIntegrationPropertyId(currentDocument.integration.id);
            setIntegration(currentDocument.integration);
        } else {
            setIntegrationPropertyId(null);
            setIntegration(null);
        }
        if (currentDocument && currentDocument.questions) {
            const newQuestions = setQuestionsToFormFromBackend(currentDocument.questions);
            newQuestions.forEach(question => {
                const answerOptionsSelected = [];
                // this index will use to keep the same order when we push and pull
                question.answerOptionsSelected.forEach((option, index) => {
                    answerOptionsSelected.push({
                        ...option,
                        index
                    });
                });
                question.answerOptionsSelected = answerOptionsSelected;
            });
            setFormData({
                agentName: currentDocument.agent.fullName,
                tenants: currentDocument.tenant
                    ? currentDocument.tenant.tenants
                    : [{ firstName: '', secondName: '', email: '' }],
                landlords: currentDocument.landlord
                    ? currentDocument.landlord.persons
                    : [{ firstName: '', lastName: '', email: '', isPrimaryLandlord: true }],
                address: currentDocument.address,
                addressDetails: currentDocument.details,
                questions: newQuestions,
                disabled: isDocumentReadOnlyMode(documentEditMode)
            });
            setLocation(currentDocument.location);
            setLocationAndAddressSelected(true);
        } else {
            setLocationAndAddressSelected(false);
            setLocation(loggedInUser?.agency?.details?.location || null);
            clearFormData();
        }
    }, [isOpen, loggedInUser, currentDocument]);

    useEffect(() => {
        if (selectedAddressEdited) {
            setFormData({
                ...formData,
                address: selectedAddressEdited
            });
        }
    }, [selectedAddressEdited]);
    /**
     * get the default questions when we change the state
     * not required at this point
     * need this if we have more states */
    useEffect(() => {
        if (!currentDocument) {
            getQuestionsAndSetAsFormData();
        }
    }, [location]);

    const closeModal = () => {
        clearFormData();
        setIsSubmitFail(false);
        setIsSubmitSuccessfull(false);
        setIsDirty(false);
        dispatch(closeDisclosureDocumentModal());
        if (currentDocument) {
            window.history.pushState(
                {},
                '',
                `/dashboard/documents/${currentDocument.docType}/${currentDocument.status}`
            );
        }
    };

    const handleCloseModal = () => {
        if (isDirty) {
            confirmAlert({
                title: '',
                message: CLOSE_MODAL_MESSAGE,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            const values = formValuesRef.current;
                            processValuesAndMakeApiCallPromise(values, VIC_DISCLOSURE_SAVE_API).then(response => {
                                dispatch(addOrReplaceDocumentInList(response.data.disclosureDocument));
                                closeModal();
                            });
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {
                            closeModal();
                        }
                    },
                    {
                        className: 'close close-modal',
                        label: <CloseSharp>Close</CloseSharp>,
                        onClick: () => {}
                    }
                ]
            });
        } else {
            closeModal();
        }
    };

    const getQuestionsAndSetAsFormData = () => {
        const questionsRelatedToLocation = find(availableQuestions, { state: location });
        if (questionsRelatedToLocation) {
            let newQuestionsList = cloneDeep(questionsRelatedToLocation.questions);
            newQuestionsList.forEach(question => {
                const answerOptionsSelected = [];
                // this index will use to keep the same order when we push and pull
                question.answerOptions.forEach((option, index) => {
                    answerOptionsSelected.push({
                        ...option,
                        index
                    });
                });
                question.answerOptionsSelected = answerOptionsSelected;
            });
            newQuestionsList = setQuestionsToFormFromBackend(newQuestionsList);
            setQuestions(newQuestionsList);
            setFormData({
                ...formData,
                questions: newQuestionsList
            });
        }
    };

    const clearFormData = () => {
        setFormData({
            agentName: loggedInUser.fullName,
            agentId: loggedInUser.id,
            agentPhone: loggedInUser.phone,
            tenants: [{ firstName: '', secondName: '', email: '' }],
            landlords: [{ firstName: '', lastName: '', email: '', isPrimaryLandlord: true }],
            questions,
            address: null,
            disabled: false
        });
        setAddressError('');
        setError('');
    };
    const handlePrimaryLandlordSelect = (values, index, value) => {
        let newState = cloneDeep(values);
        // If deselect primary landlord option, then assign that to first landlord.
        if (!value) {
            newState.landlords[0].isPrimaryLandlord = true;
            // we can't deselect first landlord. we have to select other one
            if (index !== 0) {
                newState.landlords[index].isPrimaryLandlord = false;
            }
            // if select any landlord, deselect current one and assign it to selected one
        } else {
            newState.landlords.forEach((landlord, i) => {
                // Looping through landlords and then check looped item with the item we want to change
                // if they are equal(index === i) then set it to primaty otherwise not a primary landlord
                landlord.isPrimaryLandlord = index === i;
            });
        }
        setFormData({
            ...newState
        });
    };
    const removeLandlordPerson = (values, index) => {
        let newState = cloneDeep(values);
        if (newState.landlords[index].isPrimaryLandlord) {
            newState.landlords[0].isPrimaryLandlord = true;
        }
        setFormData({
            ...newState,
            landlords: [...newState.landlords.slice(0, index), ...newState.landlords.slice(index + 1)]
        });
    };
    const processValuesAndMakeApiCallPromise = (values, url) => {
        const newValues = cloneDeep(values);
        setIsSendingDisclosure(true);
        setIsSubmitFail(false);
        setAddressError('');
        setError(null);
        newValues.location = location;
        newValues.id = currentDocument ? currentDocument.id : null;
        newValues.integrationPropertyId = integrationPropertyId;
        newValues.questions.forEach(question => {
            if (question.partyToAnswer === PARTY_LANDLORD) {
                question.answer = undefined;
                question.details = '';
            }
            question.answerOptionsSelected = sortBy(question.answerOptionsSelected, ['index']);
        });

        return axios.post(url, newValues);
    };

    const handleFormSubmit = values => {
        if (values.submitType === FORM_SUBMIT_TYPE_SEND) {
            return sendForSigning(values);
        } else {
            return saveDraft(values);
        }
    };

    const saveDraft = values => {
        setIsSaveDraft(true);
        return processValuesAndMakeApiCallPromise(values, VIC_DISCLOSURE_SAVE_API)
            .then(response => {
                setIsSendingDisclosure(false);
                setIsSubmitSuccessfull(true);
                setIsDirty(false);
                dispatch(addOrReplaceDocumentInList(response.data.disclosureDocument));
            })
            .catch(() => {
                setIsSendingDisclosure(false);
                setIsSubmitSuccessfull(false);
                setIsSubmitFail(true);
            });
    };

    const sendForSigning = values => {
        setIsSaveDraft(false);
        if (!isEmpty(agentSignature)) {
            return processValuesAndMakeApiCallPromise(values, VIC_DISCLOSURE_SEND_API)
                .then(response => {
                    setIsSendingDisclosure(false);
                    setIsSubmitFail(false);
                    setIsSubmitSuccessfull(true);
                    dispatch(removeDocumentInList(response.data.signors[0].disclosureDocument, LEASE_STATUS_DRAFT));
                    setIsDirty(false);
                    // clear success message after 5 seconds
                    setTimeout(() => {
                        setIsSubmitSuccessfull(false);
                        clearFormData();
                        setIntegrationPropertyId(null);
                        setIntegration(null);
                        dispatch(closeDisclosureDocumentModal());
                    }, HIDE_MESSAGE_TIME);
                })
                .catch(error => {
                    setIsSendingDisclosure(false);
                    setIsSubmitSuccessfull(false);
                    setIsSubmitFail(true);
                    const response = error.response;
                    let disclosureError = response.data.errors;
                    if (response.data.errors.disclosureDocument) {
                        disclosureError = {
                            ...disclosureError,
                            ...response.data.errors.disclosureDocument
                        };
                    }
                    if (response.data.errors.tenant) {
                        disclosureError.tenants = response.data.errors.tenant.tenants;
                    }
                    if (response.data.errors.landlord) {
                        disclosureError.landlords = response.data.errors.landlord.persons;
                    }
                    if (response.data.errors.address) {
                        setAddressError(response.data.errors.address);
                    }
                    setTimeout(() => {
                        scrollToError(response.data.errors);
                    }, 1000);
                    return disclosureError;
                });
        } else {
            dispatch(openNoSignatureModal());
        }
    };

    const scrollToError = errors => {
        let errorClass = 'FormError';
        if (errors.address) {
            errorClass = 'document-header';
        }
        const elementList = document.getElementsByClassName(errorClass);
        if (elementList && elementList.length > 0) {
            elementList[0].scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
    };

    // Address functions
    const confirmDetails = () => {
        setLocationAndAddressSelected(true);
    };
    const handleAddressChange = (setValue, address) => {
        setValue('address', address);
    };
    const setAddressDetails = (address, details) => {
        setFormData({
            address,
            agentName: loggedInUser.fullName,
            tenants: [{ firstName: '', secondName: '', email: '' }],
            landlords: [{ firstName: '', lastName: '', email: '', isPrimaryLandlord: true }],
            addressDetails: details,
            questions
        });
        setIntegrationPropertyId(null);
        setIntegration(null);
    };
    const setUpdateAddressDetails = (address, details, values) => {
        let data = cloneDeep(values);
        setFormData({
            address,
            agentName: loggedInUser.fullName,
            tenants: [{ firstName: '', secondName: '', email: '' }],
            landlords: [{ firstName: '', lastName: '', email: '', isPrimaryLandlord: true }],
            addressDetails: details,
            ...data
        });
        setIntegrationPropertyId(null);
        setIntegration(null);
    };
    const setIntegrationProperty = propertyDetails => {
        if (propertyDetails) {
            setFormData({
                address: propertyDetails.address,
                agentName: loggedInUser.fullName,
                tenants: propertyDetails.tenants,
                landlords: setLandlords(propertyDetails.landlords),
                questions
            });
            setIntegrationPropertyId(propertyDetails.id);
            setIntegration(propertyDetails);
        }
    };

    const linkIntegrationProperty = propertyDetails => {
        if (propertyDetails) {
            setIntegrationPropertyId(propertyDetails.id);
            setIntegration(propertyDetails);
        }
        dispatch(refreshDocumentList());
    };

    const unLinkIntegrationProperty = () => {
        setIntegrationPropertyId(null);
        setIntegration(null);
        dispatch(refreshDocumentList());
    };

    const setLandlords = landlords => {
        const landlordList = [];
        if (!landlords) {
            landlordList.push({ firstName: '', lastName: '', email: '', isPrimaryLandlord: true });
        } else {
            landlords.forEach(landlord => {
                landlordList.push({
                    firstName: landlord.firstName,
                    lastName: landlord.secondName,
                    email: landlord.email || '',
                    phone: landlord.phone || '',
                    isPrimaryLandlord: landlord.isPrimaryLandlord
                });
            });
        }
        let primaryLandlord = filter(landlordList, 'isPrimaryLandlord');
        if (isEmpty(primaryLandlord)) {
            landlordList[0].isPrimaryLandlord = true;
        }
        return landlordList;
    };

    return (
        <React.Fragment>
            <ModalDialog
                title={
                    locationAndAddressSelected && location
                        ? `Disclosure Document - ${location}`
                        : 'Create new Disclosure Document for'
                }
                isOpen={isOpen}
                closeModal={handleCloseModal}
                shouldCloseOnOverlayClick={false}
                className={`document-centre-modal document-modal-dialog questions-modal larger tenant ${
                    locationAndAddressSelected && location ? 'with-header' : 'without-header'
                }`}
                hideCloseButton={isSendingDisclosure}
                // Quick fix for search results being cut off by modal
                allowOverflow={!locationAndAddressSelected}
            >
                <Form
                    initialValues={formData}
                    mutators={{
                        ...arrayMutators
                    }}
                    onSubmit={values => handleFormSubmit(values)}
                >
                    {({
                        handleSubmit,
                        values,
                        form,
                        form: {
                            mutators: { push }
                        }
                    }) => {
                        // set the values to the ref so that the close modal can access these values
                        formValuesRef.current = values;
                        return (
                            <form noValidate onSubmit={values => handleSubmit(values)} id="create-disclosure-form">
                                <FormSpy subscription={{ values: true }} onChange={() => handleFormDirtyChange(form)} />
                                {locationAndAddressSelected && location && (
                                    <React.Fragment>
                                        <DocumentHeader
                                            parentSelector="create-disclosure-form"
                                            confirmDetails={confirmDetails}
                                            address={values.address}
                                            addressError={addressError}
                                            handleAddressChange={address => handleAddressChange(form.change, address)}
                                            setAddressDetails={(address, details) =>
                                                setUpdateAddressDetails(address, details, values)
                                            }
                                            disabled={isDocumentReadOnlyMode(documentEditMode) || integrationPropertyId}
                                            agentName={values.agentName}
                                            integration={integration}
                                            viewOnlyIntegration={false}
                                            linkIntegrationProperty={linkIntegrationProperty}
                                            unLinkIntegrationProperty={unLinkIntegrationProperty}
                                            doc={currentDocument}
                                            isFlowDocument={true}
                                        />
                                        {(isDocumentEditMode(documentEditMode) ||
                                            (currentDocument && currentDocument.status === LEASE_STATUS_DRAFT)) && (
                                            <div className="help-message help-message-and-video">
                                                <HelpSharp />
                                                <p className="description">
                                                    Victoria residential rental provider disclosure document
                                                </p>
                                                <WistiaEmbed hashedId="60zm8ewchx" width="400" height="270" />
                                            </div>
                                        )}
                                        <h2>Rental Providers</h2>
                                        <div className="tenants">
                                            <FieldArray name="landlords">
                                                {({ fields }) =>
                                                    fields.map((name, index) => (
                                                        <DisclosureRentalProviderGroup
                                                            key={index}
                                                            index={index}
                                                            values={values}
                                                            removeRentalProvider={index =>
                                                                removeLandlordPerson(values, index)
                                                            }
                                                            disabled={values.disabled}
                                                            handlePrimaryLandlordSelect={(index, value) => {
                                                                handlePrimaryLandlordSelect(values, index, value);
                                                            }}
                                                        />
                                                    ))
                                                }
                                            </FieldArray>
                                            {isDocumentEditMode(documentEditMode) && (
                                                <div className="add-item-button-container button">
                                                    <button
                                                        className="add-tenant"
                                                        type="button"
                                                        onClick={
                                                            values.disabled
                                                                ? () => {}
                                                                : () =>
                                                                      push('landlords', {
                                                                          firstName: '',
                                                                          lastName: '',
                                                                          email: ''
                                                                      })
                                                        }
                                                    >
                                                        Add rental provider
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                        <h2>Renters</h2>
                                        <div>
                                            <div className="tenants">
                                                <FieldArray name="tenants">
                                                    {({ fields }) =>
                                                        fields.map((name, index) => (
                                                            <DisclosureRenterGroup
                                                                key={index}
                                                                index={index}
                                                                removeRenter={index => fields.remove(index)}
                                                                disabled={values.disabled}
                                                            />
                                                        ))
                                                    }
                                                </FieldArray>
                                                {isDocumentEditMode(documentEditMode) && (
                                                    <div className="button add-item-button-container">
                                                        <button
                                                            className="add-tenant"
                                                            type="button"
                                                            onClick={
                                                                values.disabled
                                                                    ? () => {}
                                                                    : () =>
                                                                          push('tenants', {
                                                                              firstName: '',
                                                                              secondName: '',
                                                                              email: ''
                                                                          })
                                                            }
                                                        >
                                                            Add renter
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <h2>Disclosures </h2>
                                        <br />
                                        {(!currentDocument ||
                                            isDocumentStatusSentForSigning(currentDocument.status) ||
                                            isDocumentStatusDraft(currentDocument.status)) && (
                                            <QuestionGroup
                                                values={values}
                                                setFormData={setFormData}
                                                error={error}
                                                haveSelectAll={true}
                                                setIsDirty={setIsDirty}
                                                isDragDisabled={true}
                                            />
                                        )}
                                        {currentDocument &&
                                            (isDocumentAwaitingCompletion(currentDocument.status) ||
                                                isDocumentComplete(currentDocument.status)) && (
                                                <AnswersGroup questions={values.questions} />
                                            )}
                                        <DisclosureDocumentFormFooter
                                            closeModal={closeModal}
                                            currentDocument={currentDocument}
                                            documentEditMode={documentEditMode}
                                            isSendingDisclosure={isSendingDisclosure}
                                            isSubmitSuccessfull={isSubmitSuccessfull}
                                            isSubmitFail={isSubmitFail}
                                            isSaveDraft={isSaveDraft}
                                            form={form}
                                        />
                                    </React.Fragment>
                                )}
                                {!locationAndAddressSelected && (
                                    <LocationAndAddressSelection
                                        location={location}
                                        allowedStates={allowedStates}
                                        setLocation={setLocation}
                                        closeModal={closeModal}
                                        confirmDetails={confirmDetails}
                                        address={values.address}
                                        handleAddressChange={address => handleAddressChange(form.change, address)}
                                        setAddressDetails={(address, details) => setAddressDetails(address, details)}
                                        setIntegrationProperty={property => setIntegrationProperty(property)}
                                    />
                                )}
                            </form>
                        );
                    }}
                </Form>
            </ModalDialog>
        </React.Fragment>
    );
};
export default DisclosureDocumentForm;
