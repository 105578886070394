import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeHelp } from '../../actions/help';
import { getOpenedHelpList } from '../../selectors/help';
import { CloseSharp } from '@flk-mui-icons';
import Button from '../../common/components/Button';
import * as Popover from '@radix-ui/react-popover';

import styles from './HelpContainer.module.scss';
import cx from 'classnames';

interface HelpContainerProps {
    helpId: string;
    header: string;
    message: string;
    side?: 'right' | 'top' | 'bottom' | 'left';
    children: React.ReactNode;
}

export default function HelpContainer({
    helpId,
    header,
    message,
    side = 'right',
    children
}: HelpContainerProps): JSX.Element {
    const dispatch = useDispatch();
    const openedHelpList: string[] = useSelector(getOpenedHelpList);

    function close() {
        dispatch(closeHelp(helpId));
    }

    return (
        <div className={cx({ [styles.helpContainer]: openedHelpList.includes(helpId) })}>
            <Popover.Root open={openedHelpList.includes(helpId)}>
                <Popover.Anchor asChild>{children}</Popover.Anchor>
                <Popover.Portal>
                    <Popover.Content side={side} className={styles.help} sideOffset={16}>
                        <div className={styles.header}>
                            <p className={styles.headerText}>{header}</p>
                            <Button onClick={close} className={styles.closeButton}>
                                <CloseSharp className={styles.closeIcon} />
                            </Button>
                        </div>
                        <p className={styles.message}>{message}</p>
                        <Popover.Arrow className={styles.arrow} width={15} height={10} />
                    </Popover.Content>
                </Popover.Portal>
            </Popover.Root>
        </div>
    );
}
