import React, { memo } from 'react';

import cx from 'classnames';
import useOnClickOutside from 'use-onclickoutside';

import * as dashboard from '../../actions/dashboard';
import '../../sass/popupLeaseMore.scss';
import {
    isLeaseTypePm,
    isLeaseTypeResidentialTenancy,
    isLeaseTypeSales,
    isLongTermRenewalSubLease
} from '../../utils/agreementUtils';
import EditSharpIcon from '@flk-mui-icons/EditSharp';
import { HelpOutlineSharp, AddBoxSharp } from '@flk-mui-icons';
import RetractIcon from '@flk-mui-icons/HighlightOffSharp';
import CancelIcon from '@flk-mui-icons/CancelSharp';
import CloneIcon from '@flk-mui-icons/FileCopySharp';
import { useDispatch } from 'react-redux';
import { openRetractLeaseConfirmModal } from '../../actions/confirmModals';
import { getLabel } from '../../utils/labelUtils';
import { LEASE_TYPE_PROPERTY_MANAGEMENT, LEASE_TYPE_SALES } from '../../config';

const PopupLeaseMore = props => {
    const dispatch = useDispatch();

    const handleClickOutside = event => {
        // close the list when list is open and clicked outside the list
        // ignore this if user click more-action button
        if (event && event.path && event.path[0].className !== 'more-action' && props.morePopupListAgreements) {
            props.closeMorePopupList();
        }
    };

    const ref = React.useRef(null);
    useOnClickOutside(ref, handleClickOutside);

    const {
        moveToAwaitingRenewalStatus,
        moveToArchiveStatus,
        morePopupInfoAgreement,
        cloneAgreement,
        deleteAgreement,
        showModalInfoAgreement,
        showEditTenants,
        showEditLandlords,
        showEditVendors,
        showExtendExpiry,
        agreementStatus,
        isRenewalsDue,
        cancelSigning,
        morePopupListAgreements,
        openLeaseRenewalModal,
        showResendButton,
        openCancelRenewalModal,
        isUserAdmin,
        leaseType,
        isCompletedLease,
        initiateRenewalIntention,
        hideRenewalIntentionButton,
        lease,
        closePopup
    } = props;

    const shouldDisplayRemoveOption =
        agreementStatus === dashboard.LEASE_STATUS_DRAFT ||
        (agreementStatus === dashboard.LEASE_STATUS_AWAITING_COMPLETION && isUserAdmin) ||
        (agreementStatus === dashboard.LEASE_STATUS_ARCHIVED && isUserAdmin) ||
        (agreementStatus === dashboard.LEASE_STATUS_COMPLETE && isUserAdmin);

    function retractOfferOfRenewal(e) {
        e.stopPropagation();
        dispatch(openRetractLeaseConfirmModal(lease));
        closePopup();
    }

    return (
        <div
            ref={ref}
            className={cx(
                morePopupInfoAgreement
                    ? 'popup-draft-create'
                    : morePopupListAgreements
                    ? 'popup-draft-create create-list'
                    : 'popup-draft-create hide'
            )}
        >
            {agreementStatus !== dashboard.LEASE_STATUS_AWAITING_RENEWAL && (
                <div>
                    <div className="icon-block" onClick={cloneAgreement}>
                        <CloneIcon className="material-icon" />
                        <p className="popup-draft-p">Clone agreement</p>
                    </div>
                </div>
            )}

            {agreementStatus === dashboard.LEASE_STATUS_SENT_SIGNING && (
                <div>
                    {isLeaseTypeResidentialTenancy(leaseType) && (
                        <div className="icon-block" onClick={showEditTenants}>
                            <EditSharpIcon className="icon icon-edit-tenant" />
                            <p className="popup-draft-p">Edit tenants</p>
                        </div>
                    )}
                    {isLeaseTypePm(leaseType) && (
                        <div className="icon-block" onClick={showEditLandlords}>
                            <EditSharpIcon className="icon icon-edit-tenant" />
                            <p className="popup-draft-p">{`Edit ${getLabel(
                                'landlords',
                                lease.location,
                                LEASE_TYPE_PROPERTY_MANAGEMENT
                            )}`}</p>
                        </div>
                    )}
                    {isLeaseTypeSales(leaseType) && (
                        <div className="icon-block" onClick={showEditVendors}>
                            <EditSharpIcon className="icon icon-edit-tenant" />
                            <p className="popup-draft-p">{`Edit ${getLabel(
                                'tenant',
                                lease.location,
                                LEASE_TYPE_SALES
                            )}`}</p>
                        </div>
                    )}
                    {lease.signingExpire <= 0 &&
                        ((isLeaseTypePm(leaseType) && !lease.isManuallyShareLinks) ||
                            isLeaseTypeResidentialTenancy(leaseType)) && (
                            <div className="icon-block" onClick={showExtendExpiry}>
                                <AddBoxSharp className="icon icon-edit-tenant" />
                                <p className="popup-draft-p">
                                    {isLongTermRenewalSubLease(lease.subLeaseType)
                                        ? 'Extend offer expiry'
                                        : 'Extend expiry'}
                                </p>
                            </div>
                        )}
                    {showResendButton && (
                        <div className="icon-block" onClick={showModalInfoAgreement}>
                            <i className="icon icon-sign" />
                            <p className="popup-draft-p">Re-send for signing</p>
                        </div>
                    )}
                    <div className="icon-block" onClick={cancelSigning}>
                        <CancelIcon className="material-icon error" />
                        <p className="popup-draft-p">Move to drafts</p>
                    </div>
                    {isLeaseTypeResidentialTenancy(leaseType) && (
                        <div className="icon-block" onClick={retractOfferOfRenewal}>
                            <RetractIcon className="material-icon error" />
                            <p className="popup-draft-p">Retract offer of lease</p>
                        </div>
                    )}
                </div>
            )}
            {(agreementStatus === dashboard.LEASE_STATUS_AWAITING_RENEWAL || !!isRenewalsDue) && (
                <div>
                    <div className="icon-block" onClick={openCancelRenewalModal}>
                        <CancelIcon className="material-icon error" />
                        <p className="popup-draft-p">Cancel renewal</p>
                    </div>
                    <div className="icon-block" onClick={openLeaseRenewalModal}>
                        <i className="icon icon-renew" />
                        <p className="popup-draft-p">Renew agreement</p>
                    </div>
                </div>
            )}

            {shouldDisplayRemoveOption && (
                <div className="icon-block" onClick={deleteAgreement}>
                    <i className="icon icon-trash" />
                    <p className="popup-draft-p">Delete agreement</p>
                </div>
            )}
            {agreementStatus === dashboard.LEASE_STATUS_DRAFT &&
                !isLongTermRenewalSubLease(lease.subLeaseType) &&
                isCompletedLease && (
                    <div>
                        <div className="icon-block" onClick={moveToArchiveStatus}>
                            <i className="icon icon-archive" />
                            <p className="popup-draft-p">Move to complete</p>
                        </div>
                    </div>
                )}
            {!hideRenewalIntentionButton &&
                isLeaseTypeResidentialTenancy(leaseType) &&
                agreementStatus === dashboard.LEASE_STATUS_AWAITING_RENEWAL && (
                    <div>
                        <div className="icon-block" onClick={initiateRenewalIntention}>
                            <HelpOutlineSharp className="icon icon-renewal-intention" />
                            <p className="popup-draft-p">Ask for renewal intent</p>
                        </div>
                    </div>
                )}
            {isLeaseTypeResidentialTenancy(leaseType) &&
                !isLongTermRenewalSubLease(lease.subLeaseType) &&
                (agreementStatus === dashboard.LEASE_STATUS_ARCHIVED ||
                    agreementStatus === dashboard.LEASE_STATUS_COMPLETE) && (
                    <div>
                        <div className="icon-block" onClick={moveToAwaitingRenewalStatus}>
                            <i className="icon icon-renew" />
                            <p className="popup-draft-p">Move to awaiting renewal</p>
                        </div>
                    </div>
                )}
        </div>
    );
};

PopupLeaseMore.defaultProps = {
    showResendButton: true
};

export default memo(PopupLeaseMore);
