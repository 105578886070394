import SpeakerNotesSharpIcon from '@flk-mui-icons/SpeakerNotesSharp';
import '../../sass/lightTooltip.scss';
import ReactTooltip from 'react-tooltip';
import React from 'react';
import { useDispatch } from 'react-redux';
import { openNotesModal } from '../../actions/dashboard';

const NotesOpenButton = props => {
    const dispatch = useDispatch();

    const openNotes = () => {
        dispatch(openNotesModal(props.id, props.isDocument));
    };

    return (
        <div className="header-button notes-button for-lg-modal">
            {!props.showText && (
                <div onClick={openNotes} data-tip={true} data-for="notes">
                    <SpeakerNotesSharpIcon>Notes</SpeakerNotesSharpIcon>
                    <ReactTooltip id="notes" class="tooltip" place="bottom" effect="solid" globalEventOff="wheel">
                        <p>Notes for agreement</p>
                    </ReactTooltip>
                </div>
            )}
            {props.showText && (
                <span className="special-link" onClick={openNotes} data-tip={true} data-for="notes">
                    <SpeakerNotesSharpIcon>Notes</SpeakerNotesSharpIcon>
                    <span>See all notes</span>
                </span>
            )}
        </div>
    );
};

export default NotesOpenButton;
