import React from 'react';
import { useParams } from 'react-router-dom';
import Notice from '../components/Notice';

import '../../../sass/agentSignatures/expiredLease.scss';
import mainLogo from '../../../../assets/images/main_logo.svg?url';

import { setLoginRedirect, logOut } from '../../../actions/login';

import cookie from 'react-cookie';
import { useDispatch } from 'react-redux';
import appHistory from '../../../AppHistory';

const Expired = props => {
    const dispatch = useDispatch();
    const { token } = useParams();
    const logOutClick = () => {
        cookie.remove('rememberMe', { path: '/' });
        cookie.remove('userFromLogin', { path: '/' });
        cookie.remove('accountType', { path: '/' });
        // first log out (this will remove the login redirect)
        dispatch(logOut());
        appHistory.push('/');
        // then save redirect
        dispatch(setLoginRedirect(`/signatures/${token}`));
    };

    return (
        <div className="expired-screen">
            <div className="expired-screen-logo">
                <img src={mainLogo} alt="logo" />
            </div>
            <Notice>
                <h4>INVALID AGENT SIGNATURE LINK</h4>
                <p>The user you logged in with does not have access to add a signature with this link.</p>
                <p>
                    <a onClick={logOutClick}>
                        <u>Please click here to log out and then login with the right account.</u>
                    </a>
                </p>
            </Notice>
        </div>
    );
};

export default Expired;
