import React, { forwardRef, useState, useImperativeHandle, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { has } from 'lodash';
import { Form, FormSpy } from 'react-final-form';
import {
    commercialLeaseRentPeriods,
    REVIEW_METHOD_FIXED_PERCENTAGE,
    REVIEW_METHOD_FIXED_AMOUNT,
    REVIEW_METHOD_CPI,
    REVIEW_METHOD_CURRENT_MARKET_RENT
} from '../../../config';

import { updateSubmitTypeSuccess, setDirtyStep, formSubmitFail } from '../../../actions/lease';
import * as Lease from '../../../reducers/lease';

import { getLocation, getRent, getStep, getDirtyStep, getLeaseType, getTerm } from '../../../selectors/lease';
import { FormNumber, FormTextRegular } from '../../../components/form/FormText';
import { SelectField } from '../../../components/form/FormSelect';
import DatePickerInAgencyTimeZone from '../../../components/form/DatePickerInAgencyTimeZone';
import { FormRadioGroup } from '../../../components/form/FormRadioGroup';
import { CheckboxCheck } from '../../../components/form/FormCheckboxCheck';

const RENT_FORM = 'rentForm';

function Rent(props, ref) {
    const dispatch = useDispatch();
    const reduxRent = useSelector(getRent);
    const reduxTerm = useSelector(getTerm);
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [rent, setRent] = useState({});

    useEffect(() => {
        let dateStart = reduxRent.dateStart ? new Date(reduxRent.dateStart) : null;
        if (!dateStart && reduxTerm?.startDate) {
            dateStart = new Date(reduxTerm.startDate);
        }
        setRent({
            ...reduxRent,
            dateStart
        });
    }, [reduxRent, reduxTerm]);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(RENT_FORM).dispatchEvent(
                new Event('submit', {
                    cancelable: true,
                    bubbles: true
                })
            );
        }
    }));

    const updateRent = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/rent`, data);
    };

    const submitForm = values => {
        /**
         * Store the ref of the component so it can be used in this function
         * We need this otherwise the promise below doesn't have access to the ref
         */
        const currentRef = ref.current;

        /**
         * Grab the bypassFormValidation that was set from the parent component: LeaseAgreementForm.js
         */
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updateRent(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_RENT_SUCCESS)).then(() => {
                    /**
                     * Callback after submit this form so that the parent component can take an action
                     */
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.rent')) {
                    return error.response.data.errors.rent;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    return (
        <div className="rent">
            <Form onSubmit={submitForm} initialValues={rent}>
                {({ handleSubmit, values, form }) => {
                    return (
                        <form onSubmit={handleSubmit} id={RENT_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <CheckboxCheck name="isIncludesGst" label="Includes GST" />
                            <div className="agreement-select">
                                <div>
                                    <FormNumber
                                        name="amount"
                                        label={`An initial base rent ${
                                            values.isIncludesGst ? '(inclusive of GST)' : ''
                                        } of`}
                                        required
                                    />
                                </div>
                                <div>
                                    <SelectField
                                        dataTest="rent-payablePeriod"
                                        label="Per"
                                        name="payablePeriod"
                                        options={commercialLeaseRentPeriods}
                                    />
                                </div>
                            </div>
                            <div className="calendar">
                                <DatePickerInAgencyTimeZone
                                    label="Commencing On"
                                    name="dateStart"
                                    selected={values.dateStart}
                                    required={true}
                                />
                            </div>
                            <FormTextRegular name="payableOn" label="Date of payment of rent" required={true} />
                            <FormTextRegular name="ofEach" label="Frequency of payment of rent" required={true} />
                            <br />
                            <div className="forced-mobile-view">
                                <FormRadioGroup
                                    label="Select one method for review of rent"
                                    radioGroupClass="button-group-for-select"
                                    isYesNoGroup={true}
                                    name="methodOfReview"
                                    required={true}
                                    data={[
                                        {
                                            label: REVIEW_METHOD_CPI,
                                            value: REVIEW_METHOD_CPI
                                        },
                                        {
                                            label: REVIEW_METHOD_FIXED_AMOUNT,
                                            value: REVIEW_METHOD_FIXED_AMOUNT
                                        },
                                        {
                                            label: REVIEW_METHOD_FIXED_PERCENTAGE,
                                            value: REVIEW_METHOD_FIXED_PERCENTAGE
                                        }
                                    ]}
                                    value={values.methodOfReview}
                                />
                            </div>
                            {values.methodOfReview === REVIEW_METHOD_FIXED_AMOUNT && (
                                <FormTextRegular name="methodOfReviewFixedAmount" label="Fixed Amount" required />
                            )}
                            {values.methodOfReview === REVIEW_METHOD_FIXED_PERCENTAGE && (
                                <FormTextRegular
                                    name="methodOfReviewFixedPercentage"
                                    label="Fixed Percentage"
                                    required
                                />
                            )}
                            {reduxTerm.isThereAnOption && (
                                <>
                                    <br />
                                    <div className="forced-mobile-view">
                                        <FormRadioGroup
                                            label="Select one method for option renewal"
                                            radioGroupClass="button-group-for-select"
                                            isYesNoGroup={true}
                                            name="optionOfRenewal"
                                            data={[
                                                {
                                                    label: REVIEW_METHOD_CPI,
                                                    value: REVIEW_METHOD_CPI
                                                },
                                                {
                                                    label: REVIEW_METHOD_FIXED_AMOUNT,
                                                    value: REVIEW_METHOD_FIXED_AMOUNT
                                                },
                                                {
                                                    label: REVIEW_METHOD_FIXED_PERCENTAGE,
                                                    value: REVIEW_METHOD_FIXED_PERCENTAGE
                                                },
                                                {
                                                    label: REVIEW_METHOD_CURRENT_MARKET_RENT,
                                                    value: REVIEW_METHOD_CURRENT_MARKET_RENT
                                                }
                                            ]}
                                            value={values.optionOfRenewal}
                                            required
                                        />
                                    </div>
                                    {values.optionOfRenewal === REVIEW_METHOD_FIXED_AMOUNT && (
                                        <FormTextRegular
                                            name="optionOfRenewalFixedAmount"
                                            label="Fixed Amount"
                                            required
                                        />
                                    )}
                                    {values.optionOfRenewal === REVIEW_METHOD_FIXED_PERCENTAGE && (
                                        <FormTextRegular
                                            name="optionOfRenewalFixedPercentage"
                                            label="Fixed Percentage"
                                            required
                                        />
                                    )}
                                </>
                            )}
                        </form>
                    );
                }}
            </Form>
        </div>
    );
}

export default memo(forwardRef(Rent));
