import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { cloneDeep, has } from 'lodash';

import * as Lease from '../../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../../selectors/lease';

import '../../../../sass/management/detailsRepairs.scss';
import { FormRadioGroup } from '../../../../components/form/FormRadioGroup';
import DatePickerInAgencyTimeZone from '../../../../components/form/DatePickerInAgencyTimeZone';
import { FormTextCurrency, FormTextRegular } from '../../../../components/form/FormText';
import { getPoolSafetyAndSmokeAlarms } from '../../../../selectors/lease/pmLease';
import {
    POOL_TYPE_REGULATED_POOL_SHARED,
    POOL_TYPE_REGULATED_POOL_NOT_SHARED,
    POOL_TYPE_NO_POOL,
    SMOKE_ALARM_MAINTAIN_LESSOR,
    SMOKE_ALARM_MAINTAIN_AGENT,
    SMOKE_ALARM_MAINTAIN_STRATA,
    SMOKE_ALARM_MAINTAIN_COMPANY,
    SMOKE_ALARM_ARRANGED_PRIOR,
    SMOKE_ALARM_ALREADY_COMPLETED,
    SMOKE_ALARM_AS_SPECIFIED,
    SMOKE_ALARM_MAINTAIN_LANDLORD,
    SMOKE_ALARM_TYPE_COMPLIANT
} from '../../../../config';
import { CheckboxCheck } from '../../../../components/form/FormCheckboxCheck';

const PM_POOL_SAFETY_FORM = 'poolSafetyAndSmokeAlarmsForm';
const initState = {
    hasPool: false,
    poolType: POOL_TYPE_REGULATED_POOL_SHARED,
    smokeAlarmsMaintainedBy: SMOKE_ALARM_MAINTAIN_LANDLORD,
    smokeAlarms: SMOKE_ALARM_TYPE_COMPLIANT,
    smokeAlarmCompanyName: '',
    smokeAlarmAmount: '',
    lastTestedAndCleaned: '',
    lastBatteryChange: '',
    serviceLifeIndicatedByManufacturer: '',
    serviceAndMaintenanceOfSmokeAlarms: SMOKE_ALARM_ARRANGED_PRIOR
};

const PoolSafetyAndSmokeAlarms = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxPoolSafetyAndSmokeAlarms = useSelector(getPoolSafetyAndSmokeAlarms);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [poolSafetyAndSmokeAlarms, setPoolSafetyAndSmokeAlarms] = useState(
        reduxPoolSafetyAndSmokeAlarms || initState
    );

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(PM_POOL_SAFETY_FORM).dispatchEvent(
                new Event('submit', {
                    cancelable: true,
                    bubbles: true
                })
            );
        }
    }));

    useEffect(() => {
        const data = cloneDeep(poolSafetyAndSmokeAlarms);
        if (data.lastTestedAndCleaned) {
            data.lastTestedAndCleaned = new Date(data.lastTestedAndCleaned);
        }
        if (data.lastBatteryChange) {
            data.lastBatteryChange = new Date(data.lastBatteryChange);
        }
        setPoolSafetyAndSmokeAlarms(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updatePoolSafetyAndSmokeAlarms(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.poolSafetyAndSmokeAlarms')) {
                    return error.response.data.errors.poolSafetyAndSmokeAlarms;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const updatePoolSafetyAndSmokeAlarms = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/pool-safety-and-smoke-alarms`, data);
    };

    return (
        <div className="detailsRepairs">
            <Form onSubmit={submitForm} initialValues={poolSafetyAndSmokeAlarms}>
                {({ handleSubmit, values, form }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={PM_POOL_SAFETY_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <div>
                                <CheckboxCheck name="hasPool" label="The property has a pool" />
                                {values.hasPool && (
                                    <FormRadioGroup
                                        label="Pool Type"
                                        name={'poolType'}
                                        value={values.poolType}
                                        radioGroupClass="wrapped-radio-group"
                                        data={[
                                            {
                                                label: POOL_TYPE_REGULATED_POOL_SHARED,
                                                value: POOL_TYPE_REGULATED_POOL_SHARED
                                            },
                                            {
                                                label: POOL_TYPE_REGULATED_POOL_NOT_SHARED,
                                                value: POOL_TYPE_REGULATED_POOL_NOT_SHARED
                                            },
                                            {
                                                label: POOL_TYPE_NO_POOL,
                                                value: POOL_TYPE_NO_POOL
                                            }
                                        ]}
                                    />
                                )}
                                <h3>Smoke Alarms</h3>
                                <FormRadioGroup
                                    label="Service and maintenance of smoke alarms:"
                                    name={'serviceAndMaintenanceOfSmokeAlarms'}
                                    value={values.serviceAndMaintenanceOfSmokeAlarms}
                                    radioGroupClass="wrapped-radio-group"
                                    data={[
                                        {
                                            label:
                                                'To be arranged by the agent prior to the commencement of the 1st lease.',
                                            value: SMOKE_ALARM_ARRANGED_PRIOR
                                        },
                                        {
                                            label: 'As already completed at the commencement of the current lease.',
                                            value: SMOKE_ALARM_ALREADY_COMPLETED
                                        },
                                        {
                                            label: 'As specified:',
                                            value: SMOKE_ALARM_AS_SPECIFIED
                                        }
                                    ]}
                                />
                                {values.serviceAndMaintenanceOfSmokeAlarms === SMOKE_ALARM_AS_SPECIFIED && (
                                    <React.Fragment>
                                        <div className="calendar">
                                            <DatePickerInAgencyTimeZone
                                                label="Last Tested & Cleaned"
                                                name="lastTestedAndCleaned"
                                                selected={values.lastTestedAndCleaned}
                                                required
                                            />
                                        </div>
                                        <div className="calendar">
                                            <DatePickerInAgencyTimeZone
                                                label="Last Battery Change"
                                                name="lastBatteryChange"
                                                selected={values.lastBatteryChange}
                                                required
                                            />
                                        </div>
                                    </React.Fragment>
                                )}
                                <FormTextRegular
                                    name="serviceLifeIndicatedByManufacturer"
                                    label="Service Life Indicated by Manufacturer"
                                    required
                                />
                                <FormRadioGroup
                                    label="Smoke alarms will be maintained by:"
                                    name={'smokeAlarmsMaintainedBy'}
                                    radioGroupClass="wrapped-radio-group"
                                    value={values.smokeAlarmsMaintainedBy}
                                    data={[
                                        {
                                            label: SMOKE_ALARM_MAINTAIN_LESSOR,
                                            value: SMOKE_ALARM_MAINTAIN_LANDLORD
                                        },
                                        {
                                            label: SMOKE_ALARM_MAINTAIN_AGENT,
                                            value: SMOKE_ALARM_MAINTAIN_AGENT
                                        },
                                        {
                                            label: SMOKE_ALARM_MAINTAIN_STRATA,
                                            value: SMOKE_ALARM_MAINTAIN_STRATA
                                        },
                                        {
                                            label: SMOKE_ALARM_MAINTAIN_COMPANY,
                                            value: SMOKE_ALARM_MAINTAIN_COMPANY
                                        }
                                    ]}
                                />
                                {values.smokeAlarmsMaintainedBy === SMOKE_ALARM_MAINTAIN_COMPANY && (
                                    <React.Fragment>
                                        <FormTextRegular name="smokeAlarmCompanyName" label="Company Name" required />
                                        <FormTextCurrency
                                            name="smokeAlarmAmount"
                                            label="Amount"
                                            precision="2"
                                            required
                                        />
                                    </React.Fragment>
                                )}
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default PoolSafetyAndSmokeAlarms;
