import React from 'react';
import cx from 'classnames';
import { Field, FieldRenderProps, useField } from 'react-final-form';
import Icon, { Icons } from '../../../../../common/components/Icon';

import styles from './RadioIconGroup.module.scss';
import { getFormError } from '../../../../../utils/finalFormUtils';

type RadioItemData = {
    label: string;
    value: string;
    icon: React.ReactElement;
};

type RadioItemProps = RadioItemData & FieldRenderProps<string, HTMLElement>;

const RadioItem: React.FC<RadioItemProps> = ({ label, input, icon }) => {
    const iconWithClassName = React.cloneElement(icon, {
        className: cx(icon.props.className, styles.icon)
    });

    return (
        <label className={cx(styles.radioItem, { [styles.checked]: input.checked })}>
            {input.checked && <Icon className={styles.indicator} icon={Icons.ACTIVE_RADIO_DARK} />}
            {iconWithClassName}
            {label}
            <input className={styles.hiddenInput} type="radio" {...input} />
        </label>
    );
};

type RadioGroupProps = {
    name: string;
    label?: string;
    ariaLabel: string;
    data: RadioItemData[];
    className?: string;
    LabelElement?: React.ComponentType;
    disabled?: boolean;
};

const RadioIconGroup: React.FC<RadioGroupProps> = ({ data, name, label, LabelElement, className, disabled }) => {
    const Label = LabelElement || 'label';

    const { meta } = useField(name, { subscription: { error: true, touched: true } });

    const error = getFormError(meta, true);

    return (
        <div
            className={cx(styles.container, className, { [styles.error]: error, [styles.disabled]: disabled })}
            data-has-error={!!error}
        >
            {label && <Label className={styles.radioGroupLabel}>{label}</Label>}
            <div className={styles.radioGroup}>
                {data.map(radioItem => (
                    <Field
                        disabled={disabled}
                        key={radioItem.value}
                        type="radio"
                        label={radioItem.label}
                        name={name}
                        value={radioItem.value}
                        icon={radioItem.icon} // added icon to Field props
                        component={RadioItem}
                    />
                ))}
            </div>
            {error && <div className={styles.errorText}>{error}</div>}
        </div>
    );
};

export default RadioIconGroup;
