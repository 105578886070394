import * as React from 'react';
import createMuiIcon from '../createMuiIcon';

/**
 * @deprecated This Material UI icon is deprecated. Please use an icon from our design system instead.
 * Contact the design team for the appropriate replacement icon.
 */
export default createMuiIcon(
    <path d="M19 19H5V5h7V3H3v18h18v-9h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z" />,
    'LaunchSharp'
);
