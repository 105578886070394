import ReactPagination from 'react-js-pagination';
import { ArrowBackIosSharp, ArrowForwardIosSharp } from '@flk-mui-icons';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

const Pagination = props => {
    return (
        <React.Fragment>
            <div className="desktop-only">
                <ReactPagination
                    hideDisabled
                    activePage={props.pageNav.page ? props.pageNav.page : 1}
                    itemsCountPerPage={props.pageNav.recordsPerPage ? props.pageNav.recordsPerPage : 0}
                    totalItemsCount={props.pageNav.totalItemsCount ? props.pageNav.totalItemsCount : 0}
                    pageRangeDisplayed={3}
                    onChange={props.onChange}
                    lastPageText={<div className="last-icon" />}
                    firstPageText={<div className="first-icon" />}
                    nextPageText="..."
                    prevPageText="..."
                />
            </div>
            <div className="mobile-only">
                <ReactPagination
                    hideDisabled
                    activePage={props.pageNav.page ? props.pageNav.page : 1}
                    itemsCountPerPage={props.pageNav.recordsPerPage ? props.pageNav.recordsPerPage : 0}
                    totalItemsCount={props.pageNav.totalItemsCount ? props.pageNav.totalItemsCount : 0}
                    pageRangeDisplayed={3}
                    onChange={props.onChange}
                    lastPageText={<ArrowForwardIosSharp />}
                    firstPageText={<ArrowBackIosSharp />}
                    nextPageText="..."
                    prevPageText="..."
                />
            </div>
        </React.Fragment>
    );
};

Pagination.propTypes = {
    pageNav: PropTypes.shape({ recordsPerPage: PropTypes.any, page: PropTypes.any }),
    onChange: PropTypes.func
};

export default memo(Pagination);
