import React from 'react';
import { Table, Th, Tr, Td, Thead } from 'reactable';
import ProgressBlock from '../../../../components/dashboard/agreementList/cells/progressBlock';
import LeaseDropdown from '../../../dropdowns/LeaseDropdown';
import AgentLogo from '../../../AgentLogo';
import renewalsIcon from '../../../../../assets/images/icons/renewals-icon.svg?url';
import {
    getTenantNames,
    hasSaExtensions,
    isCompletedAndValidated,
    isLeaseLocationQLD,
    isLeaseTypePm,
    isLeaseTypeResidentialTenancy,
    isLeaseTypeSales
} from '../../../../utils/agreementUtils';
import { formatDateStandard, getAgencyTimezoneFromUser } from '../../../../utils/dateUtils';
import { getUserInfo } from '../../../../selectors/user';
import { useSelector } from 'react-redux';
import { getLabel, getSubLeaseTypeLabel } from '../../../../utils/labelUtils';
import { isAccountEnabled } from '../../../../utils/userUtils';

const TableDraft = ({ leaseStatus, agreementList, leaseType, showModalInfoAgreement }) => {
    const loggedInUser = useSelector(getUserInfo);
    const haveSaExtensions = hasSaExtensions(agreementList);
    const tableHead = leaseType => {
        const startDateHeader = isLeaseTypeResidentialTenancy(leaseType) ? 'lease start date' : 'agreement start date';
        if (isLeaseTypeSales(leaseType) || isLeaseTypePm(leaseType)) {
            return (
                <Thead className="desktop-only">
                    <Th column="agentLogo"> </Th>
                    <Th column="isRenewal"> </Th>
                    <Th column="address">address</Th>
                    <Th column="agent">agent</Th>
                    <Th column="tenants">{getLabel('signorsHeader', null, leaseType)}</Th>
                    <Th column="leaseStartDate">{startDateHeader}</Th>
                    <Th column="progress">progress</Th>
                    <Th column="action">&nbsp;</Th>
                </Thead>
            );
        } else {
            return (
                <Thead className="desktop-only">
                    <Th column="agentLogo"> </Th>
                    <Th column="isRenewal"> </Th>
                    <Th column="address">address</Th>
                    {haveSaExtensions && <Th column="subLeaseType">lease type</Th>}
                    <Th column="tenants">{getLabel('signorsHeader', null, leaseType)}</Th>
                    <Th column="leaseStartDate">{startDateHeader}</Th>
                    <Th column="progress">progress</Th>
                    <Th column="action">&nbsp;</Th>
                </Thead>
            );
        }
    };

    return (
        <Table className="pm-draft-tb">
            {tableHead(leaseType)}
            {agreementList &&
                agreementList.map((lease, key) => {
                    const loadingClassName = lease.isLoading ? 'loading' : '';
                    let startDate = '-';
                    if (isLeaseTypePm(leaseType)) {
                        if (lease?.pmAgreementTerm?.startDate) {
                            startDate = formatDateStandard(
                                lease.pmAgreementTerm.startDate,
                                getAgencyTimezoneFromUser(loggedInUser)
                            );
                        } else if (lease?.pmAuthorityPeriodDetails?.startDate) {
                            startDate = formatDateStandard(
                                lease.pmAuthorityPeriodDetails.startDate,
                                getAgencyTimezoneFromUser(loggedInUser)
                            );
                        }
                    } else if (lease?.term?.startDate) {
                        startDate = formatDateStandard(lease.term.startDate, getAgencyTimezoneFromUser(loggedInUser));
                    }
                    return (
                        <Tr
                            key={key}
                            className={`showmodal ${loadingClassName}`}
                            onClick={() => {
                                showModalInfoAgreement(lease);
                            }}
                        >
                            <Td column="agentLogo">
                                <AgentLogo agent={lease.agent} />
                            </Td>
                            {lease.isRenewal && (
                                <Td column="isRenewal">
                                    <img src={renewalsIcon} />
                                </Td>
                            )}
                            <Td column="address">
                                <b>{lease.address}</b>
                            </Td>
                            {haveSaExtensions && isLeaseTypeResidentialTenancy(leaseType) && (
                                <Td column="subLeaseType">{getSubLeaseTypeLabel(lease.subLeaseType)}</Td>
                            )}
                            {!isLeaseTypeResidentialTenancy(leaseType) && (
                                <Td className="desktop-only" column="agent">
                                    {lease.agent.fullName}
                                </Td>
                            )}
                            <Td column="tenants">{getTenantNames(lease)}</Td>
                            <Td className="desktop-only" column="leaseStartDate">
                                {startDate}
                            </Td>
                            <Td column="progress">
                                <ProgressBlock
                                    progress={lease.progress}
                                    status={leaseStatus}
                                    leaseType={leaseType}
                                    subLeaseType={lease.subLeaseType}
                                    location={lease.location}
                                />
                            </Td>
                            {isAccountEnabled(loggedInUser) && (
                                <Td className="desktop-only" column="action">
                                    <div className="action">
                                        <LeaseDropdown
                                            loggedInUser={loggedInUser}
                                            lease={lease}
                                            leaseStatus={leaseStatus}
                                            isCompletedLease={isCompletedAndValidated(
                                                lease,
                                                lease.leaseType,
                                                lease.location
                                            )}
                                        >
                                            <div className="more-action" />
                                        </LeaseDropdown>
                                        <span className="arrow-right" />
                                    </div>
                                </Td>
                            )}
                        </Tr>
                    );
                })}
        </Table>
    );
};

export default TableDraft;
