import React, { memo, useEffect, useRef, useState } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import useDoubleSendGuard, { clearDoubleSendGuard } from '../../../../hooks/useDoubleSendGuard';
import { openNoSignatureModal } from '../../../../actions/user.js';
import {
    closeEstimatedSellingPriceForm,
    addOrReplaceDocumentInList,
    setCurrentDocument
} from '../../../../actions/document.js';
import ModalDialog from '../../../../common/components/ModalDialog.js';
import DocumentUpload from '../../../../containers/shared/DocumentUpload.js';
import {
    NSW_STATE,
    FORM_SUBMIT_TYPE_SEND,
    CLOSE_MODAL_MESSAGE,
    FormSubmitType,
    DOCUMENT_ESTIMATED_SELLING_PRICE_NOTICE,
    DELIVERY_TO_AGENT,
    viewUploadedDocumentFromDocument
} from '../../../../config';
import DocumentHeader from '../../DocumentHeader.js';
import DatePickerInAgencyTimeZone from '../../../../components/form/DatePickerInAgencyTimeZone.js';
import { getCurrentDocument, getDocumentEditMode } from '../../../../selectors/document/index.js';
import { getAgentSignature } from '../../../../selectors/completion/index.js';
import { isDocumentReadOnlyMode } from '../../../../utils/generalUtils.js';
import LocationAndAddressSelection from '../../LocationAndAddressSelection.js';
import { getIsOpenEstimatedSellingPriceNoticeDocumentModal } from '../../../../selectors/document/estimatedSellingPriceDocument';
import { formatDocumentsForApi } from '../../../../utils/formUtils.js';
import '../../../../sass/documentModalDialog.scss';
import { AGENT_ATTACHED, EMAIL, ESP_API, EspApi } from './EstimatedSellingPriceForm.config';
import { getUserInfo } from '../../../../selectors/user.js';
import {
    AgreementInfoType,
    ApiModel,
    AttachedDocument,
    EspApiModel,
    EspApiModelResponse,
    EspFormSchema,
    FormModel,
    initializeFormModel,
    projectEspApiModel,
    projectFormModel,
    projectFormSalesAgreementInfo
} from './EstimatedSellingPriceForm.model';
import EstimatedSellingPriceFormFooter from './EstimatedSellingPriceFormFooter';
import { getErrorObjectFromYupError } from '../../../../utils/formUtils.js';

import AdditionalLinks from '../../../../components/dashboard/additionalLinks/AdditionalLinks';
import PrincipalSection from './components/PrincipalSection';
import DetailsRevisedEstimatedSellingPriceSection from './components/DetailsRevisedEstimatedSellingPriceSection';
import EvidenceSection from './components/EvidenceSection';
import NoticeDeliverySection from './components/NoticeDeliverySection';
import { confirmAlert } from 'react-confirm-alert';
import { User } from '../../../../types/User';
import Icon, { Icons } from '../../../../common/components/Icon';
import EditSenderConfirmation from '../../../../components/modals/document/EditSenderConfirmation';
import { FormApi } from 'final-form';
import useResendCounter from '../../../../hooks/useResendCounter';
import { ToastTypes } from '../../../../common/components/Toast';
import useToast from '../../../../hooks/useToast';
import { DOC_STATUS_ACTIVE } from '../../../../actions/dashboard.js';
import appHistory from '../../../../AppHistory.js';

const allowedStates = [NSW_STATE];

type EstimatedSellingPriceFormPops = {
    isOpenFromAgreement?: boolean;
    agreementInfo?: AgreementInfoType;
    closeFromAgreement?: () => void;
};

const EstimatedSellingPriceForm: React.FC<EstimatedSellingPriceFormPops> = ({
    isOpenFromAgreement,
    agreementInfo,
    closeFromAgreement
}) => {
    const dispatch = useDispatch();
    const { addNewToast } = useToast();

    const isOpenEstimatedSellingPriceNoticeDocumentModal = useSelector(
        getIsOpenEstimatedSellingPriceNoticeDocumentModal
    );

    const loggedInUser: User = useSelector(getUserInfo);
    const formValuesRef = useRef<FormModel | null>(null);
    const isOpen = isOpenFromAgreement ?? isOpenEstimatedSellingPriceNoticeDocumentModal;
    const currentDocument: EspApiModel = useSelector(getCurrentDocument);
    const documentEditMode: boolean = useSelector(getDocumentEditMode);
    const agentSignature = useSelector(getAgentSignature);
    const [currentDocumentId, setCurrentDocumentId] = useState(currentDocument ? currentDocument.id : null);
    const [location, setLocation] = useState<string | null>(allowedStates[0]);
    const [locationAndAddressSelected, setLocationAndAddressSelected] = useState(
        currentDocument ? currentDocument.address : false
    );

    const doubleSendGuard = useDoubleSendGuard();
    const { resendWaitSeconds, startResendCounter } = useResendCounter();
    const [documentUploadProgress, setDocumentUploadProgress] = useState(0);
    const [documentUploadError, setDocumentUploadError] = useState<string | null>(null);
    const [showEditSenderConfirmationModel, setShowEditSenderConfirmationModel] = useState(false);
    const formRef = useRef<FormApi<FormModel>>(null);
    const dirtyRef = React.useRef(false);

    const [agent, setAgent] = useState({
        value: currentDocument?.agent?.id || loggedInUser.id || '',
        label: currentDocument?.agent?.fullName || loggedInUser.fullName || ''
    });

    useEffect(() => {
        if (currentDocument && isOpen) {
            window.history.pushState({}, '', `/dashboard/document/${currentDocument.id}`);
        }
    }, [isOpen]);

    useEffect(() => {
        if (currentDocument) {
            setLocation(currentDocument.location);
            setLocationAndAddressSelected(true);
            setCurrentDocumentId(currentDocument.id);
        }
        if (!currentDocument) {
            setLocation(loggedInUser?.agency?.details?.location ? loggedInUser.agency.details.location : null);
            setLocationAndAddressSelected(false);
        }
    }, [isOpen, currentDocument]);

    const closeModal = () => {
        if (closeFromAgreement) {
            closeFromAgreement();
        } else {
            dispatch(closeEstimatedSellingPriceForm());
            if (currentDocument) {
                window.history.pushState(
                    {},
                    '',
                    `/dashboard/documents/${currentDocument.docType}/${currentDocument.status}`
                );
            }
        }
    };

    const showCloseModalWarning = (handleSave: () => void) => {
        confirmAlert({
            title: '',
            message: CLOSE_MODAL_MESSAGE,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        handleSave();
                        closeModal();
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        closeModal();
                    }
                },
                {
                    className: 'close close-modal',
                    label: <Icon icon={Icons.CLOSE} />,
                    onClick: () => {}
                }
            ]
        });
    };

    const handleCloseModal = (handleSave: () => void) => {
        if (dirtyRef.current) {
            showCloseModalWarning(handleSave);
        } else {
            closeModal();
        }
    };

    const handleSubmit = async (values: FormModel) => {
        if (values.submitType === FormSubmitType.Save) {
            await saveDraft(projectEspApiModel(values));
        } else if (values.submitType === FormSubmitType.Send) {
            if (!showEditSenderConfirmationModel && agent.value && loggedInUser.id !== agent.value) {
                setShowEditSenderConfirmationModel(true);
                return;
            }
            if (isEmpty(agentSignature)) {
                closeModal();
                dispatch(openNoSignatureModal());
                return;
            }

            // Show a modal to agents who try to send ESP without licence number
            const { salesLicenseDetails, licenseDetails } = loggedInUser?.agency || {};
            if (!salesLicenseDetails?.corporationLicenseNumber && !licenseDetails?.corporationLicenseNumber) {
                confirmAlert({
                    message:
                        'A Corporation Licence Number is required to complete this document. Add this to your Agency Details in Settings by clicking the button below.',
                    buttons: [
                        {
                            label: 'Go to Settings',
                            onClick: () => {
                                appHistory.push('/user/details');
                                closeModal();
                                return;
                            }
                        },
                        {
                            className: 'close close-modal',
                            label: <Icon icon={Icons.CLOSE} />,
                            onClick: () => {}
                        }
                    ]
                });

                return;
            }

            return await sendDocument(projectEspApiModel(values));
        }
    };

    const closeEditSenderConfirmationModel = () => {
        setShowEditSenderConfirmationModel(false);
    };

    const submitConfirmation = () => {
        if (formValuesRef.current !== null && formRef.current !== null) {
            setShowEditSenderConfirmationModel(false);
            formValuesRef.current.submitType = FormSubmitType.Send;
            formRef.current.dispatchEvent(
                new Event('submit', {
                    bubbles: true
                })
            );
        }
    };

    const { mutateAsync: saveDraftMutateAsync, isLoading: isSendingEstimatedSellingPriceNotice } = useMutation(
        (data: ApiModel) => {
            return axios.post(`${ESP_API}${currentDocumentId ? `/${currentDocumentId}/save` : ''}`, data);
        }
    );

    const { mutateAsync: uploadDocumentsMutation, isLoading: isUploadingDocuments } = useMutation(
        (documents: AttachedDocument[]) => {
            return axios.post(EspApi.UploadApi, documents, {
                onUploadProgress: progressEvent => {
                    const { loaded, total } = progressEvent;
                    setDocumentUploadProgress(Math.floor((loaded * 100) / total));
                }
            });
        }
    );

    const {
        mutateAsync: sendDocumentMutation,
        isLoading: isSendDocumentMutation,
        isSuccess: isSendDocumentMutationSuccess
    } = useMutation((data: ApiModel) => {
        return axios.post(`${ESP_API}${currentDocumentId ? `/${currentDocumentId}/send` : '/send'}`, data);
    });

    const sendDocument = async (values: ApiModel) => {
        return doubleSendGuard(async () => {
            try {
                const data = cloneDeep(values);

                const shouldUploadDocuments =
                    values.evidence === AGENT_ATTACHED && values.documents && values.documents.length > 0;

                if (shouldUploadDocuments) {
                    const uploadResponse = await uploadDocumentsMutation(formatDocumentsForApi([...values.documents]));
                    data.documents = uploadResponse.data.documents;
                }

                data.emailTo = data.deliveryType !== EMAIL ? DELIVERY_TO_AGENT : data.emailTo;

                await sendDocumentMutation(data);
                startResendCounter();
                closeEditSenderConfirmationModel();
                closeModal();
                addNewToast('Document sent successfully', ToastTypes.SUCCESS, true);
                appHistory.push(`/dashboard/documents/${DOCUMENT_ESTIMATED_SELLING_PRICE_NOTICE}/${DOC_STATUS_ACTIVE}`);
            } catch (err) {
                clearDoubleSendGuard();
                addNewToast('Document send failed', ToastTypes.ERROR, true);
                return err;
            }
        });
    };

    const saveDraft = async (values: ApiModel, preview = false) => {
        const data = cloneDeep(values);

        const shouldUploadDocuments = !preview && values.documents && values.documents.length > 0;

        if (shouldUploadDocuments) {
            const uploadResponse = await uploadDocumentsMutation(formatDocumentsForApi([...values.documents]));
            data.documents = uploadResponse.data.documents;
        }

        const saveResponse = await saveDraftMutateAsync(data);

        const { espNotice } = saveResponse.data;
        dispatch(addOrReplaceDocumentInList(espNotice));
        dispatch(setCurrentDocument(espNotice));
        setCurrentDocumentId(espNotice.id);

        return saveResponse;
    };

    const confirmDetails = async (values: FormModel) => {
        const {
            data: { espNotice }
        }: EspApiModelResponse = await saveDraft(projectEspApiModel(values));
        setCurrentDocumentId(espNotice.id);
        dispatch(addOrReplaceDocumentInList(espNotice));
        setLocationAndAddressSelected(true);
        dispatch(setCurrentDocument(espNotice));
    };

    const viewPdf = (docId: string) => {
        window.open(`/reader/document/esp-notice/${docId}`, '_blank');
    };

    const previewPdf = async (values: FormModel) => {
        if (!isEmpty(agentSignature)) {
            const {
                data: { espNotice }
            } = await saveDraft(projectEspApiModel(values), true);
            dispatch(addOrReplaceDocumentInList(espNotice));
            setCurrentDocumentId(espNotice.id);
            viewPdf(espNotice.id);
        } else {
            closeModal();
            dispatch(openNoSignatureModal());
        }
    };

    return (
        <>
            <Form
                onSubmit={handleSubmit}
                initialValues={
                    currentDocument
                        ? projectFormModel(currentDocument)
                        : agreementInfo
                          ? projectFormSalesAgreementInfo(agreementInfo)
                          : initializeFormModel({ nameOfpersonServing: agent.label })
                }
                mutators={{ ...arrayMutators }}
                initialValuesEqual={(a, b) => isEqual(a, b)}
                validate={async (values: FormModel) => {
                    try {
                        if (values.submitType === FORM_SUBMIT_TYPE_SEND) {
                            await EspFormSchema.validate(values, { abortEarly: false });
                        }
                    } catch (err) {
                        return getErrorObjectFromYupError(err);
                    }
                }}
                keepDirtyOnReinitialize
            >
                {({
                    handleSubmit,
                    values,
                    form,
                    submitting,
                    submitFailed,
                    dirty,
                    form: {
                        mutators: { push, removeBatch }
                    }
                }) => {
                    dirtyRef.current = dirty;
                    formValuesRef.current = values;
                    const isReadOnly = isDocumentReadOnlyMode(documentEditMode);

                    return (
                        <ModalDialog
                            title={
                                locationAndAddressSelected
                                    ? 'Estimated Selling Price notice'
                                    : 'Create new Estimated Selling Price Notice for'
                            }
                            isOpen={isOpen}
                            shouldCloseOnOverlayClick={false}
                            className={`document-centre-modal document-modal-dialog tenant ${
                                locationAndAddressSelected && location ? 'with-header' : 'without-header'
                            }`}
                            hideCloseButton={false}
                            // Quick fix for search results being cut off by modal
                            allowOverflow={!locationAndAddressSelected}
                            closeModal={() =>
                                handleCloseModal(() => {
                                    form.change('submitType', FormSubmitType.Save);
                                    form.submit();
                                })
                            }
                            titleClassName={'modal-title'}
                        >
                            <form onSubmit={handleSubmit} noValidate id="create-esp-form" ref={formRef}>
                                {!locationAndAddressSelected && !currentDocument ? (
                                    <LocationAndAddressSelection
                                        location={location}
                                        allowedStates={allowedStates}
                                        setLocation={setLocation}
                                        closeModal={closeModal}
                                        confirmDetails={() => confirmDetails(values)}
                                        address={values.address}
                                        handleAddressChange={(address: string) => form.change('address', address)}
                                        setAddressDetails={(address: string, details: string) =>
                                            form.batch(() => {
                                                form.change('address', address);
                                                form.change('addressDetails', details);
                                                form.change('location', location ?? '');
                                            })
                                        }
                                        defaultIsIntegrationSearch={false}
                                        hideIntegration={true}
                                        isCreating={isSendingEstimatedSellingPriceNotice}
                                    />
                                ) : (
                                    <div className="esp-notice">
                                        <DocumentHeader
                                            parentSelector="create-esp-form"
                                            confirmDetails={() => {
                                                form.change('submitType', FormSubmitType.Save);
                                                form.submit();
                                            }}
                                            address={values.address}
                                            handleAddressChange={(address: string) => form.change('address', address)}
                                            setAddressDetails={(address: string, details: string) => {
                                                form.change('address', address);
                                                form.change('addressDetails', details);
                                            }}
                                            disabled={isDocumentReadOnlyMode(documentEditMode)}
                                            agentName={values.agentName}
                                            doc={currentDocument}
                                            hideMoreOptions={currentDocument && !currentDocument.completion}
                                            showIntegration={false}
                                            setAssignedAgent={setAgent}
                                        />

                                        <div>
                                            <div className="calender">
                                                <DatePickerInAgencyTimeZone
                                                    label="Date the sales agency agreement was signed"
                                                    name="salesAgencyAgreementDate"
                                                    required={true}
                                                    selected={values.salesAgencyAgreementDate}
                                                    disabled={isReadOnly}
                                                />
                                            </div>

                                            <PrincipalSection isReadOnly={isReadOnly} />

                                            <DetailsRevisedEstimatedSellingPriceSection isReadOnly={isReadOnly} />

                                            <EvidenceSection isReadOnly={isReadOnly} />

                                            {values.evidence === AGENT_ATTACHED && (
                                                <>
                                                    <h4>Attach Evidence</h4>
                                                    <DocumentUpload
                                                        uploading={isUploadingDocuments}
                                                        leaseType={DOCUMENT_ESTIMATED_SELLING_PRICE_NOTICE}
                                                        updateDocuments={(documents: AttachedDocument[]) => {
                                                            form.change('documents', documents);
                                                        }}
                                                        documents={values.documents}
                                                        backendError={documentUploadError}
                                                        documentUploadProgress={documentUploadProgress}
                                                        hideFileCategory={true}
                                                        agencyDefault={false}
                                                        useSelectedOption={false}
                                                        viewDocument={(docId: string) =>
                                                            viewUploadedDocumentFromDocument(currentDocumentId, docId)
                                                        }
                                                        disabled={isReadOnly}
                                                    />
                                                </>
                                            )}
                                            <AdditionalLinks
                                                title="Add Relevant Links"
                                                addButtonLabel="Add link"
                                                initialLinks={currentDocument?.details?.linksList?.links}
                                                addLink={() => {
                                                    form.change('additionalLinkTitle', '');
                                                    form.change('additionalLinkUrl', '');
                                                    push('additionalLinks', {
                                                        title: values.additionalLinkTitle,
                                                        url: values.additionalLinkUrl
                                                    });
                                                }}
                                                removeLink={(index: number) => {
                                                    removeBatch('additionalLinks', [index]);
                                                }}
                                                details={{
                                                    title: values.additionalLinkTitle,
                                                    url: values.additionalLinkUrl
                                                }}
                                                disabled={isReadOnly}
                                            />

                                            <NoticeDeliverySection isReadOnly={isReadOnly} />
                                            <EstimatedSellingPriceFormFooter
                                                currentDocument={currentDocument}
                                                isSaveDraft={values.submitType === FormSubmitType.Save}
                                                documentEditMode={documentEditMode}
                                                isSendingEspNotice={submitting}
                                                isSendingEspNoticeFail={submitFailed}
                                                isSendingEspNoticeSuccess={isSendDocumentMutationSuccess}
                                                previewPdf={() => previewPdf(values)}
                                                form={form}
                                                viewPdf={viewPdf}
                                                showLoadingIcon={submitting}
                                            />
                                        </div>
                                    </div>
                                )}
                            </form>
                        </ModalDialog>
                    );
                }}
            </Form>
            <EditSenderConfirmation
                isOpen={showEditSenderConfirmationModel}
                close={closeEditSenderConfirmationModel}
                primaryHandler={submitConfirmation}
                secondaryHandler={closeEditSenderConfirmationModel}
                currentAgentName={agent.label}
                loggedUserInfo={{
                    firstName: loggedInUser?.firstName ?? '',
                    fullName: loggedInUser?.fullName ?? ''
                }}
                isLoading={isSendDocumentMutation || isUploadingDocuments}
                docStatus={currentDocument?.status}
                assignedAgentName={agent.label}
                docType={DOCUMENT_ESTIMATED_SELLING_PRICE_NOTICE}
            />
        </>
    );
};
export default memo(EstimatedSellingPriceForm);
