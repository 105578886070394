import React, { useEffect } from 'react';
import '../../../sass/headerLease.scss';
import CloseSharpIcon from '@flk-mui-icons/CloseSharp';
import { HeaderLeftTemplate } from '../../dashboard/InfoAgreementComponents/HeaderLeftTemplate';
import { useDispatch, useSelector } from 'react-redux';
import { getTemplatesPaginationByType, getTemplatesSorting } from '../../../selectors/dashboard/templates';
import { getTemplates } from '../../../actions/template';

const HeaderTemplate = props => {
    const dispatch = useDispatch();
    const templatesPagination = useSelector(state => getTemplatesPaginationByType(state, props.leaseAllInfo.leaseType));
    const templatesSorting = useSelector(state => getTemplatesSorting(state, props.leaseAllInfo.leaseType));
    useEffect(() => {
        document.addEventListener('keydown', escFunction);
        return function cleanup() {
            document.removeEventListener('keydown', escFunction);
        };
    }, []);

    function escFunction(event) {
        if (event.keyCode === 27) {
            props.closeModal();
        }
    }

    function closeModal() {
        dispatch(
            getTemplates(
                props.leaseAllInfo.leaseType,
                templatesPagination.page,
                templatesPagination.limit,
                templatesSorting.field,
                templatesSorting.direction
            )
        );
        props.closeModal();
    }

    return (
        <header>
            <div className="header-content">
                {props.leaseAllInfo && props.leaseAllInfo.agent && (
                    <HeaderLeftTemplate leaseAllInfo={props.leaseAllInfo} />
                )}
                <div className="header-right">
                    <div className="header-right-wrapper">
                        <div onClick={() => closeModal()} className="close header-button" data-test="header-close-btn">
                            <CloseSharpIcon>Close</CloseSharpIcon>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default HeaderTemplate;
