import React from 'react';
import cx from 'classnames';
import { Field } from 'react-final-form';
import { getError, handleInputValueChange } from '../../utils/formUtils';
import ReactTooltip from 'react-tooltip';
import { InfoSharp } from '@flk-mui-icons';
import Suggestion from '../suggestions/Suggestion';
import { isEmpty } from 'lodash';

const emailInput = ({
    input,
    disabledLabel,
    disabled,
    label,
    required,
    meta,
    onChange,
    validateOnTouch,
    alwaysShowOn,
    tooltip,
    containerClassName,
    suggestionsList,
    removeSuggestion,
    suggestionIcon
}) => {
    let className = 'form-group regular';
    if (alwaysShowOn || input.value) {
        className += ' on';
    }
    return (
        <div
            className={cx('FormInput', containerClassName, {
                'FormInput--error': getError(meta, validateOnTouch)
            })}
        >
            <div className={className}>
                <label className={getError(meta, validateOnTouch) ? `FormError ${disabledLabel}` : disabledLabel}>
                    {label}
                    {required ? '*' : null}
                </label>
                {tooltip ? (
                    <InfoSharp
                        className={`tooltip-info-icon text-gray form-input-tooltip ${
                            alwaysShowOn || input.value ? 'on' : ''
                        }`}
                        data-tip
                        data-for={`${label}-tooltip`}
                    />
                ) : null}
                <ReactTooltip
                    id={`${label}-tooltip`}
                    class="tooltip"
                    place="bottom"
                    effect="solid"
                    globalEventOff="wheel"
                >
                    <div>
                        <div className="info-segment">{tooltip}</div>
                    </div>
                </ReactTooltip>
                {!isEmpty(suggestionsList) && (
                    <div className="with-suggestions">
                        {suggestionsList?.map((suggestion, index) => {
                            return (
                                <Suggestion
                                    className={'form-suggestions'}
                                    key={index}
                                    text={suggestion}
                                    icon={suggestionIcon}
                                    onClick={() => {
                                        handleInputValueChange(input, onChange, { target: { value: suggestion } });
                                        removeSuggestion(index);
                                    }}
                                    onClickEndIcon={e => {
                                        e.stopPropagation();
                                        removeSuggestion(index);
                                    }}
                                />
                            );
                        })}
                    </div>
                )}
                <input
                    type="email"
                    {...input}
                    disabled={disabled}
                    onChange={item => {
                        input.onChange(item);
                        if (onChange) {
                            onChange(item);
                        }
                    }}
                />
                {getError(meta, validateOnTouch) && (
                    <span className={'FormError'}>{getError(meta, validateOnTouch)}</span>
                )}
            </div>
        </div>
    );
};

export const FormEmail = ({
    name,
    required,
    label,
    disabled,
    validateOnTouch,
    onChange,
    alwaysShowOn,
    tooltip,
    containerClassName,
    validate,
    suggestionsList,
    removeSuggestion,
    suggestionIcon
}) => {
    let disabledLabel = '';
    if (true === disabled) {
        disabledLabel = 'disabled';
    }
    /* This wraps react-final-form's <Field/> component.
     * The identity function ensures form values never get set to null,
     * but rather, empty strings.
     *
     * See https://github.com/final-form/react-final-form/issues/130
     */
    const identity = value => value;

    return (
        <Field
            name={name}
            disabled={disabled}
            type="email"
            containerClassName={containerClassName}
            required={required}
            label={label}
            disabledLabel={disabledLabel}
            component={emailInput}
            validateOnTouch={validateOnTouch}
            onChange={onChange}
            alwaysShowOn={alwaysShowOn}
            tooltip={tooltip}
            parse={identity}
            validate={validate}
            suggestionsList={suggestionsList}
            removeSuggestion={removeSuggestion}
            suggestionIcon={suggestionIcon}
        />
    );
};
