import React from 'react';
import cx from 'classnames';
import { Field, FieldRenderProps } from 'react-final-form';
import * as RadixSelect from '@radix-ui/react-select';

import styles from './Select.module.scss';
import Card, { CardShadow, CardStyles } from '../../../../../common/components/cards/Card';
import Icon, { Icons } from '../../../../../common/components/Icon';

type SelectFieldProps = {
    name: string;
    label: string;
    options: {
        value: string;
        label: string;
    }[];
    parentRef: React.RefObject<HTMLElement>;
    disabled?: boolean;
    className?: string;
    // Spread props for react-tooltip
    [rest: string]: any;
};

type SelectProps = SelectFieldProps & FieldRenderProps<string, HTMLElement>;

const Select: React.FC<SelectProps> = ({ className, input, options, label, parentRef, disabled, ...rest }) => {
    return (
        <div className={cx(styles.select, className, { [styles.disabled]: disabled })} {...rest}>
            <label className={styles.label} htmlFor={input.name}>{`${label}\u00A0`}</label>
            <RadixSelect.Root
                disabled={disabled}
                name={input.name}
                value={input.value}
                onValueChange={value => {
                    input.onChange(value);
                }}
                onOpenChange={isOpen => {
                    if (isOpen) {
                        input.onFocus();
                    } else {
                        input.onBlur();
                    }
                }}
            >
                <RadixSelect.Trigger className={styles.trigger}>
                    <RadixSelect.Value />
                    <RadixSelect.Icon>
                        <Icon icon={Icons.CHEVRON_DOWN} />
                    </RadixSelect.Icon>
                </RadixSelect.Trigger>
                <RadixSelect.Portal container={parentRef?.current}>
                    <RadixSelect.Content position="popper">
                        <Card
                            className={styles.options}
                            shadow={CardShadow.SMALL}
                            style={CardStyles.SQUARE}
                            animate={false}
                        >
                            {options.map(option => (
                                <RadixSelect.Item
                                    key={`${input.name}-${option.value}`}
                                    className={styles.option}
                                    value={option.value}
                                >
                                    <RadixSelect.ItemText>{option.label}</RadixSelect.ItemText>
                                </RadixSelect.Item>
                            ))}
                        </Card>
                    </RadixSelect.Content>
                </RadixSelect.Portal>
            </RadixSelect.Root>
        </div>
    );
};

const SelectField: React.FC<SelectFieldProps> = props => {
    return <Field component={Select} {...props} />;
};

export default SelectField;
