import ListIcon from '@flk-mui-icons/ListSharp';
import '../../sass/lightTooltip.scss';
import '../../sass/auditTrailButton.scss';
import ReactTooltip from 'react-tooltip';
import React from 'react';
import { useDispatch } from 'react-redux';
import { openAuditTrailModal } from '../../actions/dashboard';

const AuditTrailOpenButton = props => {
    const dispatch = useDispatch();

    const openAuditTrail = () => {
        dispatch(openAuditTrailModal(props.id, props.isDocument));
    };

    return (
        <div className="audit-trail-button for-lg-modal" onClick={openAuditTrail} data-tip={true} data-for="auditTrail">
            {!props.showText && (
                <React.Fragment>
                    <ListIcon>Audit</ListIcon>
                    <ReactTooltip id="auditTrail" class="tooltip" place="bottom" effect="solid" globalEventOff="wheel">
                        <p>Audit trail for agreement</p>
                    </ReactTooltip>
                </React.Fragment>
            )}
            {props.showText && (
                <span className="special-link">
                    <ListIcon>Audit</ListIcon>
                    <span>View audit trail</span>
                </span>
            )}
        </div>
    );
};

export default AuditTrailOpenButton;
