import ReactTooltip from 'react-tooltip';
import React, { memo } from 'react';
import { InfoSharp } from '@flk-mui-icons';

const TemplatesToolTip = () => {
    return (
        <React.Fragment>
            <InfoSharp className="tooltip-info-icon text-gray " data-tip={true} data-for="defaultDocsLinksInfo" />
            <ReactTooltip
                id="defaultDocsLinksInfo"
                class="tooltip"
                place="bottom"
                effect="solid"
                globalEventOff="wheel"
            >
                <div>
                    <div className="info-segment">
                        By using templates, your team
                        <br />
                        members can save valuable
                        <br />
                        time when creating new
                        <br />
                        agreements.
                    </div>
                </div>
            </ReactTooltip>
        </React.Fragment>
    );
};

export default memo(TemplatesToolTip);
