import React, { memo, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { cloneDeep, has } from 'lodash';

import * as Lease from '../../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../../selectors/lease';
import { getPropertyDescription } from '../../../../selectors/lease/salesLease';

import { FormTextMultiline, FormTextRegular } from '../../../../components/form/FormText';
import { FormRadioGroup } from '../../../../components/form/FormRadioGroup';
import { SelectField } from '../../../../components/form/FormSelect';
import { CheckboxCheck } from '../../../../components/form/FormCheckboxCheck';
import { FormYesNoCheckbox } from '../../../../components/form/responsive/FormSingleCheckbox';

const PROPERTY_DETAILS_FORM = 'propertyDescriptionForm';

const bedroomsOptions = [
    {
        label: '0',
        value: '0'
    },
    {
        label: 'S',
        value: 'S'
    },
    {
        label: '1',
        value: '1'
    },
    {
        label: '2',
        value: '2'
    },
    {
        label: '3',
        value: '3'
    },
    {
        label: '4+',
        value: '4+'
    }
];

const bathroomsOptions = [
    {
        label: '0',
        value: '0'
    },
    {
        label: '1',
        value: '1'
    },
    {
        label: '2',
        value: '2'
    },
    {
        label: '3',
        value: '3'
    },
    {
        label: '4',
        value: '4'
    },
    {
        label: '5+',
        value: '5+'
    }
];

const defaultOptions = [
    {
        label: '0',
        value: '0'
    },
    {
        label: '1',
        value: '1'
    },
    {
        label: '2',
        value: '2'
    },
    {
        label: '3',
        value: '3'
    },
    {
        label: '4+',
        value: '4+'
    }
];

const radioGroupFields = [
    {
        name: 'bedrooms',
        label: 'Bedrooms*',
        options: bedroomsOptions
    },
    {
        name: 'bathrooms',
        label: 'Bathrooms*',
        options: bathroomsOptions
    },
    {
        name: 'noOfCarSpaces',
        label: 'No. of car spaces*',
        options: defaultOptions
    },
    {
        name: 'lounge',
        label: 'Lounge*',
        options: defaultOptions
    },
    {
        name: 'dining',
        label: 'Dining*',
        options: defaultOptions
    },
    {
        name: 'rumpus',
        label: 'Rumpus/Family*',
        options: defaultOptions
    },
    {
        name: 'media',
        label: 'Media*',
        options: defaultOptions
    },
    {
        name: 'kitchen',
        label: 'Kitchen*',
        options: defaultOptions
    },
    {
        name: 'study',
        label: 'Study*',
        options: defaultOptions
    }
];

const isAirConditioningTypes = [
    {
        label: 'Split',
        value: 'Split'
    },
    {
        label: 'Ducted',
        value: 'Ducted'
    },
    {
        label: 'Not known',
        value: 'Not known'
    }
];
const yesNoFields = [
    {
        name: 'isGym',
        label: 'Gym'
    },
    {
        name: 'isSecurityAlarm',
        label: 'Security Alarm'
    },
    {
        name: 'isIntercom',
        label: 'Intercom'
    },
    {
        name: 'isElectricity',
        label: 'Electricity'
    },
    {
        name: 'isGas',
        label: 'Gas'
    },
    {
        name: 'isGasInStreet',
        label: 'Gas in Street'
    },
    {
        name: 'isCableTv',
        label: 'Cable TV'
    },
    {
        name: 'isCableInStreet',
        label: 'Cable in Street'
    },
    {
        name: 'isInternet',
        label: 'Internet'
    },
    {
        name: 'isPhone',
        label: 'Phone'
    },
    {
        name: 'isTownWater',
        label: 'Town Water'
    },
    {
        name: 'isSewerageManagement',
        label: 'Sewerage Management'
    },
    {
        name: 'isSeptic',
        label: 'Septic'
    },
    {
        name: 'isHeating',
        label: 'Heating'
    },
    {
        name: 'isFireplace',
        label: 'Fireplace'
    },
    {
        name: 'isFans',
        label: 'Fans'
    },
    {
        name: 'isAirConditioning',
        label: 'Air-Conditioning',
        options: isAirConditioningTypes,
        optionsName: 'isAirConditioningType',
        optionsLabel: 'Air-Conditioning type'
    }
];

const checkboxFields = [
    {
        name: 'isShed',
        label: 'Shed'
    },
    {
        name: 'isFences',
        label: 'Fences'
    },
    {
        name: 'isSwimmingPool',
        label: 'Swimming pool'
    },
    {
        name: 'isSauna',
        label: 'Sauna'
    },
    {
        name: 'isSpa',
        label: 'Spa'
    },
    {
        name: 'isTennisCourt',
        label: 'Tennis Court'
    },
    {
        name: 'isWaterfront',
        label: 'Waterfront'
    },
    {
        name: 'isJetty',
        label: 'Jetty'
    }
];

const textFields = [
    {
        name: 'constructionOfProperty',
        label: 'Construction of property'
    },
    {
        name: 'roofMaterials',
        label: 'Roof materials'
    },
    {
        name: 'styleOfProperty',
        label: 'Style of property'
    },
    {
        name: 'interiorWalls',
        label: 'Interior walls'
    },
    {
        name: 'hotWaterSystem',
        label: 'Hot water system'
    },
    {
        name: 'outlook',
        label: 'Outlook'
    }
];

const PropertyDescription = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxPropertyDescription = useSelector(getPropertyDescription);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [propertyDescription, setPropertyDescription] = useState(reduxPropertyDescription || {});

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(PROPERTY_DETAILS_FORM).dispatchEvent(
                new Event('submit', {
                    cancelable: true,
                    bubbles: true
                })
            );
        }
    }));

    const handleFormDirtyChange = form => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updatePropertyDescription(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_PROPERTY_DESCRIPTION_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.propertyDescription')) {
                    return error.response.data.errors.propertyDescription;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const updatePropertyDescription = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/property-description`, data);
    };

    useEffect(() => {
        const newPropertyDescription = cloneDeep(propertyDescription);
        setPropertyDescription(newPropertyDescription);
    }, []);

    return (
        <div className="form-container propertyDescription">
            <Form onSubmit={submitForm} initialValues={propertyDescription}>
                {({ handleSubmit, values, form }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={PROPERTY_DETAILS_FORM}>
                            <FormSpy subscription={{ values: true }} onChange={() => handleFormDirtyChange(form)} />
                            <div className="formBox">
                                <h3>Please complete the following</h3>
                                <br />
                                <div className="forced-mobile-view">
                                    {radioGroupFields.map((radioGroupField, index) => (
                                        <>
                                            <FormRadioGroup
                                                key={index}
                                                label={radioGroupField.label}
                                                radioGroupClass="button-group-for-select"
                                                isYesNoGroup={true}
                                                name={radioGroupField.name}
                                                data={radioGroupField.options}
                                                value={values[radioGroupField.name]}
                                            />
                                            <br />
                                        </>
                                    ))}
                                </div>
                                {yesNoFields.map((yesNoField, index) => (
                                    <>
                                        <FormYesNoCheckbox key={index} text={yesNoField.label} name={yesNoField.name} />
                                        {yesNoField.options && values[yesNoField.name] && (
                                            <SelectField
                                                label={yesNoField.optionsLabel}
                                                name={yesNoField.optionsName}
                                                options={yesNoField.options}
                                            />
                                        )}
                                    </>
                                ))}
                                <h3>Outdoor features</h3>
                                <br />
                                {checkboxFields.map((checkboxField, index) => (
                                    <CheckboxCheck key={index} label={checkboxField.label} name={checkboxField.name} />
                                ))}
                                {textFields.map((textField, index) => (
                                    <FormTextRegular key={index} label={textField.label} name={textField.name} />
                                ))}
                                <FormTextMultiline name="appliances" label="Appliances (list all)" />
                                <FormTextMultiline name="locationFacilities" label="Location facilities (list all)" />
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(PropertyDescription);
