import * as React from 'react';
import { Table, Th, Tr, Td, Thead } from 'reactable';
import { getRole } from '../../../utils/userUtils';
import avatarImage from '../../../../assets/images/icons/avatar.png';
import PersonAddIcon from '@flk-mui-icons/PersonAddSharp';
import ReactTooltip from 'react-tooltip';

export default class Users extends React.Component {
    render() {
        const { team, showModal, loggedInUser } = this.props;
        return (
            <div className="team-users">
                {!team.length && (
                    <div className="add-users">
                        <p>You don’t have users yet.</p>
                        <button className="btn-add-user" onClick={() => showModal('add')}>
                            Add new user
                        </button>
                        <button className="btn-add-user" onClick={() => showModal('add-existing')}>
                            Add existing user
                        </button>
                    </div>
                )}
                {!!team.length && (
                    <div className="team-block">
                        <div className="name">
                            <p>Users</p>
                            <div className="add-user">
                                <p onClick={() => showModal('add-existing')}>
                                    <PersonAddIcon />
                                    Add existing user
                                </p>
                                <p className="add-new-user" onClick={() => showModal('add')}>
                                    <PersonAddIcon />
                                    Add new user
                                </p>
                            </div>
                        </div>
                        <div className="table-sort table">
                            <ReactTooltip
                                id="thisisyou"
                                class="tooltip"
                                place="top"
                                effect="solid"
                                globalEventOff="wheel"
                            >
                                <p>
                                    This is you, if you would like to update your details, please go to profile settings
                                </p>
                            </ReactTooltip>
                            <Table
                                sortable={[
                                    {
                                        column: 'status',
                                        sortFunction: function(a, b) {
                                            return a.props.children > b.props.children ? 1 : -1;
                                        }
                                    },
                                    {
                                        column: 'name',
                                        sortFunction: function(a, b) {
                                            return a.props.children < b.props.children ? 1 : -1;
                                        }
                                    },
                                    {
                                        column: 'role',
                                        sortFunction: function(a, b) {
                                            return a.props.children > b.props.children ? 1 : -1;
                                        }
                                    }
                                ]}
                                defaultSort={{ column: 'name', direction: 'desc' }}
                            >
                                <Thead>
                                    <Th column="avatar">&nbsp;</Th>
                                    <Th column="name" className="sort">
                                        <b>name</b>
                                    </Th>
                                    <Th column="email">
                                        <b>email</b>
                                    </Th>
                                    <Th column="number">
                                        <b>phone</b>
                                    </Th>
                                    <Th column="role" className="sort">
                                        <b>role</b>
                                    </Th>
                                    <Th column="status" className="sort">
                                        <b>status</b>
                                    </Th>
                                </Thead>
                                {team.map((item, index) => (
                                    <Tr
                                        data-tip
                                        data-for={item.id === loggedInUser.id ? 'thisisyou' : ''}
                                        key={index}
                                        onClick={() => {
                                            if (item.id !== loggedInUser.id) {
                                                return showModal('edit', item);
                                            }
                                        }}
                                    >
                                        <Td column="avatar">
                                            <div>
                                                {item.avatar && (
                                                    <div className="user-avatar">
                                                        <img src={avatarImage} alt="avatar" />
                                                    </div>
                                                )}
                                                {!item.avatar && (
                                                    <div className="user-initials">
                                                        {item.firstName[0]}
                                                        {item.secondName[0]}
                                                    </div>
                                                )}
                                            </div>
                                        </Td>
                                        <Td column="name" className="sort">
                                            <b className="capitalize">
                                                {' '}
                                                {`${item.firstName ? item.firstName : ''} ${
                                                    item.secondName ? item.secondName : ''
                                                }`}
                                            </b>
                                        </Td>
                                        <Td column="email">{item.email ? item.email : ''}</Td>
                                        <Td column="number">{item.phone ? item.phone : ''}</Td>
                                        <Td column="role">
                                            <div className="icon-block">
                                                <i className={`icon-${item.role}`} />
                                                {getRole(item.role)}
                                            </div>
                                        </Td>
                                        <Td column="status" className="sort">
                                            <span className={`icon-${item.status}`}>{item.status}</span>
                                        </Td>
                                    </Tr>
                                ))}
                            </Table>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
