import * as React from 'react';
import createMuiIcon from '../createMuiIcon';

/**
 * @deprecated This Material UI icon is deprecated. Please use an icon from our design system instead.
 * Contact the design team for the appropriate replacement icon.
 */
export default createMuiIcon(
    <React.Fragment>
        <path d="M17.75 8L14 4.25l-10 10V18h3.75l10-10zm3.66-3.66L17.66.59 15 3.25 18.75 7l2.66-2.66z" />
        <path fillOpacity=".36" d="M0 20h24v4H0v-4z" />
    </React.Fragment>,
    'BorderColorSharp'
);
