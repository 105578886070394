import React, { memo, useEffect, useImperativeHandle, useState, forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { cloneDeep, concat, has, includes } from 'lodash';
import axios from 'axios';

import { getSpecialConditionsTemplateList } from '../../../actions/specialConditions';
import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../actions/lease';

import { SelectField } from '../../../components/form/FormSelect';
import { CheckboxCheck } from '../../../components/form/FormCheckboxCheck';
import { ConditionsGroup } from '../../../components/lease/mainScreen/common/ConditionsGroup';
import { confirmAlert } from 'react-confirm-alert';

import { getSpecialConditionsTemplateListForLeaseType } from '../../../selectors/specialConditions';
import {
    getLocation,
    getLeaseSpecialConditions,
    getLeaseType,
    getStep,
    getDirtyStep,
    getValidationErrors
} from '../../../selectors/lease';

import * as Lease from '../../../reducers/lease';

import { ReactComponent as plusIcon } from '../../../../assets/images/icons/plus.svg';
import '../../../sass/conditions.scss';
import {
    isDocTypeRentRelief,
    isLeaseTypeResidentialTenancy,
    isLeaseTypeSales,
    isLeaseTypePm,
    isLeaseTypeCommercialLease,
    isLeaseLocationNSW
} from '../../../utils/agreementUtils';
import { OPTION_TYPE_AGREE_ONLY, OPTION_TYPE_YES_OR_NO } from '../../../config';
import { ToggleButton } from '../../../components/form/FormToggleButton';

const SPECIAL_CONDITIONS_FORM = 'specialConditionsForm';
const initState = {
    onePageVersion: false,
    useTemplate: false,
    template: 'template 1',
    clauseList: []
};

const Conditions = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const reduxLeaseConditions = useSelector(getLeaseSpecialConditions);
    const specialConditionsTemplateList = useSelector(state =>
        getSpecialConditionsTemplateListForLeaseType(state, leaseType)
    );
    const validationErrors = useSelector(getValidationErrors);

    let [conditions, setConditions] = useState(reduxLeaseConditions || initState);
    let [selectedTemplateList, setSelectedTemplateList] = useState([]);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(SPECIAL_CONDITIONS_FORM).dispatchEvent(
                new Event('submit', {
                    cancelable: true,
                    bubbles: true
                })
            );
        }
    }));

    useEffect(() => {
        dispatch(getSpecialConditionsTemplateList());
    }, []);

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const updateSpecialConditions = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/special-conditions`, data);
    };

    const submitForm = values => {
        /**
         * Store the ref of the component so it can be used in this function
         * We need this otherwise the promise below doesn't have access to the ref
         */
        const currentRef = ref.current;
        /**
         * Grab the bypassFormValidation that was set from the parent component: LeaseAgreementForm.js
         */
        const data = cloneDeep(values);
        data.bypassFormValidation = currentRef.bypassFormValidation;

        return updateSpecialConditions(data)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    /**
                     * Callback after submit this form so that the parent component can take an action
                     */
                    //callbackAfterSubmit is not there in pm and sales
                    if (currentRef && currentRef.callbackAfterSubmit) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.specialConditions')) {
                    return error.response.data.errors.specialConditions;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const addClauseListFromTemplate = (templateObject, values) => {
        const template = cloneDeep(specialConditionsTemplateList[templateObject.value]);
        template.clauseList.forEach(clause => {
            if (clause._id) {
                delete clause._id;
                if (!clause.optionType) {
                    clause.optionType = getOptionType();
                }
            }
            clause.templateName = template.name;
        });

        let newClauseList = concat(values.clauseList, template.clauseList);
        setConditions({
            ...values,
            clauseList: newClauseList
        });
    };

    const selectTemplate = (templateObject, values) => {
        if (includes(selectedTemplateList, templateObject.value)) {
            confirmAlert({
                title: '',
                message: 'You have already added this template, are you sure you want to add it again?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            addClauseListFromTemplate(templateObject, values);
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {}
                    }
                ]
            });
        } else {
            const newTemplateList = cloneDeep(selectedTemplateList);
            newTemplateList.push(templateObject.value);
            setSelectedTemplateList(newTemplateList);
            addClauseListFromTemplate(templateObject, values);
        }
    };

    const templateOptions = specialConditionsTemplateList.map((item, index) => {
        return {
            value: index,
            label: item.name
        };
    });

    const removeConditions = (index, values) => {
        let newConditions = cloneDeep(values);

        newConditions.clauseList = [
            ...newConditions.clauseList.slice(0, index),
            ...newConditions.clauseList.slice(index + 1)
        ];

        setConditions(newConditions);
    };

    function getOptionType() {
        if (isLeaseTypePm(leaseType)) {
            return OPTION_TYPE_YES_OR_NO;
        }
        return OPTION_TYPE_AGREE_ONLY;
    }

    return (
        <div className="conditions">
            <Form
                onSubmit={submitForm}
                initialValues={conditions}
                mutators={{
                    ...arrayMutators
                }}
                // this validation is used for pm and sales
                validate={() => {
                    return validationErrors && validationErrors.specialConditions
                        ? validationErrors.specialConditions
                        : {};
                }}
            >
                {({
                    handleSubmit,
                    values,
                    form,
                    form: {
                        mutators: { push }
                    }
                }) => {
                    return (
                        <form id={SPECIAL_CONDITIONS_FORM} onSubmit={handleSubmit} noValidate>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            {templateOptions && templateOptions.length > 0 && (
                                <ToggleButton
                                    label="Use template?"
                                    name="useTemplate"
                                    value={false}
                                    className="use-template"
                                />
                            )}
                            {values.useTemplate && (
                                <SelectField
                                    name="template"
                                    options={templateOptions}
                                    onChange={template => selectTemplate(template, values)}
                                    selectClassName="sel-6"
                                />
                            )}
                            {isLeaseTypeSales(leaseType) && isLeaseLocationNSW(location) && (
                                <CheckboxCheck name="isCoolingOffPeriodWaived" label="Cooling off waived" />
                            )}
                            {!values.onePageVersion && (
                                <div>
                                    <FieldArray name="clauseList" initialValue={conditions.clauseList}>
                                        {({ fields }) => (
                                            <React.Fragment>
                                                {fields.map((name, index) => {
                                                    return (
                                                        <ConditionsGroup
                                                            index={index}
                                                            item={fields.value[index]}
                                                            key={index}
                                                            removeConditions={index => removeConditions(index, values)}
                                                            values={values}
                                                            leaseType={leaseType}
                                                            isSelectOptionType={
                                                                !isLeaseTypeResidentialTenancy(leaseType) &&
                                                                !isLeaseTypeCommercialLease(leaseType) &&
                                                                !isDocTypeRentRelief(leaseType)
                                                            }
                                                        />
                                                    );
                                                })}
                                            </React.Fragment>
                                        )}
                                    </FieldArray>
                                    <div className="button">
                                        <button
                                            type="button"
                                            className="add-clause mobile add-item"
                                            onClick={() =>
                                                push('clauseList', {
                                                    clause: '',
                                                    comment: '',
                                                    optionType: getOptionType()
                                                })
                                            }
                                        >
                                            <img src={plusIcon} className="for-sm-modal" />
                                            Add clause
                                        </button>
                                    </div>
                                </div>
                            )}
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(Conditions);
