import { HelpSharp } from '@flk-mui-icons';
import WistiaEmbed from '../../../common/components/WistiaEmbed';
import React from 'react';
import { FLK_A_PDF_DISPLAY } from '../../../config';
import { useAccountProvider } from '../../../components/providers/AccountProvider';
import { isBusinessUserAccount } from '../../../utils/userUtils';

export function FlkAPdfHelp() {
    const accountType = useAccountProvider();
    return (
        <div className="help-message help-message-and-video">
            <HelpSharp />
            <p className="description">
                {FLK_A_PDF_DISPLAY} will allow you to upload any PDF and send it to your client for signing or
                acknowledgement. Watch the video below to see how simple it is to use.
            </p>
            {isBusinessUserAccount(accountType) && <WistiaEmbed hashedId="lo5evuy9wl" width="300" height="200" />}
            {!isBusinessUserAccount(accountType) && <WistiaEmbed hashedId="lo5evuy9wl" width="300" height="200" />}
        </div>
    );
}
