import React from 'react';
import {
    MuiAccordion,
    MuiAccordionSummary,
    MuiAccordionDetails
} from '@app/common/components/mui-accordion/MuiAccordion';
import ExpandMoreIcon from '@flk-mui-icons/ExpandMore';
import parse from 'html-react-parser';
import cx from 'classnames';
import { NO_LABEL, YES_LABEL } from '../../../../../../config';

const ANSWER_VALUE_TRUE = 'true';
const ADDITIONAL_MATERIAL_FACTS_QUESTION_ID = 'additional_material_facts';

export default function AgreementSalesClientInstructions({ leaseAllInfo }) {
    const { signatory } = leaseAllInfo;

    return (
        <div>
            {signatory.primaryVendor && (
                <div className="section client-answers-section">
                    <h2>Client instructions</h2>
                    <p>Has the client...</p>
                    <br />
                    {signatory.salesAnswersSummary && renderMaterialFacts()}
                    {signatory.primaryVendor?.salesInsuranceAnswers && renderInsurance()}
                    {signatory.primaryVendor?.salesOutgoingsAnswers && renderOutgoings()}
                </div>
            )}
        </div>
    );

    function renderMaterialFacts() {
        const answers = signatory?.primaryVendor?.salesMaterialFactAnswers || {};
        const isAnswered = answers?.isAnswered;
        return (
            <MuiAccordion
                className={cx({
                    'fixed-accordian': !isAnswered
                })}
            >
                <MuiAccordionSummary
                    expandIcon={isAnswered && <ExpandMoreIcon />}
                    aria-controls="material-content"
                    id="material-header"
                >
                    Declared a material fact? &nbsp;
                    <b>{signatory?.salesAnswersSummary?.hasMaterialFacts}</b>
                </MuiAccordionSummary>
                <MuiAccordionDetails>
                    <ul className="bullets-list push-left-40">
                        {answers?.questions?.map((fact, index) => {
                            if (fact.parentQuestion && fact.parentQuestion.answer === ANSWER_VALUE_TRUE) {
                                return (
                                    <li key={index}>
                                        {parse(fact.questionText)}{' '}
                                        <b>{fact.answer === ANSWER_VALUE_TRUE ? YES_LABEL : NO_LABEL}</b>
                                        <p>
                                            <i>{fact.details}</i>
                                        </p>
                                        <br />
                                    </li>
                                );
                            } else if (fact.answer === ANSWER_VALUE_TRUE) {
                                return (
                                    <li key={index}>
                                        {parse(fact.questionText)}{' '}
                                        <b>{fact.answer === ANSWER_VALUE_TRUE ? YES_LABEL : NO_LABEL}</b>
                                        <p>
                                            <i>{fact.details}</i>
                                        </p>
                                        {fact.questionId === ADDITIONAL_MATERIAL_FACTS_QUESTION_ID &&
                                            renderClientAddedMaterialFacts(answers.clientAddedItems)}
                                        <br />
                                    </li>
                                );
                            }
                        })}
                    </ul>
                </MuiAccordionDetails>
            </MuiAccordion>
        );
    }

    function getPublicLiabilityInsuranceQuestion(questions) {
        const publicLiabilityInsuranceQuestion = questions.find(i => i.questionId === '2');
        const isPublicLiabilityInsuranceQuestionAnswered =
            publicLiabilityInsuranceQuestion?.answer === ANSWER_VALUE_TRUE;

        const publicLiabilityInsuranceName = publicLiabilityInsuranceQuestion?.detailsList?.find(i => i.key === 'name');
        const publicLiabilityInsurancePolicyNumber = publicLiabilityInsuranceQuestion?.detailsList?.find(
            i => i.key === 'policy-number'
        );
        const publicLiabilityInsuranceExpiryDate = publicLiabilityInsuranceQuestion?.detailsList?.find(
            i => i.key === 'expiry-date'
        );
        const publicLiabilityInsuranceText = [
            `${publicLiabilityInsuranceName?.details}`,
            '-',
            publicLiabilityInsurancePolicyNumber?.details
                ? `Policy number ${publicLiabilityInsurancePolicyNumber?.details}`
                : null,
            publicLiabilityInsuranceExpiryDate?.details
                ? `expiring ${publicLiabilityInsuranceExpiryDate?.details}`
                : null
        ]
            .map(i => i)
            .join(' ');

        return {
            publicLiabilityInsuranceQuestion,
            isPublicLiabilityInsuranceQuestionAnswered,
            publicLiabilityInsuranceText
        };
    }

    function getLessorInsuranceQuestion(questions) {
        const lessorInsuranceQuestion = questions.find(i => i.questionId === '3');
        const isLessorInsuranceQuestionAnswered = lessorInsuranceQuestion?.answer === ANSWER_VALUE_TRUE;

        const lessorInsuranceName = lessorInsuranceQuestion?.detailsList?.find(i => i.key === 'name');
        const lessorInsurancePolicyNumber = lessorInsuranceQuestion?.detailsList?.find(i => i.key === 'policy-number');
        const lessorInsuranceExpiryDate = lessorInsuranceQuestion?.detailsList?.find(i => i.key === 'expiry-date');
        const lessorInsuranceText = [
            `${lessorInsuranceName?.details}`,
            '-',
            lessorInsurancePolicyNumber?.details ? `Policy number ${lessorInsurancePolicyNumber?.details}` : null,
            lessorInsuranceExpiryDate?.details ? `expiring ${lessorInsuranceExpiryDate?.details}` : null
        ]
            .map(i => i)
            .join(' ');

        return {
            lessorInsuranceQuestion,
            isLessorInsuranceQuestionAnswered,
            lessorInsuranceText
        };
    }

    function getAnyOtherInsuranceQuestion(questions) {
        const otherInsuranceQuestion = questions.find(i => i.questionId === '4');
        const isOtherInsuranceQuestionAnswered = otherInsuranceQuestion?.answer === ANSWER_VALUE_TRUE;

        return {
            otherInsuranceQuestion,
            isOtherInsuranceQuestionAnswered,
            otherInsuranceText: otherInsuranceQuestion?.details
        };
    }

    function renderInsurance() {
        const questions = signatory.primaryVendor?.salesInsuranceAnswers?.questions || [];
        const isAnswered = signatory.primaryVendor?.salesInsuranceAnswers?.isAnswered;
        const {
            publicLiabilityInsuranceQuestion,
            isPublicLiabilityInsuranceQuestionAnswered,
            publicLiabilityInsuranceText
        } = getPublicLiabilityInsuranceQuestion(questions);
        const { lessorInsuranceQuestion, isLessorInsuranceQuestionAnswered, lessorInsuranceText } =
            getLessorInsuranceQuestion(questions);
        const { otherInsuranceQuestion, isOtherInsuranceQuestionAnswered, otherInsuranceText } =
            getAnyOtherInsuranceQuestion(questions);

        return (
            <MuiAccordion
                className={cx({
                    'fixed-accordian': !isAnswered
                })}
            >
                <MuiAccordionSummary
                    expandIcon={isAnswered && <ExpandMoreIcon />}
                    aria-controls="material-content"
                    id="material-header"
                >
                    Entered insurance? &nbsp;
                    <b>{isAnswered ? YES_LABEL : NO_LABEL}</b>
                </MuiAccordionSummary>
                <MuiAccordionDetails>
                    <div>
                        {publicLiabilityInsuranceQuestion && (
                            <div>
                                Public Liability Insurance :
                                <b>{isPublicLiabilityInsuranceQuestionAnswered ? YES_LABEL : NO_LABEL}</b>
                                {isPublicLiabilityInsuranceQuestionAnswered && (
                                    <p>
                                        <i>{publicLiabilityInsuranceText}</i>
                                    </p>
                                )}
                                <br />
                            </div>
                        )}
                        {lessorInsuranceQuestion && (
                            <div>
                                Lessor Insurance :<b>{isLessorInsuranceQuestionAnswered ? YES_LABEL : NO_LABEL}</b>
                                {isLessorInsuranceQuestionAnswered && (
                                    <p>
                                        <i>{lessorInsuranceText}</i>
                                    </p>
                                )}
                                <br />
                            </div>
                        )}
                        {otherInsuranceQuestion && (
                            <div>
                                Other Insurance :<b>{isOtherInsuranceQuestionAnswered ? YES_LABEL : NO_LABEL}</b>
                                {isOtherInsuranceQuestionAnswered && (
                                    <p>
                                        <i>{otherInsuranceText}</i>
                                    </p>
                                )}
                                <br />
                            </div>
                        )}
                    </div>
                </MuiAccordionDetails>
            </MuiAccordion>
        );
    }

    function renderOutgoings() {
        const questions = signatory.primaryVendor?.salesOutgoingsAnswers?.questions || [];
        const clientAddedItems = signatory.primaryVendor?.salesOutgoingsAnswers?.clientAddedItems || [];
        const isAnswered = signatory.primaryVendor?.salesOutgoingsAnswers?.isAnswered;

        return (
            <MuiAccordion
                className={cx({
                    'fixed-accordian': !isAnswered
                })}
            >
                <MuiAccordionSummary
                    expandIcon={isAnswered && <ExpandMoreIcon />}
                    aria-controls="material-content"
                    id="material-header"
                >
                    Entered outgoings? &nbsp;
                    <b>{isAnswered ? YES_LABEL : NO_LABEL}</b>
                </MuiAccordionSummary>
                <MuiAccordionDetails>
                    <ul>
                        {questions.map((question, index) => {
                            const textIdMap = {
                                1: 'Strata outgoings',
                                2: 'Council outgoings',
                                3: 'Water outgoings'
                            };

                            return (
                                <li key={index}>
                                    <div>
                                        {question.answer === ANSWER_VALUE_TRUE ? (
                                            <p>
                                                {textIdMap[question.questionId]}&nbsp;{question.details}&nbsp;per
                                                quarter
                                            </p>
                                        ) : (
                                            <p>
                                                {textIdMap[question.questionId]}&nbsp;<b>{NO_LABEL}</b>
                                            </p>
                                        )}
                                    </div>
                                    <br />
                                </li>
                            );
                        })}
                        {clientAddedItems.map((outgoing, index) => (
                            <li key={index}>
                                <div>
                                    <p>
                                        {outgoing.questionText} - {outgoing.details} per quarter
                                    </p>
                                </div>
                                <br />
                            </li>
                        ))}
                    </ul>
                </MuiAccordionDetails>
            </MuiAccordion>
        );
    }

    function renderClientAddedMaterialFacts(clientAddedItems) {
        if (Array.isArray(clientAddedItems)) {
            return (
                <ul className="bullets-list push-left-40">
                    {clientAddedItems.map((item, index) => {
                        return (
                            <li key={index}>
                                <p>
                                    <b>Material Fact Disclosure {index + 1}</b>: {item.details}
                                </p>
                            </li>
                        );
                    })}
                </ul>
            );
        }
        return <></>;
    }
}
