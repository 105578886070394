import React from 'react';
import { useHistory } from 'react-router';
import cx from 'classnames';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import axios from 'axios';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { capitalize, isEqual } from 'lodash';

import { useAccountProvider } from '../../../../components/providers/AccountProvider.js';
import { isBusinessUserAccount } from '../../../../utils/userUtils.js';
import ModalDialog from '../../../../common/components/ModalDialog.js';
import { getCurrentDocument, getDocumentEditMode, getIsGlobalTemplate } from '../../../../selectors/document/index.js';
import { getIsOpenCreateFlkModal } from '../../../../selectors/document/createFlk.js';
import { getUserInfo } from '../../../../selectors/user.js';
import {
    LEASE_STATUS_AWAITING_COMPLETION,
    LEASE_STATUS_DRAFT,
    LEASE_STATUS_SENT_SIGNING
} from '../../../../actions/dashboard.js';
import {
    setCurrentDocument,
    addOrReplaceDocumentInList,
    removeDocumentInList,
    closeCreateFlkModal
} from '../../../../actions/document.js';
import { getTemplates } from '../../../../actions/template.js';
import BuildADocForm from './BuildADocForm';
import {
    CLOSE_MODAL_MESSAGE,
    CREATE_A_FLK_GLOBAL_TEMPLATE,
    CREATE_A_FLK_TEMPLATE,
    DOCUMENT_CREATE_A_FLK,
    FormSubmitType
} from '../../../../config';
import Icon, { Icons } from '../../../../common/components/Icon';
import { CreateFlkSummary } from '../createFlk/CreateFlkForm.js';
import CreateFlkFooter from '../createFlk/CreateFlkFooter.js';
import DocumentSignatures from '../DocumentSignatures.js';
import { DEFINE_ME } from '../../../../types/utilityTypes';
import {
    projectUiModel,
    projectBuildADocApiModel,
    buildADocFormSchema,
    projectFormModel,
    FormModel,
    ApiModel,
    initialiseFormModel
} from './BuildADoc.model';
import { getErrorObjectFromYupError, getHeaderTitle } from '../../../../utils/formUtils.js';
import {
    isDocumentStatusDraft,
    isDocumentTemplateReadOnlyMode,
    isDocumentTemplateMode,
    isDocumentEditMode
} from '../../../../utils/generalUtils.js';
import { BuildADocApi } from './BuildADoc.config';
import useDoubleSendGuard, { clearDoubleSendGuard } from '../../../../hooks/useDoubleSendGuard';
import useResendCounter from '../../../../hooks/useResendCounter';
import { DOC_COMPLETION_EMAIL_SEND_LIST, TEMPLATE_SCREEN_V2 } from '../../../../constants/featureFlags';
import DocumentTitleHeader from '../../DocumentTitleHeader.js';
import Button from '../../../../common/components/Button.js';
import PreviewIcon from '@flk-mui-icons/Visibility';
import ClosePreviewIcon from '@flk-mui-icons/VisibilityOff';
import { CreateFlkHelp } from '../../help/CreateFlkHelp.js';
import CreateFlkPreview from '../createFlk/CreateFlkPreview';
import CreateCreateAFlk from '../createFlk/CreateCreateAFlk.js';
import { NewDocumentHeader } from '../../../../components/dashboard/InfoAgreementComponents/NewDocumentHeaderLeft.js';
import { User } from '../../../../types/User';
import { agreementsSentAlert } from '../../../shared/DocumentConfirmAlertHelper.js';
import useToast from '../../../../hooks/useToast';
import { ToastTypes } from '../../../../common/components/Toast';
import appHistory from '../../../../AppHistory.js';
import { useMutation } from '@tanstack/react-query';

import styles from './BuildADocV2.module.scss';

const BuildADocV2 = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const accountType = useAccountProvider();
    const { addNewToast } = useToast();

    const isOpen = useSelector(getIsOpenCreateFlkModal);
    const loggedInUser: User = useSelector(getUserInfo);
    const buildADoc = useSelector(getCurrentDocument);
    const documentEditMode = useSelector(getDocumentEditMode);
    const isGlobalTemplate = useSelector(getIsGlobalTemplate);
    const isBusinessUser = isBusinessUserAccount(accountType);

    const isTemplateScreenV2Active = useFeatureFlag(TEMPLATE_SCREEN_V2);
    const isCompletionSendListActive = useFeatureFlag(DOC_COMPLETION_EMAIL_SEND_LIST);
    const doubleSendGuard = useDoubleSendGuard();
    const { resendWaitSeconds, startResendCounter } = useResendCounter();

    const dirtyRef = React.useRef(false);
    const submitTypeRef = React.useRef<FormSubmitType | undefined>();

    const showCreateForm = !buildADoc || buildADoc.isCreatedFromTemplate;
    const isTemplateMode = isDocumentTemplateMode(documentEditMode);
    const isEditMode = isDocumentEditMode(documentEditMode);
    const isReadOnlyTemplate = isDocumentTemplateReadOnlyMode(documentEditMode);

    // Hide preview when showing create form on initial render. This is later updated when the doc is successfully created
    const [showPreview, setShowPreview] = React.useState(!showCreateForm && (isEditMode || isTemplateMode));

    const [availableTemplates, setAvailableTemplates] = React.useState<DEFINE_ME[]>();

    // If we come to this component from cloning a template, we will already have a document with template data.
    // This is currently indicated by the `isCreatedFromTemplate` attribute.
    // We need to set this document as the selected template so that the form is pre-populated with the template data.
    const [selectedTemplate, setSelectedTemplate] = React.useState<DEFINE_ME>(
        buildADoc?.isCreatedFromTemplate ? projectFormModel(buildADoc) : undefined
    );
    const [selectedTemplateValue, setSelectedTemplateValue] = React.useState<DEFINE_ME>(
        buildADoc?.isCreatedFromTemplate
            ? {
                  label: buildADoc?.templateName,
                  value: buildADoc?.id
              }
            : undefined
    );

    const closeModal = () => {
        if (buildADoc) {
            if (isTemplateMode) {
                if (isTemplateScreenV2Active) {
                    history.push('/templates');
                } else {
                    history.push('/user/build-a-doc-templates');
                }
            } else {
                history.push(`/dashboard/documents/${buildADoc.docType}/${buildADoc.status}`);
            }
        } else {
            history.push(`/dashboard/documents/${DOCUMENT_CREATE_A_FLK}/${LEASE_STATUS_DRAFT}`);
        }
        dispatch(closeCreateFlkModal());
    };

    const showCloseModalWarning = (handleSave: () => void) => {
        confirmAlert({
            title: '',
            message: CLOSE_MODAL_MESSAGE,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        handleSave();
                        closeModal();
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        closeModal();
                    }
                },
                {
                    className: 'close close-modal',
                    label: <Icon icon={Icons.CLOSE} />,
                    onClick: () => {}
                }
            ]
        });
    };

    const handleCloseModal = (handleSave: () => void) => {
        if (dirtyRef.current && !showCreateForm) {
            showCloseModalWarning(handleSave);
        } else {
            closeModal();
        }
    };

    const saveDraft = async (values: ApiModel) => {
        try {
            const response = await axios.post(BuildADocApi.Save, values);
            dispatch(addOrReplaceDocumentInList(response.data.createFlkDocument));
            dispatch(setCurrentDocument(response.data.createFlkDocument));
        } catch (err) {
            return err;
        }
    };

    const createDocument = async (values: ApiModel) => {
        try {
            const response = await axios.post(BuildADocApi.Save, values);
            dispatch(addOrReplaceDocumentInList(response.data.createFlkDocument));
            dispatch(setCurrentDocument(response.data.createFlkDocument));
            history.replace(`/dashboard/document/${response.data.createFlkDocument.id}`);
            setShowPreview(true);
        } catch (err) {
            return err;
        }
    };

    const createTemplate = async (values: ApiModel) => {
        try {
            const response = await axios.post(BuildADocApi.SaveTemplate, values);
            dispatch(setCurrentDocument(response.data.createFlkTemplate));
            dispatch(getTemplates(isGlobalTemplate ? CREATE_A_FLK_GLOBAL_TEMPLATE : CREATE_A_FLK_TEMPLATE));
            history.replace(`/dashboard/document/${response.data.createFlkTemplate.id}`);
        } catch (err) {
            return err;
        }
    };

    const saveTemplate = async (values: ApiModel) => {
        try {
            const response = await axios.post(BuildADocApi.SaveTemplate, values);
            dispatch(getTemplates(isGlobalTemplate ? CREATE_A_FLK_GLOBAL_TEMPLATE : CREATE_A_FLK_TEMPLATE));
            dispatch(setCurrentDocument(response.data.createFlkTemplate));
        } catch (err) {
            return err;
        }
    };

    const scrollToError = () => {
        const firstErrorElement = document.querySelector('[data-has-error="true"]');

        if (firstErrorElement) {
            setTimeout(() => {
                firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 1000);
        }
    };

    const sendForSigning = async (values: ApiModel) =>
        doubleSendGuard(async () => {
            try {
                const response = await axios.post(BuildADocApi.Send, values);
                startResendCounter();
                dispatch(removeDocumentInList(response.data.signors[0].document, LEASE_STATUS_DRAFT));
                addNewToast('Document sent successfully', ToastTypes.SUCCESS, true);
                dispatch(closeCreateFlkModal());
                appHistory.push(`/dashboard/documents/${DOCUMENT_CREATE_A_FLK}/${LEASE_STATUS_SENT_SIGNING}`);
            } catch (err) {
                clearDoubleSendGuard();
                addNewToast('Document send failed', ToastTypes.ERROR, true);
                return err;
            }
        });

    const handleSubmit = async (values: FormModel) => {
        submitTypeRef.current = values.submitType;

        if (values.submitType === FormSubmitType.Save) {
            if (isTemplateMode) {
                return await saveTemplate(projectBuildADocApiModel(values));
            } else {
                return await saveDraft(projectBuildADocApiModel(values));
            }
        } else if (values.submitType === FormSubmitType.Create) {
            if (isTemplateMode) {
                return await createTemplate(projectBuildADocApiModel(values));
            } else {
                return await createDocument(projectBuildADocApiModel(values));
            }
        } else if (values.submitType === FormSubmitType.Send) {
            const shouldShowAlert = isBusinessUser && !buildADoc.firstSentForSigning;

            const result = shouldShowAlert
                ? await agreementsSentAlert(
                      projectBuildADocApiModel(values),
                      loggedInUser,
                      sendForSigning,
                      dispatch,
                      history
                  )
                : await sendForSigning(projectBuildADocApiModel(values));

            setShowPreview(false);

            return result;
        }
    };

    const getTemplate = useMutation(
        templateValue => {
            if (templateValue?.docType === DOCUMENT_CREATE_A_FLK) {
                return axios.get(`api/document/${templateValue.value}`);
            } else if ([CREATE_A_FLK_TEMPLATE, CREATE_A_FLK_GLOBAL_TEMPLATE].includes(templateValue?.docType)) {
                return axios.get(`api/template/${templateValue.value}`);
            }
        },
        {
            onSuccess: res => {
                const template = res.data.lease || res.data.doc;
                if (template) {
                    setSelectedTemplate({
                        ...projectFormModel(template),
                        meta: {
                            isGlobalTemplate: template.docType === CREATE_A_FLK_GLOBAL_TEMPLATE,
                            isDocumentAsTemplate: template.docType === DOCUMENT_CREATE_A_FLK
                        }
                    });
                } else {
                    setSelectedTemplateValue(undefined);
                    setSelectedTemplate(undefined);
                }
            },
            onError: () => {
                setSelectedTemplateValue(undefined);
                setSelectedTemplate(undefined);
            }
        }
    );

    const selectTemplate = (templateValue?: DEFINE_ME) => {
        if (templateValue) {
            getTemplate.mutate(templateValue);
        } else {
            setSelectedTemplateValue(undefined);
            setSelectedTemplate(undefined);
        }
    };

    return (
        <Form
            validate={async values => {
                try {
                    if (values.submitType === FormSubmitType.Send) {
                        await buildADocFormSchema.validate(values, { abortEarly: false });
                    }
                } catch (err) {
                    return getErrorObjectFromYupError(err);
                }
            }}
            onSubmit={handleSubmit}
            mutators={{ ...arrayMutators }}
            // FUTURE: This currently projects the form model every time the form is rendered. If this becomes a performance issue,
            // we can store this in a ref and update it from an efect when a relevant value changes.
            initialValues={
                !showCreateForm
                    ? projectFormModel(buildADoc, loggedInUser, isBusinessUser, isGlobalTemplate)
                    : initialiseFormModel(loggedInUser, isBusinessUser, selectedTemplate, isGlobalTemplate)
            }
            initialValuesEqual={(a, b) => isEqual(a, b)}
        >
            {({ handleSubmit, form, values, errors, dirty, submitting, submitFailed, submitSucceeded }) => {
                dirtyRef.current = dirty;

                const uiState = !showCreateForm
                    ? projectUiModel(
                          values,
                          errors,
                          isBusinessUser,
                          loggedInUser,
                          isTemplateMode,
                          isReadOnlyTemplate,
                          isGlobalTemplate
                      )
                    : undefined;

                return (
                    <ModalDialog
                        containerClassName={styles.modalContainer}
                        className={cx(styles.modal, { [styles.createModal]: showCreateForm })}
                        bodyClassName={cx({ [styles.createModalBody]: showCreateForm })}
                        modalHeadClassName={cx({ [styles.createModalHead]: showCreateForm })}
                        isOpen={isOpen}
                        closeModal={() =>
                            handleCloseModal(() => {
                                form.change('submitType', FormSubmitType.Save);
                                form.submit();
                            })
                        }
                        shouldBlockBodyScroll
                        customHeader
                        allowOverflow={showCreateForm}
                        customHeaderContent={() => {
                            let docType = DOCUMENT_CREATE_A_FLK;
                            if (isDocumentTemplateMode(documentEditMode)) {
                                docType = isGlobalTemplate ? CREATE_A_FLK_GLOBAL_TEMPLATE : CREATE_A_FLK_TEMPLATE;
                            }
                            return showCreateForm ? (
                                <NewDocumentHeader
                                    headerTitle={getHeaderTitle(DOCUMENT_CREATE_A_FLK)}
                                    className={styles.createModalHeading}
                                />
                            ) : (
                                <DocumentTitleHeader
                                    isReadOnlyTemplate={isDocumentTemplateReadOnlyMode(documentEditMode)}
                                    doc={buildADoc}
                                    closeModal={() =>
                                        handleCloseModal(() => {
                                            form.change('submitType', FormSubmitType.Save);
                                            form.submit();
                                        })
                                    }
                                    showHelpIcon
                                    helpComponent={<CreateFlkHelp />}
                                    isTemplate={isDocumentTemplateMode(documentEditMode)}
                                    docType={docType}
                                    showLockedIcon
                                    hideCloseButton={submitting}
                                    saveDoc={async () => {
                                        form.change('submitType', FormSubmitType.Save);
                                        return form.submit();
                                    }}
                                    customRightButton={
                                        (isEditMode || isTemplateMode) && (
                                            <Button
                                                className={styles.previewButton}
                                                startIcon={
                                                    showPreview ? (
                                                        <ClosePreviewIcon className={styles.previewIcon} />
                                                    ) : (
                                                        <PreviewIcon className={styles.previewIcon} />
                                                    )
                                                }
                                                onClick={() => setShowPreview(oldState => !oldState)}
                                                tertiary
                                            >
                                                {showPreview ? 'Close preview' : 'Preview'}
                                            </Button>
                                        )
                                    }
                                />
                            );
                        }}
                        sideModal={
                            <CreateFlkPreview
                                data={buildADoc}
                                onRefreshPreview={() => {
                                    form.change('submitType', FormSubmitType.Save);
                                    form.submit();
                                }}
                                isRefreshing={submitting}
                            />
                        }
                        sideModalIsOpen={showPreview}
                    >
                        <form className={styles.form} onSubmit={handleSubmit} noValidate>
                            {showCreateForm ? (
                                <CreateCreateAFlk
                                    className={styles.createForm}
                                    values={values}
                                    isTemplate={isTemplateMode}
                                    changeTemplate={(value?: { label: string; value: string }) => {
                                        selectTemplate(value);
                                    }}
                                    closeModal={() => dispatch(closeCreateFlkModal())}
                                    onClickStart={() => {
                                        form.change('submitType', FormSubmitType.Create);
                                        form.submit();
                                    }}
                                    setAvailableTemplates={setAvailableTemplates}
                                    selectedTemplate={selectedTemplateValue}
                                    loggedInUser={loggedInUser}
                                    isSendingCreateFlk={submitting}
                                    isLoadingTemplates={getTemplate.isLoading}
                                />
                            ) : (
                                <>
                                    {isDocumentStatusDraft(buildADoc.status) ? (
                                        <BuildADocForm form={form} uiState={uiState!} docId={buildADoc?.id} />
                                    ) : (
                                        <div className={styles.summary}>
                                            {buildADoc &&
                                                (buildADoc.status === LEASE_STATUS_AWAITING_COMPLETION ||
                                                    buildADoc.status === LEASE_STATUS_SENT_SIGNING) && (
                                                    <DocumentSignatures
                                                        label={capitalize(uiState!.to.clients?.[0].typeDisplayName)}
                                                        className={styles.documentSignatures}
                                                    />
                                                )}
                                            <CreateFlkSummary
                                                values={values}
                                                form={form}
                                                currentDocument={buildADoc}
                                                loggedInUser={loggedInUser}
                                                isCompletionSendListActive={isCompletionSendListActive}
                                            />
                                        </div>
                                    )}
                                    <div className={styles.footer}>
                                        <CreateFlkFooter
                                            closeModal={closeModal}
                                            values={values}
                                            currentDocument={buildADoc}
                                            isSendingCreateFlk={submitting}
                                            isSubmitFail={!submitting && submitFailed}
                                            // Only display success message when saving or sending
                                            isSubmitSuccessfull={
                                                !submitting &&
                                                !submitFailed &&
                                                submitSucceeded &&
                                                submitTypeRef.current !== FormSubmitType.Create
                                            }
                                            isSaveDraft={submitTypeRef.current === FormSubmitType.Save}
                                            form={form}
                                            isTemplate={isTemplateMode}
                                            resendWaitSeconds={resendWaitSeconds}
                                            documentEditMode={documentEditMode}
                                            onBeforeSend={() => {
                                                submitTypeRef.current === FormSubmitType.Send;
                                                // Use requestAnimationFrame so that scrollToError
                                                // fires after all state updates are complete
                                                requestAnimationFrame(() => {
                                                    scrollToError();
                                                });
                                            }}
                                        />
                                    </div>
                                </>
                            )}
                        </form>
                    </ModalDialog>
                );
            }}
        </Form>
    );
};

export default BuildADocV2;
