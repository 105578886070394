import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import '../../../sass/dashboardContentTable.scss';
import CheckedOutAndOverdueTable from './CheckedOutAndOverdueTable';
import CheckInTable from './CheckInTable';
import { FLK_A_KEY_STATUS_OVERDUE, FLK_A_KEY_STATUS_CHECKED_OUT } from '../../../actions/dashboard';
import { LockOpenSharp, EventSharp } from '@flk-mui-icons';
import Button from '../../../common/components/Button';
import { changeSelectAllKey, resetSelectKey, changeReloadKeyListStatus } from '../../../actions/flkAKey';
import { getSelectAll, getSelectedKeys, getAddressFilter } from '../../../selectors/flkAKey';
import useOnClickOutside from 'use-onclickoutside';
import { getSavedTeamMemberSelection } from '../../../selectors/user';
import { PreLoaderSmall } from '../../../common/components/PreLoaderSmall';
import { confirmAlert } from 'react-confirm-alert';

const FlkAKeyListings = props => {
    const dispatch = useDispatch();
    const handleClickOutside = event => {
        // close the list when list is open and clicked outside the list
        // ignore this if user click more-action-button button
        if (!event.path[0].className.includes('more-action-button') && showActionList) {
            setShowActionList(false);
        }
    };

    const ref = React.useRef(null);
    useOnClickOutside(ref, handleClickOutside);

    const { flkAKeyList, keyStatus } = props;
    const selectAll = useSelector(getSelectAll);
    const selectedKeys = useSelector(getSelectedKeys);
    const savedTeamMemberSelection = useSelector(getSavedTeamMemberSelection);
    const addressFilter = useSelector(getAddressFilter);

    const [showActionList, setShowActionList] = useState(false);
    const [isLoadingCheckIn, setIsLoadingCheckIn] = useState(false);
    const [isLoadingReminder, setIsLoadingReminder] = useState(false);

    function clickSelectAll(event) {
        event.stopPropagation();
        let selectedIdList = [];
        if (event.target.checked) {
            selectedIdList = flkAKeyList.map(flkaKey => flkaKey.id);
        }
        dispatch(changeSelectAllKey(event.target.checked, selectedIdList));
    }

    const bulkCheckIn = () => {
        confirmAlert({
            title: '',
            message: `Are you sure you want to check in the keys selected?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setIsLoadingCheckIn(true);
                        const data = {
                            selectedKeys
                        };
                        axios
                            .post(`/api/flk-a-key/bulk-check-in`, data)
                            .then(() => {
                                dispatch(changeReloadKeyListStatus(true));
                                setShowActionList(false);
                                setIsLoadingCheckIn(false);
                                dispatch(resetSelectKey());
                            })
                            .catch(() => {
                                setIsLoadingCheckIn(false);
                            });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    };

    const bulkSendReminder = () => {
        confirmAlert({
            title: '',
            message: `Are you sure you want to request updates to the keys selected?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setIsLoadingReminder(true);
                        const data = {
                            selectedKeys
                        };
                        axios
                            .post(`/api/flk-a-key/bulk-send-reminder`, data)
                            .then(() => {
                                dispatch(changeReloadKeyListStatus(true));
                                setShowActionList(false);
                                dispatch(resetSelectKey());
                            })
                            .finally(() => {
                                setIsLoadingReminder(false);
                            });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    };
    const disabled = selectedKeys.length === 0 || isLoadingCheckIn || isLoadingReminder;
    switch (keyStatus) {
        case FLK_A_KEY_STATUS_CHECKED_OUT: {
            return (
                <div className="table-sort">
                    <div className="action-buttons">
                        <input
                            className="checkbox-select"
                            type="checkbox"
                            onClick={clickSelectAll}
                            checked={selectAll}
                        />
                        <Button
                            className="more-action-button"
                            primary
                            height={30}
                            onClick={() => setShowActionList(!showActionList)}
                        >
                            Actions
                        </Button>
                        {showActionList && (
                            <div className="action-list single" ref={ref}>
                                <Button onClick={bulkCheckIn} disabled={disabled}>
                                    {isLoadingCheckIn && <PreLoaderSmall />}
                                    {!isLoadingCheckIn && <LockOpenSharp />}
                                    Bulk Check in
                                </Button>
                            </div>
                        )}
                    </div>
                    <CheckedOutAndOverdueTable flkAKeyList={flkAKeyList} />
                </div>
            );
        }
        case FLK_A_KEY_STATUS_OVERDUE: {
            return (
                <div className="table-sort">
                    <div className="action-buttons">
                        <input
                            className="checkbox-select"
                            type="checkbox"
                            onClick={clickSelectAll}
                            checked={selectAll}
                        />
                        <Button
                            className="more-action-button"
                            primary
                            height={30}
                            onClick={() => setShowActionList(!showActionList)}
                        >
                            Actions
                        </Button>
                        {showActionList && (
                            <div className="action-list" ref={ref}>
                                <Button onClick={bulkCheckIn} disabled={disabled}>
                                    {isLoadingCheckIn && <PreLoaderSmall />}
                                    {!isLoadingCheckIn && <LockOpenSharp />}
                                    Bulk Check in
                                </Button>
                                <Button onClick={bulkSendReminder} disabled={disabled}>
                                    {isLoadingReminder && <PreLoaderSmall />}
                                    {!isLoadingReminder && <EventSharp />}
                                    Request update
                                </Button>
                            </div>
                        )}
                    </div>
                    <CheckedOutAndOverdueTable flkAKeyList={flkAKeyList} isOverdue={true} />
                </div>
            );
        }
        default: {
            return (
                <div className="table-sort">
                    <CheckInTable flkAKeyList={flkAKeyList} />
                </div>
            );
        }
    }
};

export default memo(FlkAKeyListings);
