import React, { memo, useImperativeHandle, useState, forwardRef } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { FormRadioGroup } from '../../../../components/form/FormRadioGroup';
import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../../actions/lease';
import { useDispatch, useSelector } from 'react-redux';
import { getDirtyStep, getLeaseType, getStep, getDisclosure } from '../../../../selectors/lease';
import * as Lease from '../../../../reducers/lease';
import axios from 'axios';
import { has } from 'lodash';
import { YES_LABEL, NO_LABEL } from '../../../../config';

const DISCLOSURE_FORM = 'disclosureForm';

const Disclosure = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);
    const leaseType = useSelector(getLeaseType);
    const reduxDisclosure = useSelector(getDisclosure);

    let [formState, setFormState] = useState(reduxDisclosure);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(DISCLOSURE_FORM).dispatchEvent(
                new Event('submit', {
                    cancelable: true,
                    bubbles: true
                })
            );
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const updateInsurance = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/disclosure`, data);
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updateInsurance(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_DISCLOSURE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.disclosure')) {
                    return error.response.data.errors.disclosure;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    return (
        <div className="insurance">
            <Form onSubmit={submitForm} initialValues={formState}>
                {({ handleSubmit, form }) => {
                    return (
                        <form id={DISCLOSURE_FORM} onSubmit={handleSubmit} noValidate>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <FormRadioGroup
                                label="The Landlord has a present intention to sell the Property"
                                name="hasIntentionToSell"
                                className="full-width"
                                data={[
                                    {
                                        label: YES_LABEL,
                                        value: YES_LABEL
                                    },
                                    {
                                        label: NO_LABEL,
                                        value: NO_LABEL
                                    }
                                ]}
                            />
                            <p>
                                In accordance with section 47A of the Residential Tenancies Act 1995 (SA), the tenant/s
                                acknowledge that prior to entering into this residential tenancy agreement, they have
                                been informed that the landlord has advertised, or intends to advertise, the residential
                                premises for sale and of any existing sales agency agreement for the sale of the
                                residential premises.
                            </p>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(Disclosure);
